import { flatten, get, isArray, uniqBy, values } from 'lodash';

import {
  getAllModalities,
  getAllRoles,
  getProductByType,
  getTrashForUser,
  getRequestStates,
  getGridSettings,
  getSearchFilters,
  getArchives,
} from 'modules/Login/_api';
import {
  SEARCH_FUNCTION_SEARCH,
  SEARCH_FUNCTION_SEND,
  SEARCH_FUNCTION_REORDER,
  SEARCH_FUNCTION_SPLIT,
  SEARCH_FUNCTION_EDIT,
  SEARCH_FUNCTION_EDIT_PATIENT,
  SEARCH_FUNCTION_EDIT_STUDY,
  SEARCH_FUNCTION_COPY,
  SEARCH_FUNCTION_MOVE,
  SEARCH_FUNCTION_EXPORT,
  SEARCH_FUNCTION_IMPORT,
  SEARCH_FUNCTION_ARCHIVE,
  SEARCH_FUNCTION_UNSIGNED,
  SEARCH_FUNCTION_SCIENTIFIC_ARCHIVE,
  SEARCH_FUNCTION_ALL,
  NOTIFICATIONS_STATE_NEW,
  SEARCH_FUNCTION_DOSE_MONITORING,
} from 'constants/constants';
import { getAllFilterForUser } from 'components/SearchHeader/_api';
import { IArchive } from 'modules/Search/_types';
import { useAppDispatch } from 'store/hooks';
import {
  setAllowedArchivesForFunctions,
  setGridSettingsToStore,
  setListsToStore,
  setNewNotifications,
  setSearchFilter,
  setTags,
  setTrash,
  setWasRedirected,
  storeLastPageState,
} from 'store/reducers/appReducer';
import { getAllGroups } from 'modules/Tags/_api';
import { getAllNotificationsByStateWithLimit } from 'modules/websocketNotification/_api';
import { getStudyStatuses } from 'modules/Studies/StudyDetail/_api';

export const useAppLoader = () => {
  const dispatch = useAppDispatch();

  const loadAllModalitiesToStore = async () => {
    const modalities = await getAllModalities();
    if (isArray(modalities)) {
      dispatch(setListsToStore({ modalities }));
      const modNoShow: string[] = [];
      modalities.forEach((modality) => {
        if (get(modality, 'show', false) === false) {
          modNoShow.push(get(modality, 'name'));
        }
      });

      dispatch(setListsToStore({ modNoShow }));
    }
  };

  const loadAllStatusesToStore = async () => {
    const statuses = await getStudyStatuses();
    if (isArray(statuses)) {
      dispatch(setListsToStore({ statuses }));
    }
  };

  const loadAllWorkplaceRolesToStore = async () => {
    const roles = await getAllRoles();
    if (isArray(roles)) {
      dispatch(setListsToStore({ roles }));
    }
  };

  const loadAllFiltersToStore = async () => {
    const filters = await getAllFilterForUser();
    if (isArray(filters)) {
      dispatch(setListsToStore({ filters }));
    }
  };

  const loadAllProductsToStore = async () => {
    const otherProductType = 'realisator';
    const portalProductType = 'portal';
    const signatureProductType = 'signature';

    const [realisatorProducts, portalProducts, signatureProducts] = await Promise.all([
      getProductByType(otherProductType),
      getProductByType(portalProductType),
      getProductByType(signatureProductType),
    ]);

    const finalProducts = {
      ...(isArray(realisatorProducts) ? { [otherProductType]: realisatorProducts } : {}),
      ...(isArray(portalProducts) ? { [portalProductType]: portalProducts } : {}),
      ...(isArray(signatureProducts) ? { [signatureProductType]: signatureProducts } : {}),
    };

    dispatch(setListsToStore({ products: finalProducts }));
  };

  const loadTrashForUserAndStore = async () => {
    const trash = await getTrashForUser();
    if (trash !== false) {
      dispatch(setTrash(trash));
    }
  };

  const loadAllRequestStatesToStore = async () => {
    const requestStates = await getRequestStates();
    if (isArray(requestStates)) {
      dispatch(setListsToStore({ requestStates }));
    }
  };

  const loadGridSettingsToStore = async () => {
    const gridSettings = await getGridSettings();
    dispatch(setGridSettingsToStore(gridSettings));
  };

  const loadSearchFilterAndStore = async () => {
    const searchFilters = await getSearchFilters();
    dispatch(setSearchFilter(searchFilters));
  };

  const loadAllowedArchivesForFunctionsAndStore = async () => {
    const allowedArchives = await getArchives();

    const allUniqueArchives: IArchive[] = uniqBy(flatten(values(allowedArchives)), 'id');
    const allDpgwArchives: IArchive[] = [];
    allUniqueArchives.forEach((archive) => {
      const subtype = get(archive, 'type.languageKey', '');
      if (subtype === 'product.type.dpgw') {
        allDpgwArchives.push(archive);
      }
    });
    dispatch(
      setAllowedArchivesForFunctions({
        [SEARCH_FUNCTION_SEARCH]: get(allowedArchives, 'SEARCH', []),
        [SEARCH_FUNCTION_SEND]: get(allowedArchives, 'SEND', []),
        [SEARCH_FUNCTION_REORDER]: get(allowedArchives, 'REORDER', []),
        [SEARCH_FUNCTION_SPLIT]: get(allowedArchives, 'SPLIT', []),
        [SEARCH_FUNCTION_EDIT]: get(allowedArchives, 'EDIT', []),
        [SEARCH_FUNCTION_EDIT_PATIENT]: get(allowedArchives, 'EDIT', []),
        [SEARCH_FUNCTION_EDIT_STUDY]: get(allowedArchives, 'EDIT', []),
        [SEARCH_FUNCTION_COPY]: get(allowedArchives, 'COPY', []),
        [SEARCH_FUNCTION_MOVE]: get(allowedArchives, 'MOVE', []),
        [SEARCH_FUNCTION_EXPORT]: get(allowedArchives, 'EXPORT', []),
        [SEARCH_FUNCTION_IMPORT]: get(allowedArchives, 'IMPORT', []),
        [SEARCH_FUNCTION_ARCHIVE]: get(allowedArchives, 'ARCHIVATION', []),
        [SEARCH_FUNCTION_UNSIGNED]: get(allowedArchives, 'SIGNATURE', []),
        [SEARCH_FUNCTION_SCIENTIFIC_ARCHIVE]: get(allowedArchives, 'SCIENTIFICARCHIVE', []),
        [SEARCH_FUNCTION_DOSE_MONITORING]: allDpgwArchives,
        [SEARCH_FUNCTION_ALL]: allUniqueArchives,
      }),
    );
  };

  const storeLastPageStateToStore = (state: any) => dispatch(storeLastPageState(state));
  const storeRedirectionToStore = (state: boolean) => dispatch(setWasRedirected(state));

  const loadAllTagsToStore = async () => {
    const allTags = await getAllGroups();
    dispatch(setTags(allTags));
  };

  const loadNewNotifications = async () => {
    const newNotifications = await getAllNotificationsByStateWithLimit(NOTIFICATIONS_STATE_NEW);
    dispatch(setNewNotifications(newNotifications));
  };

  return {
    loadAllModalitiesToStore,
    loadAllStatusesToStore,
    loadAllWorkplaceRolesToStore,
    loadAllProductsToStore,
    loadTrashForUserAndStore,
    loadAllFiltersToStore,
    loadAllRequestStatesToStore,
    loadGridSettingsToStore,
    loadSearchFilterAndStore,
    loadAllowedArchivesForFunctionsAndStore,
    storeLastPageStateToStore,
    storeRedirectionToStore,
    loadAllTagsToStore,
    loadNewNotifications,
  };
};

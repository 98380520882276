import { get, filter, set } from 'lodash';
import dicomParser from 'dicom-parser';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { UppyFile } from '@uppy/core';
import { IParsedDicom, IPatient, UppyFileWithDicomTags } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';

let uppyInstance: any = null;

export const useDicomParser = ({ setPatients, setFiles }: any) => {
  const { t } = useTranslation('Import');
  const { toggleLoader } = useAppGlobals();

  const parseDicomFiles = async (dicomFiles: UppyFile[]): Promise<void> => {
    let newPatientsToSave: IPatient[] = [];
    let allowedFiles: any[] = [];
    toggleLoader(true);

    uppyInstance = (window as any).UppyGlobalInstances[
      (window as any).UppyGlobalInstances.length - 1
    ];

    await Promise.all(
      dicomFiles.map(async (dicomFile: UppyFile) => {
        if (get(dicomFile, 'data.name') === 'DICOMDIR') {
          const output = await dumpDICOMDIR(dicomFile);
          allowedFiles = get(output, 'images', []);
          uppyInstance.removeFile(dicomFile.id);
        }
      }),
    );

    await Promise.all(
      dicomFiles.map(async (dicomFile: UppyFile) => {
        if (allowedFiles.length > 0) {
          if (
            allowedFiles.some(
              (item) =>
                get(dicomFile, 'data.relativePath', '').indexOf(item) > 0 ||
                get(dicomFile, 'data.webkitRelativePath', '').indexOf(item) > 0,
            )
          ) {
            const parsedDicom: IParsedDicom = await parseDicomFile(dicomFile);
            const existingPatientsInNewFiles: IPatient = findExistingPatient(
              parsedDicom,
              newPatientsToSave,
            );

            if (existingPatientsInNewFiles) {
              updateExistingPatient(existingPatientsInNewFiles, parsedDicom);
            } else {
              const newPatient = createNewPatient(parsedDicom);
              newPatientsToSave.push(newPatient);
            }
          } else {
            uppyInstance.removeFile(dicomFile.id);
          }
        } else {
          const parsedDicom: IParsedDicom = await parseDicomFile(dicomFile);
          const existingPatientsInNewFiles: IPatient = findExistingPatient(
            parsedDicom,
            newPatientsToSave,
          );

          if (existingPatientsInNewFiles) {
            updateExistingPatient(existingPatientsInNewFiles, parsedDicom);
          } else {
            const newPatient = createNewPatient(parsedDicom);
            newPatientsToSave.push(newPatient);
          }
        }
      }),
    );
    toggleLoader(false);
    setPatients((currentPatientsInGrid: IPatient[]) => {
      return mergeNewPatientsToExisting(newPatientsToSave, [...currentPatientsInGrid]);
    });
  };

  const parseDicom = (file: UppyFile) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (file2) => {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer as ArrayBuffer);
        const options = {
          untilTag: 'x7fe00010',
        };
        try {
          const dataSet = dicomParser.parseDicom(byteArray, options);
          if (dataSet) {
            const dicm = dataSet.string('x00020010');
            const tags = dicm
              ? ['x00020010']
              : filter(Object.keys(get(dataSet, 'elements', {})), (item) => item.includes('x0008'));
            if (tags.length) {
              const instance = {
                name: dataSet.string('x00100010'),
                patID: dataSet.string('x00100020'),
                patID2: dataSet.string('x00100020') || uuid(),
                studyDate: dataSet.string('x00080020'),
                studyID: dataSet.string('x00200010'),
                studyDescription: dataSet.string('x00081030'),
                studyInstanceUid: dataSet.string('x0020000d') || uuid(),
                patSex: dataSet.string('x00100040'),
                dateBirth: dataSet.string('x00100030'),
                seriesID: dataSet.string('x0020000e'),
                seriesDescription: dataSet.string('x008103e'),
                instanceNumber: parseInt(dataSet.string('x00200013') || '', 10) || 999,
                serieNumber: parseInt(dataSet.string('x00200011') || '', 10),
                sopInstanceUid: dataSet.string('x00080018') || '',
              };
              resolve({ ...instance, size: file.size, file });
            } else {
              resolve({
                name: t('unknownFormat'),
                studyInstanceUid: 'unknown',
                patID: '',
                patID2: 'unknown',
                size: file.size,
                file,
              });
            }
          } else {
            resolve({
              name: t('unknownFormat'),
              studyInstanceUid: 'unknown',
              patID: '',
              patID2: 'unknown',
              size: file.size,
              file,
            });
          }
        } catch (e) {
          if (get(e, 'dataSet')) {
            const dataSet = get(e, 'dataSet') || ({} as dicomParser.DataSet);
            const dicm = dataSet.string('x00020010');
            const tags = dicm
              ? ['x00020010']
              : filter(Object.keys(get(dataSet, 'elements', {})), (item) => item.includes('x0008'));
            if (tags.length) {
              const instance = {
                name: dataSet.string('x00100010'),
                patID: dataSet.string('x00100020'),
                patID2: dataSet.string('x00100020') || uuid(),
                studyDate: dataSet.string('x00080020'),
                studyID: dataSet.string('x00200010'),
                studyDescription: dataSet.string('x00081030'),
                studyInstanceUid: dataSet.string('x0020000d') || uuid(),
                patSex: dataSet.string('x00100040'),
                dateBirth: dataSet.string('x0010030'),
                seriesID: dataSet.string('x0020000e'),
                seriesDescription: dataSet.string('x0008103e'),
                instanceNumber: parseInt(dataSet.string('x00200013') || '', 10) || 999,
                serieNumber: dataSet.string('x00200011') || '',
                sopInstanceUid: dataSet.string('x0080018') || '',
                // pixelDataElement: dataSet.elements['x7fe00010'],
              };
              resolve({ ...instance, size: file.size, file });
            } else {
              resolve({
                name: t('unknownFormat'),
                studyInstanceUid: 'unknown',
                patID: '',
                patID2: 'unknown',
                size: file.size,
                file,
              });
            }
          } else {
            resolve({
              name: t('unknownFormat'),
              studyInstanceUid: 'unknown',
              patID: '',
              patID2: 'unknown',
              size: file.size,
              file,
            });
            console.log(e);
          }
        }
      };
      reader.readAsArrayBuffer(file.data);
      // reader.readAsArrayBuffer(file.data.slice(0, 50000));
    });
  };

  const parseDicomFile = async (dicomFile: UppyFile): Promise<any> => {
    return await parseDicom(dicomFile);
  };

  const findExistingPatient = (parsedDicom: IParsedDicom, patients: IPatient[]): IPatient => {
    return patients.find(
      (patient: IPatient) =>
        get(patient, 'patID2', 1) === get(parsedDicom, 'patID2', 2) &&
        get(patient, 'studyInstanceUid', 1) === get(parsedDicom, 'studyInstanceUid', 2),
    ) as IPatient;
  };

  const createNewPatient = (parsedDicom: IParsedDicom): IPatient => {
    const {
      file,
      sopInstanceUid,
      serieNumber,
      seriesID,
      seriesDescription,
      instanceNumber,
      ...restOfParsedDicom
    } = parsedDicom;

    return {
      id: `${restOfParsedDicom.patID}_${restOfParsedDicom.studyInstanceUid}`,
      ...restOfParsedDicom,
      files: [
        {
          ...file,
          seriesID,
          serieNumber,
          seriesDescription,
          sopInstanceUid,
          instanceNumber,
        },
      ],
    };
  };

  const updateExistingPatient = (existingPatient: IPatient, parsedDicom: IParsedDicom): void => {
    const { file, sopInstanceUid, serieNumber, seriesID, seriesDescription, instanceNumber } =
      parsedDicom;

    existingPatient.files.push({
      ...file,
      seriesID,
      serieNumber,
      sopInstanceUid,
      seriesDescription,
      instanceNumber,
    });
  };

  const addUniqueFile = (files: UppyFileWithDicomTags[], newFile: UppyFileWithDicomTags) => {
    const fileExists = files.some((file) => file.sopInstanceUid === newFile.sopInstanceUid);
    if (!fileExists) {
      files.push(newFile);
    }
    return files;
  };

  const mergeNewPatientsToExisting = (
    newPatients: IPatient[],
    currentPatientsInGrid: IPatient[],
  ): IPatient[] => {
    newPatients.forEach((newPatient: IPatient) => {
      const existingIndex = currentPatientsInGrid.findIndex(
        (patient) => patient.id === newPatient.id,
      );

      if (existingIndex !== -1) {
        newPatient.files.forEach((newFile: UppyFileWithDicomTags) => {
          currentPatientsInGrid[existingIndex].files = addUniqueFile(
            currentPatientsInGrid[existingIndex].files,
            newFile,
          );
        });
      } else {
        const uniqueFiles: UppyFileWithDicomTags[] = [];
        const seenUids = new Set();

        newPatient.files.forEach((newFile: UppyFileWithDicomTags) => {
          if (!seenUids.has(newFile.sopInstanceUid)) {
            seenUids.add(newFile.sopInstanceUid);
            uniqueFiles.push(newFile);
          }
        });

        newPatient.files = uniqueFiles;
        currentPatientsInGrid.push(newPatient);
      }
    });
    return currentPatientsInGrid;
  };

  const parseNonDicomFiles = (files: UppyFile[]) => {
    setFiles((prevFiles: UppyFile[]) => {
      // Merge the previous files and the new files
      return [...prevFiles, ...files];
    });
  };

  const dumpDICOMDIR = async (file: UppyFile) => {
    let dataSet: dicomParser.DataSet;
    const output = {};
    const images: any[] = [];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file.data);
    const result = await new Promise((resolve, reject) => {
      reader.onload = async (file2) => {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer as ArrayBuffer);
        dataSet = dicomParser.parseDicom(byteArray);
        set(output, 'fileSetID', dataSet.string('x00041130'));
        set(output, 'version', dataSet.string('x00020013'));
        for (const j in dataSet.elements) {
          if (dataSet.elements.hasOwnProperty(j)) {
            const element = dataSet.elements[j];
            if (element.tag === 'x00041220') {
              for (const item in element.items) {
                if (element.items.hasOwnProperty(item)) {
                  // @ts-expect-error
                  for (const k in element.items[item].dataSet.elements) {
                    // @ts-expect-error
                    if (element.items[item].dataSet.elements.hasOwnProperty(k)) {
                      // @ts-expect-error
                      if (element.items[item].dataSet.elements[k].tag === 'x00041430') {
                        // @ts-expect-error
                        const type = element.items[item].dataSet.string('x00041430');
                        switch (type) {
                          /*case "PATIENT" :
                          var patient = element.items[item].dataSet.string('x00100010');
                          var id = element.items[item].dataSet.string('x00100020');
                          // output += "<li>Clinical Case Name (PatientName) : " +  patient + "</li>"; 
                          output += "<li>Clinical Case ID : " +  id + "</li>"; 
                          break;
                          case "STUDY" :
                          var study = element.items[item].dataSet.string('x00081030');
                          output += "<li>STUDY : " + study + "</li>";
                          break;
                          case "SERIES" :
                          var modality = element.items[item].dataSet.string('x00080060');
                          output += "<li>SERIES MODALITY: " + modality + "</li>";
                          break;*/
                          case 'IMAGE':
                            // @ts-expect-error
                            const path = element.items[item].dataSet.string('x00041500');
                            // const instance = element.items[item].dataSet.string('x00200013');
                            if (path) {
                              images.push(path.replace(/\\/g, '/'));
                            }
                            break;
                          default:
                            // @ts-expect-error
                            const path2 = element.items[item].dataSet.string('x00041500');
                            if (path2) {
                              images.push(path2.replace(/\\/g, '/'));
                            }
                            break;
                        } // end switch
                      } // end if tag
                    } // end if hasOwnProperty(k)
                  } // end for k
                } // end if hasOwnProperty(item)
              } // end for item
            } // end if tag
          } // end if hasOwnProperty(j)
        } // end for j
        resolve({ ...output, images });
      };
    });
    return result;
  };

  return { parseDicomFiles, parseNonDicomFiles, parseDicom };
};

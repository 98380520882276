import React, { useEffect, useMemo, useState } from 'react';
import { filter, find, get, includes, isEmpty, omit } from 'lodash';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse, Grid, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import useValidationSchema from './_formComment';
import { createComment, editComment } from './_api';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import { Box } from '@mui/system';
import { IComment, ISelectItem } from './_types';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useUser } from 'utils/hooks/useUser';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { IPriorityConfig, IStatusConfig } from 'modules/Administration/Workplaces/_types';
import { FINISHED, SECOND_READ } from 'constants/constants';
import Button from 'components/Buttons/Button';

const StudyDetailCommentForm: React.FC<any> = ({
  setDialogState,
  commentItem,
  getComments,
  study,
  initialComment,
  allStatuses,
  allPriorities,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { hasRole, user } = useUser();
  const { priorityAndStatusEnabled } = useAppInfo();
  const { toggleLoader } = useAppGlobals();
  const { currentLocale } = useLanguage();
  const [formError, setFormError] = useState<any>(null);
  const [priorityItems, setPriorityItems] = useState<ISelectItem[]>([]);
  const [isFinishedType, setIsFinishedType] = useState<boolean>(false);
  const [isSecondReadType, setIsSecondReadType] = useState<boolean>(false);

  const isCreating = useMemo(() => {
    return !get(commentItem, 'id');
  }, [commentItem]);

  const commentCreatedByLoggedUser = useMemo(() => {
    return get(commentItem, 'createdBy.username') === get(user, 'sub');
  }, [commentItem, user]);

  const { CommentFormSchema } = useValidationSchema(true);
  const methods = useForm<any>({
    resolver: yupResolver(CommentFormSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const prepareValues: IComment = {
      ...omit(values, ['id', 'text']),
      comment: { id: values.id || 0, text: values.text },
    };

    const fn = isCreating ? createComment : editComment;
    await fn(prepareValues).then(
      async () => {
        await getComments();
        setDialogState(false);
      },
      () => setFormError(t('Comment:errorSaving')),
    );
    toggleLoader(false);
  });

  const getEntities = () => {
    toggleLoader();
    try {
      const defaultValues = isCreating
        ? {
            productId: parseInt(get(study, 'archive.id'), 10),
            studyUid: get(study, 'studyInstanceUid'),
            ...(initialComment !== null
              ? {
                  comment: { text: initialComment },
                }
              : { comment: { text: '' } }),
          }
        : {
            id: commentItem.id,
            text: commentItem.text,
          };
      if (priorityAndStatusEnabled) {
        let defaultPriority = '';
        let finishedType = '';
        let secondReadTypes: string[] = [];
        if (!isEmpty(allStatuses) && !isEmpty(allPriorities)) {
          const statuses: IStatusConfig[] = [...allStatuses];
          const priorites: IPriorityConfig[] = [...allPriorities];
          defaultPriority = get(find(allPriorities, { default: true }), 'code', '');
          finishedType = get(find(statuses, { type: FINISHED }), 'code', '');
          secondReadTypes = filter(statuses, { type: SECOND_READ }).map((item) => item.code);

          setPriorityItems(
            priorites.map((item) => ({
              id: item.code,
              label: get(item, `name.${currentLocale}`, get(item, 'name.cs', 'name')),
            })),
          );
        }

        const isFinishedType = get(commentItem, 'commentStatusCode') === finishedType;
        const isSecondReadType = includes(secondReadTypes, get(commentItem, 'commentStatusCode'));
        setIsFinishedType(isFinishedType);
        setIsSecondReadType(isSecondReadType);
        reset({
          ...defaultValues,
          commentPriorityCode: get(commentItem, 'commentPriorityCode') || defaultPriority,
        });
      } else {
        reset({ ...defaultValues });
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {commentItem || isCreating ? (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            {isCreating ? (
              <>
                <input {...register('productId')} type="hidden" />
                <input {...register('studyUid')} type="hidden" />
                <input {...register('comment.text')} type="hidden" />
              </>
            ) : (
              <>
                <input {...register('id')} type="hidden" />
              </>
            )}
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={12}>
                <FormInput
                  name={'text'}
                  label={t('text')}
                  required={true}
                  multiline={true}
                  rows={16}
                />
              </Grid>
              <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                <Collapse in={errors ? true : false} timeout="auto">
                  {errors && (
                    <Typography align="center" color="error">
                      {formError}
                    </Typography>
                  )}
                </Collapse>
              </Grid>
              <Grid item={true} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    displayPrint: 'none',
                  }}
                >
                  <Grid container={true} spacing={1}>
                    {priorityAndStatusEnabled && (
                      <Grid item={true} xs={12} md={2}>
                        <FormSelect
                          name="commentPriorityCode"
                          label={t('PriorityStatusesConfig:priority')}
                          items={priorityItems}
                          disabled={
                            !(
                              !isFinishedType &&
                              !isSecondReadType &&
                              (isCreating ||
                                (!isCreating &&
                                  (commentCreatedByLoggedUser ||
                                    hasRole('ROLE_MANAGE_COMMENT_PRIORITIES'))))
                            )
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Box sx={{ display: 'inline-flex', mt: 1 }}>
                    <Button
                      onClick={() => setDialogState(false)}
                      variant="contained"
                      color="inherit"
                      sx={{ mr: 1 }}
                    >
                      {t('close')}
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      <SaveIcon sx={{ mr: 1 }} />
                      {t('confirm')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      ) : (
        <Papeer>
          <Typography>{t('commentNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

export default StudyDetailCommentForm;

import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';

import { get, groupBy, map } from 'lodash';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useUser } from 'utils/hooks/useUser';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DicomGridDetailContent } from './DicomGridDetailContent';
import React, { useState, useRef, useEffect } from 'react';
import { UppyFile } from '@uppy/core';
import { IPatient, UppyFileWithDicomTags } from './_types';
import { DATA_GRID_DEFAULT_SORTING, DATA_GRID_ROW_HEIGHT } from 'constants/constants';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

const bytesToSize = (bytes: number) => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 B';
  }
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

const DicomGrid: React.FC<any> = ({
  patients,
  removePatient,
  toggleEditDialog,
  setPatientForEditDialog,
  editedCells,
  enableMasterDetail = false,
  uppy,
  selectedSeries,
  setSelectedSeries,
  seriesDetailOpened,
  setSeriesDetailOpened,
  hideEditPatient = false,
}) => {
  const { t } = useTranslation('Import');
  const { hasRole } = useUser();

  const gridLocalization = useGridLocalization();

  const hasChildMounted = useRef<any>({});

  const canUpdateImport = hasRole('ROLE_IMPORT_WITH_UPDATE');

  const renderCell = (params: GridRenderCellParams) => {
    let isEdited = false;
    if (editedCells !== undefined) {
      isEdited = editedCells[params.id] && editedCells[params.id][params.field];
    }

    //Cannot combine renderCell and formatter. Formatter is called first and renderCell overrides the formatter value.
    if (params.field === 'patSex') {
      return <span style={{ color: isEdited ? 'red' : 'inherit' }}>{formatSexValue(params)}</span>;
    }
    return <span style={{ color: isEdited ? 'red' : 'inherit' }}>{params.value}</span>;
  };

  const formatSexValue = (params: GridRenderCellParams) => {
    if (params.value === 'M') {
      return t('male');
    }
    if (params.value === 'F') {
      return t('female');
    }
    if (params.value === 'O') {
      return t('different');
    }
  };

  const columns = [
    {
      field: 'actions',
      headerName: t('Akce'),
      flex: 0.5,
      type: 'actions',
      getActions: ({ row }: GridRowParams) => {
        const actions = [];
        // Conditionally add the edit action
        if (!hideEditPatient) {
          actions.push(
            <GridActionsCellItem
              data-tour="import-dicom-editRow"
              icon={
                <Tooltip title={t('edit')}>
                  <EditIcon />
                </Tooltip>
              }
              showInMenu={false}
              label={t('isNoOk')}
              onClick={() => {
                toggleEditDialog();
                setPatientForEditDialog(row);
              }}
              disabled={!canUpdateImport}
            />,
          );
        }

        // Always add the delete action
        actions.push(
          <GridActionsCellItem
            data-tour="import-dicom-deleteRow"
            icon={
              <Tooltip title={t('delete')}>
                <DeleteIcon />
              </Tooltip>
            }
            showInMenu={false}
            label={t('isNoOk')}
            onClick={() => {
              removePatient(row);
            }}
          />,
        );

        return actions;
      },
    },
    {
      field: 'name',
      headerName: t('dicom.grid.patientName'),
      valueGetter: (value: any, row: any) => (get(row, 'name') || '').replaceAll('^', ' '),
      flex: 1,
      renderCell,
    },
    {
      field: 'patID',
      headerName: t('dicom.grid.patientId'),
      flex: 1,
      renderCell,
    },
    {
      field: 'studyDate',
      headerName: t('dicom.grid.studyDate'),
      flex: 1,
      valueGetter: (value: any, row: any) =>
        get(row, 'studyDate', null)
          ? format(parse(row.studyDate, 'yyyyMMdd', new Date()), 'dd.MM.yyyy')
          : '',
    },
    {
      field: 'patSex',
      headerName: t('dicom.grid.patSex'),
      flex: 1,
      renderCell,
    },
    {
      field: 'studyDescription',
      headerName: t('dicom.grid.studyDescription'),
      flex: 1,
    },
    {
      field: 'files',
      headerName: t('dicom.grid.files'),
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const files = get(row, 'files', []);
        const count = files.length;
        let size = 0;
        files.forEach((item: any) => {
          size += item.size;
        });
        return `${count} (${bytesToSize(size)})`;
      },
    },
  ];

  const [groupedFilesByPatientId, setGroupedFilesByPatientId] = useState<{
    patientId: string;
    groupedFiles: UppyFile[];
  }>({} as { patientId: string; groupedFiles: UppyFile[] });

  useEffect(() => {
    groupedFilesByPatientIdRef.current = groupedFilesByPatientId;
  }, [groupedFilesByPatientId]);

  useEffect(() => {
    patients.forEach((patient: IPatient) => {
      hasChildMounted.current[patient.id] = false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    patients.forEach((patient: IPatient) => {
      const groupedFiles = groupBy(patient.files, 'seriesID');
      const groupedFilesBySeriesId = map(groupedFiles, (value: UppyFileWithDicomTags[], key) => ({
        id: key,
        seriesID: key,
        files: value,
        fileCount: value.length,
        description: value[0].seriesDescription, // Not sure if it is correct to take the seriesDescription from the first file...
        serieNumber: value[0].serieNumber, // Not sure if it is correct to take the serieNumber from the first file... cause i am grouping by seriesID
      }));

      setGroupedFilesByPatientId((prev) => ({
        ...prev,
        [patient.id]: groupedFilesBySeriesId,
      }));
    });
  }, [patients]);

  const groupedFilesByPatientIdRef = useRef<{
    patientId: string;
    groupedFiles: UppyFile[];
  }>(groupedFilesByPatientId);

  const masterDetailProps = enableMasterDetail && {
    getDetailPanelContent: ({ row }: GridRowParams) => {
      return (
        <DicomGridDetailContent
          patient={row}
          uppy={uppy}
          selectedSeries={selectedSeries}
          setSelectedSeries={setSelectedSeries}
          hasChildMounted={hasChildMounted}
          groupedFilesBySeriesId={
            groupedFilesByPatientIdRef.current[row.id as keyof typeof groupedFilesByPatientId]
          }
          setSeriesDetailOpened={setSeriesDetailOpened}
        />
      );
    },
    getDetailPanelHeight: ({ row }: GridRowParams) => 'auto',
    slots: {
      detailPanelExpandIcon: CustomExpandIcon,
      detailPanelCollapseIcon: CustomCollapseIcon,
    },
  };

  //Sync uppy files with selectedSeries
  useEffect(() => {
    const uppyFiles = uppy?.getFiles();
    //Get all files from selectedSeries
    if (!selectedSeries) return;
    const selectedFiles = Array.from(selectedSeries?.values())
      .map((series: any) => series)
      .flat()
      .map((serie) => serie.files)
      .flat();
    //Create map for easier filtering
    const selectedFilesMap = new Map(selectedFiles.map((file) => [file.id, file]));
    //Remove files from uppy that are not in selectedSeries
    if (selectedFilesMap.size || Object.keys(seriesDetailOpened).length > 0) {
      uppyFiles.forEach((uppyFile: UppyFile) => {
        if (!selectedFilesMap.has(uppyFile.id)) {
          uppy.removeFile(uppyFile.id);
        }
      });
    }
    // //Add files to uppy that are in selectedSeries
    selectedFiles.forEach((file) => {
      if (!uppyFiles.some((uppyFile: UppyFile) => uppyFile.id === file.id)) {
        uppy.addFile({
          name: file.name,
          type: file.type,
          data: file.data,
          //source: 'Local', // Replace with your actual file source if different
        });
      }
    });
    //eslint-disable-next-line
  }, [selectedSeries]);

  return (
    groupedFilesByPatientIdRef.current && (
      <Box>
        <DataGridPro
          autoHeight={true}
          rowHeight={DATA_GRID_ROW_HEIGHT}
          sortingOrder={DATA_GRID_DEFAULT_SORTING}
          headerFilters={true}
          rows={patients}
          columns={columns}
          localeText={gridLocalization}
          {...masterDetailProps}
          hideFooter={true}
          disableRowSelectionOnClick={true}
          slotProps={{
            headerFilterCell: {
              InputComponentProps: {
                size: 'small',
              },
            },
          }}
        />
      </Box>
    )
  );
};

const CustomExpandIcon = (props: any) => {
  return <ExpandMore {...props} data-tour="import-dicom-expansionArrow" />;
};

const CustomCollapseIcon = (props: any) => {
  return <ExpandLess {...props} data-tour="import-dicom-expansionArrow" />;
};

export default DicomGrid;

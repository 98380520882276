import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';
import { TFunction } from 'i18next';
import { compact, find, get } from 'lodash';

const useValidationSchema = (
  t: TFunction,
  languages: any[],
  isSystemButton: boolean,
  showSearchForm: boolean,
) => {
  const { required } = useValidators();

  const language = find(languages, { default: true });

  const ButtonFormSchema = yup.object().shape({
    ...(!isSystemButton
      ? { [`languageJsonText_${get(language, 'abbreviation')}`]: required(yup.string().trim()) }
      : {}),
    ...(!showSearchForm
      ? {
          url: required(
            yup
              .string()
              .trim()
              .test('valid-start', t('urlMustStartWithSlashOrHttp'), (value) => {
                if (!value) return false;
                return value.startsWith('/') || value.startsWith('http');
              }),
          ),
        }
      : {}),
  });

  const formItems = compact([
    ...(isSystemButton
      ? []
      : (languages || []).map((lang: any) => ({
          name: `languageJsonText_${lang.abbreviation}`,
          label: t(`languageJsonText_${get(lang, 'abbreviation', '')}`),
          required: get(lang, 'default', false),
        }))),
    {
      name: 'icon',
      label: t('icon'),
    },
    ...(showSearchForm
      ? []
      : [
          {
            name: 'url',
            label: t('url'),
            required: true,
          },
        ]),
  ]);

  return { ButtonFormSchema, formItems };
};

export default useValidationSchema;

import React, { useState, useEffect, useRef } from 'react';
// import { DevTool } from '@hookform/devtools';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { Edit as EditIcon } from '@mui/icons-material';
import { find, get, isArray, isEmpty, pick, reject, sortBy, isString, isUndefined } from 'lodash';

import {
  getProduct,
  editProduct,
  createProduct,
  getProductTypes,
  getAllDefaultSystemSettings,
  getAll,
  editProductSetting,
} from './_api';
import { IProduct, IProductForm } from './_types';

import { useEntityInfo } from 'utils/hooks/useEntityInfo';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';

import useValidationSchema from './_form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

import useAlerts from 'components/Alerts/useAlerts';
import { ISelectItem } from 'components/Form/Select/_types';
import { ARCHIVE } from 'constants/constants';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const linkBack = '/administration/settings?tab=products';
const HISCONNECTOR = 'HISConnector';
const muiGridKey = 'productFormMUI';

export const ProductForm: React.FC = () => {
  const { t } = useTranslation('Products');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { id, isCreating } = useEntityInfo();

  const [entity, fetchEntity] = useState<IProduct>();
  const [productTypes, setProductTypes] = useState<any[]>([]);
  const [productType, setProductType] = useState<any>(null);
  const [allDefaultSettings, setAllDefaultSettings] = useState<any[]>([]);
  const [systemSettings, setSystemSettings] = useState<any[]>([]);
  const [settingsForProductType, setSettingsForProductType] = useState<any[]>([]);
  const [previewTypeId, setPreviewTypeId] = useState(null);
  const [previewSubTypeId, setPreviewSubTypeId] = useState(null);
  const navigate = useNavigate();
  const { title } = useWithEntityTitle(isCreating, entity, t);

  const { ProductFormSchema, formItems } = useValidationSchema(t, isCreating, productType);

  const methods = useForm<IProductForm>({
    resolver: yupResolver(ProductFormSchema),
  });
  const { handleSubmit, reset, register, control, getValues, watch } = methods;
  const { fields } = useFieldArray({ control, name: 'systemSetting' });
  const watchedTypeId: any = watch('type.id');
  const watchedTypeSubtypeId: any = watch('type_subtype_id');

  const changeValues = (typeId: any, subTypeId: any) => {
    if (typeId !== 0 && typeId !== null && typeId !== '') {
      const productType = find(productTypes, { id: typeId });
      setProductType(productType);

      const productSubtype = subTypeId
        ? find(get(productType, 'subtypes', []), {
            id: subTypeId,
          })
        : null;
      const hasSubtypes = isEmpty(get(productType, 'subtypes', [])) ? false : true;
      const settingsForProductType = hasSubtypes
        ? get(productSubtype, 'name', null)
          ? get(allDefaultSettings, get(productSubtype, 'name', null), [])
          : []
        : get(allDefaultSettings, get(productType, 'name'), []);
      if (isCreating) {
        setSettingsForProductType(settingsForProductType);

        reset({
          ...getValues(),
          type_subtype_id: subTypeId || null,
          default_id: 9,
          systemSetting: settingsForProductType,
        });
      }
    }
  };

  if (previewTypeId !== watchedTypeId && !isUndefined(watchedTypeId)) {
    setPreviewTypeId(watchedTypeId);
    if (watchedTypeId !== '' && watchedTypeId !== '0' && watchedTypeId !== null) {
      changeValues(watchedTypeId, null);
    }
  }
  if (previewSubTypeId !== watchedTypeSubtypeId && !isUndefined(watchedTypeSubtypeId)) {
    setPreviewSubTypeId(watchedTypeSubtypeId);
    changeValues(watchedTypeId, watchedTypeSubtypeId);
  }

  const prepareValues = (values: any) => {
    const pickSet = ['id', 'code', 'description', 'name'];
    if (isCreating) {
      pickSet.push('systemSetting');
    }
    let preparedValues: any = {
      ...pick(values, pickSet),
      type: {
        id: get(values, 'type_subtype_id', false) ? values.type_subtype_id : get(values, 'type.id'),
      },
    };
    if (get(productType, 'name') === ARCHIVE) {
      preparedValues = { ...preparedValues, isDefault: get(values, 'default_id', 9) === 1 };
    }
    return preparedValues;
  };

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createProduct : editProduct;
    const response = await fn(prepareValues(values));
    if (response === true) {
      addSuccessAlert(t('saved'));
      navigate(linkBack);
    } else {
      addErrorAlert(t(isString(response) ? response : 'errorSaving'));
    }
    toggleLoader(false);
  });

  const getEntities = async () => {
    toggleLoader();
    try {
      const [productTypes, settings, products] = isCreating
        ? await Promise.all([getProductTypes(), getAllDefaultSystemSettings(), getAll()])
        : await Promise.all([getProductTypes(), getAllDefaultSystemSettings()]);
      setAllDefaultSettings(settings);

      // nelze vytvořit dva a více hisconnectorů
      if (products) {
        const hisConn = find(products, (item: any) => get(item, 'type.name') === HISCONNECTOR);
        if (hisConn) {
          setProductTypes(reject(productTypes, (item: any) => get(item, 'name') === HISCONNECTOR));
        } else {
          setProductTypes(productTypes);
        }
      } else {
        setProductTypes(productTypes);
      }

      let product: IProductForm = {
        id: 0,
        name: '',
        description: '',
        code: '',
        type: { id: null },
        type_subtype_id: null,
        default_id: 9,
        systemSetting: [{ id: 0 }],
        active: true,
      };

      if (!isCreating && id) {
        const entity = await getProduct(id.toString());
        const typeId = get(entity, 'type.id', null);
        changeValues(typeId, null);
        product = {
          ...entity,
          type: { id: typeId },
          type_subtype_id: get(entity, 'type.subtype.id', null),
          default_id: get(entity, 'default', false) === true ? 1 : 9,
        };
        if (entity) {
          fetchEntity(entity);
          const systemSettings = get(entity, 'systemSetting', null).map((systemSetting: any) => {
            const type = get(systemSetting, 'type', '');
            if (type.includes('ENUM(')) {
              const enumValues = type.substring(5, type.length - 1).split(',');
              return { ...systemSetting, type: 'ENUM', enumValues, typeEnum: type };
            } else {
              return { ...systemSetting, typeEnum: type };
            }
          });
          setSystemSettings(systemSettings);
        }
      }
      reset({ ...product });
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultItems = {
    'type.id': isArray(productTypes)
      ? sortBy(
          productTypes.map((item: any) => ({
            id: item.id,
            name: item.name,
            label: t(item.name),
          })),
          ['label'],
        )
      : [],
    type_subtype_id: !isEmpty(get(productType, 'subtypes', []))
      ? sortBy(
          get(productType, 'subtypes', []).map((item: any) => ({
            id: item.id,
            name: item.name,
            label: t(item.name),
          })),
          ['label'],
        )
      : [],
    default_id: [
      { id: 1, label: t('yes') },
      { id: 9, label: t('no') },
    ],
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const onCustomEntityDetail = (row: any) => {
    const editLink = `${get(row, 'id')}`;
    navigate(editLink);
  };

  const saveSetting = async (values: any) => {
    toggleLoader();
    const response = await editProductSetting(values);
    if (response) {
    } else {
      addErrorAlert(t(isString(response) ? response : 'settingErrorSaving'));
    }
    toggleLoader(false);
  };

  const ValueEditCell = (props: GridRenderEditCellParams) => {
    const { row, field, value, id, hasFocus } = props;
    const type = get(row, 'type');
    const apiRef = useGridApiContext();
    const ref = useRef<HTMLElement>(null);
    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        try {
          const item = ref.current.querySelector<HTMLSelectElement>(`input[value='${value}']`);
          item?.focus();
        } catch (e) {
          console.debug({ e });
        }
      }
    }, [hasFocus, value]);

    const setValue = (newValue: string, callSaveSetting: boolean) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
      if (callSaveSetting) {
        saveSetting({ ...row, type: row.typeEnum, value: newValue });
      }
    };

    return (
      <FormControl variant="standard" fullWidth={true} sx={{ ml: 1 }}>
        {type === 'BOOLEAN' || type === 'ENUM' ? (
          <Select
            ref={ref}
            value={value}
            onChange={(event) => setValue(event.target.value, false)}
            onBlur={(event) => setValue(event.target.value, true)}
          >
            {(type === 'BOOLEAN' ? ['true', 'false'] : get(row, 'enumValues', [])).map(
              (item: string) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ),
            )}
          </Select>
        ) : (
          <Input
            ref={ref}
            value={value}
            type={type === 'NUMBER' ? 'number' : 'text'}
            onChange={(event) => setValue(event.target.value, false)}
            onBlur={(event) => setValue(event.target.value, true)}
          />
        )}
      </FormControl>
    );
  };

  const renderEditValue = (params: GridRenderEditCellParams) => {
    return <ValueEditCell {...params} />;
  };

  const ValuerRenderCell = (params: GridRenderEditCellParams) => {
    const apiRef = useGridApiContext();
    const setEditMode = (id: number, field: string) =>
      apiRef.current.startCellEditMode({ id, field });

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>{params.row.value}</Box>
        <Box>
          <Tooltip title={t('Grid:editableColumn')}>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => setEditMode(params.row.id, 'value')}
              size="small"
            >
              <EditIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };
  const renderValue = (params: GridRenderEditCellParams) => {
    return <ValuerRenderCell {...params} />;
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 80,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <GridActionsCellItem
              icon={
                <Tooltip title={t('Grid:edit')}>
                  <EditIcon />
                </Tooltip>
              }
              label={t('Grid:edit')}
              onClick={() => onCustomEntityDetail(row)}
            />
          );
        },
      },
      { field: 'variable', headerName: t('variable') },
      { field: 'description', headerName: t('description') },
      { field: 'type', headerName: t('type') },
      {
        field: 'value',
        headerName: t('value'),
        editable: true,
        renderEditCell: renderEditValue,
        renderCell: renderValue,
      },
    ]),
  );

  return (
    <>
      {/* <DevTool control={control} placement="bottom-right" /> */}
      {(!isEmpty(entity) && !isEmpty(systemSettings)) || isCreating ? (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Header title={title} />
            <Papeer bottomMargin={true}>
              <input {...register('id')} type="hidden" />
              <Grid container={true} alignItems="flex-end" spacing={1}>
                {formItems.map((formItem, index) => {
                  const type = get(formItem, 'type', 'text');
                  const name = get(formItem, 'name', '');
                  const items: ISelectItem[] = get(defaultItems, name, []);

                  return (
                    <Grid item={true} xs={12} md={4} key={`formItem-${index}`}>
                      {type === 'select' ? (
                        <FormSelect {...formItem} items={items} />
                      ) : (
                        <FormInput {...formItem} />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Papeer>
            {isCreating && !isEmpty(settingsForProductType) && (
              <Papeer bottomMargin={true}>
                <Header title={t('settings')} />
                <Grid container={true} spacing={2}>
                  {fields.map((item, index) => {
                    return (
                      <React.Fragment key={`${index}_${item.id}`}>
                        <Grid item={true} xs={12} md={6} lg={2}>
                          <FormInput
                            name={`systemSetting[${index}].variable`}
                            label={t('variable')}
                            defaultValue={get(settingsForProductType[index], 'variable', '')}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={4}>
                          <FormInput
                            name={`systemSetting[${index}].description`}
                            label={t('description')}
                            defaultValue={get(settingsForProductType[index], 'description', '')}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <FormInput
                            name={`systemSetting[${index}].value`}
                            label={t('value')}
                            defaultValue={get(settingsForProductType[index], 'value', '')}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={2}>
                          <FormInput
                            name={`systemSetting[${index}].type`}
                            label={t('type')}
                            defaultValue={get(settingsForProductType[index], 'type', '')}
                            disabled={true}
                          />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Papeer>
            )}
            <Papeer bottomMargin={true}>
              <EntityButtons linkBack={linkBack} />
            </Papeer>
          </form>
        </FormProvider>
      ) : (
        <Papeer>
          <Typography>{t('productNotFound')}</Typography>
        </Papeer>
      )}
      {!isEmpty(entity) && (
        <>
          <Header title={t('settings')} />
          <MuiGrid
            gridKey={muiGridKey}
            rows={systemSettings}
            columns={columns}
            initialSortMode={[{ field: 'variable', sort: 'asc' }]}
          />
        </>
      )}
    </>
  );
};

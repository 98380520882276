import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { filter, find, get, indexOf, isArray, isEmpty, isUndefined, remove, sortBy } from 'lodash';
import { AppBar, Box, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { IFunctionItem, IWorkplace } from './_types';
import { IArchive } from 'modules/Search/_types';
import {
  createMultipleWorkplaces,
  findAllWorkplaces,
  getAllWorkplaces,
  removeWorkplace,
} from './_api';
import { getAuthConfig } from '../../Login/_api';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useLanguages } from 'utils/hooks/useLanguages';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { DELETE, EDIT } from 'constants/constants';
import Button from 'components/Buttons/Button';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';

const muiGridKey = 'workplacesMUI';

export const Workplaces: React.FC = () => {
  const { t } = useTranslation('Workplaces');
  const dispatch = useAppDispatch();
  const { compactMode, confirmationData } = useAppInfo();
  const navigate = useNavigate();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  let [searchParams] = useSearchParams();
  useWithTitle();
  const [portalAuthConfig, setPortalAuthConfig] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [workplaces, fetchWorkplaces] = useState<any[]>([]);
  const [canAddOrEditWorkPlaces, setCanAddOrEditWorkPlaces] = useState<boolean>(false);
  const [actions, setActions] = useState<any[]>([]);
  const [selection, setSelection] = useState<any[]>([]);
  const [renderedSearchForm, setRenderedSearchForm] = useState<any[]>([]);

  const { languages } = useLanguages();

  const methods = useForm<any>();
  const { getValues, handleSubmit, reset } = methods;
  const formValues = getValues();

  const getAddUrl = useCallback(() => {
    const parCode = get(formValues, 'code', '');
    const addUrl = `/administration/settings/workplaces/create?userDirectoryId=${activeTab}${
      activeTab !== 'db' && parCode ? '&parCode=' + parCode : ''
    }`;
    return addUrl;
  }, [activeTab, formValues]);

  const processWorkplaces = useMemo(
    () =>
      async (workplaces: IWorkplace[] | boolean, activeTab: string, code: string = '') => {
        if (isArray(workplaces)) {
          fetchWorkplaces(
            workplaces.map((workplace) => ({
              ...workplace,
              userDirectoryId: activeTab,
              parCode: code,
              id: workplace.code,
            })),
          );
        }
      },
    [],
  );

  const loadEntity = useMemo(
    () => async (activeTab: string) => {
      toggleLoader(true);
      const workplaces = await getAllWorkplaces(activeTab);
      processWorkplaces(workplaces, activeTab);
      toggleLoader(false);
    },
    [processWorkplaces, toggleLoader],
  );

  const { onEntityRemove } = useEntityRemove(
    removeWorkplace,
    t,
    () => (activeTab === 'db' ? loadEntity(activeTab) : submitHandler(formValues, activeTab)),
    'deleted',
    'errorDeleting',
    activeTab,
  );

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const submitHandler = useMemo(
    () => async (values: any, activeTab: string) => {
      toggleLoader();
      const code = get(values, 'code', '');
      const workplaces = await findAllWorkplaces(code, activeTab);
      processWorkplaces(workplaces, activeTab, code);

      toggleLoader(false);
    },
    [processWorkplaces, toggleLoader],
  );

  const onCustomEntityDetail = (row: any) => {
    const editLink = `workplaces/${get(row, 'code')}${
      get(row, 'backToByAction', '')
        ? '?from=' + get(row, 'backToByAction', '')
        : get(row, 'userDirectoryId', '')
        ? `?userDirectoryId=${get(row, 'userDirectoryId', '')}&parCode=${get(row, 'parCode', '')}`
        : ''
    }`;
    navigate(editLink);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 120,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onCustomEntityDetail(row)}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'code') }));
                }}
                disabled={
                  get(row, 'state', null) === 'NEW' ||
                  !get(row, 'canDelete', true) ||
                  get(row, 'system', false)
                }
              />
            </>
          );
        },
      },
      ...(languages || []).map((lang: any) => ({
        field: lang.abbreviation,
        headerName: lang.name,
        valueGetter: (value: any, row: any) => get(JSON.parse(row.text), lang.abbreviation, ''),
      })),
      { field: 'code', headerName: t('code') },
      {
        field: 'viewer',
        headerName: t('viewer'),
        valueGetter: (value: any, row: any) => get(row, 'viewer.name', ''),
      },
      {
        field: 'archives',
        headerName: t('archives'),
        valueGetter: (_value: any, row: any) => {
          const value = get(row, 'archives', null);
          return isArray(value) ? value.map((archive: IArchive) => archive.name).join(', ') : null;
        },
      },
    ]),
  );

  const prepareState = useMemo(
    () =>
      (activeTab: string, allowedAuthMethods: any = portalAuthConfig) => {
        setActiveTab(activeTab);
        const renderedSearchForm = [];
        const newActions = [];
        const activetabAllowedMethod = find(allowedAuthMethods, (config) => {
          return get(config, 'id') === activeTab;
        });
        fetchWorkplaces([]);
        const canAddOrEditWorkPlaces = get(activetabAllowedMethod, 'canManage', false);
        setCanAddOrEditWorkPlaces(canAddOrEditWorkPlaces);
        if (activeTab !== null && activeTab !== '') {
          if (get(activetabAllowedMethod, 'type') === 'db' || canAddOrEditWorkPlaces) {
            newActions.push(EDIT);
            newActions.push(DELETE);
          }
          setActions(newActions);
          if (activeTab === 'db') {
            loadEntity(activeTab);
          }

          if (activeTab !== 'db' && activeTab !== undefined) {
            renderedSearchForm.push(
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(async (data) => await submitHandler(data, activeTab))}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={6} lg={4}>
                      <FormInput name="code" label={t('code')} />
                    </Grid>
                    <Grid item={true} xs={12} md={4} lg={3}>
                      <Box sx={{ marginTop: '10px' }}>
                        <Button variant="contained" color="primary" type="submit">
                          <SearchIcon sx={{ mr: 1 }} />
                          {t('search')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>,
            );
          }
          setRenderedSearchForm(renderedSearchForm);
        }
      },
    [handleSubmit, loadEntity, methods, portalAuthConfig, submitHandler, t],
  );

  const addworkplacesToDb = useMemo(
    () => async () => {
      toggleLoader(true);

      const workplacesForAdd = filter(workplaces, (workplace) => {
        return indexOf(selection, get(workplace, 'code')) > -1;
      });
      const values = (workplacesForAdd || []).map((workplace) => ({ ...workplace, id: 0 }));

      const response = await createMultipleWorkplaces(values);
      if (response) {
        addSuccessAlert(t('saved'));
        setSelection([]);
        prepareState('db', portalAuthConfig);
      } else {
        addErrorAlert(t('errorSaving'));
      }
      toggleLoader(false);
    },
    [
      addErrorAlert,
      addSuccessAlert,
      portalAuthConfig,
      prepareState,
      selection,
      t,
      toggleLoader,
      workplaces,
    ],
  );

  const getEntity = async () => {
    toggleLoader();
    try {
      const authConfig = await getAuthConfig();
      const portalAuthConfig: any[] = get(authConfig, 'allowedAuthMethods', []);
      if (portalAuthConfig !== undefined) {
        remove(portalAuthConfig, (config) => {
          return (
            get(config, 'type') === 'headers' ||
            get(config, 'type') === 'spnego' ||
            get(config, 'type') === 'openid'
          );
        });
        let dbMethod: any = null;
        dbMethod = find(portalAuthConfig, (config) => {
          return get(config, 'type') === 'db';
        });
        if (dbMethod === null || dbMethod === undefined) {
          dbMethod = { ...dbMethod, type: 'db', id: 'db', order: 0, canManage: true };
        } else {
          dbMethod = { ...dbMethod, order: 0 };
          remove(portalAuthConfig, (config) => {
            return get(config, 'type') === get(dbMethod, 'type');
          });
        }
        portalAuthConfig.push(dbMethod);
        const sortedAllowedAuthMethods = sortBy(portalAuthConfig, (conf) => {
          return conf.order;
        });
        setPortalAuthConfig(sortedAllowedAuthMethods);
        let activeTab = searchParams.get('activeTabId') || null;
        const parCode = searchParams.get('parCode') || null;
        if (activeTab === null || activeTab === '' || typeof activeTab !== 'string') {
          activeTab = get(sortedAllowedAuthMethods[0], 'id', 'db');
        }
        if (parCode) {
          reset({ code: parCode });
        }
        prepareState(activeTab as string, sortedAllowedAuthMethods);
        if (parCode) {
          submitHandler({ code: parCode }, activeTab as string);
        }
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const functionList: IFunctionItem[] = useMemo(
    () => [
      {
        key: 'addWorkplacesFromLdap',
        label: t('addWorkplacesFromLdap'),
        show: true,
        maxAllowedItem: 16,
        onClick: async () => addworkplacesToDb(),
        icon: <AddIcon fontSize="small" />,
      },
    ],
    [addworkplacesToDb, t],
  );

  const QuickSearchToolbar = useCallback(
    (props: any) => {
      return (
        <div>
          <Box style={{ padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </Box>
          </Box>
          <Box
            sx={{ px: 1, py: 0.5, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}
          >
            {functionList.map((item: IFunctionItem, index: number) => {
              const showCount = isUndefined(item.showCount) ? true : item.showCount;
              const count = selection.length;
              const xcount = showCount && count ? ` (${count})` : '';
              const maxAllowedItem = isUndefined(item.maxAllowedItem) ? 0 : item.maxAllowedItem;
              return (
                <Tooltip key={index} title={item.label + xcount}>
                  <span>
                    <Button
                      key={item.key}
                      variant="contained"
                      color="primary"
                      onClick={() => item.onClick()}
                      disabled={
                        selection.length === 0 ||
                        (maxAllowedItem > 0 && maxAllowedItem < selection.length)
                      }
                      sx={{ mr: 0.5, mb: 0.5 }}
                    >
                      {item.icon}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          </Box>
        </div>
      );
    },
    [selection, functionList],
  );

  return (
    <>
      <Header title={''} {...(canAddOrEditWorkPlaces ? { addUrl: getAddUrl() } : {})} />
      <AppBar position="static" color="default" sx={{ mb: compactMode ? 1 : 2 }}>
        {!isEmpty(portalAuthConfig) && (
          <Tabs
            value={activeTab}
            onChange={(event, value) => prepareState(value)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons={false}
          >
            {portalAuthConfig.map((config) => {
              return (
                <Tab
                  value={get(config, 'id')}
                  label={`${get(config, 'id')} (${get(config, 'type')})`}
                  key={`${get(config, 'order')}`}
                />
              );
            })}
          </Tabs>
        )}
      </AppBar>
      {actions && !isEmpty(languages) && (
        <>
          {activeTab !== 'db' && activeTab !== '' && (
            <Papeer bottomMargin={true} sx={{ p: compactMode ? 1 : 2 }}>
              {renderedSearchForm}
            </Papeer>
          )}
          <MuiGrid
            gridKey={muiGridKey}
            rows={workplaces}
            columns={columns}
            rowSelecting={{ selecting: activeTab !== 'db', selection, setSelection }}
            toolbar={activeTab !== 'db' ? QuickSearchToolbar : undefined}
            sxPapeer={{ p: 0 }}
            initialSortMode={[{ field: 'code', sort: 'asc' }]}
          />
        </>
      )}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  deleteCertificate,
  getAllCertificatesForUser,
  updateCertificate,
} from '../Certificates/_api';
import { ICertificate } from '../Certificates/_types';
import CertificateAttachmentForm from '../Certificates/CertificateAttachmentForm';
import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';

import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import Header from 'components/Header/Header';

const muiGridKey = 'userProfileCertificatesMui';

const UserProfileCertificatesComponent: React.FC<any> = ({ userCertificates }) => {
  const { t } = useTranslation('Certificates');
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();

  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [dialogState, setDialogState] = useState<boolean>(false);

  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { toggleLoader } = useAppGlobals();

  const loadCertificates = useMemo(
    () => async () => {
      toggleLoader();
      if (userCertificates) {
        setCertificates(userCertificates);
      } else {
        const data = await getAllCertificatesForUser();
        if (isArray(data)) {
          setCertificates(data);
        }
      }
      toggleLoader(false);
    },
    [toggleLoader, userCertificates],
  );

  useEffect(() => {
    loadCertificates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onEntityRemove } = useEntityRemove(deleteCertificate, t, loadCertificates);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const changeCertificate = async (row: ICertificate, state: boolean) => {
    toggleLoader();
    const changed = await updateCertificate({
      id: row.id,
      name: row.certificateName,
      favourite: state,
    });
    if (changed) {
      await loadCertificates();
      addSuccessAlert(t('change.success'));
    } else {
      addErrorAlert(t('change.error'));
    }
    toggleLoader(false);
  };

  const confirmActionChangeDefault = () => {
    changeCertificate(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 250,
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.favourite}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: row,
                            state,
                            confirmAction: 'confirmActionChangeDefault',
                          }),
                        );
                      }}
                    />
                  }
                  label={t('Grid:default') as string}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
              />
            </>
          ),
        },
        { field: 'certificateName', headerName: t('certificateName') },
      ],
      200,
    ),
  );

  const handleDialog = (state: boolean = true) => {
    setDialogState(state);
  };

  return (
    <>
      <Header
        title=""
        addItemInDialog={handleDialog}
        toggleDialogText={t('add')}
        topMargin={true}
      />
      <MuiGrid
        gridKey={muiGridKey}
        rows={certificates}
        columns={columns}
        initialSortMode={[{ field: 'certificateName', sort: 'asc' }]}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeDefault'
              ? confirmActionChangeDefault
              : confirmAction
          }
        />
      )}
      <Dialog
        open={dialogState}
        onClose={() => handleDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{t('dialog_attachment')}</DialogTitle>
        <DialogContent>
          {dialogState && (
            <CertificateAttachmentForm
              loadEntities={loadCertificates}
              handleDialog={handleDialog}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserProfileCertificatesComponent;

import React, { useEffect, useState } from 'react';
import FindingReportsSearchForm from './FindingReports/FindingReportsSearchForm';
import FindingReportsResults from './FindingReports/FindingReportsResults';
import Header from 'components/Header/Header';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { TourFindingReports } from './FindingReports/TourFindingReports';
import { IFindingReport } from './_types';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';

const FindingReports: React.FC = () => {
  const { title } = useWithTitle();
  let [searchParams] = useSearchParams();
  const [findingReports, setFindingReports] = useState<IFindingReport[]>([]);

  const renderedSteps = () => <TourFindingReports />;
  const { findingReportResults } = useAppInfo();

  useEffect(() => {
    const isBack = (searchParams.get('action') || null) === 'back';
    if (!isEmpty(findingReportResults) && isBack) {
      setFindingReports(findingReportResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={title} TourComponent={renderedSteps()} />
      <FindingReportsSearchForm setFindingReports={setFindingReports} />
      <FindingReportsResults findingReports={findingReports} />
    </>
  );
};

export default FindingReports;

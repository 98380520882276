import Tus from '@uppy/tus';
import Uppy from '@uppy/core';
import DropTarget from '@uppy/drop-target';
import Webcam from '@uppy/webcam';

import { getItem } from 'utils/hooks/useStorage';

import Czech from '@uppy/locales/lib/cs_CZ';
import { get, isArray, isEmpty } from 'lodash';

export const bytesToSize = (bytes: number) => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 B';
  }
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

// https://uppy.io/docs/react/#how-do-i-render-x-amount-of-uppy-ui-components
export const createUppy = (
  id: string,
  parseFiles: any,
  timestamp: string,
  rules: any = [],
  saveToGlobalVariable: boolean = false,
  addWebcam: boolean = false,
  t: any = undefined,
  maxNumberOfFiles: number = 0,
) => {
  const isHttps = window.location.protocol === 'https:';
  const rulesUppy = rules.map((rule: any) => `.${rule}`);
  let uppy =
    addWebcam && isHttps
      ? new Uppy({
          id,
          locale: Czech,
          ...(rules.length > 0
            ? {
                restrictions: maxNumberOfFiles
                  ? { allowedFileTypes: rulesUppy, maxNumberOfFiles }
                  : { allowedFileTypes: rulesUppy },
              }
            : {}),
        })
          .use(DropTarget, {
            target: document.body,
          })
          .use(Tus, {
            endpoint: '/portal-api/upload',
            headers: {
              timestamp: timestamp,
            },
            //chunkSize: 1024 * 1024,
            removeFingerprintOnSuccess: true,
            async onBeforeRequest(req: any) {
              const storageToken: string = getItem('token') as string;
              req.setHeader('Authorization', `Bearer ${storageToken}`);
            },
          })
          .on('files-added', (files: any) => {
            parseFiles(files);
          })
          .use(Webcam, {
            locale: {
              strings: {
                pluginNameCamera: t ? t('uppy.camera') : '',
                noCameraTitle: t ? t('uppy.noCameraTitle') : '',
                noCameraDescription: t ? t('uppy.noCameraDescription') : '',
              },
            },
            modes: ['picture'],
            mirror: false,
            videoConstraints: {
              width: { min: 720, max: 1920, ideal: 1920 },
              height: { min: 720, max: 1080, ideal: 1080 },
              facingMode: 'environment',
            },
          })
      : new Uppy({
          id,
          locale: Czech,
          ...(rules.length > 0
            ? {
                restrictions: maxNumberOfFiles
                  ? { allowedFileTypes: rulesUppy, maxNumberOfFiles }
                  : { allowedFileTypes: rulesUppy },
              }
            : {}),
        })
          .use(DropTarget, {
            target: document.body,
          })
          .use(Tus, {
            endpoint: '/portal-api/upload',
            headers: {
              timestamp: timestamp,
            },
            //chunkSize: 1024 * 1024,
            removeFingerprintOnSuccess: true,
            async onBeforeRequest(req: any) {
              const storageToken: string = getItem('token') as string;
              req.setHeader('Authorization', `Bearer ${storageToken}`);
            },
          })
          .on('files-added', (files: any) => {
            parseFiles(files);
          });

  if (saveToGlobalVariable) {
    if (!isArray((window as any).UppyGlobalInstances)) {
      (window as any).UppyGlobalInstances = [];
    }
    ((window as any).UppyGlobalInstances || []).forEach((element: any, i: any) => {
      const progress = get(element.getState(), 'totalProgress', -1);
      if (progress === 100) {
        (window as any).UppyGlobalInstances.splice(i, 1);
      }
    });
    (window as any).UppyGlobalInstances.push(uppy);
  }
  return uppy;
};

export const checkUploadingStatus = (setState: any) => {
  let all = 0;
  let progress = 0;
  ((window as any).UppyGlobalInstances || []).forEach((item: any) => {
    const state = item?.getState();
    if (!isEmpty(state.currentUploads) && state.totalProgress > 0 && state.totalProgress < 100) {
      all += 1;
      progress += state.totalProgress;
    }
  });
  // console.log('progress', all ? progress / all : 0);
  setState(all ? progress / all : 0);
};

export const cleanUppy = () => {
  const uppy = (window as any).UppyGlobalInstances[(window as any).UppyGlobalInstances.length - 1];
  const progress = uppy.getState().totalProgress;
  const currentUploads = uppy.getState().currentUploads;
  if (isEmpty(currentUploads) && (progress === 100 || progress === 0)) {
    (window as any).UppyGlobalInstances.pop();
  }
};

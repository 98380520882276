import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';

export const getUserTagsStatistics = (data: any): Promise<any> =>
  api()
    .post(`/portal-api/reports/userTagsStatistics`, data, {
      signal: (window as any).myAbortController.signal,
    })
    .then((response) => get(response, 'data'))
    .catch((error) => false);

import useAlerts from 'components/Alerts/useAlerts';
import { isArray, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useActions } from 'utils/hooks/useActions';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { generateIID } from 'utils/study';

const useSendDocument = () => {
  const { archives } = useAppInfo();
  const { addErrorAlert } = useAlerts();
  const { resetStudies, setBulkStudies } = useActions();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const prepareObjectForGetDocumentDetail = (selectedDocuments: any[]) => {
    let documents: any[] = [];
    let masterPatientId = null;
    let facilities: string[] = [];

    for (const selectedDocument of selectedDocuments) {
      if (masterPatientId == null) {
        masterPatientId = selectedDocument.patient.masterPatientID;
      }
      facilities.push(selectedDocument.facility);

      const typeOfStudy = selectedDocument.typeOfDocument.name;
      const uuid = selectedDocument.uuid;
      documents?.push({ typeOfDocument: typeOfStudy, uuid });
    }

    return {
      masterPatientId,
      facilities: uniq(facilities),
      documents,
    };
  };

  const prepareObjectForGetStudyByStudyIdentity = (getDocumentDetailResponse: any) => {
    let studyIdentities: any[] = [];
    for (const requestedDocument of getDocumentDetailResponse?.requestedDocuments) {
      const requestedDocumentData = JSON.parse(requestedDocument.document.data);
      const studyUid = requestedDocumentData.studyUid;
      for (const archive of archives) {
        studyIdentities.push({ archive: { id: archive.id }, studyInstanceUid: studyUid });
      }
    }
    return studyIdentities;
  };

  const setBulkStudiesForSendAndNavigateToStudyDetail = (
    studiesResponse: any,
    masterPatientId: string,
  ) => {
    if (isArray(studiesResponse) && studiesResponse.length > 0) {
      for (const study of studiesResponse) {
        study.iid = generateIID(study);
      }
      resetStudies('send');
      setBulkStudies({
        type: 'send',
        items: studiesResponse,
      });
      navigate(`/studies/send/detail?from=clinicPortal&backTo=${masterPatientId}`);
    } else {
      addErrorAlert(t('noStudiesFoundInArchive'));
    }
  };

  return {
    prepareObjectForGetDocumentDetail,
    prepareObjectForGetStudyByStudyIdentity,
    setBulkStudiesForSendAndNavigateToStudyDetail,
  };
};

export default useSendDocument;

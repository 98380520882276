import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find, get, isArray } from 'lodash';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DataGridPro, GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { deleteCertificate, getAllCertificates, updateCertificate } from './_api';
import { ICertificate, ICertificatesComponent } from './_types';
import CertificateAttachmentForm from './CertificateAttachmentForm';

import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Header from 'components/Header/Header';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';

import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { DATA_GRID_ROW_HEIGHT } from 'constants/constants';

const muiGridKey = 'userProfileertificatesComponentMui';

const CertificatesComponent: React.FC<ICertificatesComponent> = ({
  userCertificates,
  username,
}) => {
  const { t } = useTranslation('Certificates');
  const dispatch = useAppDispatch();
  const { compactMode, confirmationData } = useAppInfo();
  const gridLocalization = useGridLocalization();

  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [dialogState, setDialogState] = useState<boolean>(false);

  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const { toggleLoader } = useAppGlobals();

  const loadCertificates = useMemo(
    () => async () => {
      toggleLoader();
      const data = await getAllCertificates();
      if (isArray(data)) {
        const user = find(data, { username });
        const certificates: ICertificate[] = get(user, 'certificates', []);
        setCertificates(certificates);
      }
      toggleLoader(false);
    },
    [toggleLoader, username],
  );

  useEffect(() => {
    setCertificates(userCertificates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onEntityRemove } = useEntityRemove(deleteCertificate, t, loadCertificates);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const changeCertificate = async (row: ICertificate, state: boolean) => {
    toggleLoader();
    const changed = await updateCertificate({
      id: row.id,
      name: row.certificateName,
      favourite: state,
    });
    if (changed) {
      await loadCertificates();
      addSuccessAlert(t('change.success'));
    } else {
      addErrorAlert(t('change.error'));
    }
    toggleLoader(false);
  };

  const confirmActionChangeDefault = () => {
    changeCertificate(get(confirmationData, 'id'), get(confirmationData, 'state'));
    clearAction();
  };

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          width: 250,
          renderCell: ({ row }: GridRenderCellParams) => (
            <>
              <Box sx={{ width: '200px' }}>
                <FormControlLabel
                  sx={{ maxHeight: 30 }}
                  control={
                    <Switch
                      checked={row.favourite}
                      onChange={(e, state) => {
                        dispatch(
                          storeConfirmationDataToStore({
                            title: 'Grid:confirmChangeState',
                            id: row,
                            state,
                            confirmAction: 'confirmActionChangeDefault',
                            editDialog: true,
                          }),
                        );
                      }}
                    />
                  }
                  label={t('Grid:default') as string}
                />
              </Box>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id'), editDialog: true }));
                }}
              />
            </>
          ),
        },
        { field: 'certificateName', headerName: t('certificateName') },
      ],
      200,
    ),
  );

  const handleDialog = (state: boolean = true) => {
    setDialogState(state);
  };

  return (
    <Stack
      sx={{ py: compactMode ? 0.5 : 2, height: '100%', boxSizing: 'border-box' }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: 'auto', width: '95%', ...(compactMode ? { p: 0 } : { p: 1 }) }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Header
            title={t('Users:userProfileCertificates')}
            addItemInDialog={handleDialog}
            toggleDialogText={t('add')}
            topMargin={true}
          />
          <DataGridPro
            autoHeight={true}
            rows={certificates}
            columns={columns}
            rowHeight={DATA_GRID_ROW_HEIGHT}
            density={compactMode ? 'compact' : 'standard'}
            localeText={gridLocalization}
            hideFooter={true}
            initialState={{
              sorting: {
                sortModel: [{ field: 'certificateName', sort: 'asc' }],
              },
            }}
          />
        </Stack>
      </Paper>
      {confirmationData && confirmationData.id && confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeDefault'
              ? confirmActionChangeDefault
              : confirmAction
          }
        />
      )}
      <Dialog
        open={dialogState}
        onClose={() => handleDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{t('dialog_attachment')}</DialogTitle>
        <DialogContent>
          {dialogState && (
            <CertificateAttachmentForm
              loadEntities={loadCertificates}
              handleDialog={handleDialog}
              username={username}
            />
          )}
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default CertificatesComponent;

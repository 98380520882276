import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  each,
  find,
  get,
  isArray,
  isEmpty,
  isNumber,
  isPlainObject,
  isString,
  orderBy,
  reject,
  remove,
  sortBy,
  split,
} from 'lodash';
import { Box, Typography } from '@mui/material';
import queryString from 'query-string';

import {
  createPrintablePDF,
  deleteStudy,
  getSeries,
  getStatusOfStudy,
  getStudy,
  getUrlForOpenViewer,
  getValuesFromDicomFile,
  reopenStudy,
  searchWithoutAccessControlForTimeline,
  searchWithoutAccessControlGetFromTimeline,
  getAllStudyDetailBookmarks,
} from './_api';
import { ISelectItem, IStudy } from './_types';
import { checkStudyOrderWithComment } from 'modules/Orders/_api';

import { getActiveFacility } from 'modules/Administration/Facilities/_api';
import { setTimelineOrderDirection } from 'modules/Administration/Users/_api';

import { decryptId } from 'utils/hooks/useApp';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { decodeIID, generateIID } from 'utils/study';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { settingHasValue } from 'utils/products';
import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { usePreview, getPreview } from 'utils/hooks/usePreview';
import { useStudy } from 'utils/hooks/useStudy';
import { useActions } from 'utils/hooks/useActions';
import { printOrDownloadBase64String } from 'utils/hooks/useApp';
import { stateIsSame } from 'utils/componentOptimizatons';

import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import useAlerts from 'components/Alerts/useAlerts';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import StudyDetailPrint from './StudyDetailPrint';
import { StudyDetailTimelineTabs } from './StudyDetailTimelineTabs';
import { StudyDetailInfo } from './StudyDetailInfo';
import { StudyDetailButtons } from './StudyDetailButtons';
import { StudyDetailTabs } from './StudyDetailTabs';
import { StudyDetailPatientInfo } from './StudyDetailPatientInfo';
import { ChangeStatusesDialog } from './ChangeStatusesDialog';
import StudyDetailStructuredReport from './StudyDetailStructuredReport';
import GetDicomAttributes from 'components/Image/GetDicomAttributes';
import {
  ARCHIVE_TYPE_NAME_MS,
  ASC,
  CERTIFICATE_NOT_FOUND,
  DESC,
  ERROR,
  FORBIDDEN,
  INCORRECT_CERTIFICATE_PASSWORD,
  STUDY_AVAILABILITY_ARCHIVED,
  TAB_COMMENTS,
  TAB_OPERATIONS,
} from 'constants/constants';
import StudyDetailImportWebcam from './StudyDetailImportWebcam';
import StudyDetailImportImage from './StudyDetailImportImage';
import { TourStudyDetail } from './TourStudyDetail';
import { userOpenStudy } from 'modules/Administration/Folders/_api';
import { getAllCertificatesForUser } from 'modules/Administration/Certificates/_api';

const reverseStudies = (studies: any) => {
  const reversedArray: any[] = [];
  each(studies, (elem: any) => {
    reversedArray.unshift(elem);
  });
  return reversedArray;
};

const PlainStudyDetail: React.FC = () => {
  const { t } = useTranslation('StudyDetail');
  const { title } = useWithEntityTitle(false, null, t);
  const { modNoShow, products, realisator } = usePreview();
  const params = useParams();
  const tabToReturn = get(queryString.parse(window.location.search), 'tab', '');
  const { toggleLoader, toggleTimelineOrder } = useAppGlobals();
  const { hasRole } = useUser();
  const { storeSearchResults, storeConfirmationData } = useActions();

  const [archiveTypeName, setArchiveTypeName] = useState<string>('');

  const { studyArchivation, studiesProposalsShredding, getStudyActions } = useStudy();

  const { addErrorAlert, addSuccessAlert, addInfoAlert } = useAlerts();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const {
    archivesForFunctions,
    confirmationData,
    portalProductSettings,
    searchResults,
    modalities,
    statuses,
    timelineOrder,
  } = useAppInfo();

  const [study, setStudy] = useState<IStudy | undefined>(undefined);
  const [series, setSeries] = useState<any[]>([]);
  const [printDialogState, setPrintDialogState] = useState<boolean>(false);
  const [changeStatusesDialog, setChangeStatusesDialog] = useState<boolean>(false);
  const [printSetting, setPrintSetting] = useState<any>(null);
  const [activeFacility, setActiveFacility] = useState<any>(null);
  const [forbiddenError, setForbiddenError] = useState<boolean>(false);
  const [canViewCommentaryMWL, setCanViewCommentaryMWL] = useState<boolean>(false);
  const [statusOfCode, setStatusOfCode] = useState<string>('');

  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [studyID, setStudyID] = useState<any>(null);
  const [archiveID, setArchiveID] = useState<any>(null);
  const [paramsStudyID, setParamsStudyID] = useState<any>(null);
  const [paramsArchiveID, setParamsArchiveID] = useState<any>(null);
  const [studiesForPatient, setStudiesForPatient] = useState<IStudy[]>([]);
  const [dicomData, setDicomData] = useState<any>(null);
  const [linkBack, setLinkBack] = useState<string>('');

  const [activeTab, setActiveTab] = useState<string>(tabToReturn as string);
  const [allStudyDetailBookmarks, setAllStudyDetailBookmarks] = useState<any[]>([]);

  const [accessionNumber, setAccessionNumber] = useState<any>(null);
  const [patientId, setPatientId] = useState<any>(null);
  const [hashIID, setHashIID] = useState<any>(null);
  const [order2, setOrder2] = useState<any>(null);
  const [RenderedChangeStatusesDialog, setRenderedChangeStatusesDialog] =
    useState<JSX.Element | null>(null);

  const [srDialogState, setSrDialogState] = useState<boolean>(false);
  const [openDicomAttributesDialog, setOpenDicomAttributesDialog] = useState<boolean>(false);
  const [importWebcamDialog, setImportWebcamDialog] = useState<boolean>(false);
  const [importImageDialog, setImportImageDialog] = useState<boolean>(false);
  const [certNameItems, setCertNameItems] = useState<ISelectItem[]>([]);

  const { seriesWithPreviewUrl, studyArchive } = useMemo(() => {
    let studyArchive = null;
    let seriesWithPreviewUrl = [];
    if (archiveID > 0 && isArray(products) && realisator && !isEmpty(series)) {
      studyArchive = find(products, ['id', parseInt(archiveID, 10)]) || null;
      seriesWithPreviewUrl = sortBy(
        series.map((serie, index) => {
          const preview = getPreview(archiveID, get(study, 'studyInstanceUid'), serie);

          return {
            ...serie,
            previewUrl: preview,
          };
        }),
        ['modality', 'numberSerie'],
      );
    }
    return { seriesWithPreviewUrl, studyArchive };
  }, [products, realisator, archiveID, series, study]);

  useMemo(() => {
    const series2 = [...series];
    if (isArray(series2)) {
      if (isArray(modNoShow)) {
        modNoShow.forEach((mod) => {
          remove(series2, { modality: mod });
        });
      }
      if (series.length !== series2.length) {
        setSeries(series2);
      }
    }
  }, [modNoShow, series]);

  useMemo(async () => {
    if (products && studyID && archiveID) {
      const selectedArchive = find(products, ['id', parseInt(archiveID, 10)]);
      const archiveTypeName = get(selectedArchive, 'type.name', '');
      setArchiveTypeName(archiveTypeName);
      if (archiveTypeName === ARCHIVE_TYPE_NAME_MS) {
        const statusOfCode = await getStatusOfStudy(archiveID, studyID);
        setStatusOfCode(statusOfCode ? statusOfCode : ERROR);
      }
    } else {
      setStatusOfCode('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products?.length, studyID, archiveID]);

  const getEntity = async () => {
    const from = searchParams.get('from');
    const backToNotifications = searchParams.get('backToNotifications');
    const backToRequest = searchParams.get('backToRequest');
    const backToPathname = searchParams.get('pathname');
    const aBackPathName = split(backToPathname, '/');
    let folderId = '';
    (aBackPathName || []).forEach((item, index) => {
      if (item === 'folders') {
        folderId = decryptId(aBackPathName[index + 1]);
      }
    });
    let linkBack = backToPathname
      ? `${backToPathname}?action=back${from ? `&from=${from}` : ''}`
      : isNumber(backToNotifications) && backToNotifications > 0
      ? `/notifications/${backToNotifications}`
      : isNumber(backToRequest) && backToRequest > 0
      ? `/requests/${backToRequest}`
      : from === 'notifications' || from === 'basket' || from === 'studies'
      ? `/${from}?action=back`
      : from === 'export'
      ? `/exports/export?action=back`
      : from === 'queue'
      ? `/queueRequests/?action=back`
      : from === 'globalStats'
      ? `/tests/globalStats?action=back`
      : from === 'unsignedStudies'
      ? `/signature/unsignedStudies?action=back`
      : from === 'archive'
      ? `/tags/archive?action=back`
      : from === '/requests/search'
      ? `/requests/search?action=back`
      : from
      ? `/${
          from === 'proposals' || from === 'histories' ? 'shreddings' : 'studies'
        }/${from}?action=back`
      : '/studies?action=back';

    // Special case for link back to clinical portal with full path specified in "pathBack" query parameter
    if (from === 'documents') {
      const pathBack = searchParams.get('pathBack');
      if (!pathBack) {
        return;
      }
      linkBack = pathBack;
    }

    setLinkBack(linkBack);

    setPrintSetting({
      printShowDialog: settingHasValue(portalProductSettings, 'printShowDialog', 'true'),
      printComments: settingHasValue(portalProductSettings, 'printComments', 'true'),
      printSeries: settingHasValue(portalProductSettings, 'printSeries', 'true'),
    });

    const certificates = await getAllCertificatesForUser();
    if (!isEmpty(certificates)) {
      setCertNameItems(
        sortBy(
          certificates.map((item) => ({
            id: get(item, 'id', 0).toString(),
            label: get(item, 'certificateName', ''),
            favourite: get(item, 'favourite', false),
          })),
          ['label'],
        ),
      );
    }

    const hashIID = get(params, 'iid', null);
    const decodeIid = decodeIID(decryptId(hashIID));
    const studyID = get(decodeIid, 'studyID', '');
    const archiveID = get(decodeIid, 'archiveID', '');
    setHashIID(hashIID);
    setParamsArchiveID(archiveID);
    setParamsStudyID(studyID);
    await handleLifeCycle(studyID, archiveID, true);
    if (folderId) {
      userOpenStudy({
        folderId: folderId,
        studyIdentity: { studyInstanceUid: studyID, archive: { id: archiveID, code: archiveID } },
      });
    }
  };

  const loadStudy = useCallback(
    async (studyID: string, archiveID: string, firstAction: boolean) => {
      const studies = firstAction
        ? await getStudy(archiveID, studyID)
        : await searchWithoutAccessControlGetFromTimeline(
            paramsArchiveID || archiveID,
            paramsStudyID || studyID,
            archiveID,
            studyID,
          );
      if (studies === FORBIDDEN) {
        setForbiddenError(true);
        return false;
      }
      let study = null;
      if (!isEmpty(studies)) {
        study = isArray(studies) ? studies[0] : studies;
      }
      setStudy(study);
      setIsLocked(get(study, 'inProcessing', false));
      return study;
    },
    [paramsArchiveID, paramsStudyID],
  );

  const loadStudiesByTimeline = useCallback(
    async (studyID: string, archiveID: string, patientId: string) => {
      if (hasRole('ROLE_CAN_SEE_TIMELINE')) {
        const archives = get(archivesForFunctions, 'SEARCH', []);
        if (patientId && !isEmpty(archives)) {
          const studiesForPatient = await searchWithoutAccessControlForTimeline(
            archiveID,
            studyID,
            patientId,
            archives.map((archive: any) => archive.id),
          );
          if (isArray(studiesForPatient)) {
            const studies = orderBy(
              studiesForPatient.map((result: any) => ({
                ...result,
                iid: generateIID(result),
              })),
              ['dateTime', 'desc'],
            );
            setStudiesForPatient(timelineOrder === DESC ? reverseStudies(studies) : studies);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [archivesForFunctions],
  );

  const handleLifeCycle = useCallback(
    async (
      studyID: string,
      archiveID: string,
      firstAction: boolean,
      activeTab = tabToReturn as string,
      deleteSerie: boolean = false,
    ) => {
      toggleLoader(true, false, 'handleLifeCycle');

      let accessionNumber = '';
      let patientId = '';
      setStudyID(studyID);
      setArchiveID(archiveID);
      if (activeTab) {
        setActiveTab(TAB_COMMENTS);
      }
      const study = await loadStudy(studyID, archiveID, firstAction);
      if (!isEmpty(study)) {
        const series = await getSeries(parseInt(archiveID, 10), studyID);

        if (firstAction) {
          const [activeFacility, allStudyDetailBookmarks] = await Promise.all([
            getActiveFacility(),
            getAllStudyDetailBookmarks(),
          ]);
          if (activeFacility) {
            setActiveFacility(activeFacility);
          }
          if (isArray(allStudyDetailBookmarks)) {
            setAllStudyDetailBookmarks(allStudyDetailBookmarks);
          }
        }

        accessionNumber = get(study, 'accessionNumber', '');
        patientId = get(study, 'patient.identificationNumber', '');

        setAccessionNumber(accessionNumber);
        setPatientId(patientId);

        if (isArray(series)) {
          if (isArray(modNoShow)) {
            modNoShow.forEach((mod) => {
              remove(series, { modality: mod });
            });
          }
          setSeries(
            sortBy(
              series.map((serie) => {
                return {
                  ...serie,
                  id: get(serie, 'uid'),
                  numberSerie: isString(get(serie, 'seriesNumber'))
                    ? parseInt(get(serie, 'seriesNumber'), 10)
                    : 0,
                  study: {
                    studyInstanceUid: get(study, 'studyInstanceUid', ''),
                    patientIdentificationNumber: patientId,
                    archiveId: get(study, 'archive.id', ''),
                  },
                  previewUrl: null,
                };
              }),
              ['modality', 'numberSerie'],
            ),
          );
        }
      }
      toggleLoader(false, false, 'handleLifeCycle');

      if (!isEmpty(study)) {
        if (hasRole('ROLE_VIEW_COMMENTARY_MWL')) {
          const canViewCommentaryMWL = await checkStudyOrderWithComment(patientId, accessionNumber);
          if (canViewCommentaryMWL) {
            setCanViewCommentaryMWL(true);
          }
        }

        if (firstAction || deleteSerie) {
          loadStudiesByTimeline(studyID, archiveID, patientId);
        }
        setDicomData(null);
        const valuesFromDicomFile = await getValuesFromDicomFile(parseInt(archiveID, 10), studyID, {
          REFERRING_PHYSICIAN_NAME: 'NAME',
          REQUESTING_PHYSICIAN_NAME: 'NAME',
          PERFORMING_PHYSICIAN_NAME: 'NAME',
          PATIENT_WEIGHT: 'STRING',
          PATIENT_HEIGHT: 'STRING',
          INSTITUTION_NAME: 'STRING',
        });

        if (isPlainObject(valuesFromDicomFile)) {
          setDicomData({
            height: `${get(valuesFromDicomFile, 'PATIENT_HEIGHT', '-')} m`,
            weight: `${get(valuesFromDicomFile, 'PATIENT_WEIGHT', '-')} kg`,
            institutionName: `${get(valuesFromDicomFile, 'INSTITUTION_NAME', '-')}`,
            performingPhysician: get(valuesFromDicomFile, 'PERFORMING_PHYSICIAN_NAME')
              ? JSON.parse(get(valuesFromDicomFile, 'PERFORMING_PHYSICIAN_NAME'))
              : null,
            referringPhysician: get(valuesFromDicomFile, 'REFERRING_PHYSICIAN_NAME')
              ? JSON.parse(get(valuesFromDicomFile, 'REFERRING_PHYSICIAN_NAME'))
              : null,
            requestingPhysician: get(valuesFromDicomFile, 'REQUESTING_PHYSICIAN_NAME')
              ? JSON.parse(get(valuesFromDicomFile, 'REQUESTING_PHYSICIAN_NAME'))
              : null,
          });
        } else {
          setDicomData({
            height: '-',
            weight: '-',
            institutionName: '-',
            performingPhysician: '-',
            referringPhysician: '-',
            requestingPhysician: '-',
          });
        }
        setActiveTab(activeTab);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [archivesForFunctions],
  );

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archivesForFunctions]);

  useEffect(() => {
    const RenderedChangeStatusesDialog =
      changeStatusesDialog && study ? (
        <ChangeStatusesDialog
          key={TAB_OPERATIONS}
          changeStatusesDialog={changeStatusesDialog}
          setChangeStatusesDialog={setChangeStatusesDialog}
          studies={[study]}
          handleLifeCycle={handleLifeCycle}
        />
      ) : null;
    setRenderedChangeStatusesDialog(RenderedChangeStatusesDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study, changeStatusesDialog]);

  const reopen = useCallback(
    async (studyID: string, archiveID: string) => {
      toggleLoader();
      try {
        const resp = await reopenStudy(archiveID, studyID);
        toggleLoader(false);
        if (resp === FORBIDDEN) {
          setForbiddenError(true);
        } else {
          resp
            ? addSuccessAlert(t(`studyIsReopening.ok`))
            : addErrorAlert(t(`studyIsReopening.error`));
        }
      } catch (e) {
        toggleLoader(false);
        addErrorAlert(t(`studyIsReopening.error`));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const studyActions = getStudyActions(study, isLocked, studyArchive, archiveTypeName);

  const createPdfAndPrint = useCallback(
    async (printSetting: any) => {
      const signatureContext = get(printSetting, 'signatureContext', null);
      const data = {
        pdfOptions: {
          printDetail: true,
          printComments: get(printSetting, 'printComments', true),
          printSeries: get(printSetting, 'printSeries', false),
          ...(signatureContext ? { signatureContext } : {}),
        },
        studyIdentity: {
          archive: { id: archiveID * 1 },
          studyInstanceUid: studyID,
        },
      };
      const base64PdfString = await createPrintablePDF(data);
      if (base64PdfString === INCORRECT_CERTIFICATE_PASSWORD) {
        addErrorAlert(t(INCORRECT_CERTIFICATE_PASSWORD));
      } else if (base64PdfString === CERTIFICATE_NOT_FOUND) {
        addErrorAlert(t(CERTIFICATE_NOT_FOUND));
      } else if (base64PdfString === false) {
        addErrorAlert(t(`createPdfError`));
      } else {
        const contentType = 'application/pdf';
        const fileName = 'study-detail.pdf';
        printOrDownloadBase64String(base64PdfString, contentType, fileName);
      }
    },
    [studyID, archiveID, addErrorAlert, t],
  );

  const onViewer = useCallback(() => {
    const patientId = get(study, 'patient.identificationNumber', '');
    getUrlForOpenViewer(studyID, patientId, archiveID).then(
      (response) => window.open(response, '_blank'),
      (error) => console.log(error),
    );
  }, [studyID, archiveID, study]);

  const moveToTrash = useCallback(
    async () => {
      toggleLoader();
      const response = await deleteStudy(
        get(study, 'studyInstanceUid', ''),
        get(study, 'archive.id')!,
      );
      if (response) {
        const iid = generateIID(study as IStudy);
        const output = reject(searchResults, (item: any) => item.iid === iid);
        storeSearchResults(output);
        addSuccessAlert(t('deleteOk'));
        navigate(linkBack);
      } else {
        addErrorAlert(t('deleteNok'));
      }
      toggleLoader(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [study],
  );

  const proposalShredding = async () => {
    studiesProposalsShredding({
      //...props,
      // studies,
      t,
      // setInfoNotification,
      addInfoAlert,
      addSuccessAlert,
      addErrorAlert,
      study,
      // callback,
    });
  };

  const archive = async () => {
    studyArchivation({
      study,
      toggleLoader,
      t,
      addSuccessAlert,
      addErrorAlert,
      callback: () =>
        setStudy(study ? { ...study, availability: STUDY_AVAILABILITY_ARCHIVED } : undefined),
    } as any);
  };

  const clearAction = () => {
    storeConfirmationData(null);
  };
  const cancelAction = () => {
    clearAction();
  };
  const confirmAction = async () => {
    if (get(confirmationData, 'action', null) === 'moveToTrash') {
      moveToTrash();
    } else if (get(confirmationData, 'action', null) === 'proposalShredding') {
      proposalShredding();
    } else if (get(confirmationData, 'action', null) === 'archive') {
      archive();
    }
    clearAction();
  };

  const toggleImportWebcamDialog = (state: boolean = true) => {
    setImportWebcamDialog(state);
  };

  const toggleImportImageDialog = (state: boolean = true) => {
    setImportImageDialog(state);
  };

  const handleTimelineOrderChange = async () => {
    try {
      setStudiesForPatient(reverseStudies(studiesForPatient)); // Change timeline order
      const newTimelineOrder = timelineOrder === ASC ? DESC : ASC;
      toggleTimelineOrder(newTimelineOrder); // Change it in redux store
      await setTimelineOrderDirection(newTimelineOrder); // Change it in user settings via API
    } catch (e) {}
  };

  const renderedSteps = () => {
    return <TourStudyDetail type="main" showTimelineStep={studiesForPatient.length > 1} />;
  };

  return (
    <>
      <Typography component="div" sx={{ displayPrint: 'none' }}>
        <Header
          title={title}
          backUrl={linkBack}
          isLocked={isLocked}
          TourComponent={renderedSteps()}
        />
        {study === undefined ? (
          <div />
        ) : isEmpty(study) && !forbiddenError ? (
          <Papeer>{`${t('studyIsNotAvailable')} ${get(studyArchive, 'name', '')}`}</Papeer>
        ) : isEmpty(study) && forbiddenError ? (
          <Papeer>{`${t('forbidden')}`}</Papeer>
        ) : (
          <>
            <Box data-tour="study-detail-headerSection">
              <StudyDetailPatientInfo study={study} />
            </Box>

            <Box data-tour="study-detail-timeline">
              <StudyDetailTimelineTabs
                studiesForPatient={studiesForPatient}
                studyID={studyID}
                archiveID={archiveID}
                handleLifeCycle={handleLifeCycle}
                modalities={modalities}
                handleTimelineOrderChange={handleTimelineOrderChange}
              />
            </Box>

            <Papeer>
              <StudyDetailInfo
                onViewer={onViewer}
                isLocked={isLocked}
                study={study}
                dicomData={dicomData}
                series={series}
                reopen={reopen}
                statusOfCode={statusOfCode}
                statuses={statuses}
              />
              <StudyDetailButtons
                printSetting={printSetting}
                studyActions={studyActions}
                setStudy={setStudy}
                study={study}
                setPrintDialogState={setPrintDialogState}
                createPdfAndPrint={createPdfAndPrint}
                setChangeStatusesDialog={setChangeStatusesDialog}
                setSrDialogState={setSrDialogState}
                setOpenDicomAttributesDialog={setOpenDicomAttributesDialog}
                toggleImportWebcamDialog={toggleImportWebcamDialog}
                toggleImportImageDialog={toggleImportImageDialog}
              />
            </Papeer>

            <StudyDetailTabs
              canViewCommentaryMWL={canViewCommentaryMWL}
              study={study}
              handleLifeCycle={handleLifeCycle}
              studyActions={studyActions}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              accessionNumber={accessionNumber}
              patientId={patientId}
              hashIID={hashIID}
              order2={order2}
              setOrder2={setOrder2}
              allStudyDetailBookmarks={allStudyDetailBookmarks}
              seriesWithPreviewUrl={seriesWithPreviewUrl}
              certNameItems={certNameItems}
            />
          </>
        )}
      </Typography>

      <StudyDetailPrint
        printSetting={printSetting}
        setPrintSetting={setPrintSetting}
        printDialogState={printDialogState}
        setPrintDialogState={setPrintDialogState}
        study={study}
        series={seriesWithPreviewUrl}
        activeFacility={activeFacility}
        createPdfAndPrint={createPdfAndPrint}
        certNameItems={certNameItems}
      />

      {RenderedChangeStatusesDialog}

      {confirmationData && confirmationData.action && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('confirm')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={cancelAction}
          confirmAction={confirmAction}
        >
          <Typography variant="body1">{confirmationData.content}</Typography>
        </ConfirmationDialog>
      )}

      <StudyDetailStructuredReport
        srDialogState={srDialogState}
        setSrDialogState={setSrDialogState}
        study={study}
      />

      {openDicomAttributesDialog && (
        <GetDicomAttributes
          study={study}
          openDicomAttributesDialog={openDicomAttributesDialog}
          setOpenDicomAttributesDialog={setOpenDicomAttributesDialog}
        />
      )}

      {importWebcamDialog && (
        <StudyDetailImportWebcam
          toggleImportWebcamDialog={toggleImportWebcamDialog}
          importWebcamDialog={importWebcamDialog}
          study={study}
        />
      )}

      {importImageDialog && (
        <StudyDetailImportImage toggleImportImageDialog={toggleImportImageDialog} study={study} />
      )}
    </>
  );
};

export const StudyDetail = React.memo(PlainStudyDetail, stateIsSame);

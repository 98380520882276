import { useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { find, get, isArray, isEmpty } from 'lodash';
import { Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import {
  COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
  COMPACT_MODE_FORM_ELEMENT_HEIGHT,
  COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
} from 'constants/constants';
import { useDispatch } from 'react-redux';
import { setTourControlPropsToStore } from 'store/reducers/appReducer';

const MultiSelect = ({
  control,
  name,
  label,
  required,
  disabled = false,
  defaultValue = '',
  items,
  sx,
  trigger,
  includeAllOption,
  validationMode,
}: any) => {
  const { compactMode } = useAppInfo();
  const {
    field: { ref, value, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });
  const {
    formState: { errors },
  } = useFormContext();
  let isError = false;
  let errorMessage = '';
  if (!isEmpty(errors) && get(errors, name) !== undefined) {
    isError = true;
    errorMessage = get(errors, `${name}.message`, '') as string;
  }
  const inputLabel = useRef(null);

  const { t } = useTranslation('SearchForm');
  const dispatch = useDispatch();

  const handleChange = (event: any) => {
    let newValue = event.target.value;

    // Check if "All" has been selected
    if (newValue.includes('selectDeselectAllItems')) {
      // If all items are already selected, deselect them all. Otherwise, select them all.
      newValue = newValue.length - 1 === items.length ? [] : items.map((item: any) => item.id);
    }

    setNewValueToReactHookForm(newValue); // Update the value
    // trigger(name); // Manually trigger validation
  };

  const setNewValueToReactHookForm = (newValue: any) => {
    inputProps.onChange(newValue);
  };

  //For managing tour logic - timeout to give time for dropdown to render.
  const handleOpen = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: true, currentOpenDropdown: name }));
    }, 150);
  };

  const handleClose = (element: any) => {
    setTimeout(() => {
      dispatch(setTourControlPropsToStore({ isDropDownOpen: false, currentOpenDropdown: null }));
    }, 150);
  };

  return (
    <FormControl size="small" fullWidth={true} margin="dense" disabled={disabled} error={isError}>
      <InputLabel
        ref={inputLabel}
        htmlFor={name}
        sx={
          compactMode
            ? {
                fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
                lineHeight: COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT,
              }
            : {}
        }
      >
        {label}
        {required ? <span className="req-label">*</span> : null}
      </InputLabel>
      <Select
        id={name}
        labelId={`outlined-${name}`}
        // kvuli tomu, ze autofill v edgi vraci hodnotu jako string, tak se testuje, jestli je value pole, když ne, tak se string hodí do pole
        value={value ? (isArray(value) ? value : [value]) : []}
        {...inputProps}
        label={label}
        size="small"
        multiple={true}
        inputProps={{
          name,
          id: `input_${name}`,
        }}
        sx={{
          ...sx,
          ...(compactMode
            ? {
                fontSize: COMPACT_MODE_FORM_ELEMENT_FONT_SIZE,
                height: COMPACT_MODE_FORM_ELEMENT_HEIGHT,
              }
            : {}),
        }}
        inputRef={ref}
        onChange={handleChange}
        MenuProps={{
          classes: { paper: 'select-react-tour-class' },
        }}
        onOpen={handleOpen}
        onClose={handleClose}
        // {...(setIsOpen && { onOpen: handleOpen })}
        // {...(setIsOpen && { onClose: handleClose })}
        renderValue={(selected: any) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: 0.5,
              maxWidth: '100%',
              overflow: 'hidden',
            }}
            // data-tour="search-header-searchForm-selectedItems"
          >
            {
              // kvuli tomu, ze autofill v edgi vraci hodnotu jako string, tak se testuje, jestli je selected pole, když ne, tak se string hodí do pole
              (isArray(selected) ? selected : [selected]).map((selectedValue: any) => {
                const labelForValue = find(items, { id: selectedValue });
                return (
                  <Chip
                    key={selectedValue}
                    label={get(labelForValue, 'label', value)}
                    size="small"
                    sx={{ height: 20 }}
                    onDelete={() => {
                      setNewValueToReactHookForm(
                        value.filter((item: any) => item !== selectedValue),
                      );
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  />
                );
              })
            }
          </Box>
        )}
      >
        {includeAllOption && (
          <MenuItem value="selectDeselectAllItems">{t('selectDeselectAll')}</MenuItem>
        )}
        {isArray(items)
          ? items.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))
          : null}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelect;

// eslint-disable-next-line no-lone-blocks
{/* prettier-ignore */}
const yesno = {
  yes: 'ano',
  no: 'ne',
};

const sexes = {
  sexesAll: 'Pohlaví - Vše',
  female: 'Žena',
  male: 'Muž',
  different: 'Jiné',

  patient_sex: 'Pohlaví',
  patient_sex_F: 'Žena',
  patient_sex_M: 'Muž',
  patient_sex_O: 'Jiné',

  sex_F: 'žena',
  sex_M: 'muž',
  sex_O: 'jiné',
};

const studyStates = {
  studyStatus: 'Stav vyšetření',
  studyStatuses: 'Stavy vyšetření',
  Closed: 'Uzavřeno',
  InProgress: 'Rozpracováno',
  SecondReading: 'Druhé čtení',
  Urgent: 'Urgentní',
  ForShredding: 'Ke skartaci2',
  AI: {
    Doubt: 'AI: podezření',
    Normal: 'AI: normální',
    Abnormal: 'AI: abnormální',
    Mammo: {
      SuspBenign: 'AI: mamo susp. benigní',
      Benign: 'AI: mamo benigní',
      SuspMalignant: 'AI: mamo susp. maligní',
      Malignant: 'AI: mamo maligní',
      Normal: 'AI: mamo bez nálezu',
    },
  },
};

const importTitle = {
  import: {
    dicom: 'Import DICOM dat',
    nondicom: 'Import multimediálních dat',
    worklist: 'Import dat z worklistu',
  },
};

const name = {
  lastName: 'Příjmení',
  firstName: 'Křestní jméno',
  middleName: 'Prostřední jméno',
  prefix: 'Tituly před',
  suffix: 'Tituly za',
};

const checkName = {
  errorPacientName: 'Celé jméno pacienta může být dlouhé maximálně {{maxNameLength}} znaků.',
  errorRequestingPhysicianName:
    'Celé jméno žádajícího lékaře může být dlouhé maximálně {{maxNameLength}} znaků.',
  errorReferringPhysicianName:
    'Celé jméno vyšetřujícího lékaře může být dlouhé maximálně {{maxNameLength}} znaků.',
  errorPerformingPhysicianName:
    'Celé jméno provádějícího lékaře může být dlouhé maximálně {{maxNameLength}} znaků.',
};

const allowedExtensions = {
  allowedExtensions: 'Jsou povoleny soubory ve formátu {{rules}}. ',
  noFiles: 'Nejsou vybrány žádné soubory',
  moreFiles: 'Je vybráno mnoho souborů (povolen pouze 1)',
};

const sizeAndWeight = {
  patientSize: 'Výška pacienta',
  patientWeight: 'Váha pacienta',
  sizeLabel: 'Výška pacienta (m)',
  weightLabel: 'Váha pacienta (kg)',
  sizeTooltip: 'Hodnota v den vyšetření udávána v metrech',
  weightTooltip: 'Hodnota v den vyšetření udávána v kilogramech',
  sizePlaceHolder: 'Např. 1.73',
  weightPlaceHolder: 'Např. 72.5',
  errorPatientSizeMoreThen:
    'Výška pacienta je větší než {{maxPatientSize}} metrů. Opravdu chcete tuto hodnotu uložit?',
  errorPatientSizeNegative: 'Výška pacienta nemůže být záporná.',
  errorPatientWeightNegative: 'Váha pacienta nemůže být záporná.',
  errorPatientSizeLength: 'U výšky pacienta lze zadat maximálně 16 znaků',
  errorPatientWeightLength: 'U váhy pacienta lze zadat maximálně 16 znaků',
  errorNoChange: 'Ve formuláři nejsou žádné změny. Nelze odeslat.',
  loadingValue: 'Hodnota se načítá...',
};

const openInBrowser = {
  openInBrowserTitle: 'Zobrazit v prohlížeči',
  showPreviewTitle: 'Zobrazit náhled',
  closePreview: 'Zavřít náhled',
};

const select = {
  select: 'Vybrat...',
};

const eventTypes = {
  nok: {
    1: 'Chyba při zpracování.',
    2: 'Chyba při ukládání.',
    12: 'Chyba při zpracování a ukládání.',
    unknown: 'Neznámá chyba.',
  },
  errorType: {
    1: 'Chyba při zpracování. Možné příčiny: Realizátoru se nepodařilo zpracovat požadavek.',
    2: 'Chyba při ukládání. Možné příčiny: Vyšetření je již v archivu. Vyšetření má nepodporovaný formát. Archiv odmítl uložit vyšetření z jiného důvodu.',
  },

  event: {
    type: {
      update: {
        study: {
          ok: 'Oprava vyšetření provedena',
          nok: 'Oprava vyšetření se nezdařila.',
          request: { new: 'Nová žádost na úpravu vyšetření' },
        },
        patient: {
          ok: 'Oprava pacienta provedena v archivu ',
          nok: 'Oprava pacienta se nezdařila v archivu ',
        },
      },
      upload: {
        study: {
          ok: 'Upload vyšetření proveden',
          nok: {
            default: 'Upload vyšetření se nezdařil.',
            1: 'Soubory nelze zpracovat.',
            2: 'Soubory nelze uložit.',
            12: 'Soubory nelze zpracovat a uložit.',
          },
          warn: {
            default: 'Upload proběhl částečně',
            1: 'Bylo zpracováno {{par1}} z {{par2}} souborů. Chybné soubory nelze zpracovat.',
            2: 'Bylo zpracováno {{par1}} z {{par2}} souborů. Chybné soubory nelze uložit.',
            12: 'Bylo zpracováno {{par1}} z {{par2}} souborů. Chybné soubory nelze zpracovat a uložit.',
          },
        },
      },
      send: {
        study: {
          ok: 'Odeslání vyšetření provedeno.',
          nok: 'Odeslání vyšetření se nezdařilo.',
          cancelled: {
            default: 'Odeslání vyšetření zrušeno',
            nok: 'Zrušení odeslání vyšetření se nezdařilo.',
          },
        },
        files: {
          ok: 'Požadavek na odeslání souborů byl proveden.',
          nok: 'Požadavek na odeslání souborů se nezdařil.',
          cancelled: {
            default: 'Požadavek na odeslání souborů byl zrušen.',
            nok: 'Požadavek na odeslání souborů se nezdařil.',
          },
        },
      },
      share: {
        study: {
          ok: 'Sdílení vyšetření proběhlo úspěšně',
          nok: 'Sdílení vyšetření neproběhlo úspěšně.',
          request: { new: 'Nová žádost na sdílení vyšetření' },
        },
        send: {
          ok: 'Sdílení vyšetření provedeno',
          nok: 'Sdílení vyšetření se nezdařilo.',
        },
        receive: 'Příchozí sdílení vyšetření',
      },
      study: {
        request: {
          created: 'Žádost vytvořena pro ',
          approved: 'Žádost byla schválena',
          cancelled: 'Žádost byla zrušena',
          denied: 'Žádost byla zamítnuta',
        },
      },
      delete: {
        study: {
          ok: 'Smazání vyšetření provedeno',
          nok: 'Smazání vyšetření se nezdařilo.',
        },
      },
      split: {
        study: {
          ok: 'Rozdělení vyšetření provedeno',
          nok: 'Rozdělení vyšetření se nezdařilo.',
        },
      },
      reorder: {
        study: {
          ok: 'Přeskupení vyšetření provedeno',
          nok: 'Přeskupení vyšetření se nezdařilo.',
        },
      },
      distribute: {
        move: {
          ok: 'Přesunutí vyšetření provedeno',
          nok: 'Přesunutí vyšetření se nezdařilo.',
          and: {
            update: {
              ok: 'Přesunutí a oprava vyšetření provedena',
              nok: 'Přesunutí a oprava vyšetření se nezdařila.',
            },
          },
        },
        copy: {
          ok: 'Kopírování vyšetření provedeno',
          nok: 'Kopírování vyšetření se nezdařilo.',
          and: {
            update: {
              ok: 'Kopírování a oprava vyšetření provedena',
              nok: 'Kopírování a oprava vyšetření se nezdařila.',
            },
          },
        },
      },
      new: {
        facility: 'Nové zdravotnické zařízení',
      },
      signature: {
        ok: 'Vyšetření bylo podepsáno',
        nok: 'Vyšetření nebylo podepsáno.',
      },
      reopen: {
        study: {
          ok: 'Znovuotevření vyšetření provedeno',
          nok: 'Znovuotevření vyšetření se nezdařilo.',
        },
      },
      create: {
        sr: {
          ok: 'Znovuotevření vyšetření provedeno',
          nok: 'Znovuotevření vyšetření se nezdařilo.',
        },
      },
      export: {
        study: {
          ok: 'Export vyšetření proveden',
          nok: 'Export vyšetření se nezdařil.',
          cancelled: {
            default: 'Export vyšetření zrušen',
            nok: 'Zrušení exportu vyšetření se nezdařilo.',
          },
        },
      },
      import: {
        study: {
          cancelled: {
            default: 'Import vyšetření zrušen',
            nok: 'Zrušení importu vyšetření se nezdařilo.',
          },
        },
      },
      shredding: {
        study: {
          ok: 'Skartace vyšetření provedena',
          nok: 'Skartace vyšetření se nezdařila.',
        },
      },
    },
  },
};

const reactionTypes = {
  reaction: { type: { popup: 'Vyskakovací okno', email: 'E-mail' } },
  popup: 'Vyskakovací okno',
  email: 'E-mail',
};

const dialogTexts = {
  save: 'Uložit',
  close: 'Zavřít',
  confirm: 'Potvrdit',
  delete: 'Smazat',
  send: 'Odeslat',
};

const messagesForGrid = {
  filterPlaceholder: 'Filtr...',
  contains: 'Obsahuje',
  notContains: 'Neobsahuje',
  startsWith: 'Začíná na',
  endsWith: 'Končí na',
  equal: 'Rovná se',
  notEqual: 'Nerovná se',
  greaterThan: 'Větší než',
  greaterThanOrEqual: 'Větší nebo se rovná',
  lessThan: 'Menší než',
  lessThanOrEqual: 'Menší nebo se rovná',
  showColumnChooser: 'Skrýt / zobrazit sloupce',
  sortingHint: 'Seřadit',
  showAll: 'Vše',
  rowsPerPage: 'Počet řádků na stránce',
  from: 'z',
  clearFilters: 'Smazat všechny filtry sloupců',
};

const requestsActions = {
  showRequestDetail: 'Zobrazit detail žádosti',
  requestDetail: 'Detail žádosti',
  requestApprove: 'Schválit žádost',
  requestEdit: 'Upravit žádost',
  requestAbort: 'Zrušit žádost',
  requestReject: 'Zamítnout žádost',
};

const gridStudyItems = {
  grid: {
    study: {
      patientName: 'Příjmení a jméno',
      patientBirthDate: 'Datum narození',
      studyDate: 'Datum vyšetření',
      modalities: 'Modality',
      studyDescription: 'Popis',
      archiveName: 'Archiv',
      patientId: 'ID pacienta',
      accessionNumber: 'Číslo žádanky',
      patientSex: 'Pohlaví',
      noOfInstances: 'Sr/Sn',
    },
  },
};

const dataForEdit = {
  operationType: {
    edit_study: 'Nové hodnoty vyšetření',
    edit_patient: 'Nové hodnoty pacienta',
    edit_patient_old: 'Původní hodnoty pacienta',
  },

  oldPatient: {
    patientLastName: 'Příjmení pacienta',
    patientFirstName: 'Jméno pacienta',
    patientMiddleName: 'Střední jméno pacienta',
    patientPrefix: 'Tituly pacienta před',
    patientSuffix: 'Tituly pacienta za',
    patientId: 'ID pacienta',
    patientDateBirth: 'Datum narození pacienta',
    patientSex: 'Pohlaví pacienta',
  },
  data: {
    patientLastName: 'Příjmení pacienta',
    patientFirstName: 'Jméno pacienta',
    patientMiddleName: 'Střední jméno pacienta',
    patientPrefix: 'Tituly pacienta před',
    patientSuffix: 'Tituly pacienta za',
    patientId: 'ID pacienta',
    patientDateBirth: 'Datum narození pacienta',
    patientSex: 'Pohlaví pacienta',

    referringPhysicianLastName: 'Příjmení vyšetřujícího lékaře',
    referringPhysicianFirstName: 'Jméno vyšetřujícího lékaře',
    referringPhysicianMiddleName: 'Střední jméno vyšetřujícího lékaře',
    referringPhysicianPrefix: 'Tituly vyšetřujícího lékaře před',
    referringPhysicianSuffix: 'Tituly vyšetřujícího lékaře za',

    requestingPhysicianLastName: 'Příjmení žádájícího lékaře',
    requestingPhysicianFirstName: 'Jméno žádájícího lékaře',
    requestingPhysicianMiddleName: 'Střední jméno žádájícího lékaře',
    requestingPhysicianPrefix: 'Tituly žádájícího lékaře před',
    requestingPhysicianSuffix: 'Tituly žádájícího lékaře za',

    performingPhysicianLastName: 'Příjmení provádějícího lékaře',
    performingPhysicianFirstName: 'Jméno provádějícího lékaře',
    performingPhysicianMiddleName: 'Střední jméno provádějícího lékaře',
    performingPhysicianPrefix: 'Tituly provádějícího lékaře před',
    performingPhysicianSuffix: 'Tituly provádějícího lékaře za',

    studyDate: 'Datum vyšetření',
    studyTime: 'Čas vyšetření',
    studyDescription: 'Popis',
    accessionNumber: 'Číslo žádanky',
  },
};

const userPermission = {
  read: 'Zobrazit detail',
  read_plus_write: 'Přidávat/odebírat vyšetření',
  write_minus_delete: 'Přidávat vyšetření',
  write_plus_update: 'Nastavovat',
  read_tooltip: 'Právo zobrazit detail',
  read_plus_write_tooltip: 'Právo zobrazit detail, možnost přidávat/odebírat vyšetření a série',
  write_minus_delete_tooltip: 'Právo zobrazit detail, možnost přidávat vyšetření a série',
  write_plus_update_tooltip:
    'Právo zobrazit detail, možnost přidávat/odebírat vyšetření, série a uživatele a měnit nastavení pracovního seznamu',
};

const studyFunctions = {
  list: 'Vyhledání',
  listAlternative: 'Vyhledání - gmail',
  send: 'Odeslání',
  reorder: 'Přeskupení',
  split: 'Rozdělení',
  edit: 'Oprava',
  edit_patient: 'Oprava pacienta',
  edit_study: 'Oprava vyšetření',
  editPatient: 'Oprava pacienta',
  editStudy: 'Oprava vyšetření',
  copy: 'Kopírování',
  move: 'Přesunutí',
  archive: 'Vědecký archiv',
  archiving: 'Archivace',
  export: 'Export',
  import: 'Import',
  reopen: 'Znovuotevření vyšetření',
  move_to_folder: 'Přidat do pracovního seznamu',
  move_to_trash: 'Smazání',
  create_sr: 'Uložení SR',
  shredding: 'Skartace',
  studyBasketTitle: 'Dočasná složka vyšetření',
  archivation: 'Archivace',
  importStudy: 'Import dat',
  scientificArchive: 'Vědecký archiv',
  search: 'Vyhledání',
  signature: 'Elektronický podpis',
};

const histories = {
  histories: {
    send: 'Historie odeslání vyšetření',
    reorder: 'Historie přeskupení vyšetření',
    split: 'Historie rozdělení vyšetření',
    edit_patient: 'Historie oprav pacienta',
    edit_study: 'Historie oprav vyšetření',
    copy: 'Historie kopírování vyšetření',
    move: 'Historie přesunutí vyšetření',
  },
};

const searchDateButtons = {
  from: 'Od',
  to: 'Do',
  yesterday: 'Včera',
  lastTreeDays: 'Poslední 3 dny',
  today: 'Dnes',
  lastWeek: 'Posledních 7 dní', // 'Posl. týden',
  lastMonth: 'Posledních 30 dní', // 'Posl. měsíc',
  customDate: 'Vlastní',
  orderDate: 'Datum naplánovaného vyšetření',
};

const expirationButtons = {
  '1d': '1 den',
  '1w': '7 dní',
  '2w': '14 dní',
  '3w': '3 týdny',
  '1m': '1 měsíc',
};

const queueStates = {
  processed: 'Prováděný',
  waiting: 'Čekající',
  done: 'Ukončený',
};

const clinicalPortalDocumentation = {
  createdDateTime: 'Datum vytvoření',
  typeOfDocument: 'Typ dokumentu',
  author: 'Autor',
  referenceID: 'Zdroj',
  externalId: 'ID Dokumentu',
  externalWorkplaceCode: 'Kód kliniky',
  externalWorkplaceName: 'Název kliniky',
  description: 'Doplňující informace',
  unknownNameOfDocument: 'Neznámý název',
  unknownNameDate: 'Neznámé',
  unknownAuthorOfDocument: 'Neznámý',
  unknownReferenceOfDocument: 'Neznámý',
  unknownExternalWorkplaceCode: 'Neznámý',
  unknownExternalWorkplaceName: 'Neznámý',
  maxExportDocumentsCount: 'Maximální počet dokumentů k exportu: {{par1}}',
  maxViewDocumentsCount: 'Maximální počet dokumentů k zobrazení: {{par1}}',
};

const state = {
  state: 'Stav',
  active: 'Aktivní',
  inactive: 'Neaktivní',
  all: 'Vše',
  public: 'Veřejný',
  private: 'Privátní',
  activeExternalVocabularyItem: 'platná',
  inactiveExternalVocabularyItem: 'neplatná',
};

const countries = {
  CZ: 'Česká Republika',
  LU: 'Lucembursko',
  HR: 'Chorvatsko',
  GR: 'Řecko',
  PT: 'Portugalsko',
  ES: 'Španělsko',
  CY: 'Kypr',
  IE: 'Irsko',
  EE: 'Estonsko',
  FI: 'Finsko',
  HU: 'Maďarsko',
  MT: 'Malta',
  PL: 'Polsko',
  SE: 'Švédsko',
};

const header = {
  add: 'Přidat',
  back: 'Zpět',
};

const errors = {
  // incorrectNumberOfDigits: 'Nesprávný počet číslic',
  minLength: 'Minimální počet znaků: {{n}}',
  maxLength: 'Maximální počet znaků: {{n}}',
  invalidEmailAddress: 'Zadejte validní email.',
  mustBeNumber: 'Zadejte číslo',
  mustBeDate: 'Zadejte datum',
  required: 'Pole je vyžadováno.',
  requiredLastNameIfOtherNameOrTitle: 'Příjmení je vyžadováno, pokud je zadáno jméno nebo titul.',
  requiredWithLastName: 'Je vyžadováno, pokud je zadáno příjmení',
  requiredWithFirstName: 'Je vyžadováno, pokud je zadáno jméno',
  requiredWithId: 'Je vyžadováno, pokud je zadáno ID',
  hexaColor: 'Zadejte správný formát barvy.',
  maxLengthNumber: 'Maximální hodnota: {{n}}',
  minLengthNumber: 'Minimální hodnota: {{n}}',
  maxLength1: 'Je povolen maximálně {{par1}} znak.',
  maxLength234: 'Jsou povoleny maximálně {{par1}} znaky.',
  maxLength5andMore: 'Je povoleno maximálně {{par1}} znaků.',
  minLength1: 'Musí být zadán minimálně {{par1}} znak.',
  minLength234: 'Musí být zadány minimálně {{par1}} znaky.',
  minLength5andMore: 'Musí být zadáno minimálně {{par1}} znaků.',
  minMaxLength1: 'Musí být zadán {{par1}} znak.',
  minMaxLength234: 'Musí být zadány {{n}} znaky.',
  minMaxLength5andMore: 'Musí být zadáno {{par1}} znaků.',
  selectMin1Workplace: 'Vyberte alespoň jedno pracovní místo',

  maxLength64: 'Je povoleno maximálně 64 znaků.',
  maxLength16: 'Je povoleno maximálně 16 znaků.',
  maxLength10: 'Je povoleno maximálně 10 znaků.',
  minLength2: 'Musí být zadány minimálně 2 znaky',
  minLength3: 'Musí být zadány minimálně 3 znaky',
  minLength4: 'Musí být zadány minimálně 4 znaky',
  minLength5: 'Musí být zadáno minimálně 5 znaků',
  exactLength8: 'Musí být zadáno přesně 8 znaků.',
  birthDate: 'Datum musí být v minulosti.',
  dateIsFuture: 'Pozor, datum je v budoucnosti!',
  dateBirthIsFuture: 'Pozor, datum narození je v budoucnosti!',
  notFuture: 'Datum nesmí být v budoucnosti.',
  time: 'Zadejte validní čas (24 hod. formát ve tvaru hh:mm)',
  timeWithSecond: 'Zadejte validní čas (24 hod. formát ve tvaru hh:mm:ss)',

  // diagnosisBasic: 'Maximálně 5 znaků = 1 písmeno + až 4 číslice',
  onlyAlphabets: 'Jsou povolena pouze písmena',
  withoutDiacritics: 'Pouze znaky bez diakritiky',
  allCharsWithoutDiacritics: 'Pouze znaky bez diakritiky',
  withoutSpecialChars: 'Nejsou povolené speciální znaky',
  dicomTag: 'Pouze číslice a znaky A, B, C, D, E, F dle formátu (xxxx,xxxx)',
  email: 'Zadejte validní email.',
  withoutSpecial: 'Nejsou povolené speciální znaky',
  'Form.required': 'Vyberte aspoň jednu položku',
  'SearchForm.selectArchive': 'Vyberte zdroj dat',
  allowedChars: 'Neplatný formát.',
  allowedCharsWithListAllowedChars: 'Jen znaky dle regex výrazu: {{par1}}',
  dateToCanNotBeBeforeDateFrom: 'Datum od nesmí být větší než datum do',
  validToCannotBeBeforeValidTo: 'Platnost do nesmí být starší než platnost do',
  notNegative: 'Pouze nezáporná čísla',
  positiveWithoutZero: 'Pouze kladná čísla bez nuly',
  integer: 'Pouze celé číslo',
  lengthUnder60Hours: 'Zadejte čas ve formátu hh:mm:ss, maximum je 59:59:59',
  atLeaseOneAnswerMustBeAdded: 'Musíte vyplnit alespoň jednu odpověď',
  atLeaseOneAnswerMustBeCorrect: 'Alespoň jedna odpověď musí být označena jako správná',
  noChangesInForm: 'Ve formuláři nejsou žádné změny',
  lessThanTwoDefinitions:
    'Pokud test neobsahuje definice minimálně ze dvou skupin, pak jej není možné zveřejnit',

  // Tests
  'test.step.answer.late': 'Odpověděli jste na otázku příliš pozdě',
  testFinishFail: 'Chyba při odeslání testu, zkuste to prosím znovu',
  errorExecutingTest: 'Nebylo možné načíst test',
  cannotOpenLink: 'Prohlížeč není možné otevřít',

  duplicateKey: 'Zadán duplicitní klíč, změňte hodnotu',
  duplicateAet: 'Modalita tohoto jména již existuje',

  shreddingProposalsNoExist: 'Není evidován žádný požadavek na skartaci.',
  shreddingProposalsExist:
    'Evidujeme celkem {{par1}} požadavků na skartaci. Pro zobrazení požadavku prosím vyplňte formulář.',

  minLengthAndAllowedChars: 'Min. počet znaků: {{n}} | Povoleno: {{par1}}',

  additionalFieldRequired: 'Alespoň jedno další pole musí být vyplněno',
  invalidDate: 'Neplatné datum',
};

const actionList = {
  action: {
    addComment: 'Komentovat',
    addTag: 'Přidat štítek',
    send: 'Odeslat',
    reorder: 'Přeskupit',
    split: 'Rozdělit',
    update: {
      patient: 'Opravit pacienta',
      study: 'Opravit vyšetření',
    },
    copy: 'Kopírovat',
    move: 'Přesunout',
    export: 'Exportovat',
    import: 'Přidat snímky',
    importFromWebcam: 'Přidat snímek z webkamery',
    print: 'Tisknout',
    delete: 'Smazat',
    proposalShredding: 'Skartovat',
    archive: 'Archivovat',
    showStudyOrder: 'Zobrazit žádanku na vyšetření',
    addToFolder: 'Přidat do pracovního seznamu',
    doseMonitoring: 'Sledovat dávku',
    createSR: 'Vytvořit SR',
    viewDicomAttributes: 'Zobrazit DICOM atributy',
    openInBrowserTitle: 'Zobrazit v prohlížeči',
    changeStatuses: 'Změna stavů vyšetření',
    shredding: 'Skartovat',
    selectForImport: 'Vybrat pro import',
  },
};

const dataForShredding = {
  submitProposalShreddingError: 'Požadavek na založení návrhu skartace nebyl přijat.',
  submitProposalShreddingOk: 'Požadavek na založení návrhu skartace byl přijat.',
  submitProposalShreddingOkAndNok:
    'Požadavek na založení návrhu skartace byl přijat u {{par1}} vyšetření.',
  confirmProposalShreddingTitle: 'Opravdu navrhnout ke skartaci?',
  confirmProposalShredding:
    'Navrhli jste {{par1}} vyšetření ke skartaci. Opravdu si přejete provést operaci nad daným výběrem?',

  submitShreddingError: 'Požadavek na skartaci nebyl přijat.',
  submitShreddingOk: 'Požadavek na skartaci byl přijat.',
  submitShreddingOkAndNok: 'Požadavek na skartaci byl přijat u {{par1}} vyšetření.',
  confirmShreddingTitle: 'Opravdu skartovat?',
  confirmShredding:
    'Vybrali jste {{par1}} vyšetření ke skartaci. Opravdu si přejete provést operaci nad daným výběrem?',
};

const dialogOther = {
  dialog: { tag: 'Přidat štítek' },
};

const availability = {
  availability: {
    online: 'Stav: Online',
    archived: 'Stav: Archivováno',
    offline: 'Stav: Offline',
    unavailable: 'Stav: Není k dispozici',
  },
};

const printSetting = {
  print: {
    facility: 'Zdravotnické zařízení',
    patient: 'Pacient',
    patientId: 'Identifikační číslo',
    dateBirth: 'Datum narození',
    sex: 'Pohlaví',
    accessionNumber: 'Číslo žádanky',
    dateTime: 'Datum a čas vyšetření',
    setting: 'Nastavení tisku',
    referringPhysician: 'Vyšetřující lékař',
  },
  print_setting_detail: 'Hlavička vyšetření',
  print_setting_comments: 'Komentáře k vyšetření',
  print_setting_series: 'Seznam sérií',
};

const sejfSendForm = {
  sejfSendForm: 'Typ odeslání',
  dicom: 'DICOM',
  pdf: 'PDF',
  original: 'Originál',
};

const archiving = {
  submitArchivingError: 'Požadavek na archivaci vyšetření se nezdařil zpracovat.',
  submitArchivingOk:
    'Požadavek na archivaci vyšetření byl odeslán ke zpracování. Dokud se vyšetření nezpracují, zůstávají ve stavu Online.',
};

const togglePreview = {
  showPreview: 'Zobrazit náhledy sérií',
  hidePreview: 'Nezobrazovat náhledy sérií',
};

const studyOfStatus = {
  uncollapsed: 'Krátkodobý archiv',
  closed: 'Dlouhodobý archiv',
  collapsed: 'Dlouhodobý archiv',
  error: 'Nedostupný',
};

const errorViewerMessages = {
  cannotOpenLink: 'Prohlížeč není možné otevřít',
};

const decubitsCode = {
  'decubitsCode.head': 'Hlava',
  'decubitsCode.larm': 'Levá ruka',
  'decubitsCode.rarm': 'Pravá ruka',
  'decubitsCode.body': 'Tělo',
  'decubitsCode.lleg': 'Levá noha',
  'decubitsCode.rleg': 'Pravá noha',
};

export const cs = {
  Form: {
    back: 'Zpět',
    checkAll: 'Vybrat vše',
    today: 'Dnes',
    ...dialogTexts,
    clearValue: 'Vymazat hodnotu',
  },

  Errors: {
    ...errors,
  },

  Login: {
    username: 'Přihlašovací jméno',
    email: 'Email',
    password: 'Heslo',
    newPassword: 'Nové heslo',
    login: 'Přihlásit',
    title: 'Přihlášení',
    backToLogin: 'Zpět na přihlášení',
    errorLogin: 'Špatné jméno a heslo.',
    inactiveLogout: 'Byli jste odhlášeni kvůli neaktivitě',
    badToken: 'Akci se nepodařilo provést. Byl použit špatný odkaz k otevření nebo importu studie',
    error: {
      generalError: 'Chyba přihlášení. Zkuste se přihlásit znovu.',
      generalPasswordRecoveryError: 'Chyba při obnově hesla. Zkuste znovu.',
      apiNotWorking: 'API server neodpovídá. Kontaktujte technickou podporu.',
      tokenDecodeError: 'Chyba přihlášení.  Zkuste se přihlásit znovu.',
      generalSSOError:
        'Automatické SSO přihlášení se nezdařilo. Zkuste se přihlásit pomocí jména a hesla.',
      authentication: {
        user: {
          not: {
            found: 'Špatné přihlašovací údaje',
          },
        },
        invalid: {
          password: 'Chybně zadané heslo',
        },
        password: {
          invalid: 'Chybně zadané heslo',
        },
      },
      passwordRecovery: {
        10: 'Heslo je prázdné.',
        11: 'Heslo nemá minimální počet znaků.',
        12: 'Heslo neobsahuje malé znaky.',
        13: 'Heslo neobsahuje velké znaky.',
        14: 'Heslo neobsahuje čísla.',
        15: 'Heslo neobsahuje speciální znaky.',
        16: 'Heslo nesplňuje požadovaný počet povinných kategorií.',
        17: 'Heslo nemůže být změněno v krátkém časovém intervalu.',
        18: 'Heslo nemůže být použito - bylo použito nedávno.',
        19: 'Nelze nalézt uživatele pro zadaný hash.',
        20: 'Hash je neplatný.',
        21: 'Hash je neznámý.',
        22: 'Uživatel nemá nastaven email - nelze zaslat obnovu hesla.',
        23: 'Uživatel nemá správně nastaven zdroj přihlášení - nelze zaslat obnovu hesla.',
        24: 'Uživatel nenalezen.',
      },
      certificateToken: {
        400: 'Chybí token',
        403: 'Neplatný token',
        404: 'Token nelze ověřit',
        500: 'Akci se nepodařilo provést',
      },
    },
    TOTPCode: 'TOTP kód',
    openIdButton: 'Přihlásit přes OpenId',
    openIdLogoutButton: 'Odhlásit se z OpenId',
    openIdSSOlougoutMessage:
      'Byl jste odhlášen z aplikace portál. Pro odhlášení ze všech aplikací klikněte na tlačítko:',
    openIdSSOAccessDeniedMessage: 'OpenID přihlášení zrušeno',
    forgottenPasswordTitle: 'Zapomenuté heslo',
    resetPasswordTitle: 'Obnovit heslo',
    resetPasswordSend: 'Požadavek na obnovu hesla odeslán. Zkontrolujte si svůj email.',
    resetPasswordSErrorSending: 'Požadavek na obnovu hesla se nepodařilo zpracovat.',
    scaned: 'Potvrdit',
    explainPage:
      'Pro přihlášení je nutné mít nainstalovanou aplikaci Google Authenticator, ve které naskenujete vygenerovaný QR kód.',
    explainQRCodeApps: 'Aplikaci lze stáhnout na ',
    explainQRCodeAppsOr: ', nebo ',
    requestsAddPriority: 'Požadavky prioritně zadávejte písemně prostřednictvím webové aplikace',
    helpdesk: 'HelpDesk OR-CZ',
    phoneContact: 'Telefonní kontakt pro případ havárie',
    help: 'Nápověda pro zadání hesla',
    label: 'Štítek zdravotnického prostředku',
    phoneAbbr: 'tel',
    dailyRoutine: 'denní provoz',
    nightlyRoutine: 'noční provoz',
    holidays: 'víkendy, svátky',
    ruleForPasswordRecovery: {
      minimalPasswordLength: 'Minimální délka hesla: {{par1}}',
      mustContain: 'Heslo musí obsahovat: {{par1}}',
      capitalLettersRequired: 'velké písmeno',
      smallLettersRequired: 'malé písmeno',
      digitsRequired: 'číslo',
      specialCharsRequired: 'speciální znak',
    },
    passwordChanged: 'Heslo úspěšně změněno',
    passwordNoChanged: 'Změna hesla se nepodařila',
  },
  Homepage: {
    homepage: 'Úvodní stránka',
    cancel: 'Zrušit načítání',
    restrictedArea: 'Nemáte dostatečné oprávnění pro zobrazení této sekce',
    restrictedAreaTitle: 'Nedostatečné oprávnění',
    missingRoles: 'Chybí vám následující oprávnění',
    contactAdmin: 'Prosím kontaktujte správce systému',
    noRoleAnnouncement: 'Nemáte přidělena žádná oprávnění.',
    newerVersion: 'Byla zjištěna nová verze portálu',
    restartApp: 'Restartovat portál',
    urlMoved: 'Tato URL adresa již není platná',
    redirectToStudiesSearch: 'Přejít na vyhledání vyšetření',
    redirectToRequests: 'Přejít na přehled žádostí',
    forMoreInformationPleaseContactAdministrator: 'Pro více informací kontaktujte správce.',
    pageNotFound: 'Tato stránka neexistuje.',
  },
  Bar: {
    logout: 'Odhlásit se',
    compactDisplayMode: 'Kompaktní mód',
    userProfile: 'Uživatelský profil',
    showStudiesFromBasket: 'Zobrazit vyšetření z dočasné složky',
  },
  // Left menu
  Menu: {
    // Orders
    orders: 'Modality worklist',
    ordersList: 'Seznam',

    // Studies
    menuStudies: 'Vyšetření',
    ...studyFunctions,
    calculate: 'Sledování dávky',
    importMenu: 'Import dat',

    ...histories,

    // Archiving
    studyArchivingTitle: 'Archivace vyšetření',
    multipleArchiving: 'Archivovat',

    // Export
    // exports: 'Export dat',
    export: 'Export vyšetření',
    exportsList: 'Seznam exportů',
    patient: 'Oprava pacienta',
    study: 'Oprava vyšetření',

    cstore: 'Lokální vyšetření',

    // Import
    // import: 'Import dat',
    dicom: 'DICOM',
    nondicom: 'multimediálních',
    worklist: 'z worklistu',
    ...importTitle,

    // Signature
    signature: 'Elektronický podpis',
    unsignedStudies: 'Nepodepsané vyšetření',
    studyUnsignedTitle: 'Vyhledání nepodepsaných vyšetření',
    multipleSigning: 'Podepsat',
    confirmSigningStudy: 'Podepsat vybrané vyšetření?',
    confirmSigningStudies: 'Podepsat vybraná vyšetření?',

    // Share
    sharing: 'Sdílení',
    share: 'Sdílení vyšetření',
    ask: 'Požádat o sdílení',
    delivered: 'Doručená vyšetření',
    sent: 'Odeslaná vyšetření',

    userProfile: 'Uživatelský profil',

    // Requests
    request: 'Přehled žádostí',
    requestsEditStudy: 'Oprava vyšetření',
    requestsEditPatient: 'Oprava pacienta',
    requestsSplit: 'Rozdělení',
    requestsMove: 'Přesunutí',
    requestsCopy: 'Kopírování',
    requestsReorder: 'Přeskupení',
    requestsSend: 'Odeslání',
    requestsMoveToFolder: 'Přidání do pracovního seznamu',
    requestsMoveToTrash: 'Smazání',
    search: 'Vyhledat vyšetření',
    'requests.search': 'Vytvoření nové žádosti',
    'requests.edit': 'Seznam žádostí na opravu',
    'requests.editStudy': 'Seznam žádostí na opravu vyšetření',
    'requests.editPatient': 'Seznam žádostí na opravu pacienta',
    'requests.split': 'Seznam žádostí na rozdělení',
    'requests.move': 'Seznam žádostí na přesun',
    'requests.copy': 'Seznam žádostí na kopírování',
    'requests.reorder': 'Seznam žádostí na přeskupení',
    'requests.send': 'Seznam žádostí na odeslání',
    'requests.moveToFolder': 'Seznam žádostí na přidání do pracovního seznamu',
    'requests.reorder.search': 'Vyhledání vyšetření pro žádost na přeskupení',
    requests: {
      detail: 'Detail žádosti',
      editPatient: {
        create: 'Nová žádost na opravu pacienta',
        edit: 'Editace žádosti na opravu pacienta',
      },
      editStudy: {
        create: 'Nová žádost na opravu vyšetření',
        edit: 'Editace žádosti na opravu vyšetření',
      },
      copy: {
        create: 'Nová žádost na kopírování vyšetření',
        edit: 'Editace žádosti na kopírování vyšetření',
      },
      move: {
        create: 'Nová žádost na přesunutí vyšetření',
        edit: 'Editace žádosti na přesunutí vyšetření',
      },
      moveToFolder: {
        create: 'Nová žádost na přidání vyšetření do pracovního seznamu',
        edit: 'Editace žádosti na přidání vyšetření do pracovního seznamu',
      },
      moveToTrash: {
        create: 'Nová žádost na smazání vyšetření',
        edit: 'Editace žádosti na smazání vyšetření',
      },
      requests: 'Přehled žádostí',
      reorder: {
        create: 'Nová žádost na přeskupení vyšetření',
        edit: 'Editace žádosti na přeskupení vyšetření',
      },
      send: {
        create: 'Nová žádost na odeslání vyšetření',
        edit: 'Editace žádosti na odeslání vyšetření',
      },
      split: {
        create: 'Nová žádost na rozdělení vyšetření',
        edit: 'Editace žádosti na rozdělení vyšetření',
      },
    },
    studies: {
      copy: {
        detail: 'Kopírování vyšetření',
      },
      editPatient: {
        detail: 'Oprava pacienta',
      },
      editStudy: {
        detail: 'Oprava vyšetření',
      },
      move: {
        detail: 'Přesunutí vyšetření',
      },
      reorder: {
        detail: 'Přeskupení vyšetření',
      },
      send: {
        detail: 'Odeslání vyšetření',
      },
      split: {
        detail: 'Rozdělení vyšetření',
      },
      studies: 'Vyhledání vyšetření',
    },
    exports: { detail: 'Export vyšetření' },

    // Worklist
    worklists: 'Worklist',

    // Tests
    tests: 'Testy',
    startTest: 'Spustit test',
    results: 'Výsledky testů',
    manageTests: 'Přehled testů',
    stats: 'Globální statistiky',
    testResults: 'Výsledky testů',
    globalStats: 'Globální statistiky',

    // Trustworthy
    trustworthyArchive: 'Důvěryhodný archiv',
    archivedDocuments: 'Archivované dokumenty',

    // Monitoring
    monitoring: 'Monitoring',
    nasEnterprise: 'NAS Enterprise',
    patientManagement: 'Patient Management',

    // Nas Enterprise
    'nas-enterprise': 'NAS Enterprise',
    dashboards: 'Dashboards',
    'osd-daemons': 'OSD Daemons',

    // Reports
    reports: 'Reporty',
    statistics: 'Statistiky',
    daily: 'Denní',
    auditLogs: 'Aktivity uživatelů',
    studiesByStudyTagsStatistics: 'Statistiky podle štítků',
    findingReports: 'Statistika nálezů',

    // ClinicalPortal
    byPatient: 'Dle pacienta',
    byTags: 'Dle štítků',
    byNCPeH: 'NCPeH',

    // Administration
    administration: 'Administrace',
    facilities: 'Zařízení',
    exchangeNetworks: 'Sítě',
    products: 'Produkty',
    modalities: 'Modality',
    users: 'Uživatelé',
    workplaces: 'Pracovní místa',
    partnership: 'Partnerství',
    loggedActivities: 'Logované aktivity',
    logs: 'Logy',
    announcements: 'Oznámení',
    buttons: 'Dlaždice',
    tags: 'Štítky',
    administrationTags: 'Správa Štítků',
    registerGroups: 'Správa Štítků',
    units: 'Oddělení',
    stations: 'Stanice',
    languages: 'Jazyky',
    expertness: 'Odbornost',
    healthInsuranceCompany: 'Zdravotní pojišťovny',
    studyOrderPriority: 'Urgentnost',
    dose: 'DICOM atributy',
    physicians: 'Lékaři',
    filters: 'Filtry',
    folders: 'Pracovní seznamy',
    queueRequests: 'Fronta požadavků',
    tools: 'Nástroje',
    clinicalPortal: 'Zdravotnická dokumentace',
    generalSettings: 'Obecná nastavení',
    settings: 'Nastavení',
    modalitiesUnitsStations: 'Modality, oddělení, stanice',
    facilitiesAndExchangeNetworks: 'Zařízení a sítě',
    announcementsAndButtons: 'Dlaždice a oznámení',
    frontendSettings: 'Nastavení frontendu',
    mdex: 'MDEX',
    sendData: 'Odeslání dat',
    transmissions: 'Přehled přenosů',
    addressBook: 'Adresář příjemců',

    prioritiesAndOperations: 'Nastavení priorit a operací',
    loginScreenSettings: 'Nastavení přihlašovací obrazovky',
    dictionaries: 'Slovníky',

    // Help
    help: 'Nápověda',
    online: 'Online nápověda',
    offline: 'Offline nápověda',
    local: 'Lokální nápověda',
    about: 'O aplikaci',

    // Websocket notifications
    notifications: 'Notifikace',

    portalVersion: 'Verze portálu',

    // Dashboard grid
    'pools.name': 'Name',
    'pools.pgstate': 'PG status',
    'pools.usage': 'Usage',
    'pools.activity': 'Activity',

    // Tags
    documentsByTags: 'Vyhledání v integrační platformě',
    scientificArchive: 'Vyhledání ve vědeckém archivu',

    confirmArchivingStudy: 'Archivovat vybrané vyšetření?',
    confirmArchivingStudies: 'Archivovat vybraná vyšetření?',

    shreddings: {
      shreddings: 'Skartace',
      proposals: 'Návrhy',
      histories: 'Historie',
    },
    detail: {
      orders: 'Detail žádanky',
      shreddings: {
        proposals: 'Návrhy na skartaci',
        histories: 'Historie skartace',
      },
    },
    edit: {
      orders: 'Úprava žádanky',
    },
    histories: {
      send: 'Historie odeslání',
    },
  },
  SearchForm: {
    searchForm: 'Vyhledání vyšetření',
    ...studyFunctions,
    study: 'Vyšetření',
    patient: 'Pacient',
    lastName: 'Příjmení',
    firstName: 'Křestní jméno',
    middleName: 'Prostřední jméno',
    search: 'Vyhledat',
    sex: 'Pohlaví',
    ...sexes,
    other: 'Ostatní',
    all: 'Vše',
    patientID: 'ID pacienta',
    accessionNumber: 'Číslo žádanky',
    birthDate: 'Datum narození',
    dateBirthFrom: 'Datum narození od',
    birthDateFrom: 'Datum narození od',
    birthDateTo: 'Datum narození do',
    dateBirthTo: 'Datum narození do',
    studyDate: 'Datum vyšetření',
    studyDateFrom: 'Datum vyšetření od',
    studyDateTo: 'Datum vyšetření do',
    studyTime: 'Čas vyšetření',
    '24h': 'Posledních 24h',
    ...searchDateButtons,
    ...expirationButtons,
    customDateLabel: 'Datum a čas vyšetření',
    modalities: 'Modality',
    otherModalities: 'Jiné modality',
    allModalities: 'Všechny modality',
    separateModalitiesWithComma: 'Modality oddělujte čárkou',
    description: 'Popis vyšetření',
    requestingPhysician: 'Žádající lékař',
    referringPhysician: 'Vyšetřující lékař',
    performingPhysician: 'Provádějící lékař',
    source: 'Zdroj dat',
    allSources: 'Všechy zdroje',
    reset: 'Vyčistit',
    units: 'Oddělení',
    stations: 'Stanice',
    state: 'Stav',
    submitError: 'Při hledání se vyskytla chyba.',
    cancelSubmit: 'Vyhledávání bylo přerušeno.',
    selectMin1Archive: 'Vyberte zdroj dat',
    // Scientific archive
    comment: 'Komentář k vyšetření',
    createCommentFrom: 'Od data vytvoření komentáře',
    createCommentTo: 'Od data vytvoření komentáře',
    tags: 'Štítky',
    tagsActive: 'Štítky - aktivní',
    tagsInactive: 'Štítky - neaktivní',
    showInactiveGroups: 'Zobrazit neaktivní štítky',
    withoutTags: 'Vyhledat vyšetření bez štítku',
    select: 'Vybrat',
    modality: 'Modalita',
    selectDeselectAll: 'Vybrat/Odebrat vše',
    noOptions: 'Žádné položky',

    // Logs
    userName: 'Uživatel',
    logDate: 'Datum aktivity',

    // Filters
    saveFiltr: 'Uložit filtr',
    filter: {
      saved: 'Filtr uložen',
      dialogTitle: 'Vytvoření filtru',
      name: 'Název filtru',
    },
    searchFilterIsAlreadyExists: 'Název filtru je již použit',

    filters: 'Filtry',
    ...dialogTexts,

    // Queue
    stateOfQueue: 'Stav požadavku',
    ...queueStates,

    history: 'Historie',
    historyList: 'Historie odeslání',
    exportList: 'Seznam exportů',

    clinicalPortalExpansionHead: 'Rozšířené vyhledávání',
    clinicalPortalExpansionHeadExtended: '(Operace může trvat několik minut)',

    // Proposal source
    proposal: {
      User: 'Návrh uživatele',
      NIS: 'Automatický návrh z NIS',
      MASH: 'Automatický návrh z MASH',
    },
    proposalDate: 'Datum návrhu',
    confirmationDate: 'Datum potvrzení',
    userConfirmation: 'Autor potvrzení',
    shreddingConfirmation: 'Potvrzení',
    shreddingProposal: 'Návrh',
    shreddingUser: 'Autor',
    shreddingDate: 'Datum',
    sourceProposal: 'Zdroj návrhu',
    userProposal: 'Autor návrhu',
  },

  SearchResults: {
    studiesFound: 'Počet nalezených vyšetření',
    studiesSelected: 'Počet vybraných vyšetření',
    noData: 'Nenalezena žádná vyšetření',
    ...messagesForGrid,
    ...sexes,
    ...availability,
    replaceBySelected: 'Nahradit vybranými',
    addSelected: 'Přidat vybrané',
    replace: 'Nahradit',
    add: 'Přidat',
    viewed: 'Zobr.',
    report: 'Nález',
    send: 'Odeslat',
    reorder: 'Přeskupit',
    edit_study: 'Opravit vyšetření',
    edit_patient: 'Opravit pacienta',
    copy: 'Kopírovat',
    move: 'Přesunout',
    export: 'Exportovat',
    setChangeStatuses: 'Změnit stav',
    addTags: 'Přidat štítky',
    setChangeStatusesTooltip: 'Změnit stav pouze pro vyšetření z archivu DPGW',
    multipleCalculateExport: 'Exportovat dávku',
    multipleCalculate: 'Sledovat dávku',
    name: 'Příjmení a jméno',
    patientId: 'ID pacienta',
    accessionNumber: 'Číslo žádanky',
    patientSex: 'Pohlaví',
    patientBirthDate: 'Datum narození',
    dateTime: 'Datum vyšetření',
    referringPhysician: 'Vyšetřující lékař',
    modalities: 'Modality',
    noOfInstances: 'Sr/Sn',
    stationName: 'Jméno stanice',
    stationAet: 'Aet stanice',
    studyDescription: 'Popis',
    archive: 'Archiv',
    seriesName: 'Název série',
    seriesCreatedDate: 'Datum vytvoření',
    seriesModality: 'Modalita',
    seriesDescription: 'Popis',
    seriesNumber: 'Číslo série',
    seriesDateTime: 'Datum a čas série',
    seriesInstancesCount: 'Počet snímků',
    modality: 'Modalita',
    proposalDateTime: 'Datum návrhu',
    proposalSource: 'Zdroj a autor návrhu',
    confirmationDateTime: 'Datum potvrzení',
    userConfirmation: 'Autor potvrzení',
    seen: 'Zobrazeno',
    actions: 'Akce',
    comments: 'Komentáře',
    tags: 'Štítky',
    sendInfos: 'Odesláno',
    drSejf: 'Dr. Sejf',
    folderInfos: 'Pr. seznamy',
    folderExpiratedInfos: 'Expirace za',
    folderHistory: 'Poslední zobrazení',
    day1: '{{days}} den',
    day234: '{{days}} dny',
    days: '{{days}} dní',
    extendExpiration: 'Prodloužit expiraci',
    hasAttachment: 'S přílohou',
    shredding: 'Návrh na skartaci',
    shredded: 'Navrhnuto na skartaci',
    showDetailWithAttachments: 'Zobrazit detail s přílohami',
    modalitiesNotShown: 'Obsahuje skryté série',
    showDetail: 'Zobrazit detail',
    countStudies: 'Počet vyšetření',
    studyLocked: 'Studie uzamčena',
    ...openInBrowser,
    downloadECG: 'Stáhnout EKG vyšetření',
    downloadEcgError: 'Nepodařilo se stáhnout EKG vyšetření',
    details: 'Komentáře',
    setSourceStudyForRequestReorder: 'Vybrat zdrojové vyšetření pro žádost na přeskupení',
    setTargetStudyForRequestReorder: 'Vybrat cílové vyšetření pro žádost na přeskupení',
    setStudyForRequestSplit: 'Vybrat vyšetření pro žádost na rozdělení',
    setStudyForRequestMove: 'Vybrat vyšetření pro žádost na přesunutí',
    setStudyForRequestCopy: 'Vybrat vyšetření pro žádost na kopírování',
    setStudyForRequestSend: 'Vybrat vyšetření pro žádost na odeslání',
    setStudyForRequestEditPatient: 'Vybrat pro žádost na opravu pacienta',
    setStudyForRequestEditStudy: 'Vybrat pro žádost na opravu vyšetření',
    setStudyForRequestMoveToFolder: 'Vybrat vyšetření pro žádost na přidání do pracovního seznamu',
    setStudyForRequestDeleting: 'Vybrat vyšetření pro žádost na smazání',
    setStudyForRequestMoveToTrash: 'Vybrat vyšetření pro žádost na smazání',
    setStudyRequestForMoveToFolder: 'Vybrat vyšetření pro žádost na přidání do pracovního seznamu',
    setStudyRequest: 'Vybrat vyšetření pro žádost',
    setStudyForSend: 'Odeslat vyšetření',
    setStudyForSplit: 'Rozdělit vyšetření',
    setStudyForEdit: 'Opravit vyšetření',
    setPatientForEdit: 'Opravit pacienta',
    setStudyForCopy: 'Zkopírovat vyšetření',
    setStudyForMove: 'Přesunout vyšetření',
    setStudyForArchive: 'Archivovat vyšetření',
    setStudyForExport: 'Exportovat vyšetření',
    setStudyForReorder: 'Přeskupit vyšetření',
    setSourceStudyForReorder: 'Přeskupení vyšetření (zvolit zdrojové vyšetření)',
    setTargetStudyForReorder: 'Zvolit cílové vyšetření pro přeskupení',
    setStudyForMoveToFolder: 'Přidat do pracovního seznamu',
    setStudyForDeleting: 'Smazat vyšetření',
    setStudyForProposalShredding: 'Navrhnout ke skartaci',
    setStudyForShredding: 'Skartovat',
    setStudyForShreddingPrint: 'Tisknout protokol o skartaci',
    setStudyForBasket: 'Přidat do dočasné složky',
    removeStudyFromFolder: 'Smazat vyšetření z pracovního seznamu',
    removeSelectedStudiesFromFolder: 'Smazat vybraná vyšetření z pracovního seznamu',
    removeAllStudiesFromFolder: 'Smazat všechna vyšetření z pracovního seznamu',
    studyAddedToBasket: 'Vyšetření přidáno do dočasné složky',
    studiesAddedToBasket: 'Vyšetření přidána do dočasné složky',
    removeStudyFromBasket: 'Smazat vyšetření z dočasné složky',
    removeSelectedStudiesFromBasket: 'Smazat vybraná vyšetření z dočasné složky',
    removeAllStudiesFromBasket: 'Smazat všechna vyšetření z dočasné složky',
    importIcon: 'Vybrat toto vyšetření pro import',
    instanceNumber: 'Číslo snímku',
    deleteImage: 'Smazat',
    multipleRemoveImages: 'Smazat snímky',
  },

  Header: {
    addWorkplacesFromLdap: 'Přidat pracovní místa z adresní knihy',
    ...header,
  },

  StudyDetail: {
    ...sizeAndWeight,
    studyIsNotAvailable: 'Vyšetření již není dostupné v archivu',
    edit: 'Detail vyšetření',
    studyNotFound: 'Vyšetření nenalezeno.',
    accessionNumber: 'Číslo žádanky',
    dateTime: 'Datum vyšetření',
    modality: 'Modalita',
    numberOfStudyRelatedSeries: 'Počet sérií',
    numberOfStudyRelatedInstances: 'Počet snímků',
    numberOfStudyRelatedSeriesAndInstances: 'Počet sérií / snímků',
    changeTimelineOrder: 'Otočit směr osy',
    filterByModality: 'Filtrovat podle modalit',
    filterByDescription: 'Filtrovat podle popisu',
    removeFilterForTimeline: 'Obnovit časovou osu',
    filteringByModalities: 'Filtrování podle modalit',
    filteringByDescription: 'Filtrování podle popisu',
    institutionName: 'Provádějící pracoviště',
    archive: { name: 'Archiv' },
    studyDescription: 'Popis vyšetření',
    referringPhysician: { name: 'Vyšetřující lékař' },
    requestingPhysician: { name: 'Žádající lékař' },
    performingPhysician: { name: 'Provádějící lékař' },
    patient: {
      dateBirth: 'Datum narození',
      name: 'Jméno pacienta',
      identificationNumber: 'Id pacienta',
    },
    ...studyStates,
    ...sexes,
    comments: 'Komentáře',
    tags: 'Štítky',
    deleteSerie: 'Smazat',
    seriesTab: 'Série',
    order: 'Žádanka',
    attachments: 'Přílohy k žádance',

    series: {
      title: 'Série',
      number: 'Číslo série',
      stationAet: 'Aet stanice',
      modality: 'Modalita',
      description: 'Popis',
      numberOfInstances: 'Snímků',
      preview: 'Náhled série',
    },
    preview: 'Náhled',
    studyTagsCreateTitle: 'Autor a datum přidání štítku',
    studyTagsDelete: 'Smazat štítek',
    commentCreateTitle: 'Autor a datum vytvoření komentáře',
    commentUpdateTitle: 'Autor a datum úpravy komentáře',
    commentUpdate: 'Upravit komentář',
    commentDelete: 'Smazat komentář',
    confirmDelete: 'Opravdu smazat?',
    confirmCancel: 'Opravdu zrušit?',
    commentExport: 'Exportovat komentář',
    confirmExport: 'Opravdu exportovat?',
    tagCreateTitle: 'Autor a datum vytvoření štítku',
    tagDelete: 'Smazat štítek',
    fileCreateTitle: 'Autor a datum nahrání přílohy',
    fileDelete: 'Smazat přílohu',
    ...actionList,
    ...dataForShredding,
    confirmMoveStudyToTrashTitle: 'Opravdu smazat?',
    confirmMoveStudyToTrashContent: 'Vyšetření bude přesunuto do koše',
    dialog_comment: 'Komentovat',
    dialog_comment_label: 'Komentář k vyšetření',
    dialog_attachment: 'Přidat přílohu',
    dialog_createSR: 'Vytvořit SR',
    commentExported: 'Požadavek na export komentáře byl odeslán ke zpracování.',
    commentNotExported: 'Požadavek na export komentáře se nepodařilo zpracovat.',
    ...dialogOther,
    filename: 'Název souboru',
    createdWhen: 'Datum a čas vytvoření',
    size: 'Velikost',
    user: 'Nahráno uživatelem',
    folders: 'Pracovní seznamy',
    multipleAddToFolder: 'Přidat do pracovního seznamu',
    multipleRemoveSeries: 'Smazat série',
    removeSeries: 'Smazání sérií',
    reasonRemoveSeries: 'Důvod smazání',
    confirmDeletingSeries: 'Vybrané série budou smazány',
    errorDeletingSeries: 'Nepodařilo se smazat vybrané série',
    removeImages: 'Smazání snímků',
    reasonRemoveImages: 'Důvod smazání',
    confirmDeletingImages: 'Vybrané snímky budou smazány',
    errorDeletingImages: 'Nepodařilo se smazat vybrané snímky',
    report: 'Nález',
    forbidden: 'Nemáte právo zobrazit vyšetření!',
    deleteOk: 'Požadavek na smazání vyšetření úspěšně odeslán',
    deleteNok: 'Nepodařilo se odeslat požadavek na smzání vyšetření',
    seriesPreview: 'Náhled série',
    bodyPart: 'Části těla',

    ...dialogTexts,

    // Archived
    ...availability,
    ...printSetting,
    ...sejfSendForm,
    studyAvailability: 'Stav archivace',

    modified: 'Editováno',
    confirmArchivingStudy: 'Archivovat vybrané vyšetření?',

    ...archiving,

    // Dose
    doses: 'Dávky',

    unableShowAttachement:
      'Přílohu nelze zobrazit. Pro zobrazení je nutno přílohu stáhnout do počítače.',

    operations: 'Operace',
    notify: 'Počty snímků/sérií se mohou lišit s počty v záložce série vzhledem k nastavení!',

    ...togglePreview,
    statusOfCode: 'Stav',
    reopen: 'Znovu otevření vyšetření do krátkodobého archivu',
    studyIsReopening: {
      error: 'Požadavek na znovuotevření vyšetření se nezdařil zpracovat.',
      ok: 'Požadavek na znovuotevření vyšetření byl odeslán ke zpracování.',
    },
    ...studyOfStatus,

    // SR
    seriesDescription: 'Název zprávy',
    language: 'Jazyk zprávy',
    physician: 'Lékař',
    ...name,
    dataTypes: 'Typ položky',
    text: 'Textová položka',
    createSrSubmitOk: 'Požadavek na vytvoření SR byl úspěšně odeslán.',
    createSrSubmitNok: 'Nezdařilo se odeslání požadavku na vytvoření SR',

    // dicomAttributes
    dc: {
      tag: 'Tag',
      vr: 'VR',
      tagName: 'Jméno',
      value: 'Hodnota',
    },
    ...decubitsCode,

    successSetStudyStatuses: 'Nastavení stavů vyšetření bylo úspěšné',
    errorSetStudyStatuses: 'Nepodařilo se nastavit stavy vyšetření',

    certName: 'Název certifikátu',
    certKey: 'Heslo certifikátu',
    mailSend: 'Odeslat email lékaři',
    noSigned: 'Nález nebude podepsán.',

    createPdfError: 'Nepodařilo se vygenerovat PDF.',
    incorrect_certificate_password:
      'Nepodařilo se vygenerovat PDF, protože nebylo zadáno správné heslo.',
    certificate_not_found:
      'Nepodařilo se vygenerovat PDF, protože zvolený certikát nebyl nalezen na serveru.',
  },

  StudiesBasket: {
    studyBasketTitle: 'Dočasná složka vyšetření',
    ...actionList,
    someStudiesAreLocked:
      'Tlačítka nejsou dostupná, protože některé z vyšetření se aktuálně dále zpracovává.',
    cannotAction:
      'Tlačítko {{action}} není dostupné, protože jsou vybrána vyšetření z archivů {{archives}}, které nepodporují tuto operaci.',
    cannotActionMoreArchives:
      'Tlačítko {{action}} není dostupné, protože jsou vybrána vyšetření z různých archivů.',
    cannotMoveAvailability:
      'Tlačítko Přesunout není dostupné, protože některá z vyšetření jsou ve stavu Archivováno.',
    cannotArchiveAvailability:
      'Tlačítko Archivovat není dostupné, protože lze archivovat vyšetření pouze ve stavu Online.',
    openInBrowser: {
      viewerByTypeMWV:
        'Tlačítko Zobrazit v prohlížeči není dostupné, protože lze zobrazit vyšetření pouze pomocí WebVision.',
      onlyFrom1Archive:
        'Tlačítko Zobrazit v prohlížeči není dostupné, protože lze zobrazit vyšetření pouze z jednoho archivu.',
      only1StudyFromMSArchive:
        'Tlačítko Zobrazit v prohlížeči není dostupné, protože z MS archivu lze zobrazit pouze 1 vyšetření.',
      max20Studies:
        'Tlačítko Zobrazit v prohlížeči není dostupné, protože lze zobrazit maximálně 20 vyšetření.',
      noDefViewer:
        'Tlačítko Zobrazit v prohlížeči není dostupné, protože není definován prohlížeč.',
    },
    ...archiving,
    ...dataForShredding,

    confirmRemoveStudyFromBasket: 'Opravdu odebrat vybrané vyšetření z dočasné složky?',
    confirmRemoveStudiesFromBasket: 'Opravdu odebrat vybraná vyšetření z dočasné složky?',
    confirmRemoveAllStudiesFromBasket: 'Opravdu odebrat všechna vyšetření z dočasné složky?',
  },

  Comment: {
    create: 'Komentovat',
    text: 'Komentář k vyšetření',
    textMWL: 'Komentář k žádance',
    errorSaving: 'Chyba při ukládání komentáře',
    changeStateError: 'Nepodařilo se změnit stav.',
    incorrect_certificate_password: 'Nebylo zadáno správné heslo k vytvoření podpisu.',
    certificate_not_found: 'Zvolený certikát nebyl nalezen na serveru.',
    ...dialogTexts,
  },

  StudyTags: {
    successSaving: 'Štítek přidán',
    errorSaving: 'Chyba při přidání štítku',
    undefined: ' ',
    select: 'Vybrat',
    ...dialogTexts,
    registerItemSaved: "Štítek '{{registerItemName}}' úspěšně uložen",
    errorSaveRegisterItem: 'Chyba při ukládání štítku do databáze',
    create: 'Vytvořit',
  },

  StudyExport: {
    format: 'Formát',
    frameState: 'Rychlost snímkování',
    useCompress: 'Použít kompresi',
    addStudyReport: 'Přiložit nález',
    patientName: 'Jméno pacienta',
    modality: 'Modalita',
    dateStudyCreated: 'Datum vytvoření vyšetření',
    timeStudyCreated: 'Čas vytvoření vyšetření',
    dateExportSelected: 'Datum zvolení exportu',
    timeExportSelected: 'Čas zvolení exportu',
    exportType: 'Typ exportu',
    exportState: 'Stav',
    exportValidity: 'Dostupnost',
    size: 'Velikost',
    available: 'Dostupný',
    unavailable: 'Nedostupný',
    seriesOfStudy: 'Série',
    dateTime: 'Datum a čas vytvoření vyšetření',
    exportDateTime: 'Datum a čas exportu',
    downloadableTo: 'Platné do',
    validExports: 'Platné exporty',
    allExports: 'Všechny exporty',
    // Status
    APPROVED: 'Schváleno',
    FINISHED: 'Dokončeno',
    FAILED: 'Neúspěšný',
    // Type
    PICTURE: 'Obrázky',
    VIDEO: 'Video',
    DICOM: 'Vyšetření',
    IMG_CD: 'Obraz CD',
    IMG_DVD: 'Obraz DVD',
    downloadError: 'Nepovedlo se stáhnout soubor',
  },

  Orders: {
    actions: 'Akce',
    title: 'Seznam žádanek',
    detail: 'Detail žádanky',
    create: 'Nová žádanka',
    edit: 'Úprava žádanky',
    patient: 'Pacient',
    list: 'Seznam žádanek',
    description: 'Popis žádanky',
    identificationNumber: 'ID\u00A0pacienta',
    dateBirth: 'Datum narození',
    sex: 'Pohlaví',
    healthInsuranceCompany: 'Zdravotní pojišťovna',
    originalDatetimeRequested: 'Datum a čas žádosti',
    originalDateRequested: 'Datum žádosti',
    originalTimeRequested: 'Čas žádosti',
    expertness: 'Odbornost',
    diagnosisBasic: 'Číselný kód diagnózy',
    diagnosisOther: 'Ostatní diagnózy',
    detail_size: 'Výška pacienta',
    detail_weight: 'Váha pacienta',
    requestingPhysician: 'Žádající lékař',
    referringPhysician: 'Vyšetřující lékař',
    performingPhysician: 'Provádějící lékař',
    priority: 'Urgentnost',
    requestedProcedure: 'Požadované vyšetření',
    station: 'Stanice',
    unit: 'Oddělení',
    modality: 'Modalita',
    icp: 'IČP',
    // female: 'žena',
    // male: 'muž',
    other: 'jiné',
    name: 'Příjmení a jméno',
    accessionNumber: 'Číslo žádanky',
    createdWhen: 'Datum vytvoření',
    datetime: 'Plánovaný datum a čas',
    state: 'Stav',
    saved: 'Žádanka uspěšně uložena.',
    errorSaving: 'Žádanka se nepodařila uložit',
    submitError: 'Při hledání se vyskytla chyba.',
    // 'sex.F': 'žena',
    // 'sex.M': 'muž',
    // 'sex.O': 'jiné',
    ...sexes,
    comments: 'Komentáře',
    attachments: 'Přílohy',
    action: {
      addComment: 'Komentovat',
      addAttachment: 'Přidat přílohu',
      createStudy: 'Vytvořit vyšetření',
      print: 'Tisknout',
      edit: 'Upravit',
      showStudyOrder: 'Zobrazit žádanku na vyšetření',
    },
    orderNotFound: 'Žádanku se nepodařilo načíst',
    orderFromStudyNotFound: 'Žádanka pro toto vyšetření neexistuje',
    deleted: 'Modality worklist úspěšně odstraněn',
    ...name,
    ...checkName,
    ...dialogTexts,
    ...allowedExtensions,
    noData: 'Nenalezeny žádné záznamy',
    print: {
      title: 'Průvodní list k vyšetření:',
      patientName: 'Pacient',
    },
    isNotFacilityCode: 'Není vyplněn kód zařízení, kontaktujte administrátora',
    back: 'Zpět',
    ...sizeAndWeight,
    errorDeleting: 'Žádanku se nepodařilo odstranit',
    healthInsuranceCompanyRequired: 'Vyberte zdravotní pojišťovnu',
    expertnessRequired: 'Vyberte odbornost',
    ...select,
    addPhysician: 'Přidat lékaře',
    orderDetail: 'Detail žádanky',
    orderDelete: 'Smazat žádanku',
    orderEdit: 'Upravit žádanku',
    confirmDelete: 'Opravdu smazat?',
    ...messagesForGrid,
    mwlHasAttachments: 'Žádanka má minimálně jednu přílohu',
    dicom: 'DICOM data',
    nondicom: 'Multimediální data',
    ...errors,
  },

  Request: {
    requestsList: 'Seznam žádostí',
    requestsListForSplit: 'Seznam žádostí na rozdělení',
    requestsListFor: {
      editPatient: 'Seznam žádostí na opravu pacienta',
      editStudy: 'Seznam žádostí na opravu vyšetření',
      move: 'Seznam žádostí na přesunutí',
      copy: 'Seznam žádostí na kopírování',
    },
    requestsListForReorder: 'Seznam žádostí na přeskupení',
    requestsListForSend: 'Seznam žádostí na odeslání',
    requestsListForMoveToFolder: 'Seznam žádostí na přidání do pracovního seznamu',
    requestsListForMoveToTrash: 'Seznam žádostí na smazání',

    createdWhen: 'Datum vytvoření',
    typeOfOperation: 'Typ operace',
    state: 'Stav žádosti',
    studyState: 'Stav',
    empty: '-',
    aborted: 'Zrušená',
    approved: 'Schválená',
    failed: 'Nezdařená',
    finished: 'Provedená',
    waiting: 'Čekající',
    comment: 'Komentář',
    new: 'Nová',
    rejected: 'Zamítnutá',
    requestingUser: 'Žádající uživatel',
    studyDate: 'Datum vyšetření',
    queueRequestDetail: 'Detail požadavku',
    accessionNumber: 'Číslo žádanky',
    requestNotFound: 'Žádost nenalezena',
    ...studyFunctions,
    search: 'Vyhledat',
    'requestEdit.study.create': 'Vytvoření nové žádosti na opravu vyšetření',
    'requestEdit.patient.create': 'Vytvoření nové žádosti na opravu pacienta',
    requestSendCreate: 'Vytvoření nové žádosti na odeslání',
    requestSplitCreate: 'Vytvoření nové žádosti na rozdělení',
    'request.move.create': 'Vytvoření nové žádosti pro přesunutí',
    'request.copy.create': 'Vytvoření nové žádosti pro kopírování',
    requestMoveToFolderCreate: 'Vytvoření nové žádosti na přidání do pracovního seznamu',
    requestMoveToTrashCreate: 'Vytvoření nové žádosti na smazání',
    chooseMultipleStudiesForRequest: 'Vybrat vyšetření pro žádost',
    backToRequestsList: 'Zpět na seznam žádostí',
    selectedStudies: 'Vybraná vyšetření',
    requestUser: 'Žádající uživatel',
    saved: 'Žádost uspěšně uložena.',
    errorSaving: 'Žádost se nepodařila uložit',
    patient: 'Pacient',
    reset: 'Vyčistit',
    ...sexes,

    requestStateChanged: 'Stav žádosti změněn',
    errorChangingState: 'Chyba při změně stavu žádosti',
    name: 'Jméno',
    surname: 'Příjmení',
    owner: 'Vlastník',
    comments: 'Komentáře',
    history: 'Historie',
    oldState: 'Původní stav',
    newState: 'Nový stav',
    author: 'Autor',
    studies: 'Vybraná vyšetření',
    sourceStudy: 'Zdrojové vyšetření',
    sourceNewStudy: 'Zdrojové vyšetření po schválení',
    targetStudy: 'Cílové vyšetření',
    newStudy: 'Nové vyšetření',
    newPlannedStudy: 'Budoucí vyšetření',
    oldStudy: 'Původní vyšetření',
    repairStudy: 'Opravené vyšetření',
    copyStudies: 'Zkopírovaná vyšetření',
    moveStudies: 'Přesunutá vyšetření',
    ...gridStudyItems,

    'operationType.other': '?',
    'operationType.send': 'Nastavení odeslání',
    'operationType.reorder': 'Výběr sérií a snímků',
    'operationType.split': 'Nastavení rozdělení',
    'operationType.copy': 'Nastavení kopírování',
    'operationType.move': 'Nastavení přesunu',
    'operationType.move_to_folder': 'Vybrané pracovní seznamy',

    // Send
    'data.sendType': 'Způsob odeslání',
    'data.facility': 'Příjemce (zdravotnické zařízení)',
    'data.exchangeNetwork': 'Výměnná síť',
    'data.sendType.ExchangeNetwork': 'Výměnná síť',
    'data.sendType.DrSejf': 'Dr. Sejf',
    'data.sendType.CloudPacs': 'CloudPacs',
    'data.sendType.mDex': 'MDex',
    'data.viewer': 'Prohlížeč',
    'data.includeStudyReport': 'Nález',
    'data.anonymization': 'Anonymizace',
    'data.patientID': 'ID pacienta',

    // Edit
    ...dataForEdit,
    inArchive: 'v archivu ',

    // Reorder
    'data.series': 'Počet vybraných sérií',
    'data.images': 'Počet vybraných snímků',
    // Split
    'data.includeComments': 'Zahrnout komentáře',
    // Distribute: Copy / Move
    'data.archiveTo': 'Cílový archiv',

    ...yesno,
    ...requestsActions,
    ...dialogTexts,
  },

  Reports: {
    titleStatistics: 'Statistiky',
    show: 'Zobrazit',
    chartType: 'Typ grafu',
    archives: 'Archiv',
    stationNames: 'Stanice',
    INCREMENTAL: 'Přírůstky',
    CUMULATIVE: 'Kumulativně',
    year: 'Rok',
    month: 'Měsíc',
    sum: 'Celkové zaplnění vybraných stanic',
    labelY: 'Zaplnění úložiště [GB]',
    labelQuantityY: 'Počet vyšetření',
    cannotWiewStatisticNow:
      'Pro nedostatek dat není možné statistiky zobrazit. Zkuste to prosím později.',
    undefined: 'Nedefinováno',
    titleStudiesByStudyTagsStatistics: 'Statistiky podle štítků',
    tagName: 'Jméno štítku',
    tagCount: 'Počet vyšetření se štítkem',
    1: 'leden',
    2: 'únor',
    3: 'březen',
    4: 'duben',
    5: 'květen',
    6: 'červen',
    7: 'červenec',
    8: 'srpen',
    9: 'září',
    10: 'říjen',
    11: 'listopad',
    12: 'prosinec',
    setAllStations: 'Všechny stanice',
    setNoStations: 'Žádnou stanici',
    modalityNames: 'Modality',
    setAllModalities: 'Všechny modality',
    setNoModalities: 'Žádné modality',
    filling: 'Zaplnění úložiště',
    quantity: 'Počet vyšetření',
    byModalities: 'Podle modalit',
    byAets: 'Podle stanic',
    studyTagStatistics: 'Počet vyšetření podle štítku',
    userTagStatistics: 'Počet vyšetření podle uživatele',
    user: 'Uživatel',
    tag: 'Štítek',
    from: 'Od',
    to: 'Do',
    search: 'Vyhledat',
    reset: 'Vyčistit',
  },

  FindingReports: {
    user: 'Uživatel',
    hospital: 'Nemocnice',
    studyDate: 'Datum aktivity',
    search: 'Vyhledat',
    physicianName: 'Jméno lékaře',
    hospitalName: 'Nemocnice',
    findingCount: 'Počet nálezů',
  },

  Logs: {
    auditLogList: 'Aktivity uživatelů',
    dateTime: 'Datum a čas',
    username: 'Uživatel',
    ipAddress: 'IP adresa',
    activity: 'Aktivita',
    subject: 'Produkt',
    typeOfObject: 'Typ objektu',
    patientID: 'ID pacienta',
    note: 'Poznámka',
    noData: 'Žádné záznamy',
    requestId: 'ID požadavku',
    ...messagesForGrid,
  },

  Users: {
    username: 'Uživatelské jméno',
    firstname: 'Křestní jméno',
    lastname: 'Příjmení',
    middlename: 'Prostřední jméno',
    prefix: 'Tituly před',
    suffix: 'Tituly za',
    search: 'Vyhledat',
    retired: 'S ukončeným prac. poměrem',
    retire: 'Ukončit pracovní poměr',
    retiredDate: 'Pracovní poměr bude ukončen k datu',
    retireInfoText:
      'Uživateli {{firstName}} {{lastName}} ({{username}}) bude ukončen pracovní poměr ke dni {{retiredDate}}. V systému budou vedeny uživatelovy osobní údaje do {{dateTo}}. Po tomto datu budou veškeré údaje o uživateli odstraněny',
    revertInfoText: 'Uživateli byl ukončen pracovní poměr ke dni {{retiredDate}}',
    cancelTermination: 'Zrušit ukončení pracovního poměru',
    reallyCancelTermination: 'Opravdu zrušit ukončení pracovního poměru?',
    afterRevert: 'Editace uživatele poté bude povolena',
    cancelTerminationSuccessfully: 'Ukončení pracovního poměru se podařilo zrušit',
    errorSavingRevert: 'Nepodařilo se zrušit ukončení pracovního poměru',
    errorSavingNoRetired: 'Nepodařilo se ukončit pracovní poměr',
    userRetired: 'Uživateli byl ukončen pracovní poměr',
    icp: 'ICP',
    userIsInternal: 'Interní uživatel',
    userCanBeNotifiedAboutRequestActions: 'Zobrazovat notifikace o žádostech',
    workPlaces: 'Pracovní místa',
    units: 'Oddělení',
    create: 'Nový uživatel',
    edit: 'Editovat uživatele',
    password: 'Heslo',
    confirmPassword: 'Heslo (znovu)',
    invalidPassword: 'Heslo a heslo k potvrzení se neshoduje',
    saved: 'Uživatel úspěšně uložen.',
    deleted: 'Uživatel úspěšně odstraněn',
    errorDeleting: 'Uživatele se nepodařilo odstranit',

    userProfile: 'Uživatelský profil',
    userProfileWorkPlaces: 'Pracovní místa',
    userProfileViewer: 'Prohlížeč',
    userProfileLanguage: 'Jazyk',
    userProfileButtonsSelected: 'Vybrané dlaždice',
    userProfileButtonsUnselected: 'Nevybrané dlaždice',
    userProfileButtonsSetAutomaticOpened: 'Automaticky otevírat po přihlášení',
    userProfileButtons: 'Dlaždice',
    userProfileNotifications: 'Notifikace',
    userProfileFacilities: 'Zařízení',
    userProfileSetNotification: 'Nastavit způsob notifikace',
    userProfile2FASetting: 'TOTP nastavení',
    userProfileCertificates: 'Podpisové certifikáty',
    generateNew: 'Vygenerovat klíč',
    regenerateNew: 'Znovu vygenerovat klíč',
    use2FAForLogin: 'Použít TOTP při přihlašování',
    explainPage: 'TOTP slouží jako dvouaktorové ověření.',
    explainPage2:
      'Nejprve je nutné vygenerovat tajný klíč (kliknutím na tlačítko vygenerovat klíč). Po vygenerování se zobrazí klíč v podobě QR kódu i v textové podobě v dialogu. Po opuštění stránky již nebude možno QR kód zobrazit zobrazit!',
    explainQRCode:
      'Tento vygenerovaný klíč je nutno oskenovat / zadat do příslušné mobilní aplikace.',
    explainQRCodeApps: 'Aplikaci lze stáhnout v Google Play a App store. Např. - ',
    explainQRCodeAppsETC: ' apod.',
    explainSettings: 'Následně je možné nastavit, zda TOTP používat při přihlašování.',
    darkMode: 'Tmavé téma',

    // Event types
    ...eventTypes,

    // Reaction types
    ...reactionTypes,

    ...yesno,

    save: 'Uložit',
    useLocalUserDirectory: 'Lokální adresář',
    // Form errors
    error: {
      generalError: 'Chyba při ukládání uživatele.',
      apiNotWorking: 'API server neodpovídá. Kontaktujte technickou podporu.',
      errorSaving: 'Chyba při ukládání uživatele.',
      errorUserExists: 'Uživatelské jméno již existuje.',
      invalidPassword: 'Heslo a heslo k potvrzení se neshoduje',
      errorUserPasswordRequired: 'Zadejte heslo.',
    },

    checkUserPassword: 'Kontrola expirace hesla',
    openUserProfile: 'Otevřít uživatelský profil',
    passwordExpired: 'Vaše heslo již expirovalo. Nové heslo zadejte ',
    passwordWillExpired: 'Vaše heslo bude expirovat za {{inDays}}. Nové heslo zadejte ',
    passwordExpiredLink: 'v uživatelském profilu',
    withTotp: 'S aktivním TOTP',
    resetTotpDialog: 'Resetovat TOTP nastavení',
    resetTotpForUser: 'Opravdu resetovat TOTP nastavení pro uživatele',
    reset: 'Resetovat',
    storno: 'Storno',
    totpResetSuccess: 'TOTP nastavení resetováno',
    totpResetError: 'TOTP nastavení nebylo možné resetovat',
  },

  NotificationTemplates: {
    eventType: 'Typ události',
    reactionType: 'Způsob notifikace',
    create: 'Nová šablona notifikace',
    missingTemplates: 'U těchto událostí chybí nastavená šablona notifikace',
    ...header,

    // Reaction types
    ...reactionTypes,

    // Event types
    ...eventTypes,

    // Errors
    error: { notification: { template: { exists: 'Daná notifikace již existuje' } } },
    errorDeleting: 'Chyba při mazání šablony',
    deleted: 'Šablona smazána',
    saved: 'Šablona uložena',
    errorSaving: 'Chyba při ukládání šablony',
    edit: 'Editace notifikace',

    dialog: { notificationSetting: 'Notifikace' },
    ...dialogTexts,
  },

  FacilitiesAndExchangeNetworks: {
    setFacilities: 'Zařízení',
    setExchangeNetwork: 'Sítě',
  },
  ExchangeNetworks: {
    edit: 'Editace sítě',
    name: 'Název',
    description: 'Popis',
    ip: 'Ip',
    port: 'Port',
    urlForUpdate: 'Url pro aktualizaci',
    color: 'Barva',
    priority: 'Priorita',
    priorityHelp:
      'Priorita má vliv na odeslání vyšetření pomocí výměnné sítě. Zde se po výběru zařízení předvyplní síť s nejvyšší prioritou.',
    errorSaving: 'Chyba při ukládání sítě.',
    saved: 'Síť úspěšně uložena.',
    exchangeNetworkNotFound: 'Síť nenalezena.',
  },
  RegisterGroups: {
    name: 'Název',
    parent: 'Rodičovská skupina',
    children: 'Podskupina',
    edit: 'Editace skupiny štítků',
    create: 'Nová skupina štítků',
    createSubRegisterGroups: 'Nová podskupina štítků',
    editRegisterItem: 'Editace štítku ve skupině',
    createRegisterItem: 'Nový štítek ve skupině',
    parentRegisterItems: 'Rodičovské položky',
    registerItems: 'Štítky ve skupině',
    subgroups: 'Podskupiny',
    newRegisterSubgroup: 'Nová podskupina',
    newRegisterItem: 'Nová položka',
    state: 'Stav',
    active: 'Aktivní',
    inactive: 'Neaktivní',
    registerGroupNotFound: 'Skupina nenalezena',
    registerItemNotFound: 'Položka nenalezena',
    confirmChangeState: 'Opravdu chcete změnit stav',
    errorSaveRegisterGroup: 'Chyba při ukládání do databáze',
    registerItemSaved: "Položka '{{registerItemName}}' úspěšně uložena",
    errorSaveRegisterItem: 'Chyba při ukládání do databáze',
    errorSaveRegisterItemIsUsedName: 'Název štítku je již použit',
    errorRegisterGroupExists: 'Skupina již existuje.',
    errorSaving: 'Chyba při ukládání skupiny.',
    saved: 'Skupina úspěšně uložena.',
  },

  Facilities: {
    favourite: 'oblíbené',
    action: 'Akce',
    create: 'Nové zařízení',
    edit: 'Editace zařízení',
    facility: 'Zdravotnické zařízení',
    code: 'Kód zařízení',
    name: 'Název',
    epacs: 'ePacs',
    redimed: 'ReDiMed',
    city: 'Adresa',
    country: 'Země',
    errorSaving: 'Chyba při ukládání zařízení.',
    saved: 'Zařízení úspěšně uloženo.',
    facilityNotFound: 'Zařízení nenalezeno.',
    facilityExchangeNetworkNotFound: 'Výměnná síť nenalezena',
    facilityExchangeNetworks: 'Výměnné sítě',
    facilityExchangeNetwork: {
      exhangeNetwork: { name: 'Název sítě' },
      identifier: 'Identifikátor přístupového bodu',
      add: 'Přidání sítě pro zařízení',
      edit: 'Editace sítě pro zařízení',
    },
    exchangeNetworkSaved: 'Výměnná síť úspěšně uložena.',
    exchangeNetworkErrorSaving: 'Chyba při ukládání výměnné sítě',
    deleted: 'Výměnná síť odebrána ze zařízení',
    errorDeleting: 'Výměnnou síť se nepodařilo odebrat',
    isNotValid:
      'Buď není nastavené lokální zařízení nebo lokální zařízení nemá validní kód zařízení (unikátní číslo na 4 znaky).',
    updateFacilitiesByRemoteSource: 'Aktualizovat seznam zařízení',
    updateFacilitiesByRemoteSourceSuccessfully: 'Seznam zařízení zaktualizován',
    updateFacilitiesByRemoteSourceError: 'Seznam zařízení se nepodařilo zaktualizovat',
    addFavourite: 'Přidat k oblíbeným',
    removeFavourite: 'Odebrat z oblíbených',
    changeFacilities: {
      success: 'Změna v oblíbených zařízeních se zdařila',
      error: 'Změna v oblíbených zařízeních se nezdařila',
    },
  },

  Import: {
    reset: 'Vyčistit',
    dicom: {
      title: 'Import DICOM dat',
      grid: {
        patientName: 'Příjmení a jméno',
        patientId: 'ID pacienta',
        studyDate: 'Datum vyšetření',
        patSex: 'Pohlaví',
        studyDescription: 'Popis vyšetření',
        files: 'Počet (velikost)',
      },
      editDicomTagsDialog: 'Úprava DICOM atributů',
      serieNumber: 'Série č. {{number}}',
      numberAndDescription: '(počet snímků: {{number}}, popis:{{description}})',
    },
    nonDicom: {
      title: 'Import multimediálních dat',
      grid: { fileName: 'Název souboru', size: 'Velikost' },
    },
    importWebcam: 'Přidat snímky z webkamery',
    orderSearch: 'Vyhledat žádanku',
    patientManagementSearch: 'Vyhledat pacienta',
    findStudy: 'Vyhledat vyšetření',
    allowedExtensions: 'Jsou povoleny soubory ve formátu {{rules}}',
    archive: 'Cílový archiv',
    delete: 'Smazat',
    confirm: 'Potvrdit',
    close: 'Zavřít',
    notifications: {
      success: 'Požadavek na import byl odeslán ke zpracování.',
      error: 'Požadavek na import dat se nepodařilo zpracovat.',
      mdexSuccess: 'Požadavek na odeslání přes síť MDex byl úspěšně zpracován.',
      mdexError: 'Požadavek na odeslání přes síť MDex se nepodařilo zpracovat.',
    },
    modality: 'Modalita',
    save: 'Uložit',
    uploadFiles: 'Nahrát soubory',
    uploadFolder: 'Nahrát složku',
    removeAll: 'Vymazat vše',
    unknownFormat: 'Neznámý formát',
    noDecubits: 'Žádné položky k vybrání',
    ...sexes,
    uppy: {
      browseFile: 'vyberte soubor',
      browseFiles: 'vyberte soubory',
      browseFolders: 'vyberte složky',
      dropPasteBoth: 'Přetáhněte soubory nebo složky, %{browseFiles} nebo %{browseFolders}',
      dropPasteFile: 'Přetáhněte soubor nebo %{browseFiles}',
      dropPasteFiles: 'Přetáhněte soubory nebo %{browseFiles}',
      browseFileUDI: 'vyberte soubor',
      dropPasteFileUDI: 'Přetáhněte soubor nebo %{browseFiles}',
      importUDIFile: 'Importovat UDI ze souboru',
      camera: 'Kamera',
      noCameraTitle: 'Kamera není dostupná',
      noCameraDescription: 'Nemáte přístup k vaší kameře. Zkontrolujte, zda je kamera připojena.',
    },
    uploadingFiles: 'Probíhá nahrávání souborů...',
    studyNotFound: 'Vyšetření nenalezeno, bude vytvořeno nové vyšetření.',
    multipleStudies: 'Nalezeno více vyšetření s uuid, nelze vybrat.',
    noHttps: 'Funkce kamery je dostupná pouze při zabezpečeném připojení (https).',
    editFileDialog: 'Import certifikátu',
  },

  Studies: {
    ...studyFunctions,
    ...sizeAndWeight,
    ...errors,
    ...openInBrowser,
    automaticRefresh: 'Znovunačtení výsledků hledání',
    studyListTitle: 'Vyhledání vyšetření',
    studyReorderTitle: 'Přeskupení vyšetření',
    studyReorderTitleRequest: 'Nová žádost na přeskupení vyšetření',
    studyReorderTitleRequestEdit: 'Editace žádosti na přeskupení vyšetření',
    studyEditTitle: 'Oprava vyšetření',
    patientEditTitle: 'Oprava pacienta',
    studyExportTitle: 'Export vyšetření',
    multipleEdit: 'Opravit',
    selectedStudies: 'Vybraná vyšetření',
    existingStudy: 'Existující vyšetření',
    existingSourceStudy: 'Zdrojové vyšetření',
    existingTargetStudy: 'Cílové vyšetření',
    newStudy: 'Nové vyšetření',
    patientID: 'ID pacienta',
    sex: 'Pohlaví',
    dateOfBirth: 'Datum narození',
    dateCreated: 'Datum vyšetření',
    timeCreated: 'Čas vyšetření',
    accessionNumber: 'Číslo žádanky',
    studyDescription: 'Popis vyšetření',
    archive: 'Archiv',
    ...sexes,
    other: 'Jiné',
    loadFromExisting: 'Načíst z existujícího vyšetření',
    reset: 'Vyčistit',
    studySendType: 'Způsob odeslání',
    cloudRecipient: 'Cloud příjemce',
    facility: 'Příjemce (zdravotnické zařízení)',
    selectExchangeNetwork: 'Vybraná síť',
    epacs: 'ePacs',
    redimed: 'Redimed',
    sendTypeExchangeNetworks: 'Výměnná síť',
    sendTypeCloudsPacs: 'CloudPacs',
    sendTypeDrSejf: 'drSejf',
    viewer: 'Prohlížeč',
    includeStudyReport: 'Nález',
    annotations: 'Přidat pacientské údaje do snímků',
    facilities: 'Zdravotnická zařízení',
    seriesOfExistingStudy: 'Série existujícího vyšetření',
    seriesOfNewStudy: 'Série nového vyšetření',
    created: 'Vytvořeno',
    instanceNumber: 'Číslo snímku',
    save: 'Uložit',
    findOrder: 'Najít žádanku',
    loadFromOrder: 'Načíst ze žádanky',
    patient: 'Pacient',
    study: 'Vyšetření',
    birthDate: 'Datum narození',
    studyDate: 'Datum vyšetření',
    studyTime: 'Čas vyšetření',
    find: 'Vyhledat',
    name: 'Příjmení a jméno',
    dateTime: 'Datum a čas vytvoření',
    unit: 'Oddělení',
    modality: 'Modalita',
    studyList: 'Seznam vyšetření',
    otherOptions: 'Další volby',
    includeComments: 'Zahrnout komentáře z existujícího vyšetření',
    errorRealisator: 'Chyba při komunikaci s realizátorem',
    referringPhysician: 'Vyšetřující lékař',
    requestingPhysician: 'Žádající lékař',
    performingPhysician: 'Provádějící lékař',
    splitSend: 'Rozdělit vyšetření',
    patientAttributesChangedConfirm:
      'Změnili jste jeden z atributů jméno, příjmení nebo ID pacienta. Potvrdit změnu?',
    errorCreatingRequest: 'Chyba při vytváření žádosti',
    ...searchDateButtons,
    originalDatetimeRequested: 'Plánovaný datum a čas',
    ...name,
    ...checkName,
    multipleAddToFolder: 'Přidat do pracovního seznamu',
    studyListAddToFolderTitle: 'Přidání vyšetření do pracovního seznamu',
    addToFolder: 'Přidat',
    selectFolder: 'Přidat pracovní seznam',
    ...select,
    folder: 'Pracovní seznam',
    notSelectedFolder: 'Vyberte pracovní seznam',
    studySelectedToFolder: 'Vyšetření úspěšně přidána do pracovního seznamu',
    errorSelectingStudyToFolder: 'Vyšetření se nepodařila přidat do pracovního seznamu',
    errorConflictingStudies:
      'Přidání vyšetření do pracovního seznamu neproběhlo z důvodu kolizních vyšetření - id pacientů:',
    studyInstanceUid: 'StudyInstanceUid',
    editDicomAttributes: 'Úprava DICOM atributů',
    modalities: 'Modality',
    patientBirthDate: 'Datum narození',
    archiveName: 'Archiv',
    patientId: 'ID pacienta',
    patientSex: 'Pohlaví',
    noOfInstances: 'Sr/Sn',
    removeSourceStudyForReorder: 'Odebrat zdrojové vyšetření pro přeskupení',

    // Edit
    edit: 'Opravit',
    studyEditSubmitOk: 'Požadavek na opravu vyšetření byl úspěšně odeslán.',
    patientEditSubmitOk: 'Požadavek na opravu pacienta byl úspěšně odeslán.',
    editSubmitError: 'Nezdařilo se odeslání požadavku na opravu studie',
    setRequestForEditStudy: 'Vytvořit žádost na opravu vyšetření',
    setRequestForEditStudyEdit: 'Upravit žádost na opravu vyšetření',
    setRequestForEditPatient: 'Vytvořit žádost na opravu pacienta',
    setRequestForEditPatientEdit: 'Upravit žádost na opravu pacienta',
    requestForStudyEditAdded: 'Žádost na opravu vyšetření vytvořena',
    requestForStudyEditEdited: 'Žádost na opravu vyšetření upravena',
    requestForPatientEditAdded: 'Žádost na opravu pacienta vytvořena',
    requestForPatientEditEdited: 'Žádost na opravu pacienta upravena',
    editTypeStudyTitleRequest: 'Nová žádost na opravu vyšetření',
    editTypeStudyTitleRequestEdit: 'Editace žádosti na opravu vyšetření',
    editTypePatientTitleRequest: 'Nová žádost na opravu pacienta',
    editTypePatientTitleRequestEdit: 'Editace žádosti na opravu pacienta',
    patientLocked:
      'Nelze provést opravu pacienta, protože s jedním nebo více vyšetřeními se pracuje. Zkuste provést opravu později.',
    editPatientDataConfirm:
      'Provedli jste změnu pacientských dat, skutečně chcete provést změny pro všechna vyšetření daného pacienta?',
    editPatientDataForStudyConfirm:
      'Provedli jste změnu pacientských dat, skutečně chcete provést změnu pro toto vyšetření?',
    editConfirmTitle: 'Oprava pacientských dat',
    confirm: 'Potvrdit',
    showInfoAboutRequestingPhysician:
      'Údaje o žádajícím lékaři nejsou dostupné pro tento typ archivu.',
    studiesEditStudy: {
      patient: 'Pacient',
      study: 'Vyšetření',
    },
    fixWillChangeAllStudiesOfSelectedePatient: 'Oprava změní všechna vyšetření vybraného pacienta.',

    // Split
    studySplitTitle: 'Rozdělení vyšetření',
    studySplitTitleRequest: 'Nová žádost na rozdělení vyšetření',
    studySplitTitleRequestEdit: 'Editace žádosti na rozdělení vyšetření',
    split: 'Rozdělit',
    setRequestForSplit: 'Vytvořit žádost na rozdělení',
    editRequestForSplit: 'Upravit žádost na rozdělení',
    successfullySplit: 'Požadavek na rozdělení vyšetření zadán',
    errorSplitting: 'Chyba při požadavku na rozdělení vyšetření',
    requestForSplitAdded: 'Žádost na rozdělení vytvořena',

    // Archiving
    ...archiving,

    // Send
    studySendTitle: 'Odeslání vyšetření',
    multipleSend: 'Odeslat',
    studySend: 'Odeslání vyšetření',
    send: 'Odeslat',
    submitSendError: 'Požadavek na odeslání vyšetření se nezdařil zpracovat.',
    submitSendOk: 'Požadavek na odeslání vyšetření byl odeslán ke zpracování.',
    studySendTitleRequest: 'Nová žádost na odeslání vyšetření',
    studySendTitleRequestEdit: 'Editace žádosti na odeslání vyšetření',
    setRequestForSend: 'Vytvořit žádost na odeslání',
    requestForSendAdded: 'Žádost na odeslání vytvořena',
    selectFacility: 'Vyberte zařízení...',
    selectRecipient: 'Vyberte cloud příjemce...',
    selected: { studies: 'Počet vybraných vyšetření: ' },
    // selected: {studies: {and: {series: 'Počet vybraných vyšetření a sérií: '}}},
    selected_studies_and_series: 'Počet vybraných vyšetření a sérií: ',
    setRequestFor: {
      send: 'Vytvořit žádost na odeslání',
      moveToFolder: 'Vytvořit žádost na přidání do pracovního seznamu',
      moveToTrash: 'Vytvořit žádost na smazání',
      move: 'Vytvořit žádost na přesunutí',
      copy: 'Vytvořit žádost na kopírování',
    },
    editRequestFor: {
      send: 'Upravit žádost na odeslání',
      moveToFolder: 'Upravit žádost na přidání do pracovního seznamu',
      moveToTrash: 'Upravit žádost na smazání',
      move: 'Upravit žádost na přesunutí',
      copy: 'Upravit žádost na kopírování',
    },
    editRequestForSend: 'Upravit žádost na odeslání',
    favourite: 'Oblíben',
    others: 'Ostatní',
    selected_documents: 'Počet vybraných dokumentů: ',

    studyMoveToFolderTitleRequest: 'Nová žádost na přidání vyšetření do pracovního seznamu',
    studyMoveToFolderTitleRequestEdit: 'Editace žádosti na přidání vyšetření do pracovního seznamu',
    setRequestForMoveToFolder: 'Vytvořit žádost na přidání do pracovního seznamu',
    requestForMoveToFolderAdded: 'Žádost na přidání do pracovního seznamu vytvořena',
    editRequestForMoveToFolder: 'Upravit žádost na přidání do pracovního seznamu',
    requestCreate: {
      moveToFolder: {
        confirmation: 'Opravdu vytvořit žádost na přidání vyšetření do pracovního seznamu?',
      },
      moveToTrash: { confirmation: 'Opravdu vytvořit žádost na smazání vyšetření?' },
      move: { confirmation: 'Opravdu vytvořit žádost na přesunutí vyšetření?' },
      copy: { confirmation: 'Opravdu vytvořit žádost na kopírování vyšetření?' },
      send: { confirmation: 'Opravdu vytvořit žádost na odeslání vyšetření?' },
    },
    requestEdit: {
      moveToFolder: {
        confirmation: 'Opravdu upravit žádost na přidání vyšetření do pracovního seznamu?',
      },
      moveToTrash: { confirmation: 'Opravdu upravit žádost na smazání vyšetření?' },
      move: { confirmation: 'Opravdu upravit žádost na přesunutí vyšetření?' },
      copy: { confirmation: 'Opravdu upravit žádost na kopírování vyšetření?' },
      send: { confirmation: 'Opravdu upravit žádost na odeslání vyšetření?' },
    },

    // Trash
    studyMoveToTrashTitleRequest: 'Nová žádost na smazání vyšetření',
    studyMoveToTrashTitleRequestEdit: 'Editace žádosti na smazání vyšetření',
    setRequestForMoveToTrash: 'Vytvořit žádost na smazání',
    requestForMoveToTrashAdded: 'Žádost na smazání vytvořena',
    editRequestForMoveToTrash: 'Upravit žádost na smazání',

    // Distribute
    studyAction: {
      move: {
        title: 'Přesunutí vyšetření',
        confirmation: 'Opravdu přesunout vyšetření?',
      },
      copy: {
        title: 'Kopírování vyšetření',
        confirmation: 'Opravdu kopírovat vyšetření?',
      },
      send: {
        title: 'Odeslání vyšetření',
        confirmation: 'Opravdu odeslat vyšetření?',
      },
      request: {
        move: 'Nová žádost na přesunutí vyšetření',
        copy: 'Nová žádost na kopírování vyšetření',
      },
      requestEdit: {
        move: 'Editace žádosti na přesunutí vyšetření',
        copy: 'Editace žádosti na kopírování vyšetření',
      },
    },
    study_move: 'Přesunutí vyšetření',
    study_copy: 'Kopírování vyšetření',

    document: { send_documents: { confirmation: 'Opravdu odeslat dokumenty?' } },
    selectedStudiesAndSeries: 'Počet vybraných vyšetření a sérií: ',
    multiple: {
      move: 'Přesunout',
      copy: 'Kopírovat',
    },
    move: 'Přesunout',
    copy: 'Kopírovat',
    close: 'Zavřít',
    submit: {
      move: {
        error: 'Požadavek na přesunutí vyšetření se nezdařil zpracovat.',
        ok: 'Požadavek na přesunutí vyšetření byl odeslán ke zpracování.',
      },
      copy: {
        error: 'Požadavek na kopírování vyšetření se nezdařil zpracovat.',
        ok: 'Požadavek na kopírování vyšetření byl odeslán ke zpracování.',
      },
    },
    archiveTo: 'Cílový archiv',

    requestFor: {
      move: {
        added: 'Žádost na přesunutí vytvořena',
        updated: 'Žádost na přesunutí upravena',
      },
      copy: {
        added: 'Žádost na kopírování vytvořena',
        updated: 'Žádost na kopírování upravena',
      },
    },
    distributeAndUpdateStudy: 'Opravit vyšetření',
    moveNoRights:
      'Dané vyšetření není možné přesunout. Tento uživatel nemá práva přesunu do jiného archivu. Prosím kontaktujte správce modulu Portál.',
    copyNoRights:
      'Dané vyšetření není možné kopírovat. Tento uživatel nemá práva kopírování do jiného archivu. Prosím kontaktujte správce modulu Portál.',

    // Export
    exportType: 'Typ exportu',
    compression: 'Zapnout kompresi',
    report: 'Přidat nález',
    multipleExport: 'Exportovat',
    author: 'Autor',
    reportTitle: 'Nález',
    date: 'Datum',
    text: 'Zpráva',
    export: 'Exportovat',
    quality: 'Kvalita',
    framerate: 'Rychlost snímkování',
    successfullyExport: 'Požadavek na export vyšetření zadán',
    errorExport: 'Chyba při požadavku na export vyšetření',
    errorBadImage: 'Požadavek je neplatný, nelze exportovat',
    totalSize: 'Celková velikost',
    size: 'Velikost',
    maxSize: 'Maximální povolená velikost',
    maxSizeExceeded: 'Překročena maximální velikost, změňte výběr',
    loadingValue: 'Hodnota se načítá...',
    LOW: 'nízká',
    MEDIUM: 'střední',
    HIGH: 'vysoká',
    studyExportFormat: 'Formát exportu',
    exportConfirmTitle: 'Opravdu exportovat vyšetření?',
    exportConfirmMorePatientId: 'Jsou vybrány vyšetření s rozdílnými ID pacienta.',
    exportConfirmMissingReport: 'Chybí nález k jednomu z exportovaných vyšetření.',
    exportConfirmCalculationError: 'Nepodařila se dopočítat celková velikost.',
    calculationError: 'neznámá',

    // Studies, series, images list
    studyPatientId: 'Id pacienta: {{patientId}}',
    studyDateBirth: 'Datum narození: {{dateBirth}}',
    studyDateTime: 'Čas vyšetření: {{dateTime}}',
    studySeriesImages: 'Série/Snímky: {{series}}/{{images}}',
    serieImages: 'Snímků: {{images}}',

    // Reorder
    sourceStudy: 'Zdrojové vyšetření',
    targetStudy: 'Cílové vyšetření',
    searchSourceStudy: 'Vyhledat zdrojové vyšetření',
    searchTargetStudy: 'Vyhledat cílové vyšetření',
    studyContainsComment: 'Upozornění: vyšetření obsahuje komentář',
    cannotReorderSameStudies: 'Nelze přeskupit stejné vyšetření',
    reorderSend: 'Přeskupit vyšetření',
    reorder: 'Přeskupit',
    studyWillBeDeleted:
      'Přesouvají se všechny série ze zdrojového vyšetření. Zdrojové vyšetření bude smazáno.',
    seriesOfSourceStudy: 'Série zdrojového vyšetření',
    seriesOfTargetStudy: 'Série cílového vyšetření',
    addedToNewStudy: 'Série přidané do cílového vyšetření',
    successfullyReordered: 'Požadavek na přeskupení vyšetření zadán',
    errorReordering: 'Chyba při požadavku na přeskupení vyšetření',
    sourceStudySelected: 'Zdrojové vyšetření přidáno',
    targetStudySelected: 'Cílové vyšetření přidáno',
    sourceStudyRemoved: 'Zdrojové vyšetření odebráno',
    targetStudyRemoved: 'Cílové vyšetření odebráno',
    setRequestForReorder: 'Vytvořit žádost na přeskupení',
    editRequestForReorder: 'Upravit žádost na přeskupení',

    // Requests
    orderSearch: 'Vyhledat žádanku',
    requestComment: 'Komentář k žádosti',
    additionalRequestForm: 'Doplňující informace k žádosti',
    requestingUser: {
      lastName: 'Příjmení uživatele',
      firstName: 'Jméno uživatele',
    },
    requestForReorderAdded: 'Žádost na přeskupení vytvořena',
    errorEditingRequest: 'Chyba při úpravě žádosti',
    requestEdited: 'Žádost upravena',

    // Scientific archive
    scientificArchiveTitle: 'Vyhledání ve vědeckém archivu',
    //IntegrationPlatform
    integrationPlatformTitle: 'Vyhledání v integrační platformě',
    // patientManagement
    patientManagementSearch: 'Vyhledat pacienta',
    findPatientManagement: 'Najít pacienta',
    patientManagementAnnouncement:
      'Zadejte celé příjmení a minimálně dva znaky z křestního jména, nebo zadejte celé ID pacienta (rodné číslo).',
    loadingData: 'Vyhledávám vyšetření v archivu ',
    findStudy: 'Vyhledat vyšetření',

    // Signing
    submitSigningError: 'Požadavek na podepsání vyšetření se nezdařil zpracovat.',
    submitSigningOk: 'Požadavek na podepsání vyšetření byl odeslán ke zpracování.',

    // Shredding
    ...dataForShredding,

    // Dose monitoring
    doseMonitoringTitle: 'Sledování dávky',
    doseMonitoringExport: 'Export',
    noData: 'Žádné záznamy',
    doseError: 'Informaci se nepodařilo získat',
    doseErrorInIE:
      'Nepodařilo se dopočítat dávku. Zkuste obnovit stránku nebo použít jiný prohlížeč.',
    bodyPartExamined: 'Vyšetřovaná část těla',
    dose: 'Dávka',

    ...allowedExtensions,
    allowedFilesDicom: 'Jsou povoleny pouze dicom soubory.',
    uploadingFiles: 'Probíhá nahrávání souborů...',

    images: 'Snímků',
    timeTakenFromSeries: 'Čas snímku je převzatý z času série',
    openInBrowser: 'Zobrazit v prohlížeči',
    openReportTitle: 'Zobrazit nález',
    modalitiesInStudy: 'Modalita',
    addModality: 'Přidat novou modalitu',

    user: 'Autor',
    operation: 'Operace',

    ...togglePreview,

    comment: 'Komentář',

    // shreddings
    shreddingProposalsTitle: 'Seznam návrhů na skartaci',
    shreddingConfirmedTitle: 'Historie skartace',

    patientName: 'Jméno pacienta',
    confirmationDate: 'Datum provedení skartace',
    userConfirmation: 'Autor potvrzení',
    userProposal: 'Autor návrhu',
    shreddingPrintTitle: 'Protokol o provedení skartace',
    shreddingPrintContent1:
      'Celkem byla provedena skartace u {{par1}} dokumentu trvalým smazáním z archivů PACS.',
    shreddingPrintContent:
      'Celkem byla provedena skartace u {{par1}} dokumentů trvalým smazáním z archivů PACS.',
    action: { print: 'Tisknout' },

    ...printSetting,
    ...sejfSendForm,

    seriesUID: 'Series UID',
    submitDeletingError: 'Požadavek na smazání nebyl přijat.',
    submitDeletingOk: 'Požadavek na smazání byl přijat.',
    submitDeletingOkAndNok: 'Požadavek na smazání byl přijat u {{par1}} vyšetření.',
    confirmMoveToTrashTitle: 'Smazat vybraná vyšetření?',
    confirmMoveToTrash:
      'Vybrali jste {{par1}} vyšetření ke smazání. Opravdu si přejete provést operaci nad daným výběrem?',
    confirmArchivingStudiesTitle: 'Archivovat vyšetření',
    confirmArchivingStudies:
      'Vybrali jste {{par1}} vyšetření k archivování. Opravdu si přejete provést operaci nad daným výběrem?',

    ...errorViewerMessages,

    // import - decubits
    addImageType: 'Přidat obrazové určení',
    doNotAddImageType: 'Nepřidávat obrazové určení',
    imageType: 'Obrazové určení',
    selectBodyPart: 'Zvolte část těla',
    ...decubitsCode,
    M: 'mDex',
    E: 'ePacs',
    R: 'Redimed',
    allowOverwrite: 'Upravit',
    editingFromOrder:
      'Upravujete údaje převzaté ze žádanky. Operace může způsobit nekonzistenci údajů.',
    editingFromStudy:
      'Upravujete údaje převzaté z vyšetření. Operace může způsobit nekonzistenci údajů.',

    cannotSplitWithAllSelected: 'Nelze rozdělit vyšetření, pokud jsou vybrány všechny série',

    couldNotLoadSeries: 'Nebylo možné načíst série pro vyšetření',
    couldNotLoadDicomData: 'Nebylo možné načíst DICOM data pro vyšetření',

    listOfSeries: 'Seznam sérií',
    studyUID: 'StudyInstanceUid',

    reportForStudyNotFound: 'Nález pro toto vyšetření neexistuje',
    dosesForStudyNotFound: 'Dávky pro toto vyšetření neexistují',

    isDeleted: 'Je smazáno?',
    multipleRemoveStudiesFromDB: 'Smazat (nastavit příznak) vyšetření z db',
    confirmRemoveStudiesFromDB: 'Opravdu smazat (nastavit příznak) vybraná vyšetření z db?',
    deleted: 'Úspěšně smazáno.',
    errorDeleting: 'Záznam se nepodařilo odstranit',
  },

  Grid: {
    noData: 'Žádné záznamy',
    ...messagesForGrid,
    actions: 'Akce',
    edit: 'Upravit',
    edit_group: 'Upravit',
    edit_register_item: 'Upravit',
    edit_order: 'Upravit',
    edit_redirect: 'Otevřít',
    editableColumn: 'Upravit hodnotu',
    removeStudyFromFolder: 'Odebrat vyšetření z pracovního seznamu',
    confirmRemoveStudyFromFolder: 'Opravdu odebrat vyšetření z pracovního seznamu?',
    removeUserFromFolder: 'Odebrat uživatele z pracovního seznamu',
    confirmRemoveUserFromFolder: 'Opravdu odebrat uživatele z pracovního seznamu?',
    removeDocumentFromFolder: 'Odebrat dokumentaci z pracovního seznamu',
    removeDocumentsFromFolder: 'Odebrat dokumentace z pracovního seznamu',
    removeSerieFromFolder: 'Odebrat sérii z pracovního seznamu',
    confirmRemoveSerieFromFolder: 'Opravdu odebrat sérii z pracovního seznamu?',
    confirmRemoveDocumentFromFolder: 'Opravdu odebrat dokumentaci z pracovního seznamu?',
    confirmRemoveDocumentsFromFolder: 'Opravdu odebrat dokumentace z pracovního seznamu?',
    select: 'Vybrat',
    select_order: 'Vybrat',
    select_patient_management_to_import: 'Vybrat',
    detail_order: 'Detail',
    choose: 'Vybrat',
    delete: 'Smazat',
    delete_order: 'Smazat',
    delete_file: 'Smazat',
    delete_attachment: 'Smazat',
    deleteSerie: 'Smazat',
    cancel_upload: 'Přerušit',
    download: 'Stáhnout',
    confirmDelete: 'Opravdu smazat?',
    confirmChangeState: 'Opravdu změnit stav?',
    confirmChangeActiveFacility: 'Opravdu změnit domácí zařízení?',
    confirmChangeDefaultModality: 'Opravdu nastavit jako výchozí zařízení?',
    confirmChangePrintable: 'Opravdu změnit stav pro Tisknutelné?',
    confirmChangeExportable: 'Opravdu změnit stav pro Exportovatelné?',
    confirmChangeEnabled: 'Opravdu změnit stav pro zobrazeno?',
    confirmChangeBasic: 'Opravdu nastavit pro hledání v základním formuláři?',
    active: 'aktivní',
    inactive: 'neaktivní',
    activeFacility: 'lokální',
    inactiveFacility: 'externí',
    default: 'výchozí',
    detail: 'Detail',
    archive: 'Smazat',
    setStudyForEdit: 'Opravit vyšetření',
    archiveName: 'Archiv',
    remove_file: 'Smazat',
    favourite: 'oblíbené',
    unpopular: 'neoblíbené',
    stationAet: 'Aet stanice',
    notificationDetail: 'Detail notifikace',
    echoConnectedModality: 'Echo',
    selected: 'vybráno',
    addState: 'Přidat stav',
    removeState: 'Odebrat stav',

    // Order
    orderDetail: 'Detail žádanky',
    orderEdit: 'Upravit žádanku',
    orderDelete: 'Smazat žádanku',
    mwlHasAttachments: 'Žádanka má minimálně jednu přílohu',
    view_attachement: 'Zobrazit',

    // Requests
    ...requestsActions,

    // GridStudie
    createdDate: 'Datum vyšetření',
    modalities: 'Modality',
    studyDescription: 'Popis',
    countImagesSeries: 'Sn/Sr',
    studyLocked: 'Studie uzamčena',
    setStudyForRequestEditStudy: 'Vybrat pro žádost na opravu vyšetření',
    accessionNumber: 'Číslo žádanky',

    // GridSerie
    seriesPreview: 'Náhled série',
    bodyPart: 'Části těla',
    seriesNumber: 'Číslo série',
    dateTime: 'Datum a čas série',
    modality: 'Modalita',
    seriesDescription: 'Popis série',
    noOfInstances: 'Počet snímků',

    // Queue
    queueDetail: 'Detail požadavku',
    queueCancel: 'Zrušit požadavek',
    confirmQueueCancel: 'Opravdu zrušit požadavek?',

    confirmChangePriority: 'Opravdu změnit prioritu?',
    confirmChangePermission: 'Opravdu změnit oprávnění?',
    ...userPermission,

    // Tests
    testDetail: 'Detail',
    testQuestions: 'Otázky',
    testClone: 'Kopírovat',
    testPublish: 'Zveřejnit',
    definitionEdit: 'Editovat',
    testStart: 'Spustit test',
    continueTest: 'Pokračovat v testu',
    testResultDetail: 'Detail výsledků',
    studyDetail: 'Detail vyšetření',

    // Dose
    bodyPartExamined: 'Vyšetřovaná část těla',
    dose: 'Dávka',
    seriesUID: 'Series UID',
    instancesUID: 'Instances UID',

    // Clinical portal
    printable: 'Tisknutelné',
    exportable: 'Exportovatelné',
    enabled: 'zobrazeno',
    basicForm: 'základní formulář',
    showDocument: 'Zobrazit dokumentaci',

    retired: 'Ukončit poměr',
    resetTotp: 'Resetovat TOTP nastavení',
    permissions: 'Oprávnění',

    ...decubitsCode,
    ...sexes,
  },
  Queues: {
    stateOfQueue: 'Fronta požadavků',
    dateTime: 'Datum a čas',
    username: 'Uživatel',
    operation: 'Operace',
    state: 'Stav',
    patientName: 'Příjmení a jméno',
    studyDateTime: 'Datum vyšetření',
    accessionNumber: 'Číslo žádanky',
    requestId: 'ID požadavku',
    noData: 'Nenalezeny žádné záznamy',
    ...queueStates,
    ...studyFunctions,
    inArchive: ' v archivu ',

    succesCancelQueue: 'Požadavek na zrušení požadavku z fronty byl úspěšně odeslán.',
    errorCancelQueue: 'Požadavek na zrušení požadavku z fronty se nepodařilo odeslat.',

    targetNode: 'Cílový uzel:',
    drSejf: 'Dr. Sejf',
  },
  Cstore: {
    cstoreToExchangeNetwork: 'Odeslání',
    cstoreToArchive: 'Uložení',
    allowedFilesDicom: 'Jsou povoleny pouze dicom soubory.',
    archiveTo: 'Cílový archiv',
    targetAet: 'Cílový AET',
    targetIp: 'Cílová IP',
    targetPort: 'Cílový port',
    sourceAet: 'Zdrojový AET',
    reset: 'Vyčistit',
    echo: 'Echo',
    cstore: 'C-STORE',
    echoSuccessfully: 'Echo úspěšné',
    echoFailed: 'Echo selhalo',
    successfullyCstore: 'C-STORE dokončen',
    errorCstore: 'C-STORE selhal',
    noFiles: 'Nejsou vybrány žádné soubory',
    uploadingFiles: 'Probíhá nahrávání souborů...',
  },
  Tools: {
    tools: 'Administrátorské nástroje',
    bitLength: 'Délka v bitech',
    hashtext: 'Text / hash',
    generate: 'Generovat RSA klíč',
    download: 'Stáhnout RSA klíč',
    encrypt: 'Zakódovat text veřejným RSA klíčem',
    decrypt: 'Rozkódovat hash veřejným RSA klíčem',
    invalidBitLength: 'Zadejte délku v bitech',
    invalidText: 'Zadejte text',
    invalidHash: 'Zadejte hash',
    generated: 'RSA klíč vygenerován',
    errorGenerating: 'RSA klíč se nepodařilo vygenerovat',
    encrypted: 'Text zakódován',
    errorEncrypting: 'Text se nepodařilo zakódovat',
    decrypted: 'Hash rozkódován',
    errorDecrypting: 'Hash se nepodařilo rozkódovat',
    toolsRSA: 'RSA',
  },
  AnnouncementsAndButtons: {
    setAnnouncements: 'Oznámení',
    setButtons: 'Dlaždice',
  },
  Announcements: {
    create: 'Nové oznámení',
    edit: 'Editace oznámení',
    position: 'Pořadí',
    validFrom: 'Platnost od',
    validTo: 'Platnost do',
    languageJsonText: 'Text (json)',
    importanceLabel: 'Důležitost',
    workPlaces: 'Pracovní místa',
    selectMin1Workplace: 'Vyberte alespoň jedno pracovní místo',
    errorSaving: 'Chyba při ukládání oznámení.',
    saved: 'Oznámení úspěšně uloženo.',
    name: 'Text',
    importance: {
      warning: 'Varování',
      info: 'Info',
      danger: 'Problém',
    },
  },
  Buttons: {
    text: 'Text',
    icon: 'Ikona',
    url: 'Url',
    urlMustStartWithSlashOrHttp: 'Url musí začínat lomítkem nebo řetězcem "http"',
    create: 'Nová dlaždice',
    edit: 'Editace dlaždice',
    searchIcons: 'Vyhledat ikony',
    workplaces: 'Pracovní místa',
    errorSaving: 'Chyba při ukládání dlaždice',
    languageJsonText: 'Uživatelský název (json)',
    system: 'Systémová dlaždice',
    saved: 'Dlaždice uložena',
    deleted: 'Dlaždice odstraněna',
    errorDeleting: 'Dlaždici se nepodařilo odstranit',
    languageJsonText_cs: 'Název (česky)',
    languageJsonText_en: 'Název (anglicky)',
    languageJsonText_ru: 'Název (rusky)',
    searchFilter: 'Vyhledání vyšetření',
    removedSelected: 'Vybrané dlaždice odstraněny',
    errorRemovingSelected: 'Vybrané dlaždice se nepodařilo odstranit',
    deleteInactive: 'Smazat neaktivní',
    selectedButtonsSplitBy: 'Rozřadit pracovním místům podle práv',
    automatic: 'automaticky',
    manual: 'ručně',
    manualUnassign: 'Odebrat pracovní místo',
    userButtonUpdate: 'Aktualizovat uživatelské dlaždice',
    deleteInactiveButtonsWarning:
      'Po stisku tlačítka "Smazat" se vybrané dlaždice smažou z databáze.',
    assignButtonsAutomaticWarning:
      'Na základě konfiguračního souboru budou přiřazeny dlaždice pracovním místům dle toho, jaké role dané pracovní místo má.',
    assignButtonManualWarning:
      'K vybraným dlaždicím bude přidáno/odebráno pracovní místo po stisku tlačítka s pracovním místem.',
    assignUserWarning:
      'Pokud bude zapnutý přepínač "Aktualizovat uživatelské dlaždice", pak se všem uživatelům nastaví dlaždice dle nového nastavení a po dokončení operace budou mít všichni uživatelé aktivní všechny dlaždice, které mají dostupné podle svých pracovních míst.',
    successSplitButtons:
      'Právě probíhá rozřazování dlaždic. Výsledek můžete ověřit buď v logu nebo prověřením dat v prohlížeči',
    errorSplitButtons: 'Rozřazování dlaždic se nezdařilo',
    ...dialogTexts,
    ...yesno,
  },
  Dictionaries: {
    create: 'Přidání položky do slovníku',
    createPhysician: 'Přidání položky do slovníku Lékaři',
    edit: 'Úprava položky ve slovníku',
    editPhysician: 'Úprava položky ve slovníku Lékaři',
    name: 'Název',
    code: 'Kód',
    unit: 'Jednotka',
    translate: 'Překlad',
    order: 'Pořadí',
    type: 'Typ',
    value: 'Hodnota',
    dicomTag: 'Dicom tag',
    expertness: 'Odbornost',
    healthInsuranceCompany: 'Zdravotní pojišťovny',
    studyOrderPriority: 'Urgentnost',
    requestedProcedure: 'Požadované vyšetření',
    physicians: 'Lékaři',
    dose: 'DICOM atributy',
    studyDetailBookmarks: 'Záložky v detailu vyšetření',
    decubits: 'Části těla',
    bodyPartExaminated: 'Část těla',
    imageLaterality: 'Laterality',
    patientPosition: 'Pozice pacienta',
    bodyPartExaminatedRequired: 'Vyberte část těla',
    patientPositionRequired: 'Vyberte pozici pacienta',
    duplicateDecubit: 'Záznam s částí těla, lateralitou a pozicí pacienta již existuje',
    deleted: 'Položka úspěšně smazána.',
    saved: 'Uložení proběhlo úspěšně',
    errorSaving: 'Uložení se nezdařilo',
    errorDeleting: 'Záznam se nepodařilo odstranit',
    physicianNotFound: 'Záznam se nepodařilo vyhledat',
    requestingPhysician: 'Žádající lékař',
    referringPhysician: 'Vyšetřující lékař',
    dictionaryNotFound: 'Položky slovníku nenalezeny',
    ...name,
    ...yesno,
    ...decubitsCode,
    ...select,
  },
  Languages: {
    abbreviation: 'Zkratka',
    name: 'Název',
    active: 'Aktivní',
    default: 'Výchozí',
    ...yesno,
    saved: 'Uložení proběhlo úspěšně',
    errorSaving: 'Změnu se nepodařilo uložit',
    edit: 'Úprava jazyka:',
  },
  Settings: {
    setWorkplaces: 'Pracovní místa',
    setProducts: 'Produkty',
    setUsers: 'Uživatelé',
    setConnectedModalities: 'Připojení modalit',

    frontendSettings: 'Nastavení frontendu',
    primaryColor: 'Hlavní barva',
    menuBackground: 'Barva pozadí levého panelu',
    menuColor: 'Barva textu levého panelu',
    menuLogo: 'Logo v levém panelu',
    mainTitle: 'Text v záhlaví stránky',
    loginPageBackgroundColor: 'Barva pozadí přihlašovací stránky',
    loginPageColor: 'Barva textu přihlašovací stránky',
    logos: 'Loga',
    base64: 'Base64 řetězec',
    width: 'Šířka loga (px)',
    height: 'Výška loga (px)',
    openExternalConverter: 'Otevřít externí službu pro konvertování obrázků',
    frontendSettingsSaved: 'Nastavení uloženo',
    frontendSettingsNotSaved: 'Chyba při ukládání konfigurace',
    colors: 'Barvy',
    texts: 'Texty',
    logo: {
      logoVariant: 'Varianta loga aplikace',
      application: 'Logo aplikace',
      marie: 'Logo Marie Pacs',
      customer: 'Logo zákazníka',
      variant: {
        lightPortal: 'Portál - světlé',
        darkPortal: 'Portál - tmavé',
        lightClinicalPortal: 'Klinický portál - světlé',
        darkClinicalPortal: 'Klinický portál - tmavé',
      },
    },

    application: 'Aplikace',
    loginScreen: 'Přihlašovací obrazovka',
    text: 'Text na přihlašovací obrazovce',
    footer: {
      title: 'Patička',
      links: 'Odkazy v patičce',
      orCzLink: 'Odkaz na firmu ORCZ',
      link: 'URL odkazu',
      text: 'Text odkazu',
      helpdesk: 'Odkaz na helpdesk',
      contact: 'Kontaktní informace',
      label: 'Štítek',
      show: 'Zobrazit',
      contactText: 'Text ke kontaktním informacím',
      visibleOnStartup: 'Automaticky zobrazit štítek',
      day: 'Den',
      night: 'Noc',
      weekend: 'Svátky/víkendy',
      phone: 'Telefon',
      help: 'Nápověda v patičce',
    },
    footerColor: 'Barva textu v patičce',
    footerHoverColor: 'Barva textu po najetí myši',
    udiValue: 'Hodnota UDI',
    uploadUDI: 'Nahrát ze souboru',
    footerLink: 'Odkaz v patičce',
  },
  Workplaces: {
    name: 'Název',
    code: 'Kód',
    create: 'Nové pracovní místo',
    // edit: 'Editace pracovního místa',
    workplaceNotFound: 'Pracovní místo nenalezeno',
    viewer: 'Prohlížeč',
    allowedArchives: 'Povolené archivy',
    roles: 'Oprávnění',
    archives: 'Povolené archivy',
    saved: 'Pracovní místo úspěšně uloženo',
    errorSaving: 'Chyba při ukládání pracovního místa',
    deleted: 'Pracovní místo úspěšně odstraněno',
    errorDeleting: 'Pracovní místo se nepodařilo odstranit',
    addToWorkplaces: 'Přidat',
    addWorkplacesFromLdap: 'Přidat pracovní místa do databáze',
    selectWorkplaces: 'Vyberte pracovní místa z adresní knihy',
    noItems: 'Žádná pracovní místa z adresní knihy',
    addWorkplacesFromLdapConfirmSet: 'Opravdu přidat vybraná pracovní místa z adresní knihy?',
    addWorkplacesFromLdapContent: 'Přidají se pouze dosud nepřidaná pracovní místa.',
    addWorkplacesFromLdapSuccessfully: 'Pracovní místa přidány',
    addWorkplacesFromLdapError: 'Pracovní místa se nepodařilo přidat',
    userProfileFacilities: 'Zařízení',
    archiveActions: 'Akce archivů',
    archiveAction: 'Akce archivu',
    allowedArchiveActions: 'Povolené akce',
    savedActionsForArchive: 'Akce archivu uloženy',
    errorSavingActionsForArchive: 'Akce archivu se nepodařilo uložit',
    allowedFunctions: 'Povolené operace',
    isAll: 'Vše',
    ...studyFunctions,
    ...dialogTexts,
    search: 'Vyhledat',
    findingStatuses: 'Stavy nálezů',
  },
  Products: {
    create: 'Nový produkt',
    code: 'Kód',
    description: 'Popis',
    name: 'Název',
    edit: 'Editace produktu',
    type: 'Typ',
    subtype: 'Podtyp',
    saved: 'Produkt úspěšně uložen.',
    errorSaving: 'Chyba při ukládání produktu.',
    settingSaved: 'Nastavení úspěšně uloženo.',
    settingErrorSaving: 'Chyba při ukládání nastavení',
    variable: 'Název proměnné',
    value: 'Hodnota',
    createProductSetting: 'Nové nastavení',
    editProductSetting: 'Editace nastavení',
    settings: 'Nastavení',
    productNotFound: 'Produkt nenalezen',
    realisator: 'Realizátor',
    archive: 'Archiv',
    viewer: 'Prohlížeč',
    esb: 'ESB',
    portal: 'Portál',
    'patient management': 'PMI',
    'trusted archive': 'Důvěryhodný archiv',
    MS: 'Marie Server',
    MSE: 'Marie Server Express',
    mash: 'MASH',
    node: 'DICOM uzel',
    trash: 'Koš',
    ceph: 'NAS Enterprise',
    signature: 'Elektronický podpis',
    other: 'Jiné',
    drSejf: 'Dr. Sejf',
    HISConnector: 'HIS Connector',
    webvision: 'Webvision',
    MSWeb: 'MSWeb',
    DPGW: 'DPGW',
    clinical_portal: 'Klinický portál',
    default: 'Výchozí',
    ...yesno,
  },
  ConnectedModalities: {
    connectedModalities: 'Připojení modalit',
    archives: 'Výběr archivu',
    aet: 'AE title',
    ip: 'IP adresa',
    port: 'Port',
    manufacturer: 'Výrobce',
    storageDirectory: 'Adresář uložení',
    comment: 'Komentář',
    create: 'Připojení nové modality',
    edit: 'Úprava připojené modality',
    createSuccessfully: 'Modalita úspěšně připojena',
    editSuccessfully: 'Modalita úspěšně upravena',
    deleteSuccessfully: 'Modalita úspěšně odpojena',
    echoSuccessfully: 'Echo proběhlo úspěšně',
    createError: 'Připojení modality se nezdařilo',
    editError: 'Úprava modality se nezdařila',
    'error.same.name': 'Modalita nelze vytvořit z důvodu existence modality stejného jména.',
    deleteError: 'Smazání modality se nazdařilo',
    echoError: 'Echo se nepovedlo',
    getConnectedModalitiesError:
      'Nepodařilo se načíst připojené modality. Kontaktujte technickou podporu.',
    missingArchives: 'Není možné spravovat připojené modality, protože není dostupný žádný archiv!',
    add: 'Přidat',
    back: 'Zpět',
    save: 'Uložit',
  },
  Folders: {
    detail: 'Detail pracovního seznamu',
    create: 'Nový pracovní seznam',
    edit: 'Editace pracovního seznamu',
    name: 'Název',
    nameOfNew: 'Název nového pracovního seznamu',
    owner: 'Vlastník',
    showDetail: 'Zobrazit detail',
    folderNotFound: 'Pracovní seznam nenalezen.',
    errorSaving: 'Chyba při ukládání pracovního seznamu.',
    errorFolderExists: 'Název pracovního seznamu již existuje.',
    saved: 'Pracovní seznam úspěšně uložen.',
    deleted: 'Pracovní seznam úspěšně odstraněn',
    errorDeleting: 'Pracovní seznam se nepodařilo odstranit',
    userRequired: 'Vyberte uživatele',
    userAddedToFolder: 'Uživatelé úspěšně přidáni do pracovního seznamu',
    errorUserAddedToFolder: 'Chyba při přidání uživatelů do pracovního seznamu',
    deletedUser: 'Uživatel úspěšně odstraněn',
    errorDeletingUser: 'Uživatele se nepodařilo odstranit',
    updateUserPermission: 'Uživateli změněno oprávnění',
    errorUpdateUserPermission: 'Uživateli se nepodařilo změnit oprávnění',
    deletedDocument: 'Dokumentace úspěšně odebrána',
    errorDeletingDocument: 'Dokumentaci se nepodařilo odebrat',
    createButton: 'Vytvořit nový pracovní seznam',
    0: '-',
    1: 'Pondělí',
    2: 'Úterý',
    3: 'Středa',
    4: 'Čtvrtek',
    5: 'Pátek',
    6: 'Sobota',
    7: 'Neděle',
    documents: 'Vybraná dokumentace',
    studies: 'Vybraná vyšetření',
    users: 'Uživatelé',
    userAdd: 'Přidat',
    fullname: 'Jméno',
    patientFullname: 'Jméno pacienta',
    email: 'Email',
    permission: 'Oprávnění',
    dialog: { user: 'Vyberte uživatele' },
    select: 'Vyberte uživatele',
    ...userPermission,
    action: {
      edit: 'Upravit pracovní seznam',
      delete: 'Smazat pracovní seznam',
    },
    selectFromList: 'Vybrat pracovní seznam',
    folderIsNotAvailable: 'Pracovní seznam není dostupný',
    confirmDelete: 'Opravdu smazat pracovní seznam?',
    submitRemovingStudyError: 'Vyšetření se nepodařilo odebrat',
    submitRemovingStudyOk: 'Vyšetření úspěšně odebráno',
    confirmRemoveStudyFromFolder: 'Opravdu odebrat vyšetření z pracovního seznamu?',
    tabGrid: 'Seznam pracovních seznamů',
    tabCalendar: 'Kalendář',
    selectFolder: 'Vybrat pracovní seznam',
    studySelectedToFolder: 'Vyšetření úspěšně přidáno do pracovního seznamu',
    errorSelectingStudyToFolder: 'Vyšetření se nepodařilo přidat do pracovního seznamu',
    seriesSelectedToFolder: 'Série úspěšně přidány do pracovního seznamu',
    errorSelectingSeriesToFolder: 'Série se nepodařilo přidat do pracovního seznamu',
    documentsSelectedToFolders: 'Dokumenty úspěšně přidány do pracovního seznamu',
    errorSelectingDocumentsToFolders: 'Dokumenty se nepodařilo přidat do pracovního seznamu',
    removedStudyFromFolder: 'Vyšetření bylo odebráno z pracovního seznamu',
    errorRemovingStudyFromFolder: 'Vyšetření se nepodařilo odebrat z pracovního seznamu',
    removedSeriesFromFolder: 'Série byla odebrána z pracovního seznamu',
    errorRemovingSeriesFromFolder: 'Sérii se nepodařilo odebrat z pracovního seznamu',
    multipleAddToFolder: 'Přidat do pracovního seznamu',
    multipleShowDocumentsFromFolder: 'Zobrazit dokumentaci',
    multipleRemoveDocumentsFromFolder: 'Odebrat dokumentace z pracovního seznamu',
    removeSelectedStudiesFromFolder: 'Smazat z pracovního seznamu',
    removeAllStudiesFromFolder: 'Smazat všechna vyšetření z pracovního seznamu',
    confirmRemoveStudiesFromFolder: 'Opravdu odebrat vybraná vyšetření z pracovního seznamu?',
    confirmRemoveAllStudiesFromFolder: 'Opravdu odebrat všechna vyšetření z pracovního seznamu?',
    confirmExtendExpiration: 'Opravdu prodloužit expiraci?',
    extendedExpiration: 'Úspěšně prodloužena expirace',
    errorExtendedExpiration: 'Nepodařilo se prodloužit expiraci',

    ...dialogTexts,

    loadingData: 'Vyhledávám vyšetření v archivu ',
    expiration: 'Expirace za',
    expirationHelp: 'Expirace je počítána od data vložení vyšetření do pracovního seznamu.',

    ...expirationButtons,
    ...clinicalPortalDocumentation,
  },
  Filters: {
    create: 'Nový filtr',
    edit: 'Editace filtru',
    name: 'Název',
    workPlaces: 'Pracovní místa',
    selectMin1Workplace: 'Vyberte alespoň jedno pracovní místo',
    filterType: 'Typ filtru',
    onlyForMe: 'Pouze pro mě',
    errorSaving: 'Chyba při ukládání filtru.',
    errorFilterrExists: 'Název filtru již existuje.',
    saved: 'Filtr úspěšně uložen.',
    deleted: 'Filtr úspěšně odstraněn',
    errorDeleting: 'Filtr se nepodařilo odstranit',
    authorName: 'Jméno autora',
    authorTooltip: 'Autor poslední změny',
    ...state,
  },
  ModalitiesUnitsStations: {
    setModalities: 'Modality',
    setUnits: 'Oddělení',
    setStations: 'Stanice',
  },
  Modalities: {
    create: 'Nová modalita',
    edit: 'Editace modality',
    name: 'Název',
    color: 'Barva',
    show: 'Zobrazení',
    multiframe: 'Smyčka (multiframe)',
    description: 'Popis',
    modalityNotFound: 'Modalita nenalezena.',
    errorSaving: 'Chyba při ukládání modality.',
    'error.administration.modality.exists.with.this.name': 'Název modality již existuje.',
    'error.administration.modality.exists.with.this.color': 'Barva je již použita u jiné modality.',

    saved: 'Modalita úspěšně uložena.',
    deleted: 'Modalita úspěšně odstraněna',
    errorDeleting: 'Modalitu se nepodařilo odstranit',
    ...yesno,
    ...header,
  },
  Units: {
    create: 'Nové oddělení',
    edit: 'Editace oddělení',
    code: 'Kód',
    name: 'Název',
    unitNotFound: 'Oddělení nenalezeno.',
    errorSaving: 'Chyba při ukládání oddělení.',
    errorUnitExists: 'Kód nebo název oddělení již existuje.',
    saved: 'Oddělení úspěšně uloženo.',
    deleted: 'Oddělení úspěšně odstraněno',
    errorDeleting: 'Oddělení se nepodařilo odstranit',
    ...header,
  },
  Stations: {
    create: 'Nová stanice',
    edit: 'Editace stanice',
    code: 'Kód',
    aetitle: 'Název (aetitle)',
    modality: 'Modalita',
    unit: 'Oddělení',
    stationNotFound: 'Stanice nenalezena.',
    errorSaving: 'Chyba při ukládání stanice.',
    saved: 'Stanice úspěšně uložena.',
    deleted: 'Stanice úspěšně odstraněna',
    errorDeleting: 'Stanici se nepodařilo odstranit',
    ...header,
  },

  GeneralSettings: {
    setPriorities: 'Priority',
    setOperations: 'Operace',
    setNotificationSettings: 'Notifikace',
    setNotificationTemplates: 'Šablony pro notifikace',
    setDictionaries: 'Slovníky',
    setLanguages: 'Jazyky',
    setHospitals: 'Nemocnice',
    setStudies: 'Vyšetření z DB',
  },
  PrioritiesAndOperations: {
    name: 'Název priority',
    operation: 'Typ operace',
    priority: 'Priorita',
    dialog: { priority: 'Priorita' },
    errorSaving: 'Chyba při ukládání priority.',
    saved: 'Priorita úspěšně uložena.',
    deleted: 'Priorita úspěšně odstraněna',
    errorDeleting: 'Prioritu se nepodařilo odstranit',
    updateOperationPriority: 'U operace změněna priorita',
    errorUpdateOperationPriority: 'U operace se nepodařilo změnit prioritu',
    ...studyFunctions,
    ...dialogTexts,
    ...header,
  },
  Hospitals: {
    identifier: 'Identifikátor',
    alternativeIdentifier: 'Alternativní identifikátor',
    name: 'Název nemocnice',
    dialog: { hospital: 'Nemocnice' },
    errorSaving: 'Chyba při ukládání nemocnice.',
    saved: 'Nemocnice úspěšně uložena.',
    deleted: 'Nemocnice úspěšně odstraněna',
    errorDeleting: 'Nemocnici se nepodařilo odstranit',
    ...dialogTexts,
    ...header,
  },

  ClinicalPortalSettings: {
    clinicalPortal: 'Nastavení klinického portálu',
    viewer: 'Prohlížeč',
    searchPatientItems: 'Položky vyhledání pacienta',
    formats: 'Typy formátu',
    typesOfDocument: 'Typy dokumentu',
    bookmarks: 'Záložky',
    formItems: 'Položky formuláře',

    // Format
    tabFormatsName: 'Název',
    formatTitle: 'Formát',
    create: 'Nový formát',
    edit: 'Editace formátu',
    formatName: 'Název',
    formatNotFound: 'Formát nenalezen',
    error: {
      administration: {
        format: { exists: { with: { this: { name: 'Název formátu již existuje' } } } },
        typeOfDocument: {
          exists: { with: { this: { name: 'Název typu dokumentu již existuje' } } },
        },
        bookmark: { exists: { with: { this: { name: 'Název typu dokumentu již existuje' } } } },
      },
    },
    errorFormatExists: 'Formát již existuje',
    errorSaving: 'Chyba při ukládání formátu',
    saved: 'Formát úspěšně uložen',
    deleted: 'Formát úspěšně odstraněn',
    errorDeleting: 'Formát se nepodařilo odstranit',

    // TypeOfDocument
    name: 'Název',
    typesOfDocumentName: 'Technický název',
    allowedFormats: 'Formáty',
    typeOfDocumentTitle: 'Typ dokumentu',
    createTypeOfDocument: 'Nový typ dokumentu',
    editTypeOfDocument: 'Editace typ dokumentu',
    typeOfDocumentName: 'Název',
    typeOfDocumentTechnicalName: 'Technický název',
    typesOfDocumentIconName: 'Ikona',
    typeOfDocumentIconName: 'Název ikony',
    typeOfDocumentIconColor: 'Barva ikony',
    typeOfDocumentCanShow: 'Zobrazovat dokumenty tohoto typu',
    typeOfDocumentStandardizedPrintEnabled: 'Umožnit standardizovaný tisk',
    canUseEldax: 'Možnost stahovat dokumentaci v Eldaxu',
    searchIcons: 'Vyhledat ikony',
    typeOfDocumentNotFound: 'Formát nenalezen',
    errorTypeOfDocumentExists: 'Typ dokumentu již existuje',
    savedTypeOfDocument: 'Typ dokumentu úspěšně uložen',
    deletedTypeOfDocument: 'Typ dokumentu úspěšně odstraněn',
    errorDeletingTypeOfDocument: 'Typ dokumentu se nepodařilo odstranit',

    // Bookmark
    bookmarksName: 'Název',
    bookmarksPosition: 'Pozice',
    allowedTypesOfDocument: 'Typy dokumentů',
    allowedWorkplaces: 'Pracovní místa',
    createBookmark: 'Nová záložka',
    editBookmark: 'Editace záložky',
    bookmarkNotFound: 'Záložka nenalezena',
    errorBookmarkExists: 'Záložka již existuje',
    savedBookmark: 'Záložka úspěšně uložena',
    errorSavingBookmark: 'Chyba při ukládání záložky',
    deletedBookmark: 'Záložka úspěšně odstraněna',
    errorDeletingBookmark: 'Záložka se nepodařila odstranit',

    allColumns: 'Zobrazit všechny sloupce',
    author: 'Autor',
    clinic_code: 'Kód kliniky',
    clinic_name: 'Název kliniky',
    created_at: 'Datum vytvoření',
    description: 'Doplňující informace',
    document_id: 'ID Dokumentu',
    source: 'Zdroj',

    // FormItems
    formItemName: 'Kód',
    formItemPosition: 'Pořadí',
    savedFormItem: 'Položka formuláře úspěšně uložena',
    errorSavingFormItem: 'Chyba při ukládání položky formuláře',
    formItemNotFound: 'Položka formuláře nenalezena',
    editFormItem: 'Editace položky formuláře',

    ...dialogTexts,
    ...header,
  },

  ClinicalPortal: {
    title: 'Vyhledání pacienta',
    medicalDocumentationsSearch: 'Vyhledání zdravotnické dokumentace',
    name: 'Příjmení a jméno',
    patientInsuranceNumber: 'Číslo pojištěnce',
    patientIdentificationNumber: 'Rodné číslo',
    patientID: 'ID pacienta',
    patientBirthDate: 'Datum narození',
    patientSex: 'Pohlaví',
    address: 'Adresa',
    telephone: 'Telefon',
    motherMaidenName: 'Rodné jméno matky',
    masterPatientID: 'MPI číslo pacienta',
    noData: 'Nenalezeny žádné záznamy',
    noFindPatient: 'Pacient nenalezen',
    ...messagesForGrid,
    ...sexes,
    detail: 'Zdravotnická dokumentace',
    showDetail: 'Zobrazit detail',
    multipleExport: 'Exportovat dokumentaci',
    multiplePrint: 'Tisknout dokumentaci',
    multipleStandardizedPrint: 'Tisknout standardizovaně dokumentaci',
    multipleShow: 'Zobrazení',
    multipleAddToFolder: 'Přidat do pracovního seznamu',
    sendDocument: 'Odeslat dokumenty',
    unsupportedCombination:
      'Nepodporovaná kombinace typů dokumentů - operaci nelze provést! Lze zvolit pouze dokumenty typu DICOM nebo pouze dokumenty ostatních typů.',
    isEnabledSendOnlyPdfAndHasSelectedDicom:
      'Lze zvolit dokumenty libovolného typu kromě typu DICOM',
    isEnabledSendOnlyDicomAndHasSelectedPdf: 'Lze zvolit pouze dokumenty typu DICOM',
    downloadFromEldax: 'Stáhnout z Garantovaného archivu',
    downloadFromEldaxError: 'Nepodařilo se stáhnout',
    documentList: 'Seznam dokumentů',
    ...sejfSendForm,
    uuid: 'Identifikátor',
    modifiedDatetime: 'Datum změny',
    tags: 'Štítky',
    documentsDetail: 'Detail dokumentace',
    unknownDocument: 'Dokument nevybrán',
    notLoadedDocument: 'Dokument nenačten',
    unselect: 'Zrušit výběr dokumentu',
    reselect: 'Vybrat dokument',
    addTag: 'Přidat štítek',
    layoutChooser: 'Výběr rozložení',
    ...dialogOther,
    showOriginalDocument: 'Zobrazit originál',
    ...clinicalPortalDocumentation,
    documentTagDelete: 'Smazat štítek',
    confirmDelete: 'Opravdu smazat?',
    documentCreated: 'Vytvořeno:',
    documentUnit: 'Název oddělení:',
    documentNumberUnit: 'Č. oddělení:',
    documentWorkplaceNumber: 'IČP:',
    documentExpertise: 'ODB:',
    documentPrinted: 'Vytištěno:',
    documentPatientLastName: 'Příjmení',
    documentPatientFirstName: 'Jméno',
    documentPatientTitle: 'Titul',
    documentPatientInsuranceNumber: 'ČP',
    documentPatientResidence: 'Bydliště:',
    documentPatientHealthInsurance: 'ZP',
    documentPatientEmployer: 'Zaměstnavatel:',
    documentPatientProfession: 'Povolání:',
    documentPatientPhone: 'Telefon:',
    documentPatientMail: 'E-mail:',
    documentPatientAattendingGeneralPractitioner: ' Ošetřující praktický lékař:',
    documentPatientDischargedFromUnit: 'Propuštěn z oddělení:',
    documentPatientHospitalizedFrom: 'Pacient/ka byl/a hospitalizován/a od',
    documentPatientHospitalizedTo: 'do',
    documentCopyCreator: 'kopii dokumentu vytvořil:',
    hospitalizationFrom: 'Hospitalizován od:',
    hospitalizationTo: 'do:',
    diagnosisCode: 'Kód diagnózy:',
    diagnosisDescription: 'Popis diagnózy:',
    diagnosisType: 'Kód diagnózy:',
    diagnosisTypeCode: 'Kód typu diagnózy:',
    diagnosisTypeDescription: 'Popis typu diagnózy:',
    diagnosisPriorityCode: 'Úroveň diagnózy:',
    procedureFromTime: 'Datum operace od:',
    procedureToTime: 'do:',
    anesthesiaFromTime: 'Datum začátku anestézie od:',
    anesthesiaToTime: 'do:',
    performanceFromTime: 'Datum výkonu lékaře od:',
    performanceToTime: 'do:',
    procedureDescription: 'Druh operace:',
    procedureReoperation: 'Reoperace:',
    procedureOperatingRoom: 'Sál:',
    transferEnteredAt: 'Umístěn na oddělení:',
    transferFromTime: 'Od:',
    transferToTime: 'Do:',
    prescriptionDrugCode: 'Kód léku:',
    prescriptionDrugName: 'Název léku:',
    prescriptionDrugDescription: 'Popis léku:',
    prescriptionDrugExporig: 'Počet balení léku:',
    prescriptionDrugDsig: 'Dávkování:',
    prescriptionNumberOfDrugPrescription: 'Počet opakování receptu:',
    getDataError: 'Chyba při načítání údajů o pacientovi',
    printDocumentError: 'Chyba při generování pdf pro tisk',
    exportDocumentError: 'Chyba při generování pdf pro export',
    morePatientInfo: 'Více informací',
    lessPatientInfo: 'Méně informací',
    patientInsurance: 'Zdravotní pojišťovna',
    email: 'E-mail',
    employer: 'Zaměstnavatel',
    occupation: 'Zaměstnání',
    attendingGeneralPractitioner: 'Ošetřující praktický lékař',
    bloodGroup: 'Krevní skupina',
    allergy: 'Alergie',
    sendDicomDocumentsTitle: 'Odeslání DICOM dokumentů přes Dr. Sejf',
    sendPdfDocumentsTitle: 'Odeslání PDF dokumentů přes Dr. Sejf',
    submitSendError: 'Požadavek na odeslání dokumentů se nezdařil zpracovat.',
    submitSendOk: 'Požadavek na odeslání dokumentů byl odeslán ke zpracování.',
    ...studyFunctions,
    pdfPage: 'Stránka',
    pdfPageOf: 'z',
    pdfLoading: 'Načítání PDF ...',
    pdfLoadingError: 'PDF se nepodařilo načíst',
    errorEsbGetOriginalDocument: 'K tomuto dokumentu neexistuje originál',
    loadingDataForFacility: 'Načítají se dokumenty z {{par1}}',
    loadingData: 'Načítá se dokument',
    errorCodes: {
      1: 'Chyba v navazující komunikaci s garantovaným archivem - ELDAX.',
      2: 'Chyba v navazující komunikaci s integrační platformou - DocReg.',
      3: 'Požadovaný obsah nelze nalézt v garantovaném archivu.',
      4: 'Přístup k požadovanému obsahu nebyl v garantovaném archivu povolen.',
      5: 'V metadatech XDSb DocReg nebylo nalezeno žádné ELDAX ID.',
      6: "V XDSb DocReg nebyl nalezen žádný dokument pro kombinaci 'mpi_id', 'xds_aa_oid' (nebo 'xds_aa_code') a 'xds_zd_id'.",
      7: 'Chybí metadata vstupního požadavku!',
      99: 'Nespecifikovaná chyba procesu.',
    },
    noStudiesFoundInArchive:
      'Nebyla nalezena žádná vyšetření v archivu pro vybrané dokumenty pacienta.',
    masterPatientIdMismatch: 'Neshoda masterPatientId z vybraných dokumentů',
  },
  WebsocketNotifications: {
    ...eventTypes,
    ...studyFunctions,
    ID: 'ID',
    detail: 'Detail notifikace',
    subject: 'Předmět zprávy',
    ...state,
    list: 'Seznam',
    new: 'Nová',
    read: 'Přečtená',
    archived: 'Archivovaná',
    approved: 'Schválené',
    failed: 'Neúspěšné',
    finished: 'Ukončené',
    date: 'Datum',
    status: 'Status',
    edit: 'Detail notifikace',
    created: 'Vytvořeno dne',
    patient: 'Pacient',
    accessionNumber: 'Číslo žádanky',
    sourceArchive: 'Zdrojový archiv',
    archivedSuccessfully: 'Notifikace smazána',
    errorArchiving: 'Notifikace se nepodařilo smazat',
    newSetNotificationSuccessfully: 'Notifikace nastavena jako nová',
    newSetNotificationsSuccessfully: 'Notifikace nastavené jako nové',
    newSetNotificationError: 'Notifikaci se nepodařilo nastavit jako novou',
    newSetNotificationsError: 'Notifikace se nepodařilo nastavit jako nové',
    readSetNotificationSuccessfully: 'Notifikace nastavena jako přečtená',
    readSetNotificationsSuccessfully: 'Notifikace nastavené jako přečtené',
    readSetNotificationError: 'Notifikaci se nepodařilo nastavit jako přečtenou',
    readSetNotificationsError: 'Notifikace se nepodařilo nastavit jako přečtené',
    archivedSetNotificationSuccessfully: 'Notifikace smazána',
    archivedSetNotificationsSuccessfully: 'Notifikace smazány',
    archivedSetNotificationError: 'Notifikaci se nepodařilo smazat',
    archivedSetNotificationsError: 'Notifikace se nepodařilo smazat',
    multipleSetAsRead: 'Označit jako přečtené',
    multipleSetAsNew: 'Označit jako nepřečtené',
    multipleSetAsArchived: 'Smazat',
    readConfirmSet: 'Nastavit vybrané notifikace jako přečtené?',
    newConfirmSet: 'Nastavit vybrané notifikace jako nové?',
    archivedConfirmSet: 'Smazat vybrané notifikace?',
    goToStudy: 'Přejít na vyšetření',
    study: 'Vyšetření',
    operation: 'Typ operace',

    archiveNotification: 'Smazat',
    downloadAttachment: 'Stáhnout export',
    showAuditLog: 'Auditní log',
    showRequest: 'Požadavek',
    sign: 'Podepsat vyšetření',
    canSign:
      'Nové klony vyšetření je třeba manuálně podepsat. Proveďte prosím podepsání vyšetření:',
    listExport: 'Seznam exportů',

    selectedStudy: 'Vybrané vyšetření',
    exportedStudy: 'Exportované vyšetření',
    selectedStudies: 'Vybraná vyšetření',
    sourceStudy: 'Původní vyšetření',
    sourceStudyNew: 'Původní vyšetření - změněné',
    targetStudy: 'Nové vyšetření',
    updateStudy: 'Opravené vyšetření',

    ...gridStudyItems,
    ...sexes,

    nNewNotifications: [
      'Máte {{n}} novou notifikaci',
      'Máte {{n}} nové notifikace',
      'Máte {{n}} nových notifikací',
    ],
    noNotifications: 'Žádné notifikace',

    studyState: 'Stav',
    ...dataForEdit,

    downloadError: 'Nepovedlo se stáhnout soubor',
    aborted: 'Zrušená',
    waiting: 'Čekající',
    rejected: 'Zamítnutá',
    workplaceChangedFirstPArt: 'Došlo ke změně v systému.',
    workplaceChangedSecondPArt: 'Změna se projeví po odhlášení a opětovném přihlášení.',
    resultsNum: 'Zobrazeno {{size}} nejnovějších notifikací',
    loadNext: 'Načíst další notifikace',
    noNotificationForRequestId: 'Nedohledala se notifikace pro požadavek',
    noNotification: 'Nedohledala se notifikace',
    targetNode: 'Cílový uzel:',
    drSejf: 'Dr. Sejf',
    errorDescription: 'Důvod',
    SEND_MDEX_OK: 'Odeslání přes mDex provedeno.',
    SEND_MDEX_NOK: 'Odeslání přes mDex se nezdařilo.',
  },

  NCPeH: {
    title: 'Vyhledání v Národním kontaktním místu pro elektronické zdravotnictví',
    country: 'Země',
    documentTypes: 'Typ dokumentu',
    accessReasons: 'Důvod přístupu',
    EPrescription: 'Elektronický recept',
    PatientSummary: 'Patient summary',
    treatment: 'Návštěva lékaře',
    emergency: 'Naléhavý případ',
    nationalSearch: 'NCP mezinárodní volání',
    nationalSearchTooltip:
      'NCP mezinárodní volání vrací dokumenty z nejaktuálnějšího endpointu (NISu) dle hodnoty effectivetime, které vrací jednotlivé oslovené endpointy. Ostatní dokumenty z jiných endpointů jsou ignorovány. Dokumenty prochází NCP infrastrukturou a obsahují překlady kódovaných informací dle katalogu MVC (master value catalog). Vnitrostátní volání (národní) vrací všechny dokumenty od všech endpointů. Dokumenty neprochází NCP infrastrukturou a neobsahují překlady, dojde pouze k oslovení endpointů a všechny dokumenty jsou z národního konektoru přímo vrácené na portál.',
    search: 'Vyhledat',
    unknownError: 'neznámá',
    submitErrorNCPeH: 'Chyba NCPeH: {{message}} (Kód: {{code}})',
    submitError: 'Chyba: {{message}} (Kód: {{code}})',
    name: 'Příjmení a jméno',
    patientBirthDate: 'Datum narození',
    patientSex: 'Pohlaví',
    address: 'Adresa',
    email: 'Email',
    telephone: 'Telefon',
    documentsDetail: 'Pacient získaný z NCPeH',
    creationDate: 'Datum vytvoření',
    description: 'Popis',
    author: 'Autor',
    documentDetail: 'Pacientský souhrn',
    downloadError: 'Nepodařilo se stáhnout dokument',
    ...countries,
    ...sexes,
    examples: 'Příklady identifikačních dokladů dané země',
    infoForPatient: 'Informace pro pacienta',
    searchDocuments: 'Vyhledat dokumenty',
  },

  Tests: {
    ...yesno,
    startingTest: 'Spuštění testu',
    testsList: 'Přehled testů',
    type: 'Typ',
    state: 'Stav',
    draft: 'koncept',
    ready: 'aktivní',
    study: 'obrazový',
    general: 'obecný',
    all: 'Vše',
    search: 'Vyhledat',
    name: 'Název testu',
    description: 'Popis testu',
    validFrom: 'Platnost od',
    validTo: 'Platnost do',
    create: 'Nový test',
    edit: 'Editace testu',
    editTest: 'Upravit test',
    editTestShort: 'Upravit',
    maxWrongAnswersAllowed: 'Počet povol. špatných odpovědí',
    maxTestLength: 'Maximální délka testu',
    public: 'veřejný',
    showResults: 'zobrazit výsledky',
    repeatedly: 'opakovatelné spuštění',
    allowPreview: 'povolit náhledy',
    testNotFound: 'Test nenalezen',
    backToTests: 'Zpět na přehled testů',
    author: 'Autor',
    modification: 'Poslední změna',
    publish: 'Zveřejnit',
    clone: 'Kopírovat',
    testDetail: 'Detail testu',
    delete: 'Smazat',
    reallyDelete: 'Opravdu smazat?',
    definitions: 'Definice',
    newStep: 'Nový krok',
    addDefinition: 'Přidat definici',
    editDefinition: 'Upravit definici',
    label: 'Otázka',
    time: 'Čas (s)',
    addAnswer: 'Přidat odpověď',
    answer: 'Odpověď',
    correctness: 'Odpověď je správná',
    questionAdded: 'Otázka přidána',
    questionSubmitFailed: 'Chyba při přidávání otázky, zkuste pokus opakovat',
    text: 'Odpověď',
    group: 'Skupina',
    item: 'Položka',
    questionsMin: 'Minimální počet otázek',
    questionsMax: 'Maximální počet otázek',
    registerItem: 'Položka',
    testDeleted: 'Test smazán',
    questionDeleted: 'Definice smazána',
    deleted: 'Odpověď smazána',
    confirmCloneTitle: 'Opravdu kopírovat test?',
    confirmPublishTitle: 'Opravdu zveřejnit test?',
    confirm: 'Potvrdit',
    close: 'Zrušit',
    testCloned: 'Test naklonován',
    errorCloning: 'Test se nepodařilo zkopírovat',
    testPublished: 'Test publikován',
    errorPublishing: 'Test se nepodařilo publikovat',
    position: 'Pořadí',
    startTest: 'Spustit test',
    currentTest: 'Probíhá test',
    saved: 'Test uložen',
    edited: 'Test upraven',
    errorSaving: 'Chyba při ukládání testu',
    comment: 'Komentář',
    previous: 'Předchozí',
    next: 'Další',
    finish: 'Dokončit',
    finishTest: 'Dokončení testu',
    pressFinishButton: 'Stiskněte tlačítko Dokončit pro odeslání testu',
    testResults: 'Výsledky testu',
    allTestResults: 'Výsledky testů',
    successRate: 'Úspěšnost',
    yourAnswers: 'Vaše odpovědi',
    timeIsUp: 'Čas na test vypršel',
    timeIsUpDialogText: 'Čas na test vypršel. Prosím potvrďte dokončení.',
    goToTests: 'Přejít na stránku s testy',
    errorAnswerDeleting: 'Test nelze smazat',
    errorDeleting: 'Definice nelze smazat',
    questionEdited: 'Definice upravena',
    errorExecutingTest: 'Nebylo možné načíst test',
    showInBrowser: 'Otevřít v prohlížeči',
    rightAnswer: 'správně',
    wrongAnswer: 'špatně',
    openAll: 'Otevřít vše',
    closeAll: 'Zavřít vše',
    correctAnswers: 'Správné odpovědi',
    startedWhen: 'Začátek testu',
    finishedWhen: 'Konec testu',
    datePeriod: 'Období',
    wrongTests: 'Neúspěšné',
    successfullTests: 'Úspěšné',
    testState: 'Výsledek testu',
    executedBy: 'Uživatel',
    passed: 'Úspěšný',
    failed: 'Neúspěšný',
    time_abbr_h: 'hod',
    time_abbr_m: 'min',
    time_abbr_s: 'sec',
    testLength: 'Délka testu',
    globalStats: 'Globální statistiky',
    fullname: 'Uživatel',
    statType: 'Typ statistiky',
    select: 'Vyberte',
    errorRate: 'Chybovost vyšetření',
    executedTests: 'Spuštěné testy',
    allUsers: 'Všichni uživatelé',
    commentsCount: 'Komentáře',
    noExecutionsFound: 'Nenalezeny žádné testy pro daný filtr',
    noStatisticsFound: 'Nenalezeny žádné statistiky',
    cannotLoadStatistics: 'Nebylo možné načíst statistiky',
    trialStart: 'Testovací spuštění',
    errorUsageCount: 'Chybné / celkové použití',
    patientID: 'ID pacienta',
    studyDetail: 'Detail vyšetření',
    isStudyExecutedTestType: 'U obrazového testu lze vybrat pouze jednu odpověď',
    correctAnswer: 'Správná odpověď na otázku.',
    incorrectanswer: 'Nesprávná odpověď na otázku.',
    correctlyAnswered: 'Správně zodpovězená.',
    incorrectlyAnswered: 'Nesprávně zodpovězená.',
    dateToCanNotBeBeforeDateFrom: 'Platnost do nesmí být menší než platnost od',
    atLeaseOneAnswerMustBeAdded: 'Musíte vyplnit alespoň jednu odpověď',
    atLeaseOneAnswerMustBeCorrect: 'Alespoň jedna odpověď musí být označena jako správná',
    loadingComment: 'Probíhá načítání komentáře...',
    yourQuestions: 'Vaše otázky',
    reallyCompleteTheTest: 'Opravdu dokončit test?',
    unansweredQuestions: 'Počet nezodpovězených otázek {{numberOfUnanswered}} z {{questionsCount}}',
    back: 'Zpět',
    isUnanswered: 'Dosud nezodpovězené',
    confirmDelete: 'Opravdu smazat?',
    testStart: 'Spustit test',
    loadingPreviews: 'Probíhá načítání náhledů...',
    redirectingToBrowser: 'Probíhá přesměrování do prohlížeče...',
    explanationCheckbox: 'Zaškrtnutý checkbox značí uživatelem vybranou odpověď na otázku.',
    explanationIcon:
      'Modrá fajfka značí správně zvolenou odpověď. Naopak červený křížek značí nesprávně zvolenou odpověď.',
    explanationColor: 'Správná odpověď je modře podbarvená',
    announcementText: 'Před spuštěním testu si prosím zkontrolujte správnost nastavení podle ',
    announcementLink: 'návodu.',
    announcementTextVideo: 'Video návod',
    announcementViewerTitle: 'Odkaz na testovací vyšetření pro nastavení prohlížeče:',
    announcementDownloadLinkText: 'Video lze stáhnout kliknutím',
    announcementDownloadLink: 'na tento odkaz.',
  },

  Reactour: {
    common: {
      searchButtonTours: 'Po stisku tlačítka se začne vyhledávat.',
      searchButtonDisabledTours:
        'Pokud je tlačítko neaktivní, zřejmě jste nevyplnili žádnou položku formuláře a ještě tak nelze vyhledávat.',
      headTableWithSortAndFilter:
        'V záhlaví tabulky lze kliknout na label sloupce, pak se tabulka setřídí dle tohoto sloupce vzestupně případně sestupně, pokud již bylo dle tohoto sloupce setříděno. Lze myší uchopit label sloupce a sloupec přesunout. Kliknutím na 3 tečky v labelu sloupce se zobrazí další možnosti.',
      bodyTable: 'V tabulce se zobrazuje výsledek vyhledávání.',
      columnChooserButton:
        'Po stisku se zobrazí modální okno se seznamem sloupců. Lze vybrat sloupce, které budou viditelné a skryté.',
      pager:
        'U tabulky vpravo dole je umožněno nastavit počet záznamů, který má být vidět v tabulce. Pokud je vyhledaných záznamů více, pak je umožněno stránkování.',
      gridColumns:
        'Ikona umožňuje zobrazit nebo skrýt jednotlivé sloupce výsledků. Můžete si tak přizpůsobit, které informace o záznamech chcete vidět a které jsou pro vás v daném momentu nepodstatné.',
      gridFilters:
        'Ikona slouží k aplikování dodatečných filtrů na data v tabulce. Po nastavení filtrů v předchozí části formuláře stiskněte toto tlačítko k aplikování dodatečných filtrů na již vyhledaná data.',
      gridExport:
        'Lze vybrat možnost Stáhnout jako CSV. V případě použití filtrů se stáhnou pouze vyfiltrované záznamy.',
      gridReFetching:
        'Tlačítko s ikonou kruhu aktivuje nebo deaktivuje automatické obnovování dat v tabulce v nastaveném časovém intervalu. To znamená, že vaše data budou pravidelně aktualizována bez potřeby ručního obnovení.',
      dateButton:
        'Vybráním tlačítka určíte datum naplánovaného období. Pokud vyberete Vlastní, pak je ještě nutné zadat alespoň jedno datum z možností Od/Do.',
      dateButtonComment: 'Dvojklikem na tlačítko se rovnou spustí vyhledávání pro vybrané období.',
      auditDateSelect:
        'Výběrem možnosti z rozbalovací nabídky určíte datum aktivity. Pokud vyberete  Vlastní, pak je ještě nutné zadat datumy Od/Do.',
      selectItems: 'Vyberte možnosti z rozbalovací nabídky.',
      quitSelectItems: 'Stisknutím klávesy esc opustíte rozbalovací nabídku.',
      clearButton:
        'Toto tlačítko slouží k okamžitému smazání všech vyplněných hodnot ve vyhledávacím formuláři. Použijte jej, chcete-li začít vyhledávání od začátku s čistým formulářem.',
      gridActions: {
        columns: {
          nameSearch:
            'Tento vyhledávací panel slouží k rychlému nalezení a zobrazení sloupce. Stačí zadat název sloupce, který chcete vyfiltrovat, a sloupce se dynamicky aktualizují, a zobrazí se ty, které odpovídají vašemu vyhledávání.',
          select:
            'Zde můžete přepínat viditelnost jednotlivých sloupců. Kliknutím na přepínač vedle názvu sloupce můžete sloupec buď zobrazit, nebo skrýt. To vám umožní přizpůsobit si zobrazení podle vašich preferencí a potřeb.',
          hideAll:
            "Tlačítko 'Skrýt vše' okamžitě skryje všechny sloupce. Je to užitečné, pokud chcete rychle začít s čistým listem a poté ručně vybrat sloupce, které chcete zobrazit.",
          showAll:
            "Tlačítko 'Zobrazit vše' zajistí, že všechny sloupce budou v tabulce viditelné. Toto je ideální, pokud chcete mít přehled o všech dostupných datech bez nutnosti manuálně přidávat sloupce zpět do zobrazení.",
        },
        filters: {
          delete: 'Stisknutím tohoto tlačítka smažete daný filtr.',
          column:
            'Sloupec určuje, v jakém sloupci chcete aplikovat filter. Vybráním např. Příjmení pacienta, určíte filtrování pro tento konkrétní sloupec.',
          operator:
            "Operátor definuje typ porovnání, které se použije pro filtraci dat. Například 'obsahuje' nalezne všechny záznamy, které mají ve vybraném sloupci zadaný text. Další operátory mohou zahrnovat možnosti jako 'rovná se', 'větší než' a další.",
          value:
            'V poli hodnota zadejte kritéria, podle kterých chcete v daném sloupci vyhledávat. To může být konkrétní text, číslo nebo datum, v závislosti na vybraném sloupci a operátoru.',
          addFilter:
            "Tlačítko 'Přidat filtr' umožňuje přidat nová kritéria pro vyhledávání. Po jeho stisknutí se objeví nový řádek s výběrem sloupce, operátoru a pole pro zadání hodnoty. Použijte tento nástroj pro specifikaci více podmínek a pro vyhledávání záznamů, které odpovídají všem stanoveným filtrům.",
          deleteAll:
            "Tlačítko 'Odstranit vše' slouží k rychlému vymazání všech nastavených filtrů. Kliknutím na toto tlačítko okamžitě odstraníte všechny aktivní filtry a vrátíte se k zobrazení všech záznamů bez jakýchkoli omezení vyhledávání.",
        },
      },
    },
    folders: {
      addButton: 'Po stisku tlačítka se zobrazí formulář pro zadání nového pracovního seznamu.',
      bodyTable:
        'Je zde klikatelný název pracovního seznamu, po stisku odkazu se zobrazí detail pracovního seznamu. Dále jsou zde akční tlačítka, po jejichž stisku se zobrazí formulář na opravu pracovního seznamu nebo dialog na potvrzení smazání pracovního seznamu. Pokud tlačítka nejsou aktivní, pak nejste vlastníkem pracovního seznamu nebo nemáte oprávnění na akci.',
      edit: {
        form: "Zde můžete upravit název vašeho pracovního seznamu. Do pole zadejte požadovaný název složky a použijte tlačítko 'Uložit' pro uložení změn, nebo 'Zpět', pokud se rozhodnete změny neukládat.",
        usersAdd:
          "Tato sekce vám umožňuje spravovat uživatele související s daným seznamem. Pomocí tlačítka 'Přidat' můžete do seznamu přidávat nové uživatele.",
        usersGrid:
          'Tabulka zobrazuje všechny uživatele, kteří jsou aktuálně zařazeni v daném seznamu. Zde budete moci zobrazit a spravovat uživatele, jakmile budou přidáni.',
      },
      detail: {
        form: "V této části naleznete základní informace o pracovním seznamu, jako je název a vlastník. Můžete také provádět akce s pracovním seznamem pomocí tlačítek 'Upravit pracovní seznam' pro změnu informací, nebo 'Smazat pracovní seznam' pro trvalé odstranění seznamu z aplikace.",
        tabs: 'Pod základními informacemi naleznete karty ve kerých najdete související informace s daným pracovním seznamem. ',
        grids:
          'Zde jsou zobrazeny související informace v tabulce podle toho, kterou kartu máte zrovna vybranou.',
      },
    },
    orders: {
      addButton: 'Po stisku tlačítka se zobrazí formulář pro zadání nové žádanky.',
      fillInTheFields: 'Vyplňte některou z položek, podle které chcete vyhledat žádanky',
      patientId: 'Zadat ID pacienta',
      bodyTable:
        'V prvním sloupci jsou akční tlačítka, po jejichž stisku se zobrazí detail nebo formulář na opravu žádanky.',
      create:
        'Vyplňte všechny potřebné informace k založení nové žádanky. Tyto pole jsou povinná: Příjmení, Id pacienta, Datum narození, Pohlaví.',
      edit: 'Zde můžete upravit údaje o již existující žádance. Tyto pole jsou povinná: Přijmení, Id pacienta, Datum narození, Pohlaví.',
      save: 'Kliknutím na tlačítko uložíte žádanku.',
      back: 'Kliknutím na tlačítko se dostanete zpět na přehled žádanek. Pokud máte vyplněné údaje ve formuláři. Tyto údaje budou ztraceny.',
      detail: {
        mainInfo: 'Zde naleznete základní údaje o pacientovi.',
        additionalInfo: 'Zde naleznete informace o žádance a podrobnější údaje o pacientovi.',
        actionButtons:
          'Kliknutím na jednotlivá tlačítka můžete upravit žádanku, vytvořit vyšetření z žádanky nebo si žádanku vytisknout.',
        navBar:
          'V této části můžete přepínat mezi dalšími informacemi týkající se dané žádanky. Např. komentářích nebo souvisejícími přílohami.',
      },
    },
    searchList: {
      exportList:
        'Toto tlačítko Vás přivede na stránku se seznamem všech exportů studií. Zde naleznete kompletní výpis exportovaných studií, včetně detailních informací o každé studii.',
      sentHistory:
        'Kliknutím na toto tlačítko otevřete stránku s historií odeslaných studií. Stránka poskytuje přehled všech odeslaných studií s možností prohlížet detaily jednotlivých záznamů.',
      filters:
        'Tento rozbalovací seznam umožňuje aplikovat předem uložené filtry na vyhledávání studií. Díky tomu můžete rychle vyhledávat bez nutnosti znovu vyplňovat všechny parametry ve vyhledávacím formuláři. Stačí vybrat již dříve uložený filtr a vyhledávání se automaticky upraví podle zvolených kritérií.',
      searchFormFields:
        'Tato sekce vám umožňuje zadat konkrétní kritéria pro nalezení záznamů, které potřebujete.',
      expansionArrow:
        'Kliknutím na šipku směřující dolů zobrazíte další pole, která vám umožní více specifikovat vaše vyhledávání podle dalších parametrů.',
      searchSources:
        'Zde si můžete vybírat jednotlivé archivy, ve kterých proběhne vyhledání vyšetření. Můžete vybrat více archivů najednou, nebo pouze jeden.',
      searchButton:
        'Po zadání všech potřebných údajů do vyhledávacího formuláře stiskněte toto tlačítko pro spuštění vyhledávání. Systém zpracuje vaše kritéria a zobrazí vám relevantní výsledky.',
      clearButton:
        'Toto tlačítko slouží k okamžitému smazání všech vyplněných hodnot ve vyhledávacím formuláři. Použijte jej, chcete-li začít vyhledávání od začátku s čistým formulářem.',
      saveFilterButton:
        'Pokud jste nastavili specifické vyhledávací kritéria a plánujete je v budoucnu opět použít, můžete si tato nastavení uložit pro rychlejší přístup. Po kliknutí na toto tlačítko se vám uloží aktuální konfigurace filtrů.',
      searchResultGrid:
        'V této oblasti se zobrazují výsledky vašeho vyhledávání. Po provedení hledání se zde objeví seznam studií odpovídajících zadaným kritériím. Můžete s nimi dále pracovat – zobrazit detaily, exportovat data, nebo provádět další akce dle potřeby.',
      searchFormSelectedItems: 'Zde jsou možnosti modalit.',
    },
    studyDetail: {
      headerSection:
        'Zde najdete klíčové informace o pacientovi a vyšetření. Včetně jména, ID pacienta, pohlaví, data narození a specifik vyšetření jako jsou datum, čas a modalita.',
      timeline:
        'Časová osa zobrazuje klíčové milníky nebo časové údaje související s vyšetřením, což vám umožňuje sledovat vývoj nebo různé fáze vyšetření.',
      mainSection:
        'Tato část obsahuje detailní údaje o vyšetření, včetně čísla žádanky, dat a časů, výšky a váhy pacienta, popisu vyšetření a jeho aktuálního stavu.',
      mainSection_preview:
        'Náhledový obrázek, který poskytuje vizuální představu o vyšetření nebo souvisejících snímcích.',
      buttons:
        'Pod informacemi o vyšetření najdete lištu s tlačítky pro různé akce, jako jsou odeslání, tisk, úprava, kopírování a další funkce související s vyšetřením.',
      tabs: 'Na spodní části obrazovky jsou záložky pro různé sekce související s vyšetřením, včetně komentářů, operací, štítků, sérií a pracovních seznamů.',
      statusDialog: {
        selectAll: 'Kliknutím na toto tlačítko vyberete všechny stavy.',
        choices: 'Zde můžete vybrat stavy, které budou přiřazeny k vyšetření.',
        saveButton: 'Stisknutím tohoto tlačítka uložíte Vámi vybrané stavy k vyšetření.',
        closeButton: 'Stisknutím tohoto tlačítka zavřete dialogové okno. Stavy nebudou uloženy.',
      },
    },
    exportList: {
      appBar:
        "Nacházíte se na stránce s exporty. Můžete přepínat mezi 'Platnými exporty' a 'Všemi exporty', abyste filtrovali zobrazené záznamy.",
      grid: "Zde naleznete tabulku s exporty, kde každý řádek reprezentuje jeden export. V prvním sloupci 'Akce' můžete stáhnout příslušné exporty.",
    },
    importDicom: {
      uppySelector:
        'Tato sekce je určena pro import DICOM dat. Můžete sem přetáhnout soubory nebo složky z adresáře z vašeho počítače, nebo použít tlačítko pro výběr souborů/složek, které chcete nahrát.',
      grid: 'V této části vidíte seznam souborů, které jste nahráli. Každý řádek představuje jednotlivé DICOM soubory s podrobnostmi jako jméno pacienta, ID pacienta, datum vyšetření, pohlaví pacienta, popis vyšetření, a velikost souborů. Při nahrání multimediálních souborů se jako přijmení a jméno ukáže neznámý formát.',
      targetArchive:
        "Pomocí rozbalovací nabídky 'Cílový archiv' vyberte, kam má být importováno. Je důležité vybrat správný archiv, aby byla data správně kategorizována a snadno dostupná pro další použití.",
      saveButton:
        "Po výběru souborů a určení cílového archivu stiskněte 'Uložit' pro zahájení procesu importu. Není možné začít importovat, pokud nejsou vybrány žádné soubory nebo není vybrát cílový archív.",
      expansionArrow:
        'Kliknutím na šipku rozbalíte záznam, kde můžete vybírat konkrétní série, které mají být naimportovány. Při dalším rozbalení je možné vidět konkrétní DICOM snímky.',
      editRow:
        'Kliknutím na ikonu můžete upravit jednotlivé údaje záznamu, které byly naimportovány z DICOM souboru.',
      deleteRow: 'Kliknutím na ikonu můžete vymazat záznam, pokud jej nechcete naimportovat.',
      deleteAll: 'Vymaže veškeré záznamy z přehledu.',
      uploadFolder: 'Umožňuje dodatečně nahrát další složky, které mají být naimportovány.',
      uploadFiles: 'Umožňuje dodatečně nahrát další soubory, které mají být naimportovány.',
    },
    importNonDicom: {
      uppySelector:
        'Tato sekce je určena pro import multimediálních dat. Můžete sem přetáhnout soubory nebo složky z adresáře z vašeho počítače, nebo použít tlačítko pro výběr souborů/složek, které chcete nahrát.',
      allowedFiles:
        'Mezi povolené formáty patří - jpg, jpeg, mp4, mpeg4, pdf. Pokud se pokusíte nahrát jiný typ formátu, daný soubor se nenahraje.',
      grid: 'V této části vidíte seznam souborů, které jste nahráli. Každý řádek představuje jednotlivé soubory s podrobnostmi jako jméno pacienta, ID pacienta, datum vyšetření, pohlaví pacienta, popis vyšetření, a velikost souborů. Při nahrání multimediálních souboru se jako přijmení a jméno ukáže neznámý formát.',
      targetArchive:
        "Pomocí rozbalovací nabídky 'Cílový archiv' vyberte do jakého archivu se mají data naimportovat. Je důležité vybrat správný archiv, aby byla data správně kategorizována a snadno dostupná pro další použití.",
      saveButton:
        "Po výběru souborů a určení cílového archivu stiskněte 'Uložit' pro zahájení procesu importu. Není možné začít importovat, pokud nejsou vybrány žádné soubory nebo není vybrán cílový archív.",
      deleteRow: 'Kliknutím na ikonu můžete vymazat záznam, pokud jej nechcete naimportovat.',
      deleteAll: 'Vymaže veškeré záznamy z přehledu.',
      uploadFolder: 'Umožňuje dodatečně nahrát další složky, které mají být naimportovány.',
      uploadFiles: 'Umožňuje dodatečně nahrát další soubory, které mají být naimportovány.',
      findButtons:
        'Zde můžete vyhledat existující žádanku, pacienta nebo vyšetření. Při výběru se automaticky předvyplní formulář s dostupnými daty.',
      patientAndStudy:
        'Formulářová sekce s pacientskými údaji a s údaji o vyšetření. Může být vyplněna ručně a nebo pomocí vyhledání žádanky, pacienta nebo vyšetření.',
      decubits:
        'Při kliknutí na tlačítko se objeví nová sekce. Kde můžete pomocí interaktivního obrázku těla vybrat specifickou oblast a poté vybrat specifickou část těla.',
      resetButton: 'Vymaže všechny vyplněné údaje ve formuláři.',
    },
    mdex: {
      send: {
        navBar: 'Zde si můžete vybrat, odkud a jaké vyšetření chcete nahrát a odeslat.',
        recipients: 'Pomocí rozbalovací nabídky můžete vybrat příjemce.',
        button: 'Po stisknutí tlačítka se vyšetření odešlou Vámi zvolenému příjemci.',
      },
      transmission: {
        navBar: 'Zde můžete přepínat mezi přehledy Přijatých nebo Odeslaných dat.',
        gridIncoming: 'V této části vidíte všechny přijaté přenosy.',
        gridOutgoing: 'V této částí vidíte všechny odeslané přenosy.',
      },
      addressBook: {
        synchronize:
          'Stisknutím tohoto tlačítka spustíte synchronizaci/aktualizaci všech příjemců.',
        grid: 'V této části vidíte seznam všech dostupných příjemců',
      },
    },
    cstore: {
      send: {
        navBar: 'Možnost odeslání lokálních vyšetření.',
        recipients: 'Pomocí rozbalovací nabídky můžete vybrat příjemce.',
        networks:
          'Pokud jste v předchozím kroku vybrali příjemce s více než jednou sítí, pak pomocí rozbalovací nabídky můžete vybrat síť.',
        button: 'Po stisknutí tlačítka se vyšetření odešlou Vámi zvolenému příjemci.',
      },
      save: {
        navBar: 'Možnost uložení lokálních vyšetření.',
        archiveId: 'Pomocí rozbalovací nabídky můžete vybrat cílový archiv.',
        targetAet: 'Můžete zadat cílový AET',
        targetIp: 'Můžete zadat cílovou IP',
        targetPort: 'Můžete zadat cílový port',
        sourceAet: 'Můžete zadat zdrojový AET',
        clear: 'Stisknutím tlačítka vymažete Vámi vybraná kritéria.',
        echo: 'Po stisknutí tlačítka se ověří cílový uzel.',
        button: 'Po stisknutí tlačítka se vyšetření odešlou na Vámi zvolený cílový uzel.',
      },
    },
    shreddings: {
      common: {
        dateForm:
          'Vybráním tlačítka rychle určíte období, pro které si přejete zobrazit návrhy na skartaci. Pokud vyberete Vlastní, pak je ještě nutné zadat alespoň jedno datum z možností Od/Do.',
        clear: 'Stisknutím tlačítka vymažete vámi vybrané kritéria.',
        search: 'Stisknutim tlačítka spustíte vyhledávání navrhů na skartaci.',
      },
      proposals: {
        form: 'Zde můžete vyplnit položky pro vyhledání návrhů na skartaci v tabulce. Můžete filtrovat podle data návrhu. Stisknutím na tlačítko vyhledat aktualizujete tabulku návrhů na skartaci.',
        dateSelect:
          'Výběrem možnosti z rozbalovací nabídky určíte datum návrhu. Pokud vyberete  Vlastní, pak je ještě nutné zadat datumy Od/Do.',
        grid: 'V této části vidíte seznam všech požadavků na skartaci ve Vámi zvoleném období.',
      },
      history: {
        form: 'Zde můžete vyplnit položky pro vyhledání historie skartace v tabulce. Můžete filtrovat mezi návrhy na skartaci nebo potvrzeními o skartaci, podle autora, data, dle automatického návrhu či návrhu uživatele. Stisknutím na tlačítko vyhledat aktualizujete tabulku historie skartace.',
        shreddingDate:
          'Zde můžete vyplnit položky pro vyhledání historie skartace v tabulce. Můžete filtrovat mezi návrhy na skartaci nebo potvrzeními o skartaci, podle autora, data, dle automatického návrhu či návrhu uživatele. Stisknutím na tlačítko vyhledat aktualizujete tabulku historie skartace.',
        dateSelect:
          'Výběrem možnosti z rozbalovací nabídky určíte datum. Pokud vyberete  Vlastní, pak je ještě nutné zadat datumy Od/Do.',
        dateFrom:
          'Podle hodnoty vybrané z rozbalovací nabídky pro datum je zobrazen dopočítaný datum. Případně pro hodnotu Vlastní lze zadat datum Od ručně.',
        dateTo:
          'Podle hodnoty vybrané z rozbalovací nabídky pro datum je zobrazen dopočítaný datum. Případně pro hodnotu Vlastní lze zadat datum Do ručně.',
        confirmationProposalButton:
          'Můžete zvolit jestli chcete vyhledávat mezi návrhy na skartaci nebo potvrzeními o skartaci.',
        authorSelector:
          'Pomocí výběru autora v rozbalovacím menu můžete snadno zúžit vyhledávání na jeho záznamy.',
        sourceShredding:
          'Tato tlačítka vám umožňují filtrovat skartace podle jejich původu. Výběrem jednoho z těchto tlačítek můžete snadno segmentovat a zobrazit specifické typy návrhů.',
        search: 'Stisknutim tlačítka spustíte vyhledávání historie skartace.',
        grid: 'V této části vidíte seznam všech filtrovaných požadavků na skartaci nebo potvrzeních o skartaci podle Vašich vyhledávacích kriterií.',
      },
    },
    request: {
      addButton:
        'Po stisknutí budete přesměrování na novou stránku, kde můžete vyhledat vyšetření a následně podat žádost na Vámi vybranou akci.',
      operationType:
        'Pomocí rozbalovací nabídky, můžete upřesnit, který typ operace chcete vyhledat.',
      requestingUser:
        "V poli 'Žadající uživatel' zadejte jméno uživatele, jehož požadavky chcete vyhledat. Tento filtr vám umožní zúžit vyhledávání na specifické uživatele a rychleji tak najít potřebné záznamy.",
      dateForm:
        "Vybráním tlačítka rychle určíte období, pro které si přejete zobrazit žádosti. Pokud vyberete 'Vlastní', pak je ještě nutné zadat alespoň jedno datum z možností Od/Do.",
      dateButtonComment:
        'Pokud na tlačítko s datem dvakrát kliknete, aplikace automaticky spustí vyhledávání pro zvolené časové období bez nutnosti dalšího potvrzení.',
      requestStatus:
        'Zde můžete zvolit, který stav žádostí chcete vyhledat. Je možné vybrat více možností. Pokud chcete všechny stavy, klikněte na tlačítko vybrat vše.',
      clear: 'Stisknutím tlačítka vymažete vámi vybrané kritéria.',
      search: 'Stisknutim tlačítka spustíte vyhledávání žádostí.',
      grid: 'V této oblasti se zobrazují výsledky vašeho vyhledávání. Po provedení hledání se zde objeví seznam žádostí odpovídajících zadaným kritériím.',
      requestGridActions:
        'První ikona otevře detail žádosti. Kliknutím na druhou ikonu žádost schválíte a třetí ikona žádost zamítne.',
      detail: {
        mainSection:
          'V této části vidíte hlavní informace týkající se dané žádosti. Tlačítky buďto můžete žádost schválit nebo zamítnout.',
        otherSections: 'V této části vidíte podrobnější informace o žádosti.',
        comments: 'V této části vidíte veškeré komentáře týkající se žádosti.',
        studies: 'V této části vidíte všechny vyšetření, kterých se žádost týka.',
        folders: 'V této části vidíte všechny pracovní seznamy, kterých se žádost týka.',
        history: 'V této části vidíte veškerou historii žádosti.',
      },
    },
    filters: {
      addButton: 'Po stisku tlačítka se zobrazí formulář pro zadání nového filtru.',
      bodyTable:
        'V prvním sloupci jsou akční tlačíka, kde můžete aktivovat/deaktivovat jednotlivé. Dále můžete jednotlivé filtry upravit či smazat.',
      studyForm:
        'Zde si můžete zvolit, jaké hodnoty do filtru budou uloženy. Formulář je stejný jako u vyhledání vyšetření.',
      additionalInfo:
        'Zde nastavíte podrobnosti daného filtru. Jako je název, jestli filtr bude vididelný pouze pro Vás, popř. pro jaké pracovní místa bude dostupný.',
      save: 'Kliknutím na tlačítko uložíte filtr.',
      back: 'Kliknutím na tlačítko se dostanete zpět na přehled filtrů. Pokud máte vyplněné údaje ve formuláři, tyto údaje budou ztraceny.',
      edit: 'Zde můžete upravit hodnoty již existujícího filtru.',
      editAdditionalInfo:
        'Zde můžete upravit podrobnosti daného filtru. Název filtru je povinné pole.',
    },
    requestQueue: {
      status:
        'Zde si můžete zvolit stav požadavků, podle kterých chcete vyhledat. Kliknutím na vybrat vše se označí všechny stavy požadavků.',
      searchButton: 'Kliknutím na tlačítko spustíte vyhledávání.',
      grid: 'V prvním sloupci jsou akční tlačítka, kde si můžete zobrazit detail požadavku. Kliknutím na příjmení a jméno pacienta budete přesměrování na detail vyšetření pacienta.',
    },
    tagsArchive: {
      comment:
        'V tomto poli můžete přidat komentář ke svému vyhledávání. Komentář slouží jako specifikace toho, co hledáte ve vědeckém archivu. Hledá se shoda na podřetězec,',
    },
    tagsArchiveAndIntegrationPlatform: {
      search: 'Stisknutím tlačítka spustíte vyhledávání žádostí.',
      clear: 'Stisknutím tlačítka vymažete všechny vybrané štítky.',
      activeTags:
        'Zde můžete upřesnit své vyhledávání pomocí výběru z různých tagů. Každý tag představuje kategorii nebo kritérium, které můžete použít k filtrování výsledků. Šipky ukazují na hierarchickou strukturu tagů, kde některé tagy mohou být podskupinou jiných. Toto uspořádání vám umožňuje přesněji specifikovat oblast vašeho zájmu.',
      inActiveTags:
        'Zaškrnutím boxu si zobrazíte neaktivní tagy, podle kterých pak můžete upřesnit své hledání podobně jako u aktivních tagů.',
      multipleActiveTags:
        'Zde můžete upřesnit své vyhledávání pomocí výběru z různých aktivních štítků. Lze zadat více hodnot.',
      multipleInActiveTags:
        'Zde můžete upřesnit své vyhledávání pomocí výběru z různých již neaktivních štítků. Lze zadat více hodnot.',
      grid: 'Pomocí šipky si můžete zobrazit konkrétní série daného vyšetření.',
    },
    registerGroups: {
      addButton: 'Po stisku tlačítka se zobrazí formulář pro zadání nového štítku.',
      registerGroups:
        'V prvním sloupci jsou akční tlačítka, kde jednotlivé štitky můžete aktivovat/deaktivovat nebo již existující štítky upravit.',
      grid: 'Zde jsou zobrazeny všechny štítky a jejich struktura - šipky ukazují na hierarchickou strukturu tagů, kde některé tagy mohou být podskupinou jiných. Jednotlivé štítky můžete upravovat či aktivovat/deaktivovat.',
      detail: {
        form: 'Zde můžete upravit název pro skupinu štítků. Pomocí přepínače můžete skupinu štítků aktivovat/deaktivovat.  ',
        registerGroupsChildrenAdd: 'Zde můžete přidávat podskupiny  k vybráné skupině štítků.',
        registerGroupsChildrenGrid:
          'Zde vidíte všechny podskupiny přiřazené k dané skupině štítků. Jednotlivé podskupiny můžete aktivovat/deaktivovat nebo také upravit.',
        tagsAdd: 'Zde můžete přidávat štítky k vybrané skupině štítků.',
        tagsGrid:
          'Zde vidíte všechny štítky přiřazené k dané skupině štítků. Jednotlivé podskupiny můžete aktivovat/deaktivovat nebo také upravit.',
      },
    },
    tests: {
      addButton: 'Po stisku tlačítka se zobrazí formulář pro zadání nového testu.',
      searchForm:
        'Zde si můžete zvolit, jaký typ a jaký stav testu chcete vyhledat. Kliknutím na tlačítko vyhledat spustí vyhledávání.',
      grid: 'Zde vidíte všechny existující testy. V prvním sloupci jsou akční tlačítka.',
      filterHeaders:
        'Zde máte možnost filtrovat podle sloupců. Stačí, když začnete psát do jednotlivých polí.',
      actions:
        'Zde si můžete zobrazit detail testu, upravit či kopírovat test. Dále testy můžete zveřejnit nebo smazat.',
      create:
        'Vyplňte všechny potřebné informace k založení nového testu. Tyto pole jsou povinná:  Název testu, Typ, Platnost Od, Počet povol. špatných odpovědí, Maximální délka testu.',
      edit: 'Zde můžete upravit údaje o již existujícím testu. Tyto pole jsou povinná:  Název testu, Typ, Platnost Od, Počet povol. špatných odpovědí, Maximální délka testu.',
      save: 'Kliknutím na tlačítko uložíte test.',
      back: 'Kliknutím na tlačítko se dostanete zpět na přehled testů. Pokud máte vyplněné údaje ve formuláři, tyto údaje budou ztraceny.',
      startForm: 'Zde můžete vybrat typ testu pro upřesnění hledání.',
      startGrid:
        'Zde vidíte výsledky hledání. V prvním sloupci je akční ikona, která spustí daný test.',
      resultForm:
        'Zde si můžete filtrovat výsledky podle data. Kliknutím na tlačítko vyhledat se spustí vyhledávání.',
      dateSelect:
        'Výběrem možnosti z rozbalovací nabídky určíte období. Pokud vyberete  Vlastní, pak je ještě nutné zadat datumy Od/Do.',
      resultGrid: 'Zde vidíte výsledky vašeho hledání.',
      globalStatisticsForm:
        'Zde můžete upřesnit výsledky globálních testů podle typu, uživatele nebo podle data. Kliknutím na tlačítko vyhledat spustíte vyhledávání.',
      globalStatisticsTypeExecuted:
        'Pokud vyberete "Spuštěné testy", pak můžete upřesnit výsledky podle uživatele a data.',
      globalStatisticsTypeErrorRate:
        'Pokud vyberete "Chybovost vyšetření", pak už nelze dále upřesnit výsledky.',
      globalStatisticsUserSelector:
        'Pomocí výběru uživatele v rozbalovacím menu můžete snadno zúžit vyhledávání na jeho záznamy.',
      globalStatisticsGrid:
        'Zde vidíte globální statistky podle kritérií vašeho vyhledávání. Výsledky jsou graficky znázorněny nad tabulkou.',
      detail: {
        mainSection: 'Zde se nacházejí základní informace o testu.',
        buttons:
          'Pomocí tlačítek můžete test upravit, kopírovat, zveřejnit, smazat nebo vykoušet testovací spuštění. Testovací spuštění se nepočítá do statistik. Po zveřejnění testu, již není možné přidávat otázky do testu či je editovat.',
        definitions:
          "Otázky do testu přidáváte jako definice. V kartě definice vidíte přehled všech otázek. Pro přidání další otázky do testu klikněte na kartu 'Přidat definici'.",
        question: 'Zde můžete zadat znění otázky.',
        order: 'Zde určíte pořadí otázky v testu.',
        answer:
          'Zde můžete zadat znění odpovědi a také příznak jestli je odpověď správná. Zadání odpovědí není povinné.',
        addAnswer: 'Stisknutím tlačítka přidáte další odpovědi k otázce.',
        addQuestion: 'Stisknutím tlačítka přidate odpověď do testu.',
      },
    },
    statistics: {
      checkBoxes:
        "Zde můžete vybrat typ statistiky, kterou chcete zobrazit. 'Zaplnění úložiště' poskytuje přehled o využití datového úložiště, zatímco 'Počet vyšetření' zobrazuje celkový počet vyšetření za určité období. Dále můžete specifikovat výsledky grafu podle různých parametrů, jako je 'Rok', 'Měsíc', typ grafu a archiv.",
      additionalInfo:
        'Další možnosti umožňují přizpůsobení zobrazení statistik podle konkrétních stanic nebo modalit.',
      showButton:
        "Kliknutím na 'Zobrazit' se aplikují všechny nastavené filtry a aktualizuje se zobrazení grafu.",
      grid: 'Zde můžete vidět graf, který odpovídá vašim vybraným kritériím.',
      byTagStatistics: 'Zde můžete vidět statistiky podle štítku.',
    },
    auditLogs: {
      form: 'Zde můžete vyplnit položky pro upřesnění výsledků audit logů v tabulce. Můžete filtrovat podle uživatele, id pacienta a časového období. Stisknutím na tlačítko vyhledat, aktualizujete tabulku audit logů.',
      grid: 'V této oblasti vidíte audit logy, které byly vyfiltrovány podle kriterií, které jste zadali. Defaultně se zobrazují dnešní logy.',
    },
    clinicalPortal: {
      byPatient: {
        searchForm:
          'Zde můžete vyhledat pacienta podle jeho jména, příjmení, rodného čísla nebo ID pacienta. Kliknutím na tlačítko vyhledat se spustí vyhledávání. Pokud potřebujete zadat konkrétnější údaje, klikněte na šipku a zobrazí se více možností, podle kterých můžete vyhledávat.',
        grid: 'Zde můžete vidět výsledek vašeho hledání.',
      },
      ncpeh: {
        searchForm:
          'Zde můžete vyhledávat v Národním kontakním místu pro elektronické zdravotnictví. Můžete zvolit zemi, typ dokumentu, který chcete vyhledat a důvod přístupu. Podle výběru země, musíte zadat údaje pro identifikaci pacienta.',
        searchButton: 'Kliknutím na toto tlačítko spustíte vyhledávání.',
      },
    },
    basket: {
      buttons:
        'Zde můžete provádět různé akce s vyšetřeními v Dočasné složce vyšetření. Můžete vyšetření odeslat, kopírovat, archivovat, exportovat, přidat do pracovního seznamu, přidat štítky nebo zobrazit v prohlížeči. Každé tlačítko odpovídá specifické akci, kterou můžete s vybranými záznamy provést.',
      grid: 'Zde se zobrazuje seznam všech vyšetření, která byla přidána do dočasné složky. Informace zahrnují jméno pacienta, popis, archiv, ID pacienta, číslo žádanky, pohlaví, datum narození, datum vyšetření, modalitu, a další. Můžete záznamy selektovat pro provedení akcí z prvního kroku.',
      deleteButtons:
        'Zde můžete odstranit vybraná nebo všechna vyšetření z dočasné složky. Pozn.: odstranění vyšetření z dočasné složky nevede k jeho nenávratné ztrátě. Vyšetření zůstává dostupné v systému a můžete jej najít ve výčtu všech studií.',
    },
    study: {
      back: 'Kliknutím na tlačítko se vrátíte na předchozí stránku.',
      send: {
        sendBy: 'Zde zvolte, jakým způsobem chcete vyšetření odeslat.',
        listOfStudies:
          'Zde vidíte seznam vyšetření které budou odeslány. Jednotlivé vyšetření si můžete rozkliknout a zobrazit si tak jednotlivé série vyšetření.',
        sendButton: 'Kliknutím na tlačítko, odešlete vyšetření.',
      },
      reorderSource: {
        deleteButton: 'Kliknutím na toto tlačítko odeberete zdrojové vyšetření pro přeskupení.',
        grid: 'Zde vidíte zdrojové vyšetření k přeskupení.',
      },
      split: {
        existingStudyForm: 'V této části najdete veškeré informace o existujícím vyšetření',
        newStudyButton:
          'V této části jsou tlačítka, pomocí kterých můžete najít existující žádanku, vyšetření nebo pacienta. Vybráním se automaticky předvyplní formulář o novém vyšetření s dostupnými informacemi.',
        newStudyForm:
          'V této části jsou informace o novém vyšetřením, které bude založeno při rozdělení stavajícího vyšetření.',
        existingStudySeries:
          'V této časti najdete série vyšetření existujicího vyšetření. Můžete si prohlédnout jednotlivé snímky každé série. A pomocí zaškrtávacího políčka vybrat celé série či jednotlivé snímky ze série.',
        newStudySeries: 'V této části najdete série, které budou součástí nového vyšetření.',
        otherChoices:
          'Pomocí zaškrtávacího políčka můžete zvolit, jestli se při rozdělení mají zahrnout komentáře z původního vyšetření.',
        splitButton: 'Stisknutím tlačítka zahájíte rozdělení vyšetření.',
      },
      edit: {
        patientStudyForm:
          'Zde můžete upravit údaje o pacientovi a vyšetření. Tyto pole jsou povinná: Přijmení, Id pacienta, Datum narození, Pohlaví, Popis vyšetření, Datum vyšetření, Čas vyšetření.',
        patientForm:
          'Zde můžete upravit údaje o pacientovi. Tyto pole jsou povinná: Příjmení, Id pacienta, Datum narození, Pohlaví.',
        patientFormGrid: 'Zde jsou zobrazeny vybraná vyšetření týkajicí se daného pacienta.',
        loadFromOrder:
          'Po stisknutí tlačítka budete moci vyhledat žádanku. Po výběru žádanky se předvyplní údaje pro vyšetření podle dostupných informací z žádanky.',
        saveButton: 'Po stisknutí tlačítka uložíte změny.',
      },
      copy: {
        targetArchive:
          'Pomocí rozbalovací nabídky si můžete zvolit, do jakého archivu má být vyšetření zkopírováno.',
        anonymization:
          'Pomocí rozbalovací nabídky si můžete zvolit, jaký typ anonymizace chcete zvolit.',
        listOfStudy:
          'Zde vidíte seznam vyšetření daného pacienta. Pomocí zaškrtávacích políček, můžete zvolit, které série mají být zkopírovány.',
        copyButton: 'Stisknutím tlačítka zahájíte kopírování vyšetření.',
      },
      move: {
        targetArchive:
          'Pomocí rozbalovací nabídky si můžete zvolit, do jakého archivu má být vyšetření přesunuto.',
        anonymization:
          'Pomocí rozbalovací nabídky si můžete zvolit, jaký typ anonymizace chcete zvolit.',
        listOfStudy:
          'Zde vidíte seznam vyšetření daného pacienta. Pomocí zaškrtavacích políček můžete zvolit, které série mají být přesunuty.',
        moveButton: 'Stisknutím tlačítka zahájíte přesunutí vyšetření.',
        showEditForm: 'Při přepnutí stavu vám bude umožněno upravit údaje o detailu vyšetření.',
      },
      export: {
        type: 'Pomocí rozbalovací nabídky si můžete zvolit typ exportu.',
        anonymization:
          'Pomocí rozbalovací nabídky si můžete zvolit, jaký typ anonymizace chcete zvolit při exportu.',
        listOfStudy:
          'Zde vidíte seznam vyšetření daného pacienta. Pomocí zaškrtávacích políček můžete zvolit, které série mají být přesunuty.',
        exportButton: 'Stisknutím tlačítka zahájíte export vyšetření.',
      },
      addToFolder: {
        newFolder:
          'Pokud chcete založit nový pracovní seznam, zde můžete napsat jeho název a tlačítkem potvrdit ho vytvoříte.',
        gridExistingFolders:
          'Zde máte na výběr z již existujících pracovních seznamů, kam můžete vyšetření přesunout. Můžete vybrat více pracovních seznamů zároveň.',
        listOfStudy:
          'Zde vidíte seznam vyšetření daného pacienta. Pomocí zaškrtávacích políček můžete zvolit, které série mají být přidány do pracovního seznamu.',
        addToFolderButton: 'Stisknutím tlačítka zahájíte přesun vyšetření.',
      },
      doseMonitoring: {
        export: 'Stisknutím tohoto tlačítka vyexportuje dávky.',
        grid: 'Zde můžete sledovat všechny dávky daného pacienta.',
      },
    },
    buttonFormIcon:
      'Název ikony naleznete po stisknutí odkazu "Vyhledat ikony". Musí být zadán malým písmem. V případě víceslovných názvů ikon je nutné použít jako oddělovač slov podtržítko.',
    buttonFormIconExample: 'Např: ikona s názvem AddHomeWork bude zadána jako add_home_work.',
    buttonFormIconConvert:
      'Pokud zkopírujete název ikony z odkazu, pak dojde k automatické konverzi. Ke konverzi dochází i při zadávání názvu ikony.',
    findingReport: {
      user: 'Pomocí rozbalovací nabídky můžete vybrat uživatele.',
      hospital: 'Pomocí rozbalovací nabídky můžete vybrat nemocnici.',
      grid: 'V této oblasti se zobrazují výsledky vašeho vyhledávání. Po provedení hledání se zde objeví statistika nálezů odpovídajících zadaným kritériím.',
    },
  },

  Histories: {
    requestCreatedWhen: 'Datum a čas',
    user: 'Uživatel',
    patientId: 'ID pacienta',
    name: 'Příjmení a jméno',
    dateTime: 'Datum vyšetření',
    accessionNumber: 'Číslo žádanky',
    sentMethod: 'Výměnná síť/Dr. Sejf',
    recipient: 'Příjemce',
    loadingData: 'Vyhledávám historii',
    webSocketError: 'Informaci se nepodařilo získat',
    webSocketErrorInIE:
      'Nepodařilo se dohledat historii. Zkuste obnovit stránku nebo použít jiný prohlížeč.',
    requestDetail: 'Detail požadavku',
  },

  StudyAnonymization: {
    ...name,
    PatientID: 'ID pacienta',
    PatientBirthDate: 'Datum narození',
    noAnonymization: 'Žádná',
    noName: 'Beze jména',
    anonymization: 'Anonymizace',
    parameters: 'Parametry anonymizace',
  },

  About: {
    about: 'O aplikaci',
    content:
      'Tato webová aplikace je vyvíjena a spravována společností OR-CZ spol. s r.o., IČO: 48168921, se sídlem Gorazdova 1477/2, Předměstí, 571 01 Moravská Třebová.',
    portalVersion: 'Aktuální verze aplikace frontend/backend:',
    label: 'Štítek',
  },

  Mdex: {
    mdex: 'MDEX',
    mdexSendTitle: 'MDEX - Odeslání dat',
    sendData: 'Odeslání dat',
    transmissions: 'Přehled přenosů',
    addressBook: 'MDEX Adresář příjemců',

    institutionName: 'Příjemce',
    aeTitle: 'Aet',
    city: 'Město',
    address: 'Adresa',
    email: 'E-mail',
    phone: 'Telefon',
    dicomHost: 'Dicom host',
    dicomPort: 'Dicom port',
    synchronizeAddressBooks: 'Synchronizovat',
    synchronized: 'Synchronizace proběhla úspěšně',
    errorSynchronizing: 'Synchronizace se nezdařila',
    ...select,
    submitSendOk: 'Data odeslána ke zpracování',
    submitSendError: 'Data se nepodařilo odeslat ke zpracování',
    dicomFromArchive: 'DICOM - data z archivu',
    dicomFromStation: 'DICOM - lokální data',
    nondicom: 'Multimediální data',
    ...dialogTexts,
  },

  Transmissions: {
    setIncoming: 'Přijatá data',
    setOutgoing: 'Odeslaná data',
    transferStart: 'Začátek přenosu',
    transferEnd: 'Konec přenosu',
    institutionFrom: 'Odesílatel',
    institutionTo: 'Příjemce',
    city: 'Město',
    cityFrom: 'Město odesílatele',
    cityTo: 'Město příjemce',
    patientName: 'Jméno pacienta',
    patientIdent: 'Identifikace pacienta',
    objectIdent: 'Identifikace objektu',
    objectCount: 'Počet objektů',
    objectSize: 'Velikost',
    transmissionDetail: 'Detail přenosu',
    actions: 'Akce',
  },
  StudyFunctions: {
    ...studyFunctions,
  },

  PriorityStatusesConfig: {
    order: 'Pořadí',
    code: 'Kód',
    name: 'Název',
    priority: 'Priorita',
    state: 'Stav',
    dialog: {
      commentState: 'Stavy nálezů',
      saved: 'Stav nálezu uspěšně uložen',
      errorSaving: 'Chyba při ukládání stavu nálezu',
    },
    deleted: 'Stav nálezu uspěšně odstraněn',
    canUse: 'Může používat?',
    canView: 'Může vidět?',
    grid: {
      canUse: 'používat',
      canView: 'vidět',
    },
    isDeprecated: 'Neplatný',
    isNotDeprecated: 'Platný',
    showOnlyValid: 'Zobrazit pouze platné komentáře',
    ...header,
  },
  Certificates: {
    ...header,
    dialog_attachment: 'Přidat certifikát',
    certificateName: 'Název',
    deleted: 'Certifikát úspěšně odstraněn',
    errorDeleting: 'Certifikát se nepodařilo odstranit',
    change: {
      success: 'Změna výchozího certifikátu se zdařila',
      error: 'Změna výchozího certifikátu se nezdařila',
    },
  },
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStudiesByTagsStatistics } from './_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useWithTitle } from 'utils/hooks/useWithTitle';
//import { TourReports } from './TourReports';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';
import { AppBar, Tab, Tabs } from '@mui/material';
import { useUser } from 'utils/hooks/useUser';
import UserTagReports from './UserTagReports';

export const StudiesByStudyTagsStatistics: React.FC = () => {
  const { t } = useTranslation('Reports');
  const { hasRole } = useUser();
  const { toggleLoader } = useAppGlobals();
  useWithTitle(); // sets title to document

  const [data, setData] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('userTagStatistics');

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(activeTab);

  const loadEntity = async () => {
    toggleLoader();
    const resp = await getStudiesByTagsStatistics();
    if (resp) {
      setData(
        Object.keys(resp.data).map((key, index) => ({
          id: index,
          name: key,
          count: resp.data[key],
        })),
      );
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        { field: 'name', headerName: t('tagName') },
        { field: 'count', headerName: t('tagCount'), type: 'number' },
      ],
      250,
    ),
  );

  /*/const renderedSteps = () => {
    return <TourReports type="byTagStatistics" />;
  };*/

  return (
    <>
      <AppBar position="static" color="default" sx={{ mt: 2, mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons={false}
        >
          {hasRole('ROLE_VIEW_TAG_STATISTICS') && (
            <Tab value="userTagStatistics" label={t('userTagStatistics')} />
          )}
          {hasRole('ROLE_VIEW_STATISTICS') && (
            <Tab value="studyTagStatistics" label={t('studyTagStatistics')} />
          )}
        </Tabs>
      </AppBar>

      {activeTab === 'studyTagStatistics' && (
        <MuiGrid
          gridKey={activeTab}
          rows={data}
          columns={columns}
          dataTour="statisticsByTagStatistics"
          initialSortMode={[{ field: 'name', sort: 'asc' }]}
        />
      )}
      {activeTab === 'userTagStatistics' && <UserTagReports />}
    </>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { addUserCommentState, addWorkplaceCommentState } from './_api';
import { ICommentItem, ICommentStateForm } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import CheckboxForm from 'components/Form/Checkbox/Checkbox';
import FormSelect from 'components/Form/Select/Select';
import useAlerts from 'components/Alerts/useAlerts';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';

const CommentStateForm: React.FC<ICommentStateForm> = ({
  toggleDialog,
  keyValues,
  id,
  loadEntity,
  statusItems,
}) => {
  const { t } = useTranslation('PriorityStatusesConfig');
  const { compactMode } = useAppInfo();
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const isUser = keyValues?.userId ? true : false;
  const isWorkPlace = keyValues?.workPlaceId ? true : false;

  const methods = useForm<ICommentItem>({
    defaultValues: {
      commentStatusCode: '',
      canView: false,
      canUse: false,
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const prepareValues = {
      ...(isUser ? { userId: keyValues.userId } : {}),
      ...(isWorkPlace ? { workPlaceId: keyValues.workPlaceId } : {}),
      commentStatusCode: values.commentStatusCode,
      canUse: values.canUse,
      canView: values.canView,
    };
    const fn = isUser ? addUserCommentState : addWorkplaceCommentState;
    const response = await fn(prepareValues);
    if (response === true) {
      addSuccessAlert(t('dialog.saved'));
      loadEntity();
      toggleDialog(false);
    } else {
      addErrorAlert(t('dialog.errorSaving'));
    }
    toggleLoader(false);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Grid container={true} spacing={0}>
          <Grid item={true} xs={12}>
            <FormSelect name="commentStatusCode" label={t('state')} items={statusItems} />
          </Grid>
          <Grid item={true} xs={12}>
            <CheckboxForm name="canUse" label={t('canUse')} />
          </Grid>
          <Grid item={true} xs={12}>
            <CheckboxForm name="canView" label={t('canView')} />
          </Grid>
        </Grid>
        <EntityButtons
          toggleDialog={toggleDialog}
          otherStyles={{ marginTop: compactMode ? 8 : 16 }}
        />
      </form>
    </FormProvider>
  );
};

export default CommentStateForm;

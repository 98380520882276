import * as yup from 'yup';
import useValidators from 'components/Form/validations/useValidators';

const useValidationSchema = (requiredText: boolean) => {
  const { required } = useValidators();

  const CommentFormSchema = yup.object().shape({
    ...(requiredText ? { text: required(yup.string()) } : {}),
  });

  return { CommentFormSchema };
};

export default useValidationSchema;

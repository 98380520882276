import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { find, get, isArray, isEmpty, omitBy, remove, sortBy } from 'lodash';
import {
  AppBar,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { findUser, removeUser, setEnabledState } from './_api';
import { IUsersSearch } from './_types';
import { IWorkplace } from '../Workplaces/_types';
import { getAuthConfig } from '../../Login/_api';
import Header from 'components/Header/Header';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import { useLanguage } from 'utils/hooks/useLanguage';

import { ENABLED_USER } from 'constants/constants';
import { useUser } from 'utils/hooks/useUser';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import RetireForm from './RetireForm';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import RetireIcon from '@mui/icons-material/Block';
import TotpIcon from '@mui/icons-material/VpnKeyOff';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import styles from 'components/SearchResultsMUI/_styles';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useActions } from 'utils/hooks/useActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { v1 as uuidv1 } from 'uuid';
import Button from 'components/Buttons/Button';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';
import { ResetTotpDialog } from './ResetTotpDialog';

const iconStyle = { width: 16, height: 16 };
const muiGridKey = 'usersMUI';

export const Users: React.FC = () => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('Users');
  const { pathname } = useLocation();
  const { toggleLoader } = useAppGlobals();
  let [searchParams] = useSearchParams();
  const { currentLocale } = useLanguage();
  useWithTitle();
  const { hasRole } = useUser();
  const dispatch = useAppDispatch();
  const { compactMode, confirmationData } = useAppInfo();
  const { storeConfirmationData } = useActions();
  const [users, fetchUsers] = useState<any[]>([]);
  const [portalAuthConfig, setPortalAuthConfig] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [canAddOrEditUsers, setCanAddOrEditUsers] = useState<boolean>(false);
  const [retired, setRetired] = useState<boolean>(false);
  const [retireUser, setRetireUser] = useState<any>(null);
  const [userToResetTotp, setUserToResetTotp] = useState<any>(null);

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);
  const activeTabRef = useRef(activeTab);
  const canAddOrEditUsersRef = useRef(canAddOrEditUsers);

  const extendedPath = 'users';

  const methods = useForm<IUsersSearch>();
  const { handleSubmit, reset } = methods;

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };
  const loadEntity = async () => {
    triggerSubmit();
  };
  const { onEntityRemove } = useEntityRemove(
    removeUser,
    t,
    loadEntity,
    'deleted',
    'errorDeleting',
    activeTab,
  );

  const handleOnRemove = (row: any) => {
    dispatch(
      storeConfirmationDataToStore({
        title: t('confirmDelete'),
        row,
        id: get(row, 'username'),
        confirmAction: 'confirmActionDelete',
      }),
    );
  };

  const handleCloseRetireDialog = () => {
    setRetireUser(null);
  };

  const handleCloseTotpDialog = () => {
    setUserToResetTotp(null);
  };

  const handleOpenRetireDialog = (row: any) => {
    setRetireUser(row);
  };

  const handleOpenResetTotpDialog = (row: any) => {
    setUserToResetTotp(row);
  };

  const clearAction = () => storeConfirmationData(null);
  const cancelAction = () => clearAction();

  const onChangeEnabled = async (row: any, isActive: boolean) => {
    toggleLoader();
    const changed = await setEnabledState(get(row, 'username'), isActive, activeTab);
    if (changed) {
      loadEntity();
    }
    toggleLoader(false);
  };

  const canResetTotp = hasRole('ROLE_TOTP_RESET_KEY');

  const submitHandler = async (values: any) => {
    toggleLoader();
    let data = omitBy(values, isEmpty);
    const retired = get(values, 'retired', false);
    if (activeTab === 'db' && hasRole('ROLE_EMPLOYMENT_MANAGEMENT')) {
      data = { ...data, retired };
      setRetired(retired);
    }
    if (activeTab === 'db' && canResetTotp) {
      data = { ...data, withTotp: get(values, 'withTotp', false) };
    }
    const users = await findUser(data, activeTab);
    if (isArray(users)) {
      fetchUsers(
        users.map((user) => {
          const workPlaces = get(user, 'workPlaces', []);
          const workPlaceNames = isArray(workPlaces)
            ? sortBy(
                workPlaces.map((workPlace: IWorkplace) =>
                  get(
                    get(workPlace, 'text', false) ? JSON.parse(get(workPlace, 'text', '')) : {},
                    currentLocale,
                    workPlace.code,
                  ),
                ),
              ).join(', ')
            : null;
          return {
            ...user,
            id: user?.id ? user?.id : uuidv1(),
            workPlaces: workPlaceNames,
            userDirectoryId: activeTab,
          };
        }),
      );
    }
    toggleLoader(false);
  };

  const prepareState = (activeTab: string, allowedAuthMethods: any = portalAuthConfig) => {
    setActiveTab(activeTab);
    const activetabAllowedMethod = find(allowedAuthMethods, (config) => {
      return get(config, 'id') === activeTab;
    });

    fetchUsers([]);
    setCanAddOrEditUsers(get(activetabAllowedMethod, 'canManage', false));
  };

  const getEntity = async () => {
    toggleLoader();
    try {
      const authConfig = await getAuthConfig();
      const allowedAuthMethods = get(authConfig, 'allowedAuthMethods', []);
      let activeTab = '';
      if (allowedAuthMethods !== undefined) {
        remove(allowedAuthMethods, (config) => {
          return get(config, 'type') === 'headers' || get(config, 'type') === 'spnego';
        });
        activeTab = get(allowedAuthMethods[0], 'id', '');
        setPortalAuthConfig(allowedAuthMethods);
      }

      const username = searchParams.get('username') || null;
      const firstName = searchParams.get('firstName') || null;
      const lastName = searchParams.get('lastName') || null;
      const email = searchParams.get('email') || null;
      const usersSearch: IUsersSearch = { username, firstName, lastName, email };
      reset({ ...usersSearch });

      const activeTabFromUrl = searchParams.get('activeTabId') || null;
      if (activeTabFromUrl !== null && typeof activeTabFromUrl === 'string') {
        activeTab = activeTabFromUrl;
      }
      prepareState(activeTab, allowedAuthMethods);

      // setFirstStep(true);
      if (
        username ||
        firstName ||
        lastName ||
        email /*  ||
        (activeTabFromUrl !== null && typeof activeTabFromUrl === 'string') */
      ) {
        loadEntity();
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  const RenderEditIcon = ({ row }: any) => {
    return (
      <GridActionsCellItem
        component={Link}
        {...({
          to: `${pathname}/${extendedPath}/${
            get(row, 'username', '') ? get(row, 'username', '') : ''
          }${
            get(row, 'backToByAction', '')
              ? '?from=' + get(row, 'backToByAction', '')
              : get(row, 'userDirectoryId', '')
              ? `?userDirectoryId=${get(row, 'userDirectoryId', '')}&parCode=${get(
                  row,
                  'parCode',
                  '',
                )}`
              : ''
          }`,
        } as any)}
        icon={
          <Tooltip title={t('Grid:edit')}>
            <EditIcon />
          </Tooltip>
        }
        sx={{ mr: 1 }}
      />
    );
  };

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns(
      [
        {
          field: 'actions',
          headerName: t('Grid:actions'),
          type: 'actions',
          hideable: false,
          minWidth: 200,
          renderCell: ({ row }: GridRenderCellParams) => {
            if (activeTabRef.current === 'db') {
              return (
                <>
                  <FormControlLabel
                    sx={{ ...styles.activeButton, minWidth: '115px' }}
                    control={
                      <Switch
                        checked={row.enabled}
                        onChange={(e, state) => {
                          dispatch(
                            storeConfirmationDataToStore({
                              title: ENABLED_USER ? 'confirmChangeState' : 'confirmChangeEnabled',
                              row,
                              id: get(row, 'id'),
                              state,
                              confirmAction: 'confirmActionChangeEnabled',
                            }),
                          );
                        }}
                      />
                    }
                    label={
                      (ENABLED_USER && row.enabled
                        ? t('Grid:active')
                        : ENABLED_USER && !row.enabled
                        ? t('Grid:inactive')
                        : t('enabled')) as string
                    }
                  />
                  {RenderEditIcon({ row })}
                  {hasRole('ROLE_EMPLOYMENT_MANAGEMENT') && !retired && (
                    <GridActionsCellItem
                      icon={
                        <Tooltip title={t('Grid:retired')}>
                          <RetireIcon style={iconStyle} />
                        </Tooltip>
                      }
                      label="Detail"
                      onClick={() => handleOpenRetireDialog(row)}
                      sx={{ mr: 1 }}
                    />
                  )}
                  {canResetTotp && (
                    <GridActionsCellItem
                      icon={
                        <Tooltip title={t('Grid:resetTotp')}>
                          <TotpIcon style={iconStyle} />
                        </Tooltip>
                      }
                      label="Detail"
                      onClick={() => handleOpenResetTotpDialog(row)}
                      sx={{ mr: 1 }}
                    />
                  )}
                </>
              );
            } else if (canAddOrEditUsersRef.current) {
              return (
                <>
                  {RenderEditIcon({ row })}
                  <GridActionsCellItem
                    icon={
                      <Tooltip title={t('Grid:delete')}>
                        <DeleteIcon style={iconStyle} />
                      </Tooltip>
                    }
                    label="Detail"
                    onClick={() => handleOnRemove(row)}
                  />
                </>
              );
            }
          },
        },
        {
          field: 'username',
          headerName: t('username'),
        },
        {
          field: 'firstName',
          headerName: t('firstname'),
        },
        {
          field: 'lastName',
          headerName: t('lastname'),
        },
        {
          field: 'middleName',
          headerName: t('middlename'),
        },
        {
          field: 'prefix',
          headerName: t('prefix'),
        },
        {
          field: 'suffix',
          headerName: t('suffix'),
        },
        {
          field: 'email',
          headerName: t('email'),
        },
        {
          field: 'userIsInternal',
          headerName: t('userIsInternal'),
          valueGetter: (value: any, row: any) => (get(row, 'value') ? t('yes') : t('no')),
        },
        {
          field: 'icp',
          headerName: t('icp'),
        },
        {
          field: 'workPlaces',
          headerName: t('workPlaces'),
        },
      ],
      200,
    ),
  );

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Combination of ref + useEffect to update values inside renderCell function - closure problem -> https://www.developerway.com/posts/fantastic-closures
  useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab]);

  useEffect(() => {
    canAddOrEditUsersRef.current = canAddOrEditUsers;
  }, [canAddOrEditUsers]);

  return (
    <>
      <Header
        title=""
        addUrl={
          canAddOrEditUsers
            ? `/administration/settings/users/create?userDirectoryId=${activeTab}`
            : undefined
        }
      />
      {!isEmpty(activeTab) && (
        <>
          <AppBar position="static" color="default" sx={{ mb: compactMode ? 1 : 2 }}>
            <Tabs
              value={activeTab}
              onChange={(event, value) => {
                setActiveTab(value);
                prepareState(value);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={false}
            >
              {!isEmpty(portalAuthConfig) &&
                portalAuthConfig.map((config) => {
                  return (
                    <Tab
                      value={get(config, 'id')}
                      label={`${get(config, 'id')} (${get(config, 'type')})`}
                      key={get(config, 'order')}
                    />
                  );
                })}
            </Tabs>
          </AppBar>
          <Papeer bottomMargin={true}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitHandler)}>
                <button hidden={true} ref={refSubmitButtom} type="submit" />
                <Grid container={true} spacing={2}>
                  {['username', 'firstName', 'lastName', 'email'].map((formItem) => (
                    <Grid item={true} xs={12} md={6} lg={3} xl={1.5} key={`formItem-${formItem}`}>
                      <FormInput name={formItem} label={t(formItem.toLowerCase())} type="text" />
                    </Grid>
                  ))}
                  {hasRole('ROLE_EMPLOYMENT_MANAGEMENT') && activeTab === 'db' && (
                    <Grid
                      item={true}
                      xs={12}
                      md={6}
                      lg={3}
                      xl={2}
                      sx={{ mt: compactMode ? 0.5 : 0.8 }}
                    >
                      <FormControlLabel
                        control={
                          <Controller
                            name={'retired'}
                            control={methods.control}
                            defaultValue={false}
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                              />
                            )}
                          />
                        }
                        label={t('retired')}
                      />
                    </Grid>
                  )}
                  {canResetTotp && activeTab === 'db' && (
                    <Grid
                      item={true}
                      xs={12}
                      md={6}
                      lg={3}
                      xl={2}
                      sx={{ mt: compactMode ? 0.5 : 0.8 }}
                    >
                      <FormControlLabel
                        control={
                          <Controller
                            name="withTotp"
                            control={methods.control}
                            defaultValue={false}
                            render={({ field }) => (
                              <Checkbox
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                              />
                            )}
                          />
                        }
                        label={t('withTotp')}
                      />
                    </Grid>
                  )}
                  <Grid item={true} xs={12} md={6} lg={3} xl={2}>
                    <Button variant="contained" color="primary" type="submit" sx={{ mt: 1 }}>
                      <SearchIcon sx={{ mr: 1 }} />
                      {t('search')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Papeer>
        </>
      )}

      <MuiGrid
        gridKey={muiGridKey}
        rows={users}
        columns={columns}
        initialSortMode={[{ field: 'username', sort: 'asc' }]}
      />

      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title && t(`Grid:${confirmationData.title}`)}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={cancelAction}
          confirmAction={() => {
            if (confirmationData.confirmAction === 'confirmActionDelete') {
              onEntityRemove(confirmationData.row?.username);
            } else if (confirmationData.confirmAction === 'confirmActionChangeEnabled') {
              onChangeEnabled(confirmationData.row, confirmationData.state);
            }
            clearAction();
          }}
        />
      )}
      <OverflowedDialog
        key={'retireDialog'}
        open={retireUser ? true : false}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        withScrolling={true}
        title={t('retire')}
        toggleDialog={handleCloseRetireDialog}
      >
        <RetireForm
          retireUser={retireUser}
          toggleDialog={handleCloseRetireDialog}
          loadEntity={loadEntity}
          clearAction={handleCloseRetireDialog}
        />
      </OverflowedDialog>
      {userToResetTotp && (
        <OverflowedDialog
          key="resetTotpDialog"
          open={userToResetTotp ? true : false}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
          withScrolling={true}
          title={t('resetTotpDialog')}
          toggleDialog={handleCloseTotpDialog}
        >
          <ResetTotpDialog
            user={userToResetTotp}
            closeDialog={handleCloseTotpDialog}
            loadEntity={loadEntity}
            clearAction={handleCloseRetireDialog}
          />
        </OverflowedDialog>
      )}
    </>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { filter, find, get, includes, isArray, isEmpty, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import ExportIcon from '@mui/icons-material/ImportExport';
import {
  IComment,
  IDialogChangeStateOrPriority,
  ISelectItem,
  IStudyDetailComments,
} from './_types';
import { IPriorityConfig, IStatusConfig } from 'modules/Administration/Workplaces/_types';
import { editComment, exportComment, setActiveComment } from './_api';
import { getUserStatuses } from 'modules/Administration/Users/_api';
import { getPriorityStatusConfig } from 'modules/Administration/Workplaces/_api';
import StudyDetailCommentForm from './StudyDetailCommentForm';
import StudyDetailCommentStatePriorityForm from './StudyDetailCommentStatePriorityForm';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useStudy } from 'utils/hooks/useStudy';
import { useActions } from 'utils/hooks/useActions';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useUser } from 'utils/hooks/useUser';
import { stateIsSame } from 'utils/componentOptimizatons';
import { useLanguage } from 'utils/hooks/useLanguage';
import { CheckedButton } from 'components/Buttons/CheckedButton';
import { Line } from 'components/Line/Line';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import useAlerts from 'components/Alerts/useAlerts';
import { FINISHED } from 'constants/constants';

const formatDateTime = 'dd. MM. yyyy, HH:mm:ss';

const PlainStudyDetailComments: React.FC<IStudyDetailComments> = ({
  study,
  setStudy,
  certNameItems,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { hasRole, user } = useUser();
  const { currentLocale } = useLanguage();
  const [commentItem, setCommentItem] = useState<any>(null);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [dialogChangeStateOrPriority, setDialogChangeStateOrPriority] =
    useState<IDialogChangeStateOrPriority>({ dialogState: false });
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { toggleLoader } = useAppGlobals();
  const { compactMode, confirmationData, lastPageState, priorityAndStatusEnabled } = useAppInfo();
  const { storeConfirmationData } = useActions();
  const { getCommentNameWithLogin, getStudyCommentsByStudyID, studyIsLocked } = useStudy();
  const [comments, setComments] = useState<IComment[]>([]);
  const [userStatuses, setUserStatuses] = useState<string[]>([]);
  const [statusItems, setStatusItems] = useState<ISelectItem[]>([]);
  const [allStatusItems, setAllStatusItems] = useState<ISelectItem[]>([]);
  const [priorityItems, setPriorityItems] = useState<ISelectItem[]>([]);
  const [stateHasToBeSigned, setStateHasToBeSigned] = useState<string>('');
  const [emailToPhysicians, setEmailToPhysicians] = useState<string[]>([]);
  const [commentStatusAndPriorityCode, setCommentStatusAndPriorityCode] = useState<any>();
  const [allStatuses, setAllStatuses] = useState<IStatusConfig[]>([]);
  const [allPriorities, setAllPriorities] = useState<IPriorityConfig[]>([]);
  const [showOnlyValid, setShowOnlyValid] = useState<boolean>(false);

  const isLocked = useMemo(
    () => studyIsLocked(study),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [study],
  );

  const [initialComment, setInitialComment] = useState<any>(null);

  const getEntities = async () => {
    toggleLoader();
    try {
      const [userStatuses, prioritiesAndStatuses] = await Promise.all([
        getUserStatuses(),
        getPriorityStatusConfig(),
      ]);
      if (!isEmpty(userStatuses) && isArray(userStatuses)) {
        setUserStatuses(userStatuses);
      }

      if (!isEmpty(prioritiesAndStatuses)) {
        const statuses = get(prioritiesAndStatuses, 'statuses', []) || [];
        const priorities = get(prioritiesAndStatuses, 'priorities', []) || [];
        setAllStatuses(statuses);
        setAllPriorities(priorities);
        const statusItems: ISelectItem[] = [];
        const allStatusItems: ISelectItem[] = [];
        statuses.forEach((item) => {
          const prepaireItem = {
            id: item.code,
            label: get(item, `name.${currentLocale}`, get(item, 'name.cs', 'name')),
          };
          if (
            !isEmpty(userStatuses) &&
            isArray(userStatuses) &&
            includes(userStatuses, item.code)
          ) {
            statusItems.push(prepaireItem);
          }
          allStatusItems.push(prepaireItem);
        });
        setStatusItems(statusItems);
        setAllStatusItems(allStatusItems);

        setPriorityItems(
          priorities.map((item) => ({
            id: item.code,
            label: get(item, `name.${currentLocale}`, get(item, 'name.cs', 'name')),
            color: get(item, 'color', 'primary') || 'primary',
          })),
        );
        const emailToPhysicians: string[] = [];
        statuses.forEach((status) => {
          if (status.hasToBeSigned) {
            setStateHasToBeSigned(status.code);
          }
          if (status.emailToPhysician) {
            emailToPhysicians.push(status.code);
          }
        });
        setEmailToPhysicians(emailToPhysicians);
      }
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    if (priorityAndStatusEnabled) {
      getEntities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityAndStatusEnabled]);

  const getAllComments = useCallback(async () => {
    const archiveID = get(study, 'archive.id');
    const studyID = get(study, 'studyInstanceUid');
    if (archiveID && studyID) {
      toggleLoader();
      const comments = await getStudyCommentsByStudyID(archiveID, studyID);
      setComments(comments);
      let commentStatusAndPriorityCode = {};
      comments.forEach((comment) => {
        commentStatusAndPriorityCode = {
          ...commentStatusAndPriorityCode,
          [comment.id]: {
            commentPriorityCode: comment.commentPriorityCode || '',
            commentStatusCode: comment.commentStatusCode || '',
            status: { ...find(allStatuses, { code: comment.commentStatusCode || '' }) },
          },
        };
      });
      setCommentStatusAndPriorityCode(commentStatusAndPriorityCode);
      toggleLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study, allStatuses, stateHasToBeSigned, emailToPhysicians]);

  // Load all comments
  useEffect(() => {
    getAllComments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study, allStatuses, stateHasToBeSigned, emailToPhysicians]);

  const saveStatusOrPriority = async (comment: IComment, priority: string, state: string) => {
    toggleLoader();
    const prepareValues = {
      comment: {
        id: get(comment, 'id', 0) || (0 as number),
        text: get(comment, 'text', ''),
      },
      ...(priority ? { commentPriorityCode: priority } : {}),
      ...(state ? { commentStatusCode: state } : {}),
    };
    await editComment(prepareValues).then(async () => {
      await getAllComments();
    });
    toggleLoader(false);
  };

  const handleChangeStatus = (state: string, comment: IComment) => {
    const emailToPhysician = includes(emailToPhysicians, state);
    const hasToBeSigned = state === stateHasToBeSigned;
    if (emailToPhysician || hasToBeSigned) {
      setDialogChangeStateOrPriority({
        dialogState: true,
        comment,
        state,
        emailToPhysician,
        hasToBeSigned,
      });
    } else {
      saveStatusOrPriority(comment, '', state);
      setCommentStatusAndPriorityCode({
        ...commentStatusAndPriorityCode,
        [get(comment, 'id', 0)]: {
          ...get(commentStatusAndPriorityCode, [get(comment, 'id', 0)]),
          commentStatusCode: state,
        },
      });
    }
  };

  useMemo(() => {
    const commentForm = get(lastPageState, 'form.comment', null);
    if (commentForm != null) {
      const comment = get(commentForm, 'values.comment.text', null);
      if (comment !== null) {
        setInitialComment(comment);
        setDialogState(true);
      }
    }
  }, [lastPageState, setDialogState]);

  const onChangeActive = async (id: string) => {
    toggleLoader();
    const changed = await setActiveComment(id, false);
    if (changed) {
      await getAllComments();
    }
    toggleLoader(false);
  };
  const onExportComment = async (id: string, text: string) => {
    toggleLoader();
    const changed = await exportComment({ comment: { id, text }, study });
    if (changed) {
      addSuccessAlert(t('commentExported'));
    } else {
      addErrorAlert(t('commentNotExported'));
    }
    toggleLoader(false);
  };
  const clearAction = () => {
    storeConfirmationData(null);
  };
  const cancelAction = () => {
    clearAction();
  };
  const confirmAction = async () => {
    onChangeActive(get(confirmationData, 'id'));
    clearAction();
  };
  const confirmActionExportComment = async () => {
    onExportComment(get(confirmationData, 'id'), get(confirmationData, 'text'));
    clearAction();
  };

  const renderedComments = useMemo(() => {
    return (
      <>
        {hasRole('ROLE_COMMENTARY_ALL') || hasRole('ROLE_COMMENTARY_MINE') ? (
          <Button
            size={compactMode ? 'small' : 'medium'}
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogState(true);
              setCommentItem(null);
            }}
            sx={{ mb: 2 }}
          >
            {t('action.addComment')}
          </Button>
        ) : null}
        {priorityAndStatusEnabled && (
          <CheckedButton
            active={showOnlyValid}
            onClick={() => setShowOnlyValid(!showOnlyValid)}
            leftMargin={true}
            topMargin={false}
            sx={{ mb: 2 }}
          >
            {t('PriorityStatusesConfig:showOnlyValid')}
          </CheckedButton>
        )}

        {isArray(comments)
          ? (showOnlyValid ? filter(comments, ['deprecated', false]) : comments).map(
              (comment, index) => {
                const createdByAuthor = getCommentNameWithLogin(get(comment, 'createdBy'));
                const modifiedByAuthor = getCommentNameWithLogin(get(comment, 'modifiedBy'));
                const commentCreatedByLoggedUser =
                  get(comment, 'createdBy.username') === get(user, 'sub');
                const isFinished =
                  get(commentStatusAndPriorityCode, `${comment.id!}.status.type`) === FINISHED;
                const isDeprecated = get(comment, 'deprecated', false);
                const commentStatusCode = get(comment, 'commentStatusCode', null);
                const commentPriorityCode = get(comment, 'commentPriorityCode', null);
                const commentPriorityCodeTranslation = get(
                  find(priorityItems, { id: commentPriorityCode }),
                  'label',
                  '?',
                );
                const commentPriorityColor = get(
                  find(priorityItems, { id: commentPriorityCode }),
                  'color',
                  'primary',
                );
                const commentStatusCodeTranslation: any = commentStatusCode
                  ? get(find(allStatusItems, { id: commentStatusCode }), 'label', '')
                  : '';
                const allowedNextStatuses = get(
                  find(allStatuses, { code: commentStatusCode }),
                  'allowedNextStatuses',
                  [],
                );
                const allowedNextUserStatuses: any[] = [];
                allowedNextStatuses.forEach((item) => {
                  if (includes(userStatuses, get(item, 'code'))) {
                    allowedNextUserStatuses.push(item);
                  }
                });
                return (
                  <div key={`comment-${comment.id}`}>
                    {priorityAndStatusEnabled && (
                      <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                        <Chip
                          label={t(
                            `PriorityStatusesConfig:is${!isDeprecated ? 'Not' : ''}Deprecated`,
                          )}
                          color={isDeprecated ? 'error' : 'success'}
                          variant="outlined"
                          size="small"
                        />
                        {commentStatusCodeTranslation && (
                          <Box sx={{ fontWeight: 'bold', ml: 1 }}>
                            <Tooltip title={t('PriorityStatusesConfig:state')}>
                              <Chip
                                label={commentStatusCodeTranslation}
                                color="default"
                                variant="outlined"
                                size="small"
                              />
                            </Tooltip>
                          </Box>
                        )}
                        {priorityAndStatusEnabled && (
                          <Box sx={{ fontWeight: 'bold', ml: 1 }}>
                            <Chip
                              label={commentPriorityCodeTranslation}
                              variant="outlined"
                              size="small"
                              sx={{
                                color: (theme) =>
                                  commentPriorityColor[0] === '#'
                                    ? commentPriorityColor
                                    : commentPriorityColor
                                    ? get(theme, `palette.${commentPriorityColor}.main`)
                                    : get(theme, `palette.primary.main`),
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {get(comment, 'modifiedBy') && (
                        <Tooltip
                          title={
                            <>
                              <div>{t('modified')}:</div>
                              <div>
                                {modifiedByAuthor} -{' '}
                                {format(
                                  new Date(get(comment, 'modifiedWhen') || ''),
                                  formatDateTime,
                                )}
                              </div>
                            </>
                          }
                        >
                          <HistoryIcon sx={{ mr: 1, color: 'inherit' }} />
                        </Tooltip>
                      )}
                      <Tooltip title={t('commentCreateTitle') as String}>
                        <div>
                          {get(comment, 'createdBy.email') ? (
                            <Link
                              href={`mailto: ${comment?.createdBy?.email}`}
                              underline="none"
                              sx={{ color: (theme) => theme.palette.success.main }}
                            >
                              {createdByAuthor}
                            </Link>
                          ) : (
                            createdByAuthor
                          )}{' '}
                          - {format(new Date(get(comment, 'createdWhen') || ''), formatDateTime)}
                        </div>
                      </Tooltip>

                      {!isLocked && (
                        <>
                          {filter(
                            [
                              {
                                title: 'commentUpdate',
                                children: <EditIcon />,
                                onClick: () => {
                                  setDialogState(true);
                                  setCommentItem(comment);
                                },
                                show:
                                  hasRole('ROLE_COMMENTARY_ALL') ||
                                  (commentCreatedByLoggedUser && hasRole('ROLE_COMMENTARY_MINE')),
                                disabled: priorityAndStatusEnabled && isDeprecated,
                              },
                              {
                                title: 'commentDelete',
                                children: <DeleteIcon />,
                                onClick: () => {
                                  storeConfirmationData({
                                    id: comment.id,
                                  });
                                },
                                show: hasRole('ROLE_COMMENTARY_ALL'),
                                disabled: false,
                              },
                              {
                                title: 'commentExport',
                                children: <ExportIcon />,
                                onClick: () => {
                                  storeConfirmationData({
                                    title: 'confirmExport',
                                    id: comment.id,
                                    text: comment.text,
                                    confirmAction: 'confirmActionExportComment',
                                  });
                                },
                                show: hasRole('ROLE_COMMENTARY_SEND'),
                                disabled: priorityAndStatusEnabled && !isFinished,
                              },
                            ],
                            'show',
                          ).map((button) => (
                            <Tooltip key={button.title} title={t(button.title) as String}>
                              <IconButton
                                {...pick(button, ['children', 'onClick'])}
                                sx={{ padding: (theme) => theme.spacing(1 / 4), ml: 1 }}
                                size="large"
                                disabled={button.disabled}
                              />
                            </Tooltip>
                          ))}
                          {isDeprecated
                            ? []
                            : allowedNextUserStatuses.map((item) => {
                                return (
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      handleChangeStatus(item.code, comment);
                                    }}
                                    sx={{ ml: 1 }}
                                  >
                                    {get(
                                      item,
                                      `buttonText.${currentLocale}`,
                                      get(item, 'buttonText.cs', 'name'),
                                    )}
                                  </Button>
                                );
                              })}
                        </>
                      )}
                    </Box>
                    <Box
                      sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', mt: 1, lineHeight: 1 }}
                    >
                      {comment.text}
                    </Box>
                    {++index < comments.length ? <Line topMargin={2} bottomMargin={2} /> : null}
                  </div>
                );
              },
            )
          : null}
        <Dialog
          open={dialogState}
          onClose={() => setDialogState(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">{t('dialog_comment')}</DialogTitle>
          <DialogContent>
            <StudyDetailCommentForm
              setDialogState={setDialogState}
              commentItem={commentItem}
              getComments={getAllComments}
              study={study}
              setStudy={setStudy}
              initialComment={initialComment}
              allStatuses={allStatuses}
              allPriorities={allPriorities}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={dialogChangeStateOrPriority.dialogState}
          onClose={() => setDialogChangeStateOrPriority({ dialogState: false })}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">{t('dialog_comment')}</DialogTitle>
          <DialogContent>
            <StudyDetailCommentStatePriorityForm
              commentItem={dialogChangeStateOrPriority.comment}
              hasMailSend={dialogChangeStateOrPriority.emailToPhysician}
              hasToBeSigned={dialogChangeStateOrPriority.hasToBeSigned}
              priority={dialogChangeStateOrPriority.priority}
              state={dialogChangeStateOrPriority.state}
              setDialogState={setDialogChangeStateOrPriority}
              getComments={getAllComments}
              certNameItems={certNameItems}
            />
          </DialogContent>
        </Dialog>
        {confirmationData && confirmationData.id && (
          <ConfirmationDialog
            title={confirmationData.title ? t(confirmationData.title) : t('confirmDelete')}
            open={true}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            cancelAction={cancelAction}
            confirmAction={
              confirmationData.confirmAction === 'confirmActionExportComment'
                ? confirmActionExportComment
                : confirmAction
            }
          />
        )}
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    comments,
    confirmationData,
    dialogState,
    commentStatusAndPriorityCode,
    statusItems,
    dialogChangeStateOrPriority,
    showOnlyValid,
  ]);

  return <>{renderedComments}</>;
};

export const StudyDetailComments = React.memo(PlainStudyDetailComments, stateIsSame);

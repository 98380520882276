import React, { useEffect, useState } from 'react';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import UserTagStatisticsSearchForm from './UserTagStatistics/UserTagStatisticsSearchForm';
import UserTagStatisticsResults from './UserTagStatistics/UserTagStatisticsResults';

const UserTagReports: React.FC = () => {
  let [searchParams] = useSearchParams();
  const [userTagReports, setUserTagReports] = useState<any[]>([]);

  const { userTagReportResults } = useAppInfo();

  useEffect(() => {
    const isBack = (searchParams.get('action') || null) === 'back';
    if (!isEmpty(userTagReportResults) && isBack) {
      setUserTagReports(userTagReportResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UserTagStatisticsSearchForm setUserTagReports={setUserTagReports} />
      <UserTagStatisticsResults userTagReports={userTagReports} />
    </>
  );
};

export default UserTagReports;

import React, { useEffect, useState } from 'react';
import { find, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  deleteUserCommentState,
  deleteWorkplaceCommentState,
  updateUserCommentState,
  updateWorkplaceCommentState,
} from './_api';
import { IStatuses, IWorkplaceOrUserFindingStatuses } from './_types';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useLanguage } from 'utils/hooks/useLanguage';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';
import Header from 'components/Header/Header';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import CommentStateForm from './CommentStateForm';
import { ISelectItem } from 'components/Form/Select/_types';

const muiGridKey = 'workplaceOrUserFindingStatusesMUI';
const getRowId = (row: IStatuses) => get(row, 'status.code');

const WorkplaceOrUserFindingStatuses: React.FC<IWorkplaceOrUserFindingStatuses> = ({
  userId,
  workPlaceId,
  statuses,
  allStatuses,
  loadEntity,
}) => {
  const isUser = userId ? true : false;
  const isWorkPlace = workPlaceId ? true : false;
  const { t } = useTranslation('PriorityStatusesConfig');
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const { currentLocale } = useLanguage();

  const [configStatuses, setConfigStatuses] = useState<IStatuses[]>([]);
  const [statusItems, setStatusItems] = useState<ISelectItem[]>([]);
  const [dialogState, setDialogState] = useState<boolean>(false);

  const { toggleLoader } = useAppGlobals();
  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };
  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        flex: 0.3,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon sx={{ mr: 4 }} />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(
                    storeConfirmationDataToStore({
                      id: get(row, 'status.code'),
                      confirmAction: 'confirmAction',
                    }),
                  );
                }}
              />
              <FormControlLabel
                sx={{ maxHeight: 100 }}
                control={
                  <Switch
                    checked={row.canUse}
                    onChange={(e, state) =>
                      dispatch(
                        storeConfirmationDataToStore({
                          title: 'Grid:confirmChangeState',
                          id: get(row, 'status.code'),
                          state,
                          fieldName: 'canUse',
                          confirmAction: 'confirmActionChangeCanUse',
                        }),
                      )
                    }
                  />
                }
                label={t('grid.canUse')}
              />
              <FormControlLabel
                sx={{ maxHeight: 100 }}
                control={
                  <Switch
                    checked={row.canView}
                    onChange={(e, state) =>
                      dispatch(
                        storeConfirmationDataToStore({
                          title: 'Grid:confirmChangeState',
                          id: get(row, 'status.code'),
                          state,
                          fieldName: 'canView',
                          confirmAction: 'confirmActionChangeCanView',
                        }),
                      )
                    }
                  />
                }
                label={t('grid.canView')}
              />
            </>
          );
        },
      },
      {
        field: 'order',
        headerName: t('order'),
        type: 'number',
        valueGetter: (value: any, row: any) => get(row, 'status.order', 1),
        flex: 0.1,
      },
      {
        field: 'code',
        headerName: t('code'),
        valueGetter: (value: any, row: any) => get(row, 'status.code'),
        flex: 0.2,
      },
      { field: 'currentLocaleName', headerName: t('name'), flex: 0.2 },
      {
        field: 'currentLocaleDescription',
        headerName: t('Grid:permissions'),
        flex: 1,
      },
    ]),
  );

  useEffect(() => {
    const configStatuses: IStatuses[] = [];
    const statusItems: ISelectItem[] = [];
    statuses.forEach((item) => {
      const currentLocaleName = get(
        item,
        `status.name.${currentLocale}`,
        get(item, 'status.name.cs', ''),
      );
      configStatuses.push({
        ...item,
        currentLocaleName,
        currentLocaleDescription: get(
          item,
          `description.${currentLocale}`,
          get(item, 'status.description.cs', 'description'),
        ),
      });
    });
    allStatuses.forEach((item) => {
      const id = item.code;
      const label = get(item, `name.${currentLocale}`, get(item, 'name.cs', ''));
      if (!find(statuses, { status: { code: id } })) {
        statusItems.push({ id, label });
      }
    });
    setStatusItems(statusItems);
    setConfigStatuses(configStatuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses]);

  const onChangeSelected = async (commentStatusCode: string, state: boolean, fieldName: string) => {
    toggleLoader();
    if (isWorkPlace) {
      await updateWorkplaceCommentState({ commentStatusCode, workPlaceId, [fieldName]: state });
    } else if (isUser) {
      await updateUserCommentState({ commentStatusCode, userId, [fieldName]: state });
    }
    loadEntity();

    toggleLoader(false);
  };

  const confirmActionChangeField = () => {
    onChangeSelected(
      get(confirmationData, 'id'),
      get(confirmationData, 'state'),
      get(confirmationData, 'fieldName'),
    );
    clearAction();
  };

  const { onEntityRemove } = useEntityRemove(
    isWorkPlace ? deleteWorkplaceCommentState : deleteUserCommentState,
    t,
    loadEntity,
  );

  const confirmAction = async () => {
    const data = {
      commentStatusCode: get(confirmationData, 'id'),
      ...(isWorkPlace ? { workPlaceId } : {}),
      ...(isUser ? { userId } : {}),
    };
    await onEntityRemove(data);
    clearAction();
  };

  const toggleDialog = (state: boolean = false) => {
    setDialogState(state);
  };

  const addItemInDialog = () => {
    toggleDialog(true);
  };

  const renderedDialog = (
    <OverflowedDialog
      title={t('dialog.commentState')}
      open={dialogState}
      onClose={() => {
        toggleDialog(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <CommentStateForm
        toggleDialog={toggleDialog}
        keyValues={{ userId, workPlaceId }}
        id="create"
        loadEntity={loadEntity}
        statusItems={statusItems}
      />
    </OverflowedDialog>
  );

  return (
    <>
      <Header
        title={t('Workplaces:findingStatuses')}
        addItemInDialog={addItemInDialog}
        toggleDialogText={t('add')}
        topMargin={true}
      />
      <MuiGrid
        gridKey={muiGridKey}
        getRowId={getRowId}
        rows={configStatuses}
        columns={columns}
        initialSortMode={[{ field: 'order', sort: 'asc' }]}
        withoutMaxHight={userId ? true : false}
      />
      {renderedDialog}
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={
            confirmationData.confirmAction === 'confirmActionChangeCanUse' ||
            confirmationData.confirmAction === 'confirmActionChangeCanView'
              ? confirmActionChangeField
              : confirmAction
          }
        />
      )}
    </>
  );
};

export default WorkplaceOrUserFindingStatuses;

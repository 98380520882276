import { get } from 'lodash';
import { api } from 'utils/hooks/useApi';
import { ICertificate } from './_types';

export const sendMetadata = (data: ICertificate): Promise<boolean> =>
  api()
    .post(`/portal-api/electronicSignature/addCertificate`, data)
    .then((response) => true)
    .catch((error) => false);

export const deleteCertificate = (id: number): Promise<boolean> =>
  api()
    .delete(`/portal-api/electronicSignature/deleteCertificate`, { params: { id } })
    .then((response) => true)
    .catch((error) => false);

export const updateCertificate = (data: ICertificate): Promise<boolean> =>
  api()
    .put(`/portal-api/electronicSignature/updateCertificate`, data)
    .then((response) => true)
    .catch((error) => false);

export const getAllCertificates = (): Promise<ICertificate[]> =>
  api()
    .get(`/portal-api/electronicSignature/getAll`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

export const getAllCertificatesForUser = (): Promise<ICertificate[]> =>
  api()
    .get(`/portal-api/electronicSignature/getAllForUser`)
    .then((response) => get(response, 'data'))
    .catch((error) => false);

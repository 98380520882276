import { endOfDay, format, startOfDay } from 'date-fns';
import { get, isObject } from 'lodash';
import { getFilter } from 'modules/Administration/Filters/_api';
import { getSettingValueForVariable } from 'utils/products';
import { useAppInfo } from './useAppInfo';

export const useSearch = () => {
  const { portalProductSettings } = useAppInfo();

  const formatDateForSearch = (dateString: string, apiDateFormat: string) => {
    const date = new Date(dateString);
    return date ? format(date, apiDateFormat) : null;
  };

  const formatDateTimeForSearch = (date: Date, apiDateFormat: string, type = '') => {
    let sDate = null;
    if (type === 'start' && apiDateFormat === "yyyy-MM-dd'T'HH:mm:ss") {
      sDate = date ? format(startOfDay(date), apiDateFormat) : null;
    } else if (type === 'end' && apiDateFormat === "yyyy-MM-dd'T'HH:mm:ss") {
      sDate = date ? format(endOfDay(date), apiDateFormat) : null;
    } else {
      sDate = date ? format(date, apiDateFormat) : null;
    }
    return sDate;
  };

  const getParsedSearchFilter = async (id: number) => {
    try {
      const filter = await getFilter(id.toString());
      if (isObject(filter)) {
        const parsedFilter = JSON.parse(get(filter, 'filter', ''));
        return parsedFilter;
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  };

  const getSearchReloadTime = () => {
    try {
      const reloadTime = getSettingValueForVariable(portalProductSettings, 'searchReloadTime');
      if (reloadTime === undefined || reloadTime === null || reloadTime === '') {
        return 15;
      }
      return parseInt(reloadTime, 10);
    } catch (e) {
      return 15;
    }
  };

  const searchReloadStarted = getSettingValueForVariable(
    portalProductSettings,
    'searchReloadStarted',
  );

  return {
    formatDateForSearch,
    formatDateTimeForSearch,
    getParsedSearchFilter,
    getSearchReloadTime,
    searchReloadStarted,
  };
};

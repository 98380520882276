import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { each, filter, get, isArray, isEmpty, keys, omitBy, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Grid, IconButton } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

import { findPatient } from './_api';
import { IField, IPatientResponse, IPatientSearchForm } from './_types';

import useAlerts from 'components/Alerts/useAlerts';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormDatePicker from 'components/Form/Date/Date';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useActions } from 'utils/hooks/useActions';
import { useLanguage } from 'utils/hooks/useLanguage';
import useRefetchGrid from 'utils/grid/useRefetchGrid';
import { useStudy } from 'utils/hooks/useStudy';

import { getClinicalPortalSetting } from 'modules/Administration/ClinicalPortal/_api';
import { useSearch } from 'utils/hooks/useSearch';
import { useStorage } from 'utils/hooks/useStorage';
import Button from 'components/Buttons/Button';

const apiDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

const FORM_DATA_KEY = 'patientSearchForm';
const OTHER_DATA_KEY = 'patientSearchFormOther';

const PatientSearchForm: React.FC<IPatientSearchForm> = ({ setCanShowResults }) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('SearchForm');
  const { storePatients } = useActions();
  const { linkBack } = useStudy();
  const { formatDateTimeForSearch } = useSearch();
  const { getItem, putItem, removeItem } = useStorage();

  const { addErrorAlert } = useAlerts();
  const { refetchGrid } = useRefetchGrid();

  const [fields, setFields] = useState<IField[]>([]);
  const [searchFieldOpened, setSearchFieldOpened] = useState(false);

  const { currentLocale } = useLanguage();

  const methods = useForm();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = methods;

  const formValues = watch();
  const patientBirthDateFrom = watch('patientBirthDateFrom');
  const patientBirthDateTo = watch('patientBirthDateTo');
  const canSearch =
    !isEmpty(omitBy(formValues, isEmpty)) || patientBirthDateFrom || patientBirthDateTo;
  const { toggleLoader } = useAppGlobals();

  const backFromDetail = linkBack();

  const submitHandler = async (formValues: any) => {
    toggleLoader();
    callPersistForm(formValues);
    const values = omitBy(formValues, isEmpty);
    const patientBirthDateFrom = get(formValues, 'patientBirthDateFrom')
      ? formatDateTimeForSearch(get(formValues, 'patientBirthDateFrom'), apiDateTimeFormat, 'start')
      : null;
    const patientBirthDateTo = get(formValues, 'patientBirthDateTo')
      ? formatDateTimeForSearch(get(formValues, 'patientBirthDateTo', ''), apiDateTimeFormat, 'end')
      : null;
    const resp = await findPatient({
      ...values,
      ...(patientBirthDateFrom ? { patientBirthDateFrom } : {}),
      ...(patientBirthDateTo ? { patientBirthDateTo } : {}),
    });
    storePatients([]);
    if (isArray(resp)) {
      const patientResults: IPatientResponse[] = resp.map((item, index) => ({
        ...item,
        id: index + 1,
        internalID: get(item, 'masterPatientID', ''),
      }));
      storePatients(patientResults);
      setCanShowResults(true);
    } else {
      addErrorAlert(t('submitError'));
    }
    refetchGrid();

    toggleLoader(false);
  };

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click();
  };

  const loadEntities = async () => {
    toggleLoader();

    if (!backFromDetail) {
      storePatients(null);
    }

    const clinicalPortal = await getClinicalPortalSetting();
    if (clinicalPortal) {
      const searchAttributes = filter(get(clinicalPortal, 'searchAttributes', []), {
        active: true,
      });
      const sexes = [
        { id: '', label: t('all') },
        { id: 'F', label: t('female') },
        { id: 'M', label: t('male') },
        { id: 'O', label: t('other') },
      ];
      const fields: IField[] = searchAttributes.map((item) => {
        let nameTranslate = get(item, 'nameTranslate', null);
        if (nameTranslate) {
          nameTranslate = JSON.parse(nameTranslate);
        }
        return {
          name: get(item, 'name', ''),
          label: get(nameTranslate, currentLocale, get(item, 'name', '')),
          position: get(item, 'position', 0),
          searchType: get(item, 'basic', false) ? 'basic' : 'extended',
          type:
            get(item, 'name') === 'patientSex'
              ? 'select'
              : get(item, 'name') === 'patientBirthDateFrom' ||
                get(item, 'name') === 'patientBirthDateTo'
              ? 'datePicker'
              : null,
          items: get(item, 'name') === 'patientSex' ? sexes : null,
        };
      });
      setFields(sortBy(fields, ['position']));
    }

    if (backFromDetail) {
      const data = getItem(FORM_DATA_KEY);
      if (data) {
        // Parse it to a javaScript object
        try {
          const values = JSON.parse(data);
          const transformedValues = {
            ...values,
            patientBirthDateFrom: values.patientBirthDateFrom
              ? new Date(values.patientBirthDateFrom)
              : null,
            patientBirthDateTo: values.patientBirthDateTo
              ? new Date(values.patientBirthDateTo)
              : null,
          };
          each(keys(transformedValues), (key: any) => {
            setValue(key, get(transformedValues, key));
          });
          triggerSubmit();
        } catch (err) {
          console.log(err);
        }
      }
      const otherData = getItem(OTHER_DATA_KEY);
      if (otherData) {
        // Parse it to a javaScript object
        try {
          const parsedOtherData = JSON.parse(otherData);
          setSearchFieldOpened(get(parsedOtherData, 'expanded', false));
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      removeItem(FORM_DATA_KEY);
      removeItem(OTHER_DATA_KEY);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callPersistForm = (value: any) => {
    putItem(FORM_DATA_KEY, JSON.stringify(value));
  };

  const putToStorage = (expandedValue: boolean) => {
    putItem(
      OTHER_DATA_KEY,
      JSON.stringify({
        expanded: expandedValue,
      }),
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
          <Box sx={{ position: 'relative', boxShadow: searchFieldOpened ? 4 : 'none' }}>
            <Box sx={{ position: 'relative', zIndex: 1300 }}>
              <Papeer bottomMargin={true}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: {
                      xs: 'wrap',
                      lg: 'nowrap',
                    },
                    paddingRight: '48px',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ position: 'absolute', right: 0, top: 8 }}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setSearchFieldOpened(!searchFieldOpened);
                        putToStorage(!searchFieldOpened);
                      }}
                    >
                      {searchFieldOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Box>

                  {filter(fields, { searchType: 'basic' }).map((field) => (
                    <Box sx={mainSx} key={field.name}>
                      {field.type === 'select' && (
                        <FormSelect
                          name={get(field, 'name')}
                          label={get(field, 'label')}
                          items={get(field, 'items')}
                          errors={errors}
                          sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        />
                      )}
                      {get(field, 'type') === 'datePicker' && (
                        <FormDatePicker
                          name={field.name}
                          label={field.label}
                          errors={errors}
                          sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        />
                      )}
                      {!get(field, 'type') && (
                        <FormInput
                          {...field}
                          errors={errors}
                          InputProps={{
                            sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                          }}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
                <Grid container={true} justifyContent="flex-end">
                  <Grid item={true} xs={12} lg={4} xl={3}>
                    <Box sx={{ mt: 1 }} display="flex" gap={1} justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="inherit"
                        type="button"
                        disabled={!canSearch}
                        onClick={() => reset()}
                      >
                        {t('reset')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!canSearch}
                        className="search-form-button"
                        data-tour="searchButtonTours"
                      >
                        {t('search')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Papeer>
            </Box>
            <Box sx={searchFieldOpened ? undefined : { display: 'none' }}>
              <Box
                sx={{
                  position: 'absolute',
                  background: (theme) => theme.palette.background.paper,
                  zIndex: 1030,
                  p: 2,
                  pt: 0,
                  left: 0,
                  right: 0,
                  top: '100%',
                  boxShadow: 4,
                  borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
                  borderBottomRightRadius: (theme) => theme.shape.borderRadius,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: {
                      xs: 'wrap',
                    },
                  }}
                >
                  {filter(fields, { searchType: 'extended' }).map((field) => (
                    <Box
                      key={field.name}
                      sx={{
                        width: { xs: '100%', md: '50%', lg: 1 / 5, xl: 1 / 7 },
                        pr: { lg: 1 },
                      }}
                    >
                      <Grid item={true} xs={12} md={4} lg={3}>
                        {field.type === 'select' && (
                          <FormSelect
                            name={get(field, 'name')}
                            label={get(field, 'label')}
                            items={get(field, 'items')}
                            errors={errors}
                          />
                        )}
                        {get(field, 'type') === 'datePicker' && (
                          <FormDatePicker name={field.name} label={field.label} errors={errors} />
                        )}
                        {!get(field, 'type') && <FormInput {...field} errors={errors} />}
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default PatientSearchForm;

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/material';
import { DataGridPro, GridCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { Papeer } from 'components/Papeer/Papeer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DicomCornestoneFileViewer } from './DicomCornestoneFileViewer';
import { IDicomGridDetailContent } from './_types';
import { DATA_GRID_DEFAULT_SORTING, DATA_GRID_ROW_HEIGHT } from 'constants/constants';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

export const DicomGridDetailContent: React.FC<IDicomGridDetailContent> = ({
  patient,
  selectedSeries,
  setSelectedSeries,
  hasChildMounted,
  groupedFilesBySeriesId,
  setSeriesDetailOpened,
}) => {
  const { t } = useTranslation('Import');
  const columns = [
    {
      field: 'serieNumber',
      headerName: 'serieNumber',
      flex: 1,
      renderCell: (params: GridCellParams) => t('dicom.serieNumber', { number: params.value }),
    },
    {
      field: 'fileCount',
      headerName: 'fileCount',
      flex: 2,
      renderCell: (params: GridCellParams) => {
        return t('dicom.numberAndDescription', {
          number: params.value,
          description: params.row.description,
        });
      },
    },
  ];

  const gridLocalization = useGridLocalization();

  const masterDetailProps = {
    getDetailPanelContent: ({ row }: GridRowParams) => <DicomCornestoneFileViewer row={row} />,
    getDetailPanelHeight: (() => 'auto') as unknown as () => number,
    slots: {
      detailPanelExpandIcon: CustomExpandIcon,
      detailPanelCollapseIcon: CustomCollapseIcon,
    },
  };

  useEffect(() => {
    setSeriesDetailOpened((prev: any) => {
      return {
        ...prev,
        [patient.id]: true,
      };
    });

    return () => {
      setSeriesDetailOpened((prev: any) => {
        return {
          ...prev,
          [patient.id]: false,
        };
      });
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!hasChildMounted.current[patient.id] && groupedFilesBySeriesId?.length > 0) {
      setSelectedSeries((prevValues) => {
        const newValues = new Map(prevValues);
        // Set an array of series objects associated with the patient.id
        newValues.set(patient.id, [...groupedFilesBySeriesId]);
        hasChildMounted.current[patient.id] = true;
        return newValues;
      });
    }
    //eslint-disable-next-line
  }, [groupedFilesBySeriesId]);

  const selectedIds = selectedSeries.has(patient.id)
    ? selectedSeries.get(patient.id).map((item: any) => item.id)
    : [];

  return (
    groupedFilesBySeriesId &&
    groupedFilesBySeriesId.length > 0 && (
      <Box marginLeft={3} marginTop={1} marginRight={3} marginBottom={1}>
        <Papeer sx={{ p: 0 }}>
          <DataGridPro
            autoHeight={true}
            sortingOrder={DATA_GRID_DEFAULT_SORTING}
            rowHeight={DATA_GRID_ROW_HEIGHT}
            rows={groupedFilesBySeriesId}
            columns={columns}
            localeText={gridLocalization}
            {...masterDetailProps}
            columnHeaderHeight={0}
            slots={{
              ...masterDetailProps.slots,
              columnHeaders: () => null,
            }}
            slotProps={{
              headerFilterCell: {
                InputComponentProps: {
                  size: 'small',
                },
              },
            }}
            hideFooter={true}
            disableRowSelectionOnClick={true}
            checkboxSelection
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={(ids) => {
              setSelectedSeries((prev) => {
                const updatedSelections = new Map(prev);

                // Get the details for the currently selected items by their ids
                const selectedItemsDetails = groupedFilesBySeriesId.filter((item: any) =>
                  ids.includes(item.id),
                );

                if (selectedItemsDetails) {
                  // Ensure we are setting an array of selected items for the patient's id
                  updatedSelections.set(patient.id, selectedItemsDetails);
                } else {
                  updatedSelections.delete(patient.id);
                }

                return updatedSelections;
              });
            }}
          />
        </Papeer>
      </Box>
    )
  );
};

const CustomExpandIcon = (props: any) => {
  return <ExpandMore {...props} />;
};

const CustomCollapseIcon = (props: any) => {
  return <ExpandLess {...props} />;
};

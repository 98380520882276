import { yupResolver } from '@hookform/resolvers/yup';
import { Image } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import useAlerts from 'components/Alerts/useAlerts';
import FormSelect from 'components/Form/Select/Select';
import FormSwitch from 'components/Form/Switch/Switch';
import Header from 'components/Header/Header';
import { Papeer } from 'components/Papeer/Papeer';
import { get, isObject } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import useValidationSchema from './_form';
import { IFrontendSettingsFormValues } from './_types';
import InputForm from 'components/Form/Input/Input';
import { Save } from '@mui/icons-material';
import { getFrontendConfigFromApi, updateFeConfig } from 'modules/Start/_api';
import { setConfigFromApi } from 'store/reducers/appReducer';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import FrontendSettingsImportUDI from './FrontendSettingsImportUDI';

export const FrontendSettings = () => {
  const { t } = useTranslation('Settings');
  useWithTitle(); // sets title to document
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();

  const { FrontendSettingsFormSchema } = useValidationSchema();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [importImageDialog, setImportImageDialog] = useState<boolean>(false);

  const methods = useForm<IFrontendSettingsFormValues>({
    resolver: yupResolver(FrontendSettingsFormSchema),
    defaultValues: {
      primaryColor: '',
      login: {
        background: '',
        logo: {
          application: {
            variant: 'light',
          },
          marie: {
            src: '',
            height: 0,
          },
          customer: {
            src: '',
            height: 0,
          },
        },
        leftBlock: {
          color: '',
        },
        footer: {
          color: '',
          hoverColor: '',
        },
      },
      udi: '',
    },
    mode: 'all',
  });

  const selectedValues = methods.watch();

  const getFeConfigFromApiAndStore = async () => {
    const config = await getFrontendConfigFromApi();
    dispatch(setConfigFromApi(get(config, 'config')));
  };

  const submitHandler = methods.handleSubmit(async (values) => {
    try {
      toggleLoader();
      await updateFeConfig(values);
      await getFeConfigFromApiAndStore();
      addSuccessAlert(t('frontendSettingsSaved'));
    } catch (e) {
      addErrorAlert(t('frontendSettingsNotSaved'));
    } finally {
      toggleLoader(false);
    }
  });

  const fetchFeConfigForEdit = async () => {
    toggleLoader();
    try {
      const feSettings = await getFrontendConfigFromApi();
      if (isObject(feSettings)) {
        methods.reset(feSettings.config);
      }
    } catch (e) {
      console.error('errorEditing');
      console.error(e);
    } finally {
      toggleLoader(false);
    }
  };

  useEffect(() => {
    fetchFeConfigForEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedTab, setSelectedTab] = useState('app');

  const toggleImportImageDialog = (state: boolean = true) => {
    setImportImageDialog(state);
  };

  return (
    <>
      <Header title={t('frontendSettings')} backUrl="/" />

      <Tabs
        value={selectedTab}
        onChange={(_e, value) => setSelectedTab(value)}
        aria-label="Frontent tabs"
      >
        <Tab label={t('application')} value="app" />
        <Tab label={t('loginScreen')} value="login" />
      </Tabs>

      <FormProvider {...methods}>
        <form onSubmit={submitHandler}>
          {/* App tab panel */}
          <Box sx={{ py: 2, display: selectedTab === 'app' ? 'block' : 'none' }}>
            <Typography variant="h6">{t('colors')}</Typography>
            <Papeer bottomMargin={true}>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={12} sm={6} lg={3} xl={3}>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        border: '2px solid #000',
                        background: selectedValues.primaryColor,
                        position: 'absolute',
                        right: 8,
                        top: 14,
                      }}
                    />
                  </Box>
                  <InputForm name="primaryColor" label={t('primaryColor')} placeholder="#XXXXXX" />
                </Grid>
                <Grid item={true} xs={12} />
                <Grid item={true} xs={12} sm={6} lg={3} xl={3}>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        border: '2px solid #000',
                        background: selectedValues.menuBackground,
                        position: 'absolute',
                        right: 8,
                        top: 14,
                      }}
                    />
                  </Box>
                  <InputForm
                    name="menuBackground"
                    label={t('menuBackground')}
                    placeholder="#XXXXXX"
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} lg={3} xl={3}>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        border: '2px solid #000',
                        background: selectedValues.menuColor,
                        position: 'absolute',
                        right: 8,
                        top: 14,
                      }}
                    />
                  </Box>
                  <InputForm name="menuColor" label={t('menuColor')} placeholder="#XXXXXX" />
                </Grid>
              </Grid>
            </Papeer>
            <Typography variant="h6">{t('logos')}</Typography>
            <Papeer bottomMargin={true}>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={12}>
                  <Typography variant="h6">{t('menuLogo')}</Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <InputForm name={`menuLogo.src`} label={t('base64')} />
                </Grid>
                <Grid item={true} xs={12} sm={6} lg={3}>
                  <InputForm
                    name={`menuLogo.width`}
                    label={t('width')}
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} lg={3}>
                  <InputForm
                    name={`menuLogo.height`}
                    label={t('height')}
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                  />
                </Grid>
                {get(selectedValues, `menuLogo.src`) ? (
                  <Grid item={true} xs={12}>
                    <Box
                      sx={{
                        background: selectedValues.menuBackground,
                        p: 2,
                        display: 'inline-flex',
                      }}
                    >
                      <img
                        src={get(selectedValues, `menuLogo.src`, '')}
                        width={get(selectedValues, `menuLogo.width`, 0)}
                        height={get(selectedValues, `menuLogo.height`, 0)}
                        alt="Logo"
                      />
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Papeer>
            <Typography variant="h6">{t('texts')}</Typography>
            <Papeer>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={12} sm={6} lg={3} xl={3}>
                  <InputForm name="mainTitle" label={t('mainTitle')} />
                </Grid>
              </Grid>
            </Papeer>
            <Typography variant="h6">UDI</Typography>
            <Papeer>
              <Grid container={true} spacing={1} alignItems="center">
                <Grid item={true} xs={12} sm={8} lg={6} xl={4}>
                  <InputForm name="udi" label={t('udiValue')} />
                </Grid>
                <Grid item={true} xs={12} sm={6} lg={3} xl={3}>
                  <Button
                    variant="contained"
                    startIcon={<Save />}
                    className="submit-form-button"
                    onClick={() => {
                      toggleImportImageDialog(true);
                    }}
                  >
                    {t('uploadUDI')}
                  </Button>
                </Grid>
              </Grid>
            </Papeer>
          </Box>

          {/* Login tab panel */}
          <Box sx={{ py: 2, display: selectedTab === 'login' ? 'block' : 'none' }}>
            <Typography variant="h6">{t('loginScreen')}</Typography>
            <Papeer>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={12}>
                  <InputForm
                    name="login.leftBlock.text"
                    label={t('text')}
                    rows={3}
                    multiline={true}
                    fullWidth={true}
                  />
                </Grid>{' '}
                <Grid item={true} xs={12} sm={6} lg={4} xl={3}>
                  <Box sx={{ position: 'relative' }}>
                    <InputForm
                      name="login.background"
                      label={t('loginPageBackgroundColor')}
                      placeholder="#XXXXXX"
                    />
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        border: '2px solid #000',
                        background: selectedValues.login.background,
                        position: 'absolute',
                        right: 8,
                        top: 14,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item={true} xs={12} sm={6} lg={4} xl={3}>
                  <Box sx={{ position: 'relative' }}>
                    <InputForm
                      name="login.leftBlock.color"
                      label={t('loginPageColor')}
                      placeholder="#XXXXXX"
                    />
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        border: '2px solid #000',
                        background: selectedValues.login.leftBlock.color,
                        position: 'absolute',
                        right: 8,
                        top: 14,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item={true} xs={12}>
                  <Box sx={{ background: selectedValues.login.background, p: 3 }}>
                    <Typography
                      component="p"
                      sx={{
                        color: selectedValues.login.leftBlock.color,
                        fontSize: {
                          xs: 20,
                          lg: 26,
                        },
                        fontWeight: 300,
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {selectedValues.login.leftBlock.text}
                    </Typography>
                    <Typography
                      component="ul"
                      sx={{
                        listStyle: 'none',
                        p: 0,
                        m: 0,
                        mt: 2,
                        mb: {
                          xs: 2,
                          lg: 0,
                        },
                        color: selectedValues.login.footer.color,
                        '& a': {
                          color: 'inherit',
                          textDecoration: 'none',
                        },
                        '& > li > a:hover, & > li > p:hover': {
                          color: `${selectedValues.login.footer.hoverColor} !important`,
                        },
                        fontSize: 16,
                        fontWeight: 300,
                      }}
                    >
                      <Box
                        component="li"
                        sx={{
                          position: 'relative',
                          mr: { xs: 0, lg: 3 },
                          display: { lg: 'inline-block' },
                        }}
                      >
                        <a href="https://orcz.cz" target="_blank" rel="noreferrer">
                          {t('footerLink')}
                        </a>
                      </Box>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Papeer>

            <Grid item={true} xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {t('footer.title')}
              </Typography>
            </Grid>
            <Papeer>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={12} sm={6} lg={4} xl={3}>
                  <Box sx={{ position: 'relative' }}>
                    <InputForm
                      name="login.footer.color"
                      label={t('footerColor')}
                      placeholder="#XXXXXX"
                    />
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        border: '2px solid #000',
                        background: selectedValues.login.footer.color,
                        position: 'absolute',
                        right: 8,
                        top: 14,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item={true} xs={12} sm={6} lg={4} xl={3}>
                  <Box sx={{ position: 'relative' }}>
                    <InputForm
                      name="login.footer.hoverColor"
                      label={t('footerHoverColor')}
                      placeholder="#XXXXXX"
                    />
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        border: '2px solid #000',
                        background: selectedValues.login.footer.hoverColor,
                        position: 'absolute',
                        right: 8,
                        top: 14,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography variant="h6" sx={{ mt: 1 }}>
                    {t('footer.links')}
                  </Typography>
                </Grid>

                <Grid item={true} xs={12} sm={12} lg={4} xl={4}>
                  <Typography variant="h6" sx={{ fontSize: 14 }}>
                    {t('footer.orCzLink')}
                  </Typography>
                  <FormSwitch name="login.footer.orCzLink.show" label={t('footer.show')} />
                  <InputForm name="login.footer.orCzLink.url" label={t('footer.link')} />
                  <InputForm name="login.footer.orCzLink.text" label={t('footer.text')} />
                </Grid>

                <Grid item={true} xs={12} sm={12} lg={4} xl={4}>
                  <Typography variant="h6" sx={{ fontSize: 14 }}>
                    {t('footer.helpdesk')}
                  </Typography>
                  <FormSwitch name="login.footer.helpdesk.show" label={t('footer.show')} />
                  <InputForm name="login.footer.helpdesk.url" label={t('footer.link')} />
                  <InputForm name="login.footer.helpdesk.text" label={t('footer.text')} />
                </Grid>

                <Grid item={true} xs={12} sm={12} lg={4} xl={4}>
                  <Typography variant="h6" sx={{ fontSize: 14 }}>
                    {t('footer.label')}
                  </Typography>
                  <FormSwitch name="login.footer.label.show" label={t('footer.show')} />
                  <FormSwitch
                    name="login.footer.label.visibleOnStartup"
                    label={t('footer.visibleOnStartup')}
                  />
                  <InputForm name="login.footer.label.text" label={t('footer.text')} />
                  <InputForm name="login.footer.label.src" label={t('base64')} />
                </Grid>

                <Grid item={true} xs={12} sm={12} lg={6} xl={4}>
                  <Typography variant="h6" sx={{ fontSize: 14 }}>
                    {t('footer.contact')}
                  </Typography>
                  <FormSwitch name="login.footer.contact.show" label={t('footer.show')} />
                  <InputForm name="login.footer.contact.text" label={t('footer.text')} />
                  {['day', 'night', 'weekend'].map((dayTime) => (
                    <>
                      <Typography sx={{ fontWeight: 'bold' }}>{t(`footer.${dayTime}`)}</Typography>
                      <Box
                        key={dayTime}
                        sx={{
                          display: 'flex',
                          flexDirection: {
                            xs: 'column',
                            md: 'row',
                          },
                        }}
                      >
                        <FormSwitch
                          name={`login.footer.contact.hours.${dayTime}.show`}
                          label={t('footer.show')}
                        />
                        {/* <Box sx={{display: {xs: "block", md: "none",}, width: "100%"}} /> */}
                        {dayTime !== 'weekend' && (
                          <InputForm
                            name={`login.footer.contact.hours.${dayTime}.hours`}
                            label={t(`footer.${dayTime}`)}
                            sx={{ mr: 1 }}
                          />
                        )}

                        <InputForm
                          name={`login.footer.contact.hours.${dayTime}.phone`}
                          label={t('footer.phone')}
                        />
                      </Box>
                    </>
                  ))}
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography variant="h6" sx={{ mt: 1 }}>
                    {t('footer.help')}
                  </Typography>
                </Grid>

                <Grid item={true} xs={12} sm={12} lg={8} xl={6}>
                  <FormSwitch name="login.helpBlock.show" label={t('footer.show')} />
                  <InputForm name="login.helpBlock.text" label={t('footer.help')} />
                </Grid>
              </Grid>
            </Papeer>

            <Typography variant="h6" sx={{ mt: 2 }}>
              {t('logos')}
              <Tooltip title={t('openExternalConverter')}>
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => window.open('https://www.base64-image.de/', '_blank')}
                >
                  <Image />
                </IconButton>
              </Tooltip>
            </Typography>
            <Papeer>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={12}>
                  <Typography variant="h6">{t('logo.application')}</Typography>
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4} lg={3}>
                  <FormSelect
                    name="login.logo.application.variant"
                    label={t('logo.logoVariant')}
                    items={[
                      {
                        id: 'portal-white',
                        label: t('logo.variant.lightPortal'),
                      },
                      { id: 'portal-black', label: t('logo.variant.darkPortal') },
                      {
                        id: 'klinicky-portal-white',
                        label: t('logo.variant.lightClinicalPortal'),
                      },
                      {
                        id: 'klinicky-portal-black',
                        label: t('logo.variant.darkClinicalPortal'),
                      },
                    ]}
                  />
                  <Box
                    sx={{
                      background: selectedValues.login.background,
                      p: 2,
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/img/${selectedValues.login.logo.application.variant}.svg`}
                      alt="Logo"
                    />
                  </Box>
                </Grid>
              </Grid>
              {['marie', 'customer'].map((logo) => (
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h6">{t(`logo.${logo}`)}</Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <InputForm name={`login.logo.${logo}.src`} label={t('base64')} />
                  </Grid>
                  <Grid item={true} xs={12} sm={6} lg={3}>
                    <InputForm
                      name={`login.logo.${logo}.height`}
                      label={t('height')}
                      type="number"
                      inputProps={{ min: 0, step: 1 }}
                    />
                  </Grid>
                  {get(selectedValues, `login.logo.${logo}.src`) ? (
                    <Grid item={true} xs={12}>
                      <Box
                        sx={{
                          background: selectedValues.login.background,
                          p: 2,
                          display: 'inline-flex',
                        }}
                      >
                        <img
                          src={get(selectedValues, `login.logo.${logo}.src`, '')}
                          height={get(selectedValues, `login.logo.${logo}.height`, 0)}
                          alt="Logo"
                        />
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              ))}
            </Papeer>
          </Box>

          <Box sx={{ my: 2, justifyContent: 'center', display: 'flex' }}>
            <Button
              type="submit"
              variant="contained"
              startIcon={<Save />}
              className="submit-form-button"
            >
              {t('Form:save')}
            </Button>
          </Box>
        </form>
      </FormProvider>
      {importImageDialog && (
        <FrontendSettingsImportUDI
          toggleImportImageDialog={toggleImportImageDialog}
          setValue={methods.setValue}
        />
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { find, get, isEmpty, omit } from 'lodash';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse, Grid, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import useValidationSchema from './_formComment';
import { editComment } from './_api';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Papeer } from 'components/Papeer/Papeer';
import FormInput from 'components/Form/Input/Input';
import FormSelect from 'components/Form/Select/Select';
import FormSwitch from 'components/Form/Switch/Switch';
import { Box } from '@mui/system';
import { IComment } from './_types';
import { Announcement } from 'components/Announcement/Announcement';
import Button from 'components/Buttons/Button';
import { CERTIFICATE_NOT_FOUND, INCORRECT_CERTIFICATE_PASSWORD } from 'constants/constants';

const StudyDetailCommentStatePriorityForm: React.FC<any> = ({
  commentItem,
  hasMailSend,
  hasToBeSigned,
  priority,
  state,
  setDialogState,

  getComments,
  certNameItems,
}) => {
  const { t } = useTranslation('StudyDetail');
  const { toggleLoader } = useAppGlobals();

  const [formError, setFormError] = useState<any>(null);

  const { CommentFormSchema } = useValidationSchema(false);
  const methods = useForm<any>({
    resolver: yupResolver(CommentFormSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    watch,
  } = methods;

  const certName = watch('certName');
  const certKey = watch('certKey');

  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const prepareValues: IComment = {
      ...omit(values, ['id', 'text', 'certName', 'certKey']),
      comment: { id: values.id || 0, text: commentItem.text },
      ...(certName
        ? { signatureContext: { createAndSignPDF: true, certId: certName, certKey } }
        : {}),
      ...(priority ? { commentPriorityCode: priority } : {}),
      ...(state ? { commentStatusCode: state } : {}),
    };

    const resp = await editComment(prepareValues);
    if (resp === true) {
      await getComments();
      setDialogState(false);
    } else if (resp === INCORRECT_CERTIFICATE_PASSWORD) {
      setFormError(t(`Comment:${INCORRECT_CERTIFICATE_PASSWORD}`));
    } else if (resp === CERTIFICATE_NOT_FOUND) {
      setFormError(t(`Comment:${CERTIFICATE_NOT_FOUND}`));
    } else if (resp === false) {
      setFormError(t('Comment:changeStateError'));
    }

    toggleLoader(false);
  });

  const getEntities = () => {
    toggleLoader();
    try {
      const defaultValues = {
        id: commentItem.id,
        sendMail: hasMailSend,
        ...(hasToBeSigned
          ? { certName: get(find(certNameItems, { favourite: true }), 'id', '') }
          : {}),
      };

      reset({
        ...defaultValues,
      });
    } catch (e) {
      console.debug(e);
    }

    toggleLoader(false);
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {commentItem ? (
        <FormProvider {...methods}>
          <form autoComplete="off" onSubmit={onSubmit}>
            <>
              <input {...register('id')} type="hidden" />
            </>

            <Grid container={true} spacing={1}>
              {hasMailSend && (
                <Grid item={true} xs={12}>
                  <Box sx={{ mt: 1 }}>
                    <FormSwitch name="sendMail" label={t('mailSend')} />
                  </Box>
                </Grid>
              )}
              {hasToBeSigned && isEmpty(certNameItems) && (
                <Grid item={true} xs={12} md={12}>
                  <Box sx={{ mt: 1, mb: 0 }}>
                    <Announcement label="" type="danger" spaced={false} component={'div'}>
                      {t('noSigned')}
                    </Announcement>
                  </Box>
                </Grid>
              )}
              {hasToBeSigned && !isEmpty(certNameItems) && (
                <>
                  <Grid item={true} xs={12} md={6}>
                    <FormSelect
                      name="certName"
                      label={t('certName')}
                      items={certNameItems}
                      required={true}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <FormInput
                      name="certKey"
                      label={t('certKey')}
                      type="password"
                      required={true}
                      inputProps={{
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item={true} xs={12} md={12} lg={12} xl={12}>
                <Collapse in={errors ? true : false} timeout="auto">
                  {errors && (
                    <Typography align="center" color="error">
                      {formError}
                    </Typography>
                  )}
                </Collapse>
              </Grid>
              <Grid item={true} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    displayPrint: 'none',
                  }}
                >
                  <Button
                    onClick={() => setDialogState(false)}
                    variant="contained"
                    color="inherit"
                    sx={{ mr: 1 }}
                  >
                    {t('close')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      hasToBeSigned &&
                      !isEmpty(certNameItems) &&
                      (isEmpty(certName) || isEmpty(certKey))
                    }
                  >
                    <SaveIcon sx={{ mr: 1 }} />
                    {t('confirm')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      ) : (
        <Papeer>
          <Typography>{t('commentNotFound')}</Typography>
        </Papeer>
      )}
    </>
  );
};

export default StudyDetailCommentStatePriorityForm;

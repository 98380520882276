import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { compact, filter, find, get, isEmpty, omit, uniq } from 'lodash';
import { Box } from '@mui/system';
import { CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { Report } from '@mui/icons-material';

import { IReportDialog, IStudiesExportDetail, IStudiesExportForm } from './_types';
import useValidationSchema from './_form';
import { exportStudy, getAllStudyExportTypes, getSize } from './_api';

import RequestAdditionalForm from '../Form/RequestAdditionalForm';
import AnonymizationForm from '../Anonymization/AnonymizationForm';

import Header from 'components/Header/Header';
import FormSelect from 'components/Form/Select/Select';
import FormSwitch from 'components/Form/Switch/Switch';
import useAlerts from 'components/Alerts/useAlerts';
import { Papeer } from 'components/Papeer/Papeer';
import { useSeriesViewer } from 'components/SeriesViewer/useSeriesViewer';
import { SeriesViewer } from 'components/SeriesViewer/SeriesViewer';

import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';
import { useStudyInfo } from 'utils/hooks/useStudyInfo';
import { formatAnonymizedValues } from 'utils/anonymization';
import StudiesExportConfirmDialog from './StudiesExportConfirmDialog';
import { getProductByType } from 'modules/Administration/Products/_api';
import { decodeIID } from 'utils/study';
import { getReport } from '../StudyDetail/_api';
import StudiesExportReportDialog from './StudiesExportReportDialog';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import Button from 'components/Buttons/Button';
import { TourExport } from './TourExport';

const StudiesExportDetail: React.FC<IStudiesExportDetail> = () => {
  const { t } = useTranslation('Studies');
  useWithTitle(); // sets title to document
  const { toggleLoader } = useAppGlobals();
  const { hasRole, user } = useUser();

  let [searchParams] = useSearchParams();
  const detailIID = searchParams.get('backTo') || null;
  const linkBack = detailIID ? `/study/${detailIID}` : '/studies';

  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { exportStore } = useStudyInfo();
  const studiesFromStore = exportStore;

  const [exportTypes, setExportTypes] = useState<any[]>([]);
  const [exportType, setExportType] = useState<any>({ viewerSize: 0 });
  const [maxSize, setMaxSize] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isMorePatientIds, setIsMorePatientIds] = useState<boolean>(false);
  const [isMissingReport, setIsMissingReport] = useState<boolean>(false);
  const [isCalculationError, setIsCalculationError] = useState<boolean>(false);
  const [sizesLoaded, setSizesLoaded] = useState<any[]>([]);
  const [studySizes, setStudySizes] = useState<any[]>([]);
  const [reports, setReports] = useState<any[]>([]);
  const [reportDialog, setReportDialog] = useState<IReportDialog>({ opened: false, iid: null });
  const [actualSize, setActualSize] = useState<any>(null);
  const [allSize, setAllSize] = useState<number>(0);
  const [sizeEvaluation, setSizeEvaluation] = useState<boolean>(false);

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [initVal, setInitVal] = useState<any>(null);
  const [anonymizationProfile, setAnonymizationProfile] = useState<any>({});
  const [anonymizationProfiles, setAnonymizationProfiles] = useState<any[]>([]);
  const anonymization = '0';
  const [fullSelectedStudies, setFullSelectedStudies] = useState<any>(false);
  const [numberOfSelectedStudies, setNumberOfSelectedStudies] = useState<number>(0);
  const [anonymizationFields, setAnonymizationFields] = useState<any[]>([]);

  const navigate = useNavigate();

  const { studiesForViewer, setStudiesForViewer, operations, somethingSelected } =
    useSeriesViewer();

  const { StudiesExportFormSchema } = useValidationSchema(anonymizationFields);

  // const { exportDetailStepsDefinition } = useExportSteps();
  // const { tourControlProps } = useReactTour({
  //   stepsDefinition: exportDetailStepsDefinition,
  // });

  const renderedSteps = () => {
    return <TourExport type="detail" />;
  };

  const prepareInitVal = (initVal: any) => {
    const prepareInitialValues = {
      ...(initVal
        ? {
            ...initVal,
            ...get(initVal, 'anonymized', {}),
            requestComment: get(watchAllFields, 'requestComment', ''),
          }
        : {
            ...watchAllFields,
            ...{
              anonymized_lastName: '',
              anonymized_firstName: '',
              anonymized_middleName: '',
              anonymized_prefix: '',
              anonymized_suffix: '',
              anonymized_PatientID: '',
            },
          }),

      requestingUser: {
        name: {
          lastName: get(user, 'last_name'),
          firstName: get(user, 'first_name'),
        },
      },
    };
    return prepareInitialValues;
  };
  const callReset = (initVal: any) => {
    reset(initVal);
  };

  const methods = useForm<IStudiesExportForm>({
    resolver: yupResolver(StudiesExportFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit, reset, watch, register, setValue, trigger } = methods;

  const watchAllFields = watch();

  const anonymizationWatch = get(watchAllFields, 'anonymization', '0');

  const selectedExportType = get(watchAllFields, 'type', null);

  const handleFormSubmit = async (formValues: any) => {
    toggleLoader();
    const requestComment = get(formValues, 'requestComment', null) || null;

    const preparedStudies = compact(filter(fullSelectedStudies, { studyIsSelected: true })).map(
      (study: any) => ({
        ...study,
        listOfSeries: compact(study.listOfSeries).map((serie: any) => ({
          ...serie,
          image: compact(filter(serie.image, { imageIsSelected: true })),
        })),
      }),
    );

    let values = {
      ...omit(formValues, ['annotations']),
      studies: preparedStudies,
      requestComment,
      ...(selectedExportType === 'PICTURE'
        ? { annotations: get(formValues, 'annotations', false) }
        : {}),
    };

    await exportStudy(values).then(
      (response) => {
        addSuccessAlert(t('successfullyExport'));
        navigate(linkBack);
      },
      (error) => {
        let tError = 'errorExport';
        if (
          get(error, 'response.data', '') === 'can.not.export.as.video.number.of.frames.is.to.low'
        ) {
          tError = 'errorBadImage';
        }
        addErrorAlert(t(tError));
      },
    );
    toggleLoader(false);
  };

  const callHandleSubmit = async (values: any) => {
    const preparedValues = formatAnonymizedValues(values, anonymizationProfile);
    handleFormSubmit(preparedValues);
  };

  const onSubmit = handleSubmit(async (values: IStudiesExportForm) => {
    callHandleSubmit(values);
  });

  const getInitialValues = (studies: any) => {
    let anonymization = '0';
    const initVal = {
      studies,
      anonymization,
    };
    return initVal;
  };

  const openReportDialog = (studyIID: string) => {
    setReportDialog(
      studyIID ? { opened: true, iid: studyIID } : { ...reportDialog, opened: false },
    );
  };
  const sizeLoad = (sizesLoaded: any[], studyID: string) => {
    const addSizesLoaded = [...sizesLoaded, studyID];
    setSizesLoaded(addSizesLoaded);
    return addSizesLoaded;
  };
  const saveSize = (studySizes: any[], data: any) => {
    const addStudySizes = [...studySizes, data];
    setStudySizes(addStudySizes);
    return addStudySizes;
  };
  const saveReport = (reports: any[], data: any) => {
    const addReports = [...reports, data];
    setReports(addReports);
    return addReports;
  };

  const loadStart = async () => {
    toggleLoader();
    const [allExportTypes, productsByType] = await Promise.all([
      getAllStudyExportTypes(),
      getProductByType('portal'),
    ]);
    const product = find(productsByType, (item) => {
      return item.active;
    });
    const studyExportShowStudyReportSettings = find(get(product, 'systemSetting', []), {
      variable: 'studyExportShowStudyReport',
    });
    const studyExportShowStudyReport =
      get(studyExportShowStudyReportSettings, 'value', false) === 'true';
    if (allExportTypes) {
      const exportTypes = allExportTypes.map((type: any) => {
        if (
          (get(type, 'name', '') === 'DICOM' && !hasRole('ROLE_EXPORT_DICOM')) ||
          (get(type, 'name', '') === 'IMG_CD' && !hasRole('ROLE_EXPORT_CD')) ||
          (get(type, 'name', '') === 'IMG_DVD' && !hasRole('ROLE_EXPORT_DVD')) ||
          (get(type, 'name', '') === 'PICTURE' && !hasRole('ROLE_EXPORT_PICTURE')) ||
          (get(type, 'name', '') === 'VIDEO' && !hasRole('ROLE_EXPORT_VIDEO'))
        ) {
          return null;
        }
        return type;
      });
      setExportTypes(compact(exportTypes));
    }
    const isDefault = find(allExportTypes, { default: true });
    if (isDefault) {
      setValue('type', get(isDefault, 'name', null));
    }

    let studySizes: any[] = [];
    let sizesLoaded: any[] = [];
    let reports: any[] = [];
    studiesForViewer.forEach(async (study: any) => {
      const { studyID, archiveID } = decodeIID(study.iid);
      await getSize(parseInt(archiveID, 10), studyID).then((resp: any) => {
        if (resp) {
          sizesLoaded = sizeLoad(sizesLoaded, studyID);
          studySizes = saveSize(studySizes, { ...resp, iid: study.iid, status: true });
        } else {
          sizesLoaded = sizeLoad(sizesLoaded, studyID);
          studySizes = saveSize(studySizes, { iid: study.iid, status: false });
          setIsCalculationError(true);
        }
      });

      if (studyExportShowStudyReport) {
        await getReport(parseInt(archiveID, 10), studyID).then((resp: any) => {
          if (resp) {
            reports = saveReport(reports, { ...resp, iid: study.iid, status: true });
          } else {
            reports = saveReport(reports, { iid: study.iid, status: false });
          }
        });
      }
    });
    toggleLoader(false);
  };

  useEffect(() => {
    const studies: any[] = studiesFromStore;
    if (studies.length < 1) {
      return navigate(linkBack);
    }
    setStudiesForViewer(
      studies.map((study: any) => ({
        ...study,
        studyIsSelected: true,
        studyIsOpened: false,
        loadedSeries: [],
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(studiesForViewer)) {
      loadStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studiesForViewer.length]);

  useEffect(() => {
    callReset(prepareInitVal(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymizationWatch]);

  useEffect(() => {
    setValue('useCompression', null);
    setValue('includeStudyReport', null);
    setValue('quality', null);
    setValue('format', null);
    setValue('framerate', null);
    setValue('annotations', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExportType]);

  useEffect(() => {
    if (exportTypes.length > 0) {
      const exportType = find(exportTypes, { name: selectedExportType });
      if (get(exportType, 'quality', false)) {
        setValue('quality', 'MEDIUM');
      }
      if (get(exportType, 'studyExportFramerate', false)) {
        setValue('framerate', get(get(exportType, 'studyExportFramerate').split('/'), '[0]', null));
      }
      if (get(exportType, 'studyExportFormat', false)) {
        setValue('format', get(get(exportType, 'studyExportFormat').split('/'), '[0]', null));
      }
      setExportType(exportType || { viewerSize: 0 });
      setMaxSize(get(exportType, 'size', 0) / 1024 / 1024);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportTypes.length, selectedExportType]);

  useEffect(() => {
    const fullSelectedStudies = compact(
      studiesForViewer.map((study: any) => {
        return {
          ...study,
          listOfSeries: compact(
            study.loadedSeries.map((serie: any) => {
              if (!serie.serieIsSelected) {
                return false;
              }
              return serie;
            }),
          ),
        };
      }),
    );
    const compress = get(watchAllFields, 'useCompression', false);
    const viewerSize = get(exportType, 'viewerSize', 0);
    const allId = 'all';
    const size: any = {};
    size[allId] = 0;
    const error = 'isError';
    let isError = false;
    fullSelectedStudies.forEach((study) => {
      if (studySizes.length) {
        const loadedSeriesForStudy = get(study, 'studyIsOpened', false);
        const studyIid = get(study, 'iid', '');
        const sizeForStudy = get(
          find(studySizes, { iid: get(study, 'iid', false) }),
          'status',
          'loading',
        );
        // pokud došlo k chybě při načítání velikosti pro danou studii
        if (!sizeForStudy) {
          size[studyIid] = 'calculationError';
          isError = true;
          // ve stavu kdy probíhá načítání velikosti pro danou studii
        } else if (sizeForStudy === 'loading') {
          size[studyIid] = t('loadingValue');
          // nejsou načteny serie pro danou studii
        } else if (!loadedSeriesForStudy) {
          if (get(study, 'studyIsSelected')) {
            size[studyIid] = get(
              find(studySizes, { iid: get(study, 'iid', false) }),
              compress ? 'sizeCompressed' : 'sizeOriginal',
              0,
            );
            size[allId] += size[studyIid];
            size[studyIid] = `${Math.ceil(size[studyIid] / 1024 / 1024).toFixed(1)} MB`;
          }
        } else {
          size[studyIid] = 0;
          const seriesSize = get(find(studySizes, { iid: get(study, 'iid', false) }), 'series', []);
          (get(study, 'listOfSeries', null) || []).forEach((serie: any) => {
            const imagesSize = get(
              find(seriesSize, { seriesUID: get(serie, 'uid', false) }),
              'images',
              [],
            );
            get(serie, 'image', []).forEach((image: any) => {
              const imageSize = get(image, 'imageIsSelected', false)
                ? get(
                    find(imagesSize, { instanceUID: get(image, 'sopinstanceUid', false) }),
                    compress ? 'sizeCompressed' : 'sizeOriginal',
                    0,
                  )
                : 0;
              size[studyIid] += imageSize;
            });
          });
          size[allId] += size[studyIid];
          size[studyIid] = `${Math.ceil(size[studyIid] / 1024 / 1024).toFixed(1)} MB`;
        }
      }
    });
    if (isError) {
      size[allId] = 0;
    } else {
      size[allId] = Math.ceil((size[allId] + viewerSize) / 1024 / 1024).toFixed(1);
    }
    size[error] = isError;
    setAllSize(size[allId]);
    setActualSize(size);

    // setCount
    setNumberOfSelectedStudies(fullSelectedStudies.length);
    setFullSelectedStudies(fullSelectedStudies);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studySizes.length, somethingSelected, exportType.viewerSize]);

  useEffect(() => {
    setIsMissingReport(reports.length !== numberOfSelectedStudies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports.length, numberOfSelectedStudies]);

  useEffect(() => {
    const value =
      sizesLoaded.length === studiesForViewer.length &&
      ((allSize * 1 < maxSize && selectedExportType) || selectedExportType === null);
    const canSubmit: boolean = value && somethingSelected > 0 && selectedExportType ? true : false;
    setCanSubmit(canSubmit);
    setSizeEvaluation(sizesLoaded.length === studiesForViewer.length ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sizesLoaded.length,
    studiesForViewer.length,
    selectedExportType,
    somethingSelected,
    allSize,
    maxSize,
  ]);

  useEffect(() => {
    if (anonymizationProfiles && !isEmpty(studiesForViewer)) {
      if (studiesForViewer.length > 0) {
        const initialValues = prepareInitVal(getInitialValues(studiesForViewer));
        setInitVal(initialValues);
        callReset(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymizationProfiles.length, studiesForViewer.length]);

  useEffect(() => {
    const isMorePatientIds =
      uniq(
        compact(
          studiesForViewer.map((study: any) => get(study, 'patient.identificationNumber', null)),
        ),
      ).length > 1;
    setIsMorePatientIds(isMorePatientIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studiesForViewer.length]);

  const handleClickOpen = async () => {
    const result = await trigger();
    if (result) {
      // setConfirmDialog(true);
      if (
        isCalculationError ||
        isMorePatientIds ||
        (isMissingReport && get(exportType, 'report', false))
      ) {
        setOpenDialog(true);
      } else {
        callHandleSubmit(watchAllFields);
      }
    }
  };

  const sendingConfirmed = () => {
    setOpenDialog(false);
    callHandleSubmit(watchAllFields);
  };

  return (
    <>
      <Header
        title={t('studyExportTitle')}
        backUrl={`${linkBack}?action=back`}
        TourComponent={renderedSteps}
      />
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <input {...register('studies')} type="hidden" />
          <Papeer bottomMargin={true}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={6} lg={3} data-tour="studyExportType">
                <FormSelect
                  name="type"
                  label={t('exportType')}
                  items={exportTypes.map((type: any) => ({
                    label: get(type, 'name', ''),
                    id: get(type, 'name', ''),
                  }))}
                  required={true}
                />
              </Grid>
              {get(exportType, 'studyExportFormat', false) && (
                <Grid item={true} xs={12} md={6} lg={3}>
                  <FormSelect
                    name="format"
                    label={t('studyExportFormat')}
                    items={get(exportType, 'studyExportFormat')
                      .split('/')
                      .map((type: any) => ({
                        label: type,
                        id: type,
                      }))}
                    required={true}
                  />
                </Grid>
              )}
              {get(exportType, 'quality', false) && (
                <Grid item={true} xs={12} md={6} lg={3}>
                  <FormSelect
                    name="quality"
                    label={t('quality')}
                    items={get(exportType, 'quality')
                      .split('/')
                      .map((type: any) => ({
                        label: t(type),
                        id: type,
                      }))}
                    required={true}
                  />
                </Grid>
              )}
              {get(exportType, 'studyExportFramerate', false) && (
                <Grid item={true} xs={12} md={6} lg={3}>
                  <FormSelect
                    name="framerate"
                    label={t('framerate')}
                    items={get(exportType, 'studyExportFramerate')
                      .split('/')
                      .map((type: any) => ({
                        label: type,
                        id: type,
                      }))}
                    required={true}
                  />
                </Grid>
              )}

              {hasRole('ROLE_ANONYMIZATION_IN_OPERATION') && (
                <AnonymizationForm
                  anonymizationWatch={anonymizationWatch}
                  setAnonymizationProfile={setAnonymizationProfile}
                  setAnonymizationProfiles={setAnonymizationProfiles}
                  anonymization={anonymization}
                  fields={anonymizationFields}
                  setFields={setAnonymizationFields}
                  disabled={false}
                />
              )}
            </Grid>
            {get(exportType, 'report', false) && (
              <Grid item={true} xs={12}>
                <FormSwitch name="includeStudyReport" label={t('report')} />
              </Grid>
            )}
            {selectedExportType === 'PICTURE' && (
              <Grid item={true} xs={12}>
                <FormSwitch name="annotations" label={t('annotations')} />
              </Grid>
            )}
          </Papeer>
          <Papeer sx={{ mt: 2 }}>
            <Box data-tour="studyExportListOfStudy">
              <SeriesViewer
                studies={studiesForViewer}
                showStudiesCheckboxes={true}
                showSeriesCheckboxes={true}
                showInstancesCheckboxes={true}
                allowOpenSeries={true}
                operations={operations}
                actualSize={actualSize}
              />
              <Box sx={{ mt: 2 }}>
                {`${t('totalSize')}: `}
                {sizeEvaluation && (
                  <Tooltip title={t('loadingValue')} placement="right">
                    <CircularProgress size={16} />
                  </Tooltip>
                )}

                {get(actualSize, 'isError', false)
                  ? t('calculationError')
                  : get(actualSize, 'all', '0') + ' MB'}
                {maxSize && parseFloat(get(actualSize, 'all', 0)) > maxSize ? (
                  <Tooltip title={t('maxSizeExceeded')} placement="right">
                    <IconButton aria-label="Report">
                      <Report style={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Box>
              <Box>{maxSize > 0 ? `${t('maxSize')}: ${maxSize.toFixed(1)} MB` : null}</Box>
              <StudiesExportReportDialog
                dialogState={reportDialog}
                setDialogState={openReportDialog}
                reports={reports}
              />
            </Box>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <RequestAdditionalForm isRequest={false} initialValues={initVal} />
              </Grid>
            </Grid>
            <Grid container={true} justifyContent="flex-end">
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                  disabled={!canSubmit}
                  data-selenium-selector="export-submit-button"
                  data-tour="studyExportExportButton"
                >
                  {t('export')}
                </Button>
              </Box>
            </Grid>
          </Papeer>
        </form>
      </FormProvider>

      <StudiesExportConfirmDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        sendingConfirmed={sendingConfirmed}
        isMorePatientIds={isMorePatientIds}
        isMissingReport={isMissingReport}
        isCalculationError={isCalculationError}
      />
    </>
  );
};

export default StudiesExportDetail;

import React, { useEffect, useState } from 'react';
import { get, set, find, isArray, constant, times } from 'lodash';
import { Box } from '@mui/material';
import { IDocumentDetailGrids } from './_types';
import { getAllGroups } from 'modules/Tags/_api';
import { DocumentDetail } from './DocumentDetail';

export const DocumentDetailGrids: React.FC<IDocumentDetailGrids> = ({
  gridLayout,
  documentUids,
  selectDocumentUuid,
  unselectDocumentUuid,
  patientDocuments,
  setIsPrinting,
  setStandardizedPrint,
  setIsExporting,
  requestedDocuments,
  onlyAddTag = false,
  gridLayoutParams,
  replaceDocumentAtIndex,
  setIsDownloadingFromEldax,
  facilities,
}) => {
  const [activeRegisterGroups, setActiveRegisterGroups] = useState<any[]>([]);
  const [gridLayoutUids, setGridLayoutUids] = useState<any>({});
  // const [grids, setGrids] = useState<any[]>([]);
  // const [selectedDocumentUuid, setSelectedDocumentUuid] = useState<string>('');

  // const documentUidsJsonString = JSON.stringify(documentUids);
  // const gridLayoutUidsJsonString = JSON.stringify(gridLayoutUids);

  // const generateGridLayoutUids = (uuids: any[]) => {
  //   const newGridLayoutUuids = {};

  //   if (uuids.length === 0) {
  //     const dimesions = gridLayout.split('x');
  //     if (dimesions.length === 2) {
  //       const x = Number(dimesions[0]);
  //       const y = Number(dimesions[1]);

  //       for (let i = 0; i < x; i++) {
  //         for (let ii = 0; ii < y; ii++) {
  //           set(newGridLayoutUuids, `${i}-${ii}`, null);
  //         }
  //       }
  //     }
  //   } else {
  //     forOwn(gridLayoutUids, (value, key) => {
  //       set(
  //         newGridLayoutUuids,
  //         key,
  //         find(uuids, (uuid) => {
  //           return uuid === value;
  //         }),
  //       );
  //     });
  //   }
  //   setGridLayoutUids(newGridLayoutUuids);
  // };

  // const getDocumentUuid = (documentUids: any, row: number, column: number, gridLayoutUids: any) => {
  //   const possibleUuid = get(gridLayoutUids, `${row}-${column}`);
  //   if (possibleUuid !== undefined && possibleUuid !== null) {
  //     return possibleUuid;
  //   }
  //   const reducedDocumentUids = [...documentUids];
  //   const usedUuids: any[] = [];
  //   keys(gridLayoutUids).forEach((key) => {
  //     usedUuids.push(get(gridLayoutUids, key));
  //   });
  //   pullAll(reducedDocumentUids, usedUuids);
  //   if (reducedDocumentUids.length > 0) {
  //     return head(reducedDocumentUids);
  //   }
  //   return undefined;
  // };

  const setDocumentToGridPosition = (oldUuid: any, newUuid: any, itemKey: any) => {
    const newGridLayoutUids = { ...gridLayoutUids };
    set(newGridLayoutUids, itemKey, newUuid);
    setGridLayoutUids(newGridLayoutUids);
    replaceDocumentAtIndex(newUuid, itemKey);

    // console.debug({ oldUuid, newUuid, itemKey });

    // unselectDocumentUuid(oldUuid);
    // setSelectedDocumentUuid(newUuid);
  };

  // useEffect(() => {
  //   if (selectedDocumentUuid) {
  //     selectDocumentUuid(selectedDocumentUuid);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedDocumentUuid]);

  // const generateGrids = (gridLayout: string) => {
  //   const newGridLayoutUids = { ...gridLayoutUids };
  //   const newGrids = [];
  //   const dimesions = gridLayout.split('x');
  //   if (dimesions.length === 2) {
  //     const x = Number(dimesions[0]);
  //     const y = Number(dimesions[1]);
  //     for (let i = 0; i < x; i++) {
  //       const items = [];
  //       for (let ii = 0; ii < y; ii++) {
  //         const key = `${i}-${ii}`;
  //         let patientDocument;

  //         const documentUiid = getDocumentUuid(documentUids, i, ii, newGridLayoutUids);

  //         if (documentUiid !== undefined) {
  //           patientDocument = find(patientDocuments, { uuid: documentUiid });
  //           set(newGridLayoutUids, `${i}-${ii}`, documentUiid);
  //         }

  //         items.push({
  //           xs: 6,
  //           md: 12 / y,
  //           lg: 12 / y,
  //           key,
  //           patientDocument,
  //         });
  //       }
  //       newGrids.push({ spacing: 2, key: `${i}`, items });
  //     }
  //   }
  //   setGridLayoutUids(newGridLayoutUids);
  //   setGrids(newGrids);
  // };

  const loadDocument = async () => {
    const activeRegisterGroups = await getAllGroups(true);
    if (isArray(activeRegisterGroups)) {
      setActiveRegisterGroups(activeRegisterGroups);
    }
  };
  useEffect(() => {
    loadDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   generateGridLayoutUids([]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [gridLayout]);

  // useEffect(() => {
  //   generateGridLayoutUids(documentUids);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [documentUidsJsonString]);

  // useEffect(() => {
  //   // generateGrids(gridLayout);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [gridLayoutUidsJsonString]);

  const gridFields = times(16, constant(null));

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${gridLayoutParams.y}, minmax(0, 1fr))`,
          // gridTemplateRows: `repeat(${gridLayoutParams.x}, 1fr)`,
          gridTemplateRows: `auto auto 1fr`,
          gridColumnGap: '16px',
          gridRowGap: '16px',
        }}
      >
        {gridFields.map((gridField, index) => {
          const uuid = documentUids[index];
          const patientDocument = find(patientDocuments, { uuid });
          const selectingDocumentDisabled = index > gridLayoutParams.lastDocumentPositionIndex + 1;
          return index >= gridLayoutParams.totalAvailableFields ? null : (
            <Box
              sx={{
                display: 'flex',
              }}
              key={uuid}
            >
              <DocumentDetail
                patientDocument={patientDocument || {}}
                patientDocumentUuid={uuid}
                masterPatientID={get(patientDocument, 'patientEntity.masterPatientID', null)}
                unselectDocumentUuid={unselectDocumentUuid}
                documentUids={documentUids}
                patientDocuments={patientDocuments}
                setDocumentToGridPosition={setDocumentToGridPosition}
                itemKey={index}
                onlyAddTag={onlyAddTag}
                setIsPrinting={setIsPrinting}
                setStandardizedPrint={setStandardizedPrint}
                setIsExporting={setIsExporting}
                requestedDocuments={requestedDocuments}
                activeRegisterGroups={activeRegisterGroups}
                setActiveRegisterGroups={setActiveRegisterGroups}
                gridLayoutParams={gridLayoutParams}
                selectingDocumentDisabled={selectingDocumentDisabled}
                setIsDownloadingFromEldax={setIsDownloadingFromEldax}
                facilities={facilities}
              />
            </Box>
          );
        })}
      </Box>

      {/*grids != null && grids.length > 0 && (
        <>
          <Typography component="div" style={{ marginTop: 8 }}>
            {grids.map((grid) => {
              return (
                <Grid container={true} spacing={get(grid, 'spacing', 2)} key={get(grid, 'key')}>
                  {get(grid, 'items', []).length > 0 &&
                    get(grid, 'items', []).map((item: any) => {
                      return (
                        <Grid
                          item={true}
                          xs={get(item, 'xs', 12)}
                          md={get(item, 'md', 12)}
                          lg={get(item, 'lg', 12)}
                          key={get(item, 'key')}
                        >
                          <DocumentDetail
                            patientDocument={get(item, 'patientDocument', {})}
                            patientDocumentUuid={get(item, 'patientDocument.uuid', '')}
                            masterPatientID={get(
                              item,
                              'patientDocument.patientEntity.masterPatientID',
                              null,
                            )}
                            unselectDocumentUuid={unselectDocumentUuid}
                            documentUids={documentUids}
                            patientDocuments={patientDocuments}
                            setDocumentToGridPosition={setDocumentToGridPosition}
                            itemKey={get(item, 'key')}
                            onlyAddTag={onlyAddTag}
                            setIsPrinting={setIsPrinting}
                            setStandardizedPrint={setStandardizedPrint}
                            setIsExporting={setIsExporting}
                            requestedDocuments={requestedDocuments}
                            activeRegisterGroups={activeRegisterGroups}
                            setActiveRegisterGroups={setActiveRegisterGroups}
                            gridLayoutParams={gridLayoutParams}
                            selectingDocumentDisabled={false}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              );
            })}
          </Typography>
        </>
          )*/}
    </>
  );
};

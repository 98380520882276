import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isArray } from 'lodash';
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { deleteCertificate, getAllCertificates } from './_api';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useWithTitle } from 'utils/hooks/useWithTitle';
import { useMuiGrid } from 'utils/hooks/useMuiGrid';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { useEntityRemove } from 'utils/hooks/useEntityRemove';
import Header from 'components/Header/Header';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { MuiGrid } from 'components/MuiGrid/MuiGrid';
import { useAppDispatch } from 'store/hooks';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';
import { ICertificate } from './_types';
import CertificatesComponent from './CertificatesComponent';

const muiGridKey = 'certificatesMUI';

export const Certificates: React.FC = () => {
  const { t } = useTranslation('Certificates');
  useWithTitle();
  const { toggleLoader } = useAppGlobals();
  const dispatch = useAppDispatch();
  const { confirmationData } = useAppInfo();
  const navigate = useNavigate();

  const [certificates, setCertificates] = useState<any[]>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);

  const clearAction = () => {
    dispatch(storeConfirmationDataToStore(null));
  };

  const onCustomEntityDetail = (row: any) => {
    const editLink = `product/${get(row, 'id')}${
      get(row, 'backToByAction', '')
        ? '?from=' + get(row, 'backToByAction', '')
        : get(row, 'userDirectoryId', '')
        ? `?userDirectoryId=${get(row, 'userDirectoryId', '')}&parCode=${get(row, 'parCode', '')}`
        : ''
    }`;
    navigate(editLink);
  };

  const { injectColumnWidthsIntoColumns, reorderColumnsByGridSettings } = useMuiGrid(muiGridKey);

  const columns = reorderColumnsByGridSettings(
    injectColumnWidthsIntoColumns([
      {
        field: 'actions',
        headerName: t('Grid:actions'),
        type: 'actions',
        hideable: false,
        width: 220,
        renderCell: ({ row }: GridRenderCellParams) => {
          return (
            <>
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                label={t('Grid:edit')}
                onClick={() => onCustomEntityDetail(row)}
              />
              <GridActionsCellItem
                icon={
                  <Tooltip title={t('Grid:delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                label={t('Grid:delete')}
                onClick={() => {
                  dispatch(storeConfirmationDataToStore({ id: get(row, 'id') }));
                }}
              />
            </>
          );
        },
      },
      { field: 'lastName', headerName: t('Users:lastname') },
      { field: 'firstName', headerName: t('Users:firstname') },
    ]),
  );

  const loadEntity = async () => {
    toggleLoader();
    try {
      const certificates = await getAllCertificates();
      if (certificates && isArray(certificates)) {
        setCertificates(certificates);
      } else {
        setCertificates([]);
      }
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    loadEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onEntityRemove } = useEntityRemove(deleteCertificate, t, loadEntity);

  const confirmAction = async () => {
    await onEntityRemove(get(confirmationData, 'id'));
    clearAction();
  };

  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds);
  }, []);

  const DetailPanel = ({ row }: GridRowParams) => {
    const userCertificates: ICertificate[] = get(row, 'certificates', []);
    const username: string = get(row, 'username', '');
    return <CertificatesComponent userCertificates={userCertificates} username={username} />;
  };

  return (
    <>
      <Header title="" addUrl="/administration/settings/product/create" />
      <MuiGrid
        gridKey={muiGridKey}
        rows={certificates}
        columns={columns}
        initialSortMode={[{ field: 'lastName', sort: 'asc' }]}
        rowDetail={{
          showRowDetail: true,
          DetailPanel,
          getDetailPanelHeight: () => 'auto',
          detailPanelExpandedRowIds,
          handleDetailPanelExpandedRowIdsChange,
        }}
      />
      {confirmationData && confirmationData.id && !confirmationData.editDialog && (
        <ConfirmationDialog
          title={confirmationData.title ? t(confirmationData.title) : t('Grid:confirmDelete')}
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          cancelAction={clearAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { filter, find, get, isArray, isEmpty, isFunction, omit } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { createBookmark, getAllColumns, getBookmark, updateBookmark } from './_api';
import { IBookmarkDefaultColumn, IBookmarkForm } from './_types';
import useValidationSchema from './_form';

import { Papeer } from 'components/Papeer/Papeer';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import Header from 'components/Header/Header';
import useAlerts from 'components/Alerts/useAlerts';
import { EntityButtons } from 'components/Form/EntityButtons/EntityButtons';
import FormInput from 'components/Form/Input/Input';

import { useWithEntityTitle } from 'utils/hooks/useWithEntityTitle';
import { useEntityInfo } from 'utils/hooks/useEntityInfo';

import { Line } from 'components/Line/Line';
import { SimpleCheckboxArray } from 'components/Form/SimpleCheckboxArray/SimpleCheckboxArray';

import { ILanguage } from '../GeneralSettings/_types';
import { getAllLanguages } from '../GeneralSettings/_apiLanguages';
import { getAllWorkplaces } from '../Workplaces/_api';
import { useLanguage } from 'utils/hooks/useLanguage';
import { storeConfirmationDataToStore } from 'store/reducers/appReducer';

export const BookmarkForm: React.FC<any> = ({
  bookmarkId,
  toggleDialog,
  loadEntity,
  typesOfDocument,
  dispatch,
}) => {
  const { t } = useTranslation('ClinicalPortalSettings');
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { currentLocale } = useLanguage();
  const { id } = useEntityInfo();
  const [entity, fetchEntity] = useState<IBookmarkForm>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [languages, setLanguages] = useState<any[]>([]);
  const [workplaces, fetchWorkplaces] = useState<any[]>([]);
  const [allColumns, fetchAllColumns] = useState<IBookmarkDefaultColumn[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<IBookmarkDefaultColumn[]>([]);

  const { title } = useWithEntityTitle(isCreating, entity, t, 'createBookmark', 'editBookmark');
  const { BookmarkFormSchema } = useValidationSchema(t, languages);

  const methods = useForm<any>({
    resolver: yupResolver(BookmarkFormSchema),
  });
  const { handleSubmit, reset, register } = methods;

  const { toggleLoader } = useAppGlobals();

  const clearAction = () => {
    toggleDialog(false);
    if (isFunction(dispatch)) {
      dispatch(storeConfirmationDataToStore(null));
    }
  };

  const prepareValues = (values: any) => {
    let name = {};
    const aKey: any[] = ['allColumns'];
    (languages || []).forEach((lang: any) => {
      const abbr = get(lang, 'abbreviation');
      const key = `name_${abbr}`;
      const value = get(values, key, null);
      name = { ...name, [abbr]: value };
      aKey.push(key);
    });
    const allColumns = get(values, 'allColumns', []);
    const bookmarkDefaultColumns = get(entity, 'bookmarkDefaultColumns', []).map((item) => ({
      ...item,
      columnName: item.columnName,
      shown: find(allColumns, { columnName: item.columnName }) ? true : false,
    }));
    const preparedValues: any = {
      ...omit(values, aKey),
      name: JSON.stringify(name),
      enabled: get(entity, 'enabled', true),
      bookmarkDefaultColumns,
    };
    return preparedValues;
  };
  const onSubmit = handleSubmit(async (values) => {
    toggleLoader();
    const fn = isCreating ? createBookmark : updateBookmark;
    await fn(prepareValues(values)).then(
      (response) => {
        if (response) {
          addSuccessAlert(t('savedBookmark'));
          clearAction();
          loadEntity();
        } else {
          addErrorAlert(t('errorSavingBookmark'));
        }
      },
      (error) => {
        let tError = 'errorSavingBookmark';
        if (get(error, 'response.data', '') === 'error.administration.bookmark.exists') {
          tError = 'errorBookmarkExists';
        }
        addErrorAlert(t(tError));
      },
    );
    toggleLoader(false);
  });

  const getEntity = async () => {
    toggleLoader();
    try {
      let languages = null;
      const languagesLists: ILanguage[] = await getAllLanguages();
      if (isArray(languagesLists)) {
        languages = filter(languagesLists, { active: true });
        setLanguages(languages);
      }
      const workplaces = await getAllWorkplaces();
      if (isArray(workplaces)) {
        fetchWorkplaces(workplaces);
      }

      let bookmark: IBookmarkForm = {
        id: 0,
        name: '',
        position: 0,
        enabled: true,
        allowedTypesOfDocument: [],
        allowedWorkplaces: [],
        allColumns: [],
      };
      const entityId = bookmarkId ? bookmarkId : id;
      const isCreating = entityId === 'create';
      setIsCreating(isCreating);

      let allColumns: string[] = [];
      if (isCreating) {
        allColumns = await getAllColumns();
        fetchAllColumns(
          allColumns.map((item) => ({ id: item, columnName: item, name: t(item.toLowerCase()) })),
        );
        const bookmarkDefaultColumns = allColumns.map((item) => ({
          columnName: item,
          shown: false,
        }));
        fetchEntity({ bookmarkDefaultColumns });
      }

      if (!isCreating && entityId) {
        const entity: any = await getBookmark(entityId);
        if (entity) {
          let name = {};
          (languages || []).forEach((lang: any) => {
            const key = `name_${get(lang, 'abbreviation')}`;
            const value = get(entity, `name.${lang.abbreviation}`, '');
            name = { ...name, [key]: value };
          });
          let selectedColumns: IBookmarkDefaultColumn[] = [];
          const bookmarkDefaultColumns: IBookmarkDefaultColumn[] = get(
            entity,
            'bookmarkDefaultColumns',
            [],
          ).map((column: IBookmarkDefaultColumn) => {
            if (column.shown) {
              selectedColumns.push({
                id: column.id,
                columnName: column.columnName,
                name: t(get(column, 'columnName', '').toLocaleLowerCase()),
              });
            }
            return {
              id: column.id,
              columnName: column.columnName,
              name: t(get(column, 'columnName', '').toLocaleLowerCase()),
            };
          });
          fetchAllColumns(bookmarkDefaultColumns);
          setSelectedColumns(selectedColumns);
          bookmark = { ...entity, ...name, allColumns: selectedColumns };
          fetchEntity(entity);
        }
      }
      reset({ ...bookmark });
    } catch (e) {
      console.debug(e);
    }
    toggleLoader(false);
  };

  useEffect(() => {
    getEntity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEmpty(entity) || isCreating ? (
        <Typography component="div">
          <Header title={title} />
          <Papeer>
            <FormProvider {...methods}>
              <form onSubmit={onSubmit}>
                <input {...register('id')} type="hidden" />
                <input {...register('enabled')} type="hidden" />
                <Grid container={true} alignItems="flex-end" spacing={2}>
                  <Grid item={true} xs={12} style={{ padding: 0 }} />
                  {(languages || []).map((lang: any) => (
                    <Grid item={true} xs={12} key={lang.id}>
                      <FormInput
                        name={`name_${lang.abbreviation}`}
                        label={`${t('name')} - ${get(lang, 'name', '')}`}
                        required={get(lang, 'default', false)}
                      />
                    </Grid>
                  ))}
                  <Grid item={true} xs={12}>
                    <FormInput
                      name="position"
                      label={t(`bookmarksPosition`)}
                      type="number"
                      required={true}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Line />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <SimpleCheckboxArray
                      name="allowedTypesOfDocument"
                      label={t('allowedTypesOfDocument')}
                      items={typesOfDocument.map((item: any) => {
                        const loc = get(item, 'locale', 'cs');
                        let name = get(item, 'name', '?');
                        const translateJson = JSON.parse(get(item, 'translateJson', null));
                        if (translateJson) {
                          name = get(translateJson, loc, name);
                        }
                        return { ...item, name };
                      })}
                      defaultValue={get(entity, 'allowedTypesOfDocument', [])}
                      grid={{ xs: 12, md: 6, lg: 4 }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Line />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <SimpleCheckboxArray
                      name="allowedWorkplaces"
                      label={t('allowedWorkplaces')}
                      items={workplaces.map((item) => ({
                        ...item,
                        name: get(
                          get(item, 'text', false) ? JSON.parse(item.text) : {},
                          currentLocale,
                          item.code,
                        ),
                      }))}
                      defaultValue={get(entity, 'allowedWorkplaces', [])}
                      grid={{ xs: 12, md: 6, lg: 3 }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Line topMargin={2} bottomMargin={2} />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <SimpleCheckboxArray
                      name="allColumns"
                      label={t('allColumns')}
                      items={allColumns.map((item) => ({ ...item }))}
                      defaultValue={selectedColumns}
                      grid={{ xs: 12, md: 6, lg: 3 }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Line topMargin={2} bottomMargin={2} />
                  </Grid>
                  <Grid item={true} xs={12} />
                </Grid>
                <EntityButtons toggleDialog={clearAction} />
              </form>
            </FormProvider>
          </Papeer>
        </Typography>
      ) : (
        <Typography>{t('bookmarkNotFound')}</Typography>
      )}
    </>
  );
};

import { Papeer } from 'components/Papeer/Papeer';
import { IResetTotpDialog } from './_types';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { resetTotp } from './_api';
import useAlerts from 'components/Alerts/useAlerts';

export const ResetTotpDialog: React.FC<IResetTotpDialog> = ({ user, closeDialog }) => {
  const { t } = useTranslation('Users');
  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const resetTotpForUser = async () => {
    try {
      await resetTotp([user.id]);
      addSuccessAlert(t('totpResetSuccess'));
      closeDialog();
    } catch (e) {
      addErrorAlert(t('totpResetError'));
    }
  };

  return (
    <Papeer>
      <Typography>
        {t('resetTotpForUser')}{' '}
        <strong>
          {user.firstName} {user.lastName}
        </strong>
        ?
      </Typography>
      <ButtonGroup sx={{ mt: 1, justifyContent: 'center', width: '100%' }}>
        <Button variant="contained" color="primary" onClick={() => resetTotpForUser()}>
          {t('reset')}
        </Button>
        <Button variant="contained" color="inherit" onClick={() => closeDialog()}>
          {t('storno')}
        </Button>
      </ButtonGroup>
    </Papeer>
  );
};

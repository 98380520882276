import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { each, get, isArray, isEmpty, keys, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v1 as uuid } from 'uuid';
import { Box } from '@mui/system';
import { IUserTagStatisticsSearch } from './_types';

import { Papeer } from 'components/Papeer/Papeer';
import FormDatePicker from 'components/Form/Date/Date';
import Button from 'components/Buttons/Button';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { getUserTagsStatistics } from './_api';
import FormAutocomplete from 'components/Form/Autocomplete/Autocomplete';
import { findUser } from 'modules/Administration/Users/_api';
import { getAllItems } from 'modules/Tags/_api';
import { formatDateForSearch } from 'utils/search';
import { useActions } from 'utils/hooks/useActions';
import { getItem, putItem } from 'utils/hooks/useStorage';
import { useSearchParams } from 'react-router-dom';

const sorter = new Intl.Collator('cs', { usage: 'sort' });

const apiDateTimeFormat = 'yyyyMMdd';

const mainSx = { width: { xs: '100%', md: '50%', lg: '20%' } };

const UserTagStatisticsSearchForm: React.FC<any> = ({ setUserTagReports }) => {
  const { t } = useTranslation('Reports');
  const [users, setUsers] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  let [searchParams] = useSearchParams();

  const methods = useForm<IUserTagStatisticsSearch>({
    defaultValues: { userId: '', dateRange: { dateFrom: null, dateTo: null } },
  });
  const { storeUserTagReports } = useActions();

  const getInitialEntities = async () => {
    toggleLoader();
    const [users, registerGroups] = await Promise.all([findUser({}), getAllItems()]);

    if (isArray(users)) {
      setUsers(
        users
          .map((user: any) => ({
            value: user.id,
            label: `${user.lastName} ${user.firstName} `,
          }))
          .sort((a, b) => sorter.compare(get(a, 'label'), get(b, 'label'))),
      );
    }

    if (!isEmpty(registerGroups)) {
      const activeTags = sortBy(registerGroups, [
        (item: any) =>
          `${get(item, 'registerGroupName', '')}_${get(item, 'label', '')} || ''`.toLowerCase(),
      ]);
      setTags(activeTags);
    }
    toggleLoader(false);
  };
  const { handleSubmit, setValue, reset } = methods;

  useEffect(() => {
    const isBack = (searchParams.get('action') || null) === 'back';
    if (isBack) {
      const formValues = getItem('userTagReportsFormValues');
      const values = formValues ? JSON.parse(formValues) : {};
      each(keys(values), (key: any) => {
        if (key === 'dateFrom' || key === 'dateTo') {
          setValue(key, get(values, key) ? new Date(get(values, key)) : null);
        } else setValue(key, get(values, key));
      });
    }
    getInitialEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { toggleLoader } = useAppGlobals();

  const submitHandler = async (values: any) => {
    toggleLoader();
    setUserTagReports([]);
    const resp = await getUserTagsStatistics({
      userId: get(values, 'user.value'),
      tagId: get(values, 'tag.id'),
      dateRange: {
        from: get(values, 'dateFrom')
          ? formatDateForSearch(values.dateFrom, apiDateTimeFormat)
          : undefined,
        to: get(values, 'dateTo')
          ? formatDateForSearch(values.dateTo, apiDateTimeFormat)
          : undefined,
      },
    });
    putItem('userTagReportsFormValues', JSON.stringify(values));

    if (isArray(resp) && !isEmpty(resp)) {
      const items = resp.map((item: any) => ({
        ...item,
        id: uuid(),
        studies: item.studyIdentities,
        count: item.studyIdentities.length,
      }));
      setUserTagReports(items);
      storeUserTagReports(items);
    }

    toggleLoader(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Papeer bottomMargin={true}>
            <Box
              data-tour="auditLogsForm"
              sx={{
                display: 'flex',
                flexWrap: {
                  xs: 'wrap',
                  lg: 'nowrap',
                },
                paddingRight: { xs: '0px', sm: 0 },
              }}
            >
              <Box sx={mainSx}>
                <FormAutocomplete
                  name="user"
                  label={t('user')}
                  options={users}
                  required={true}
                  placeholder=""
                />
              </Box>
              <Box sx={mainSx}>
                <FormAutocomplete name="tag" label={t('tag')} options={tags} placeholder="" />
              </Box>

              <Box sx={mainSx}>
                <FormDatePicker name="dateFrom" label={t('from')} sx={{ borderRadius: 0 }} />
              </Box>
              <Box sx={mainSx}>
                <FormDatePicker
                  name="dateTo"
                  label={t('to')}
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'right',
                gap: 1,
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
            >
              <Button
                variant="contained"
                color="inherit"
                className="search-form-button"
                data-tour="search-header-searchForm-searchButton"
                onClick={() => {
                  reset();
                }}
              >
                {t('reset')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="search-form-button"
                data-tour="search-header-searchForm-searchButton"
              >
                {t('search')}
              </Button>
            </Box>
          </Papeer>
        </form>
      </FormProvider>
    </>
  );
};

export default UserTagStatisticsSearchForm;

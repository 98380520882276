import { useEffect, useState } from 'react';
import { get, isArray, isEmpty, isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useValidationSchema from './_formTag';
import { addStudyTags } from './_api';
import useAlerts from 'components/Alerts/useAlerts';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { Box } from '@mui/system';
import { TagForm } from 'components/TagForm/TagForm';
import { IStudy, IStudyDetailTagForm } from './_types';
import { useAddTagsTransformValues } from 'modules/Tags/hooks/useAddTagsTransformValues';
import {
  FormType,
  IGroupTagsHierarchicalStructure,
  ITagAutocomplete,
  ITagFormAutocomplete,
} from 'components/TagForm/_types';
import { IAddGroupTagRequest } from 'modules/Tags/_types';
import { addDocumentTag } from 'modules/ClinicalPortal/Patient/_api';

const StudyDetailTagForm: React.FC<IStudyDetailTagForm> = ({
  setDialogState,
  studies,
  callback,
  getStudyTags,
  document,
  getDocumentTags,
  reloadTagsInGrid,
}) => {
  const { t } = useTranslation('StudyTags');
  const { toggleLoader } = useAppGlobals();
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const { TagFormSchema } = useValidationSchema();
  const { onAddTagsTransformValues } = useAddTagsTransformValues();
  const [disableSearch, setDisableSearch] = useState(true);
  const methods = useForm<ITagFormAutocomplete>({
    resolver: yupResolver(TagFormSchema),
  });
  const { handleSubmit, watch } = methods;
  const registerItems: ITagAutocomplete[] = Object.values(watch().registerItems || {});
  const registerItemsJsonString = JSON.stringify(registerItems);

  useEffect(() => {
    const canSearch = registerItems.some((item: ITagAutocomplete) => item !== null);
    setDisableSearch(!canSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerItemsJsonString]);

  const handleFormSubmit = async (requestGroupTags: IGroupTagsHierarchicalStructure) => {
    toggleLoader();
    if (isArray(requestGroupTags)) {
      if (!isEmpty(studies)) {
        studies = studies as IStudy[];
        addTagsToStudies({
          studies,
          registerItems: requestGroupTags,
        });
        setDialogState(false);
        if (isFunction(callback)) {
          callback(studies);
        }
      }
    }

    if (document && isFunction(getDocumentTags)) {
      await addDocumentTag({
        document: {
          ...document,
          author: get(document, 'author', null) === null ? '' : get(document, 'author'),
        },
        registerItems: requestGroupTags,
      }).then(
        async (response) => {
          await getDocumentTags(undefined);
          setDialogState(false);
        },
        (error) => {
          addErrorAlert(t('errorSaving'));
        },
      );
    }

    toggleLoader(false);
  };

  const addTagsToStudies = async (values: {
    studies: IStudy[];
    registerItems: IAddGroupTagRequest[];
  }) => {
    await addStudyTags(values).then(
      async (response) => {
        addSuccessAlert(t('successSaving'));
        if (isFunction(getStudyTags)) {
          await getStudyTags();
          setDialogState(false);
        }
        if (isFunction(reloadTagsInGrid)) {
          reloadTagsInGrid();
        }
      },
      (error) => {
        addErrorAlert(t('errorSaving'));
      },
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(async (values: ITagFormAutocomplete) => {
            const groupTagStructureArray = await onAddTagsTransformValues(values); // create the hierarchical structure of GroupTags
            handleFormSubmit(groupTagStructureArray);
          })}
        >
          <TagForm
            formType={FormType.AddTagForm}
            hideSearchAndClearButton={true}
            showOnlyActiveTags={true}
            isModalBoxStyling={true}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              displayPrint: 'none',
            }}
          >
            <Button
              onClick={() => setDialogState(false)}
              variant="contained"
              size="large"
              sx={{ mr: 1 }}
            >
              {t('close')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={disableSearch}
            >
              <SaveIcon sx={{ mr: 1 }} />
              {t('confirm')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default StudyDetailTagForm;

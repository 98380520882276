import { Dashboard } from '@uppy/react';

// Don't forget the CSS: core and the UI components + plugins you are using.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { IImportProps } from './_types';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { get } from 'lodash';

const Import: React.FC<IImportProps> = ({ uppy, addWebcam = false, maxNumberOfFiles = 0 }) => {
  const theme = useTheme() as Theme;
  const isDarkMode = theme.palette.mode === 'dark';
  const { t } = useTranslation('Import');

  const [isWebcamOpen, setIsWebcamOpen] = useState<boolean>(false);

  //tlacitko pro vyfoceni z webkamery
  const webcamButton = document.querySelector(
    '[aria-controls="uppy-DashboardContent-panel--Webcam"]',
  );

  //tlacitko pro zruseni foceni z webkamery
  const webcamBackButton = document.querySelector('.uppy-DashboardContent-back');

  //listener na tlacitko pro vyfoceni z webkamery
  const listenerCameraOpen = () => {
    //dashboard pro upload souboru
    setTimeout(() => {
      const uppyDashboad = document.querySelector<HTMLElement>('.uppy-Dashboard-inner');
      const isOpen = get(uppy?.getState(), 'plugins.react:Dashboard.activePickerPanel');
      setIsWebcamOpen(isOpen);
      if (uppyDashboad) {
        uppyDashboad.style.height = '600px';
      }
    }, 100);
  };

  //listener na tlacitko pro zruseni foceni z webkamery
  const listenerBack = () => {
    //dashboard pro upload souboru
    const uppyDashboad = document.querySelector<HTMLElement>('.uppy-Dashboard-inner');
    const isOpen = get(uppy?.getState(), 'plugins.react:Dashboard.activePickerPanel');
    setIsWebcamOpen(isOpen);
    if (uppyDashboad) {
      uppyDashboad.style.height = isMobile ? '300px' : '200px';
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const button = document.querySelector(
        '[aria-controls="uppy-DashboardContent-panel--Webcam"]',
      );
      button?.addEventListener('click', listenerCameraOpen);
    }, 100);
    return () => {
      const button = document.querySelector(
        '[aria-controls="uppy-DashboardContent-panel--Webcam"]',
      );
      button?.removeEventListener('click', listenerCameraOpen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamButton]);

  useEffect(() => {
    webcamBackButton?.addEventListener('click', listenerBack);
    return () => {
      webcamBackButton?.removeEventListener('click', listenerBack);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamBackButton]);

  //zjisteni zda je webkamera otevrena nebo zavrena
  const isWebcamClose = get(uppy?.getState(), 'plugins.react:Dashboard.activePickerPanel');

  //akce po vyfocení z webkamery, změnšení výšky dashboardu
  useEffect(() => {
    //dashboard pro upload souboru
    const uppyDashboad = document.querySelector<HTMLElement>('.uppy-Dashboard-inner');
    if (isWebcamOpen && isWebcamClose === false) {
      if (uppyDashboad) {
        uppyDashboad.style.height = isMobile ? '300px' : '200px';
        setIsWebcamOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebcamOpen, isWebcamClose]);

  return (
    <Box
      //Changes the color of Dashboard according to materialUI dark/light mode
      sx={{
        '& .uppy-Dashboard-inner': {
          backgroundColor: isDarkMode
            ? theme.palette.background.paper
            : theme.palette.background.default,
        },
        //Changes the color of text according to materialUI dark/light mode and keep the color of upload buttons as blue
        '& .uppy-Dashboard-inner, & .uppy-Dashboard-inner :not(button)': {
          color: theme.palette.text.primary,
        },
        mt: 2,
      }}
    >
      <Dashboard
        uppy={uppy}
        plugins={addWebcam ? ['Webcam'] : []}
        height={isMobile && addWebcam ? 300 : 200}
        width="auto"
        fileManagerSelectionType={maxNumberOfFiles === 1 ? 'files' : 'both'}
        proudlyDisplayPoweredByUppy={false}
        showSelectedFiles={false}
        hideUploadButton={true}
        className=""
        locale={{
          strings: {
            browseFiles: t(maxNumberOfFiles === 1 ? 'uppy.browseFile' : 'uppy.browseFiles'),
            browseFolders: t('uppy.browseFolders'),
            dropPasteFiles: t(
              maxNumberOfFiles === 1 ? 'uppy.dropPasteFile' : 'uppy.dropPasteFiles',
            ),
            dropPasteBoth: t('uppy.dropPasteBoth'),
            dropPasteImportBoth: t('uppy.dropPasteBoth'),
          },
        }}
      />
    </Box>
  );
};

export default Import;

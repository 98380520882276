import { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppGlobals } from 'utils/hooks/useAppGlobals';
import { useUser } from 'utils/hooks/useUser';

import Import from 'components/Import/Import';
import { useDicomParser } from 'components/Import/useFileParser';
import useAlerts from 'components/Alerts/useAlerts';
import { Announcement } from 'components/Announcement/Announcement';

import { getRules } from 'modules/Import/_api';
import { join, get } from 'lodash';
import FilesGrid from 'components/Import/FilesGrid';
import { FormProvider, useForm } from 'react-hook-form';

import { createUppy } from 'utils/import';
import { Papeer } from 'components/Papeer/Papeer';
import { CloseButtonDialog } from 'components/Dialog/CloseDialogButton';
import { sendMetadata } from '../Certificates/_api';
import { ICertificateAttachmentForm, IUpdatedCerificate } from './_types';
import EditFileDialog from './EditFileDialog';

const maxNumberOfFiles = 1;

const CertificateAttachmentForm: React.FC<ICertificateAttachmentForm> = ({
  loadEntities,
  handleDialog,
  username = '',
}) => {
  const { t } = useTranslation('Import');
  const [files, setFiles] = useState<any[]>([]);
  const [rules, setRules] = useState<any[]>([]);
  const { parseNonDicomFiles } = useDicomParser({ setFiles });
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString());
  const [uppy, setUppy] = useState(() => createUppy('uppy', parseNonDicomFiles, timestamp));
  const [editDialogState, setEditDialogState] = useState<boolean>(false);
  const [fileForEditDialog, setFileForEditDialog] = useState<any>({});

  const { toggleLoader } = useAppGlobals();
  const { user } = useUser();
  const { addErrorAlert } = useAlerts();

  const methods = useForm<any>();
  const { handleSubmit } = methods;

  const removeFile = (id: any) => {
    uppy.removeFile(id);
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const onSubmit = async (values: any) => {
    toggleLoader();
    uppy.upload().then(async () => {
      // Upload complete
      let resp = true;
      for (const file of files) {
        const response = await sendMetadata({
          favourite: false,
          name: file.name,
          path: `${timestamp}_${user?.sub}`,
          username: username || user?.sub,
        });
        if (!response) {
          resp = false;
        }
      }
      setTimeout(() => {
        toggleLoader(false);
      }, 3000);

      if (resp) {
        await loadEntities();
        handleDialog(false);
      } else {
        addErrorAlert(t('notifications.error'));
      }
      setFiles([]);
      const newTimestamp = new Date().getTime().toString();
      setUppy(() =>
        createUppy(
          'uppy',
          parseNonDicomFiles,
          newTimestamp,
          get(rules, 'certificate', ['p12', 'pfx']),
          false,
          false,
          undefined,
          maxNumberOfFiles,
        ),
      );
      setTimestamp(newTimestamp);
    });
  };

  const loadRules = async () => {
    const resp = await getRules();
    if (resp) {
      setRules(resp);
      setUppy(() =>
        createUppy(
          'uppy',
          parseNonDicomFiles,
          timestamp,
          get(resp, 'certificate', ['p12', 'pfx']),
          false,
          false,
          undefined,
          maxNumberOfFiles,
        ),
      );
    }
  };

  useEffect(() => {
    loadRules();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCLoseDialog = () => {
    handleDialog(false);
  };

  const toggleEditDialog = (state: boolean = true) => {
    setEditDialogState(state);
  };

  const updateFiles = (newValuesFile: IUpdatedCerificate) => {
    setFiles((currentFiles) =>
      currentFiles.map((file) => ({
        ...file,
        name: newValuesFile.name,
      })),
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CloseButtonDialog onClose={() => handleCLoseDialog()} />
          <Papeer>
            {!files.length && (
              <Announcement
                label={t('allowedExtensions', {
                  rules: join(get(rules, 'certificate', ['p12', 'pfx']), ', '),
                })}
                type={'info'}
                spaced={true}
                component={'div'}
              />
            )}
            <Grid container spacing={2} mt={2}>
              {!files.length && (
                <Grid item xs={12}>
                  <Import uppy={uppy} maxNumberOfFiles={maxNumberOfFiles} />
                </Grid>
              )}

              {!!files.length && (
                <>
                  <Grid item xs={12}>
                    <FilesGrid
                      files={files}
                      removeFile={removeFile}
                      toggleEditDialog={toggleEditDialog}
                      setFileForEditDialog={setFileForEditDialog}
                      canEdit={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    sx={{ mt: 1, gap: 1 }}
                  ></Grid>
                </>
              )}
            </Grid>
          </Papeer>

          {!!files.length && (
            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="center"
              marginTop={2}
            >
              <Button variant="contained" type="submit" disabled={!files.length}>
                {t('confirm')}
              </Button>
            </Grid>
          )}
        </form>
      </FormProvider>
      <EditFileDialog
        editDialogState={editDialogState}
        toggleEditDialog={toggleEditDialog}
        fileForEditDialog={fileForEditDialog}
        updateFiles={updateFiles}
      />
    </>
  );
};

export default CertificateAttachmentForm;

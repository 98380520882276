// eslint-disable-next-line no-lone-blocks
{/* prettier-ignore */}
const yesno = {
  yes: 'yes',
  no: 'no',
};

const sexes = {
  sexesAll: 'Sex - All',
  female: 'Female',
  male: 'Male',
  different: 'Other',

  patient_sex: 'Sex',
  patient_sex_F: 'Female',
  patient_sex_M: 'Male',
  patient_sex_O: 'Other',

  sex_F: 'female',
  sex_M: 'male',
  sex_O: 'other',
};

const studyStates = {
  studyStatus: 'Study Status',
  studyStatuses: 'Study Statuses',
  Closed: 'Closed',
  InProgress: 'In Progress',
  SecondReading: 'Second Reading',
  Urgent: 'Urgent',
  ForShredding: 'For Shredding',
  AI: {
    Doubt: 'AI: Doubt',
    Normal: 'AI: Normal',
    Abnormal: 'AI: Abnormal',
    Mammo: {
      SuspBenign: 'AI: Mammo Susp. Benign',
      Benign: 'AI: Mammo Benign',
      SuspMalignant: 'AI: Mammo Susp. Malignant',
      Malignant: 'AI: Mammo Malignant',
      Normal: 'AI: Mammo Normal',
    },
  },
};

const importTitle = {
  import: {
    dicom: 'Import DICOM data',
    nondicom: 'Import multimedia data',
    worklist: 'Import data from worklist',
  },
};

const name = {
  lastName: 'Last Name',
  firstName: 'First Name',
  middleName: 'Middle Name',
  prefix: 'Prefix',
  suffix: 'Suffix',
};

const checkName = {
  errorPacientName: 'The full name of the patient can be up to {{maxNameLength}} characters long.',
  errorRequestingPhysicianName:
    'The full name of the requesting physician can be up to {{maxNameLength}} characters long.',
  errorReferringPhysicianName:
    'The full name of the referring physician can be up to {{maxNameLength}} characters long.',
  errorPerformingPhysicianName:
    'The full name of the performing physician can be up to {{maxNameLength}} characters long.',
};

const allowedExtensions = {
  allowedExtensions: 'Files in {{rules}} format are allowed. ',
  noFiles: 'No files selected',
  moreFiles: 'Too many files selected (only 1 allowed)',
};

const sizeAndWeight = {
  patientSize: 'Patient Height',
  patientWeight: 'Patient Weight',
  sizeLabel: 'Patient Height (m)',
  weightLabel: 'Patient Weight (kg)',
  sizeTooltip: 'Value on the day of study given in meters',
  weightTooltip: 'Value on the day of study given in kilograms',
  sizePlaceHolder: 'E.g. 1.73',
  weightPlaceHolder: 'E.g. 72.5',
  errorPatientSizeMoreThen:
    'The patient height is greater than {{maxPatientSize}} meters. Do you really want to save this value?',
  errorPatientSizeNegative: 'The patient height cannot be negative.',
  errorPatientWeightNegative: 'The patient weight cannot be negative.',
  errorPatientSizeLength: 'The patient height can have a maximum of 16 characters',
  errorPatientWeightLength: 'The patient weight can have a maximum of 16 characters',
  errorNoChange: 'There are no changes in the form. Cannot submit.',
  loadingValue: 'Value is loading...',
};

const openInBrowser = {
  openInBrowserTitle: 'Open in Browser',
  showPreviewTitle: 'Show Preview',
  closePreview: 'Close Preview',
};

const select = {
  select: 'Select...',
};

const eventTypes = {
  nok: {
    1: 'Error during processing.',
    2: 'Error during saving.',
    12: 'Error during processing and saving.',
    unknown: 'Unknown error.',
  },
  errorType: {
    1: 'Error during processing. Possible causes: The request could not be processed by the performer.',
    2: 'Error during saving. Possible causes: The study is already in the archive. The study has an unsupported format. The archive refused to save the study for another reason.',
  },

  event: {
    type: {
      update: {
        study: {
          ok: 'Study update performed',
          nok: 'Study update failed.',
          request: { new: 'New study update request' },
        },
        patient: {
          ok: 'Patient update performed in the archive',
          nok: 'Patient update failed in the archive',
        },
      },
      upload: {
        study: {
          ok: 'Study upload performed',
          nok: {
            default: 'Study upload failed.',
            1: 'Files cannot be processed.',
            2: 'Files cannot be saved.',
            12: 'Files cannot be processed and saved.',
          },
          warn: {
            default: 'Partial study upload completed',
            1: 'Processed {{par1}} out of {{par2}} files. Incorrect files cannot be processed.',
            2: 'Processed {{par1}} out of {{par2}} files. Incorrect files cannot be saved.',
            12: 'Processed {{par1}} out of {{par2}} files. Incorrect files cannot be processed and saved.',
          },
        },
      },
      send: {
        study: {
          ok: 'Study sending performed.',
          nok: 'Study sending failed.',
          cancelled: {
            default: 'Study sending cancelled',
            nok: 'Study sending cancellation failed.',
          },
        },
        files: {
          ok: 'File sending request performed.',
          nok: 'File sending request failed.',
          cancelled: {
            default: 'File sending request cancelled.',
            nok: 'File sending request cancellation failed.',
          },
        },
      },
      share: {
        study: {
          ok: 'Study sharing completed successfully',
          nok: 'Study sharing failed.',
          request: { new: 'New study sharing request' },
        },
        send: {
          ok: 'Study sharing performed',
          nok: 'Study sharing failed.',
        },
        receive: 'Incoming study sharing',
      },
      study: {
        request: {
          created: 'Request created for ',
          approved: 'Request has been approved',
          cancelled: 'Request has been cancelled',
          denied: 'Request has been denied',
        },
      },
      delete: {
        study: {
          ok: 'Study deletion performed',
          nok: 'Study deletion failed.',
        },
      },
      split: {
        study: {
          ok: 'Study splitting performed',
          nok: 'Study splitting failed.',
        },
      },
      reorder: {
        study: {
          ok: 'Study reordering performed',
          nok: 'Study reordering failed.',
        },
      },
      distribute: {
        move: {
          ok: 'Study move performed',
          nok: 'Study move failed.',
          and: {
            update: {
              ok: 'Study move and update performed',
              nok: 'Study move and update failed.',
            },
          },
        },
        copy: {
          ok: 'Study copy performed',
          nok: 'Study copy failed.',
          and: {
            update: {
              ok: 'Study copy and update performed',
              nok: 'Study copy and update failed.',
            },
          },
        },
      },
      new: {
        facility: 'New healthcare facility',
      },
      signature: {
        ok: 'Study has been signed',
        nok: 'Study has not been signed.',
      },
      reopen: {
        study: {
          ok: 'Study reopening performed',
          nok: 'Study reopening failed.',
        },
      },
      create: {
        sr: {
          ok: 'Study reopening performed',
          nok: 'Study reopening failed.',
        },
      },
      export: {
        study: {
          ok: 'Study export performed',
          nok: 'Study export failed.',
          cancelled: {
            default: 'Study export cancelled',
            nok: 'Study export cancellation failed.',
          },
        },
      },
      import: {
        study: {
          cancelled: {
            default: 'Study import cancelled',
            nok: 'Study import cancellation failed.',
          },
        },
      },
      shredding: {
        study: {
          ok: 'Study shredding performed',
          nok: 'Study shredding failed.',
        },
      },
    },
  },
};

const reactionTypes = {
  reaction: { type: { popup: 'Popup', email: 'Email' } },
  popup: 'Popup',
  email: 'Email',
};

const dialogTexts = {
  save: 'Save',
  close: 'Close',
  confirm: 'Confirm',
  delete: 'Delete',
  send: 'Send',
};

const messagesForGrid = {
  filterPlaceholder: 'Filter...',
  contains: 'Contains',
  notContains: 'Does not contain',
  startsWith: 'Starts with',
  endsWith: 'Ends with',
  equal: 'Equals',
  notEqual: 'Not equal',
  greaterThan: 'Greater than',
  greaterThanOrEqual: 'Greater than or equal',
  lessThan: 'Less than',
  lessThanOrEqual: 'Less than or equal',
  showColumnChooser: 'Show / hide columns',
  sortingHint: 'Sort',
  showAll: 'Show all',
  rowsPerPage: 'Rows per page',
  from: 'from',
  clearFilters: 'Clear all column filters',
};

const requestsActions = {
  showRequestDetail: 'Show Request Detail',
  requestDetail: 'Request Detail',
  requestApprove: 'Approve Request',
  requestEdit: 'Edit Request',
  requestAbort: 'Abort Request',
  requestReject: 'Reject Request',
};

const gridStudyItems = {
  grid: {
    study: {
      patientName: 'Last Name and First Name',
      patientBirthDate: 'Date of Birth',
      studyDate: 'Study Date',
      modalities: 'Modalities',
      studyDescription: 'Description',
      archiveName: 'Archive',
      patientId: 'Patient ID',
      accessionNumber: 'Accession Number',
      patientSex: 'Patient Gender',
      noOfInstances: 'Sr/Sn',
    },
  },
};

const dataForEdit = {
  operationType: {
    edit_study: 'New Study Values',
    edit_patient: 'New Patient Values',
    edit_patient_old: 'Original Patient Values',
  },

  oldPatient: {
    patientLastName: 'Patient Last Name',
    patientFirstName: 'Patient First Name',
    patientMiddleName: 'Patient Middle Name',
    patientPrefix: 'Patient Prefix',
    patientSuffix: 'Patient Suffix',
    patientId: 'Patient ID',
    patientDateBirth: 'Patient Date of Birth',
    patientSex: 'Patient Gender',
  },
  data: {
    patientLastName: 'Patient Last Name',
    patientFirstName: 'Patient First Name',
    patientMiddleName: 'Patient Middle Name',
    patientPrefix: 'Patient Prefix',
    patientSuffix: 'Patient Suffix',
    patientId: 'Patient ID',
    patientDateBirth: 'Patient Date of Birth',
    patientSex: 'Patient Gender',

    referringPhysicianLastName: 'Referring Physician Last Name',
    referringPhysicianFirstName: 'Referring Physician First Name',
    referringPhysicianMiddleName: 'Referring Physician Middle Name',
    referringPhysicianPrefix: 'Referring Physician Prefix',
    referringPhysicianSuffix: 'Referring Physician Suffix',

    requestingPhysicianLastName: 'Requesting Physician Last Name',
    requestingPhysicianFirstName: 'Requesting Physician First Name',
    requestingPhysicianMiddleName: 'Requesting Physician Middle Name',
    requestingPhysicianPrefix: 'Requesting Physician Prefix',
    requestingPhysicianSuffix: 'Requesting Physician Suffix',

    performingPhysicianLastName: 'Performing Physician Last Name',
    performingPhysicianFirstName: 'Performing Physician First Name',
    performingPhysicianMiddleName: 'Performing Physician Middle Name',
    performingPhysicianPrefix: 'Performing Physician Prefix',
    performingPhysicianSuffix: 'Performing Physician Suffix',
    studyDate: 'Study Date',
    studyTime: 'Study Time',
    studyDescription: 'Description',
    accessionNumber: 'Accession Number',
  },
};

const userPermission = {
  read: 'View Detail',
  read_plus_write: 'Add/Remove Studies',
  write_minus_delete: 'Add Studies',
  write_plus_update: 'Set',
  read_tooltip: 'Right to view detail',
  read_plus_write_tooltip: 'Right to view detail, ability to add/remove studies and series',
  write_minus_delete_tooltip: 'Right to view detail, ability to add studies and series',
  write_plus_update_tooltip:
    'Right to view detail, ability to add/remove studies, series, users, and change worklist settings',
};

const studyFunctions = {
  list: 'Search',
  listAlternative: 'Search - gmail',
  send: 'Send',
  reorder: 'Reorder',
  split: 'Split',
  edit: 'Edit',
  edit_patient: 'Edit Patient',
  edit_study: 'Edit Study',
  editPatient: 'Edit Patient',
  editStudy: 'Edit Study',
  copy: 'Copy',
  move: 'Move',
  archive: 'Scientific Archive',
  archiving: 'Archiving',
  export: 'Export',
  import: 'Import',
  reopen: 'Reopen Study',
  move_to_folder: 'Add to Worklist',
  move_to_trash: 'Delete',
  create_sr: 'Save SR',
  shredding: 'Shredding',
  studyBasketTitle: 'Temporary Study Basket',
  archivation: 'Archiving',
  importStudy: 'Import Data',
  scientificArchive: 'Scientific Archive',
  search: 'Search',
  signature: 'Electronic Signature',
};

const histories = {
  histories: {
    send: 'Study Sending History',
    reorder: 'Study Reordering History',
    split: 'Study Splitting History',
    edit_patient: 'Patient Editing History',
    edit_study: 'Study Editing History',
    copy: 'Study Copying History',
    move: 'Study Moving History',
  },
};

const searchDateButtons = {
  from: 'From',
  to: 'To',
  yesterday: 'Yesterday',
  lastTreeDays: 'Last 3 Days',
  today: 'Today',
  lastWeek: 'Last 7 Days',
  lastMonth: 'Last 30 Days',
  customDate: 'Custom',
  orderDate: 'Scheduled Study Date',
};

const expirationButtons = {
  '1d': '1 day',
  '1w': '7 days',
  '2w': '14 days',
  '3w': '3 weeks',
  '1m': '1 month',
};

const queueStates = {
  processed: 'Processed',
  waiting: 'Waiting',
  done: 'Done',
};

const clinicalPortalDocumentation = {
  createdDateTime: 'Creation Date',
  typeOfDocument: 'Document Type',
  author: 'Author',
  referenceID: 'Source',
  externalId: 'Document ID',
  externalWorkplaceCode: 'Clinic code',
  externalWorkplaceName: 'Clinic name',
  unknownNameOfDocument: 'Unknown Name',
  unknownNameDate: 'Unknown',
  unknownAuthorOfDocument: 'Unknown',
  unknownReferenceOfDocument: 'Unknown',
  unknownExternalWorkplaceCode: 'Unknown',
  unknownExternalWorkplaceName: 'Unknown',
  maxExportDocumentsCount: 'Maximum number of documents for export: {{par1}}',
  maxViewDocumentsCount: 'Maximum number of documents to display: {{par1}}',
};

const state = {
  state: 'State',
  active: 'Active',
  inactive: 'Inactive',
  all: 'All',
  public: 'Public',
  private: 'Private',
  activeExternalVocabularyItem: 'valid',
  inactiveExternalVocabularyItem: 'invalid',
};

const countries = {
  CZ: 'Czech Republic',
  LU: 'Luxembourg',
  HR: 'Croatia',
  GR: 'Greece',
  PT: 'Portugal',
  ES: 'Spain',
  CY: 'Cyprus',
  IE: 'Ireland',
  EE: 'Estonia',
  FI: 'Finland',
  HU: 'Hungary',
  MT: 'Malta',
  PL: 'Poland',
  SE: 'Sweden',
};

const header = {
  add: 'Add',
  back: 'Back',
};

const errors = {
  minLength: 'Minimum number of characters: {{n}}',
  maxLength: 'Maximum number of characters: {{n}}',
  invalidEmailAddress: 'Enter a valid email address.',
  mustBeNumber: 'Enter a number',
  mustBeDate: 'Enter a date',
  required: 'Field is required.',
  requiredLastNameIfOtherNameOrTitle: 'Last name is required if first name or title is entered.',
  requiredWithLastName: 'Required if last name is entered',
  requiredWithFirstName: 'Required if first name is entered',
  requiredWithId: 'Required if ID is entered',
  hexaColor: 'Enter a valid color format.',
  maxLengthNumber: 'Maximum value: {{n}}',
  minLengthNumber: 'Minimum value: {{n}}',
  maxLength1: 'Only {{par1}} character is allowed.',
  maxLength234: 'Only {{par1}} characters are allowed.',
  maxLength5andMore: 'Maximum {{par1}} characters are allowed.',
  minLength1: 'Minimum {{par1}} character is required.',
  minLength234: 'Minimum {{par1}} characters are required.',
  minLength5andMore: 'Minimum {{par1}} characters are required.',
  minMaxLength1: '{{par1}} character is required.',
  minMaxLength234: '{{n}} characters are required.',
  minMaxLength5andMore: '{{par1}} characters are required.',
  selectMin1Workplace: 'Select at least one workplace',

  maxLength64: 'Maximum 64 characters are allowed.',
  maxLength16: 'Maximum 16 characters are allowed.',
  maxLength10: 'Maximum 10 characters are allowed.',
  minLength2: 'Minimum 2 characters are required',
  minLength3: 'Minimum 3 characters are required',
  minLength4: 'Minimum 4 characters are required',
  minLength5: 'Minimum 5 characters are required',
  exactLength8: 'Exactly 8 characters are required.',
  birthDate: 'Date must be in the past.',
  dateIsFuture: 'Attention, the date is in the future!',
  dateBirthIsFuture: 'Attention, the date of birth is in the future!',
  notFuture: 'Date cannot be in the future.',
  time: 'Enter a valid time (24-hour format in the form of hh:mm)',
  timeWithSecond: 'Enter a valid time (24-hour format in the form of hh:mm:ss)',

  onlyAlphabets: 'Only alphabets are allowed',
  withoutDiacritics: 'Only characters without diacritics',
  allCharsWithoutDiacritics: 'Only characters without diacritics',
  withoutSpecialChars: 'Special characters are not allowed',
  dicomTag: 'Only digits and characters A, B, C, D, E, F according to the format (xxxx,xxxx)',
  email: 'Enter a valid email address.',
  withoutSpecial: 'Special characters are not allowed',
  'Form.required': 'Select at least one item',
  'SearchForm.selectArchive': 'Select data source',
  allowedChars: 'Invalid format.',
  allowedCharsWithListAllowedChars: 'Only characters according to the regex expression: {{par1}}',
  dateToCanNotBeBeforeDateFrom: 'Date to cannot be earlier than date from',
  validToCannotBeBeforeValidTo: 'Valid to cannot be earlier than valid to',
  notNegative: 'Only non-negative numbers',
  positiveWithoutZero: 'Only positive numbers without zero',
  integer: 'Only whole numbers',
  lengthUnder60Hours: 'Enter time in the format hh:mm:ss, maximum is 59:59:59',
  atLeaseOneAnswerMustBeAdded: 'You must fill in at least one answer',
  atLeaseOneAnswerMustBeCorrect: 'At least one answer must be marked as correct',
  noChangesInForm: 'There are no changes in the form',
  lessThanTwoDefinitions:
    'If the test does not contain definitions from at least two groups, it cannot be published',

  'test.step.answer.late': 'You answered the question too late',
  testFinishFail: 'Error submitting the test, please try again',
  errorExecutingTest: 'Unable to load the test',
  cannotOpenLink: 'Unable to open the browser',

  duplicateKey: 'Duplicate key entered, change the value',
  duplicateAet: 'This name of modality already exists',

  shreddingProposalsNoExist: 'No shredding proposals are recorded.',
  shreddingProposalsExist:
    'We have a total of {{par1}} shredding proposals. Please fill out the form to view the proposal.',

  minLengthAndAllowedChars: 'Min. number of characters: {{n}} | Allowed: {{par1}}',

  additionalFieldRequired: 'At least one additional field must be filled in',
};

const actionList = {
  action: {
    addComment: 'Add Comment',
    addTag: 'Add Tag',
    send: 'Send',
    reorder: 'Reorder',
    split: 'Split',
    update: {
      patient: 'Edit Patient',
      study: 'Edit Study',
    },
    copy: 'Copy',
    move: 'Move',
    export: 'Export',
    import: 'Add Images',
    importFromWebcam: 'Add Image from Webcam',
    print: 'Print',
    delete: 'Delete',
    proposalShredding: 'Shred',
    archive: 'Archive',
    showStudyOrder: 'View Study Order',
    addToFolder: 'Add to Folder',
    doseMonitoring: 'Monitor Dose',
    createSR: 'Create SR',
    viewDicomAttributes: 'View DICOM Attributes',
    openInBrowserTitle: 'Open in Browser',
    changeStatuses: 'Change Study Statuses',
    shredding: 'Shredding',
  },
};

const dataForShredding = {
  submitProposalShreddingError: 'Request to create a shredding proposal was not accepted.',
  submitProposalShreddingOk: 'Request to create a shredding proposal was accepted.',
  submitProposalShreddingOkAndNok:
    'Request to create a shredding proposal was accepted for {{par1}} studies.',
  confirmProposalShreddingTitle: 'Are you sure you want to propose shredding?',
  confirmProposalShredding:
    'You have proposed shredding for {{par1}} studies. Are you sure you want to perform the operation on the selected items?',

  submitShreddingError: 'Request for shredding was not accepted.',
  submitShreddingOk: 'Request for shredding was accepted.',
  submitShreddingOkAndNok: 'Request for shredding was accepted for {{par1}} studies.',
  confirmShreddingTitle: 'Are you sure you want to shred?',
  confirmShredding:
    'You have selected {{par1}} studies for shredding. Are you sure you want to perform the operation on the selected items?',
};

const dialogOther = {
  dialog: { tag: 'Add Tag' },
};

const availability = {
  availability: {
    online: 'Status: Online',
    archived: 'Status: Archived',
    offline: 'Status: Offline',
    unavailable: 'Status: Unavailable',
  },
};

const printSetting = {
  print: {
    facility: 'Healthcare Facility',
    patient: 'Patient',
    patientId: 'Identification Number',
    dateBirth: 'Date of Birth',
    sex: 'Sex',
    accessionNumber: 'Accession Number',
    dateTime: 'Date and Time of Study',
    setting: 'Print Settings',
    referringPhysician: 'Referring Physician',
  },
  print_setting_detail: 'Study Header',
  print_setting_comments: 'Study Comments',
  print_setting_series: 'Series List',
};

const sejfSendForm = {
  sejfSendForm: 'Type of Sending',
  dicom: 'DICOM',
  pdf: 'PDF',
  original: 'Original',
};

const archiving = {
  submitArchivingError: 'Request for study archiving failed to process.',
  submitArchivingOk:
    'Request for study archiving has been submitted for processing. Until the studies are processed, they remain in the Online state.',
};

const togglePreview = {
  showPreview: 'Show Series Previews',
  hidePreview: 'Hide Series Previews',
};

const studyOfStatus = {
  uncollapsed: 'Short-term Archive',
  closed: 'Long-term Archive',
  collapsed: 'Long-term Archive',
  error: 'Unavailable',
};

const errorViewerMessages = {
  cannotOpenLink: 'Unable to open the browser',
};

const decubitsCode = {
  'decubitsCode.head': 'Head',
  'decubitsCode.larm': 'Left Arm',
  'decubitsCode.rarm': 'Right Arm',
  'decubitsCode.body': 'Body',
  'decubitsCode.lleg': 'Left Leg',
  'decubitsCode.rleg': 'Right Leg',
};

export const ru = {
  Form: {
    back: 'Back',
    checkAll: 'Select All',
    today: 'Today',
    ...dialogTexts,
    clearValue: 'Clear Value',
  },

  Errors: {
    ...errors,
  },

  Login: {
    username: 'Username',
    email: 'Email',
    password: 'Password',
    newPassword: 'New password',
    login: 'Login',
    title: 'Login',
    backToLogin: 'Back to login',
    errorLogin: 'Incorrect username and password.',
    inactiveLogout: 'You have been logged out due to inactivity',
    badToken: 'Action failed. Incorrect link used for opening or importing a study',
    error: {
      generalError: 'Login error. Please try logging in again.',
      generalPasswordRecoveryError: 'Password recovery error. Try again.',
      apiNotWorking: 'API server is not responding. Please contact technical support.',
      tokenDecodeError: 'Login error. Please try logging in again.',
      generalSSOError:
        'Automatic SSO login failed. Please try logging in using your username and password.',
      authentication: {
        user: {
          not: {
            found: 'Incorrect login credentials',
          },
        },
        invalid: {
          password: 'Incorrect password entered',
        },
        password: {
          invalid: 'Incorrect password entered',
        },
      },
      passwordRecovery: {
        10: 'Password is empty.',
        11: 'The password has no minimum number of characters.',
        12: 'The password does not contain lowercase characters.',
        13: 'The password does not contain uppercase characters.',
        14: 'The password does not contain numbers.',
        15: 'The password does not contain special characters.',
        16: 'The password does not meet the required number of mandatory categories.',
        17: 'The password cannot be changed in a short time interval.',
        18: 'Password cannot be used - it has been used recently.',
        19: 'Could not find user for the specified hash.',
        20: 'The hash is invalid.',
        21: 'The hash is unknown.',
        22: 'The user does not have an email set - password recovery cannot be sent.',
        23: 'The user has not correctly set the login source - password recovery cannot be sent.',
        24: 'User not found.',
      },
    },
    TOTPCode: 'TOTP Code',
    openIdButton: 'Login via OpenId',
    openIdLogoutButton: 'Logout from OpenId',
    openIdSSOlougoutMessage:
      'You have been logged out from the portal application. To logout from all applications, click the button:',
    openIdSSOAccessDeniedMessage: 'OpenID login cancelled',
    forgottenPasswordTitle: 'Forgotten password',
    resetPasswordTitle: 'Reset password',
    resetPasswordSend: 'Password recovery request sent. Check your email.',
    resetPasswordSErrorSending: 'The password reset request could not be processed.',
    scaned: 'Confirm',
    explainPage:
      'To login, you need to have the Google Authenticator app installed, where you will scan the generated QR code.',
    explainQRCodeApps: 'The app can be downloaded from ',
    explainQRCodeAppsOr: ', or ',
    requestsAddPriority: 'Please submit priority requests in writing through the web application',
    helpdesk: 'HelpDesk OR-CZ',
    phoneContact: 'Phone contact for emergencies',
    help: 'Password help',
    label: 'Label of medical device',
    phoneAbbr: 'tel',
    dailyRoutine: 'daily routine',
    nightlyRoutine: 'nightly routine',
    holidays: 'weekends, holidays',
    ruleForPasswordRecovery: {
      minimalPasswordLength: 'Minimum password length: {{par1}}',
      mustContain: 'The password must contain: {{par1}}',
      capitalLettersRequired: 'capital letter',
      smallLettersRequired: 'small letter',
      digitsRequired: 'number',
      specialCharsRequired: 'special character',
    },
    passwordChanged: 'Password changed successfully',
    passwordNoChanged: 'Password change failed',
  },
  Homepage: {
    homepage: 'Homepage',
    cancel: 'Cancel loading',
    restrictedArea: 'You do not have sufficient permissions to view this section',
    restrictedAreaTitle: 'Insufficient Permissions',
    missingRoles: 'You are missing the following permissions',
    contactAdmin: 'Please contact the system administrator',
    noRoleAnnouncement: 'You have not been assigned any permissions.',
    newerVersion: 'A new version of the portal has been detected',
    restartApp: 'Restart Portal',
    urlMoved: 'This URL address is no longer valid',
    redirectToStudiesSearch: 'Go to studies search',
    redirectToRequests: 'Go to requests overview',
    forMoreInformationPleaseContactAdministrator:
      'For more information, please contact the administrator.',
    pageNotFound: 'This page does not exist.',
  },
  Bar: {
    logout: 'Logout',
    compactDisplayMode: 'Compact Mode',
    userProfile: 'User Profile',
    showStudiesFromBasket: 'Show Studies from Basket',
  },
  // Left menu
  Menu: {
    // Orders
    orders: 'Modality worklist',
    ordersList: 'List',

    // Studies
    menuStudies: 'Studies',
    ...studyFunctions,
    calculate: 'Dose tracking',
    importMenu: 'Import data',

    ...histories,

    // Archiving
    studyArchivingTitle: 'Study archiving',
    multipleArchiving: 'Archive',

    // Export
    // exports: 'Data export',
    export: 'Export studies',
    exportsList: 'Export list',
    patient: 'Patient correction',
    study: 'Study correction',

    cstore: 'Local studies',

    // Import
    // import: 'Data import',
    dicom: 'DICOM',
    nondicom: 'multimedia',
    worklist: 'from worklist',
    ...importTitle,

    // Signature
    signature: 'Electronic signature',
    unsignedStudies: 'Unsigned studies',
    studyUnsignedTitle: 'Find unsigned studies',
    multipleSigning: 'Sign',
    confirmSigningStudy: 'Sign selected study?',
    confirmSigningStudies: 'Sign selected studies?',

    // Share
    sharing: 'Sharing',
    share: 'Share studies',
    ask: 'Request sharing',
    delivered: 'Delivered studies',
    sent: 'Sent studies',

    userProfile: 'User profile',

    // Requests
    request: 'Request overview',
    requestsEditStudy: 'Study correction',
    requestsEditPatient: 'Patient correction',
    requestsSplit: 'Split',
    requestsMove: 'Move',
    requestsCopy: 'Copy',
    requestsReorder: 'Reorder',
    requestsSend: 'Send',
    requestsMoveToFolder: 'Add to worklist',
    requestsMoveToTrash: 'Delete',
    search: 'Search studies',
    'requests.search': 'Create new request',
    'requests.edit': 'List of correction requests',
    'requests.editStudy': 'List of study correction requests',
    'requests.editPatient': 'List of patient correction requests',
    'requests.split': 'List of split requests',
    'requests.move': 'List of move requests',
    'requests.copy': 'List of copy requests',
    'requests.reorder': 'List of reorder requests',
    'requests.send': 'List of send requests',
    'requests.moveToFolder': 'List of add to worklist requests',
    'requests.reorder.search': 'Search studies for reorder request',
    requests: {
      detail: 'Request detail',
      editPatient: {
        create: 'New patient correction request',
        edit: 'Edit patient correction request',
      },
      editStudy: {
        create: 'New study correction request',
        edit: 'Edit study correction request',
      },
      copy: {
        create: 'New study copy request',
        edit: 'Edit study copy request',
      },
      move: {
        create: 'New study move request',
        edit: 'Edit study move request',
      },
      moveToFolder: {
        create: 'New add to worklist request',
        edit: 'Edit add to worklist request',
      },
      moveToTrash: {
        create: 'New study delete request',
        edit: 'Edit study delete request',
      },
      requests: 'Request overview',
      reorder: {
        create: 'New study reorder request',
        edit: 'Edit study reorder request',
      },
      send: {
        create: 'New study send request',
        edit: 'Edit study send request',
      },
      split: {
        create: 'New study split request',
        edit: 'Edit study split request',
      },
    },
    studies: {
      copy: {
        detail: 'Study copy',
      },
      editPatient: {
        detail: 'Patient correction',
      },
      editStudy: {
        detail: 'Study correction',
      },
      move: {
        detail: 'Study move',
      },
      reorder: {
        detail: 'Study reorder',
      },
      send: {
        detail: 'Study send',
      },
      split: {
        detail: 'Study split',
      },
      studies: 'Search studies',
    },
    exports: { detail: 'Export studies' },

    // Worklist
    worklists: 'Worklist',

    // Tests
    tests: 'Tests',
    startTest: 'Start test',
    results: 'Test results',
    manageTests: 'Test overview',
    stats: 'Global statistics',
    testResults: 'Test results',
    globalStats: 'Global statistics',

    // Trustworthy
    trustworthyArchive: 'Trustworthy archive',
    archivedDocuments: 'Archived documents',

    // Monitoring
    monitoring: 'Monitoring',
    nasEnterprise: 'NAS Enterprise',
    patientManagement: 'Patient Management',

    // Nas Enterprise
    'nas-enterprise': 'NAS Enterprise',
    dashboards: 'Dashboards',
    'osd-daemons': 'OSD Daemons',

    // Reports
    reports: 'Reports',
    statistics: 'Statistics',
    daily: 'Daily',
    auditLogs: 'User activities',
    studiesByStudyTagsStatistics: 'Statistics by study tags',
    findingReports: 'Finding reports',

    // ClinicalPortal
    byPatient: 'By patient',
    byTags: 'By tags',
    byNCPeH: 'NCPeH',

    // Administration
    administration: 'Administration',
    facilities: 'Facilities',
    exchangeNetworks: 'Networks',
    products: 'Products',
    modalities: 'Modalities',
    users: 'Users',
    workplaces: 'Workplaces',
    partnership: 'Partnership',
    loggedActivities: 'Logged activities',
    logs: 'Logs',
    announcements: 'Announcements',
    buttons: 'Buttons',
    tags: 'Tags',
    administrationTags: 'Management',
    registerGroups: 'Tags',
    units: 'Units',
    stations: 'Stations',
    languages: 'Languages',
    expertness: 'Expertness',
    healthInsuranceCompany: 'Health insurance companies',
    studyOrderPriority: 'Priority',
    dose: 'DICOM attributes',
    physicians: 'Physicians',
    filters: 'Filters',
    folders: 'Folders',
    queueRequests: 'Request queue',
    tools: 'Tools',
    clinicalPortal: 'Clinical documentation',
    generalSettings: 'General settings',
    settings: 'Settings',
    modalitiesUnitsStations: 'Modalities, units, stations',
    facilitiesAndExchangeNetworks: 'Facilities and networks',
    announcementsAndButtons: 'Tiles and announcements',
    frontendSettings: 'Frontend settings',
    mdex: 'MDEX',
    sendData: 'Send Data',
    transmissions: 'Transmission overview',
    addressBook: 'Recipient address book',

    prioritiesAndOperations: 'Priority and operation settings',
    loginScreenSettings: 'Login screen settings',
    dictionaries: 'Dictionaries',

    // Help
    help: 'Help',
    online: 'Online help',
    offline: 'Offline help',
    local: 'Local help',
    about: 'About application',

    // Websocket notifications
    notifications: 'Notifications',

    portalVersion: 'Portal version',

    // Dashboard grid
    'pools.name': 'Name',
    'pools.pgstate': 'PG status',
    'pools.usage': 'Usage',
    'pools.activity': 'Activity',

    // Tags
    documentsByTags: 'Search in integration platform',

    confirmArchivingStudy: 'Archive selected study?',
    confirmArchivingStudies: 'Archive selected studies?',

    shreddings: {
      shreddings: 'Shreddings',
      proposals: 'Proposals',
      histories: 'Histories',
    },
    detail: {
      orders: 'Order detail',
      shreddings: {
        proposals: 'Shredding proposals',
        histories: 'Shredding histories',
      },
    },
    edit: {
      orders: 'Edit order',
    },
    histories: {
      send: 'Send history',
    },
  },
  SearchForm: {
    searchForm: 'Study Search',
    ...studyFunctions,
    study: 'Study',
    patient: 'Patient',
    lastName: 'Last Name',
    firstName: 'First Name',
    middleName: 'Middle Name',
    search: 'Search',
    sex: 'Sex',
    ...sexes,
    other: 'Other',
    all: 'All',
    patientID: 'Patient ID',
    accessionNumber: 'Accession Number',
    birthDate: 'Birth Date',
    dateBirthFrom: 'Date of Birth From',
    birthDateFrom: 'Birth Date From',
    birthDateTo: 'Birth Date To',
    dateBirthTo: 'Date of Birth To',
    studyDate: 'Study Date',
    studyDateFrom: 'Study Date From',
    studyDateTo: 'Study Date To',
    studyTime: 'Study Time',
    '24h': 'Last 24 Hours',
    ...searchDateButtons,
    ...expirationButtons,
    customDateLabel: 'Date and time of study',
    modalities: 'Modalities',
    otherModalities: 'Other modalities',
    allModalities: 'All modalities',
    separateModalitiesWithComma: 'Separate modalities with comma',
    description: 'Study description',
    requestingPhysician: 'Requesting physician',
    referringPhysician: 'Referring physician',
    performingPhysician: 'Performing physician',
    source: 'Data source',
    allSources: 'All sources',
    reset: 'Reset',
    units: 'Departments',
    stations: 'Stations',
    state: 'State',
    submitError: 'An error occurred while searching.',
    cancelSubmit: 'Search was canceled.',
    selectMin1Archive: 'Select data source',
    // Scientific archive
    comment: 'Study comment',
    createCommentFrom: 'From comment creation date',
    createCommentTo: 'To comment creation date',
    tags: 'Tags',
    tagsActive: 'Tags - active',
    tagsInactive: 'Tags - inactive',
    showInactiveGroups: 'Show inactive tags',
    withoutTags: 'Search for studies without a tag',
    select: 'Select',
    modality: 'Modality',
    selectDeselectAll: 'Select/Deselect all',
    noOptions: 'No items',

    // Logs
    userName: 'User',
    logDate: 'Activity date',

    // Filters
    saveFiltr: 'Save filter',
    filter: {
      saved: 'Filter saved',
      dialogTitle: 'Create filter',
      name: 'Filter name',
    },
    searchFilterIsAlreadyExists: 'Filter name is already used',

    filters: 'Filters',
    ...dialogTexts,

    // Queue
    stateOfQueue: 'Queue State',
    ...queueStates,

    history: 'History',
    historyList: 'Sending history',
    exportList: 'Export list',

    clinicalPortalExpansionHead: 'Advanced search',
    clinicalPortalExpansionHeadExtended: '(Operation may take several minutes)',

    // Proposal source
    proposal: {
      User: 'User proposal',
      NIS: 'Automatic proposal from NIS',
      MASH: 'Automatic proposal from MASH',
    },
    proposalDate: 'Proposal date',
    confirmationDate: 'Confirmation date',
    userConfirmation: 'Confirmation author',
    shreddingConfirmation: 'Confirmation',
    shreddingProposal: 'Proposal',
    shreddingUser: 'Author',
    shreddingDate: 'Date',
    sourceProposal: 'Proposal source',
    userProposal: 'Proposal author',
  },

  SearchResults: {
    studiesFound: 'Studies found',
    studiesSelected: 'Studies selected',
    noData: 'No data found',
    ...messagesForGrid,
    ...sexes,
    ...availability,
    replaceBySelected: 'Replace by selected',
    addSelected: 'Add selected',
    replace: 'Replace',
    add: 'Add',
    viewed: 'Viewed',
    report: 'Report',
    send: 'Send',
    reorder: 'Reorder',
    edit_study: 'Edit study',
    edit_patient: 'Edit patient',
    copy: 'Copy',
    move: 'Move',
    export: 'Export',
    setChangeStatuses: 'Change status',
    setChangeStatusesTooltip: 'Change status only for DPGW archive studies',
    multipleCalculateExport: 'Dose export',
    multipleCalculate: 'Monitor Dose',
    name: 'Last name and first name',
    patientId: 'Patient ID',
    accessionNumber: 'Accession number',
    patientSex: 'Sex',
    patientBirthDate: 'Date of birth',
    dateTime: 'Date of study',
    referringPhysician: 'Referring physician',
    modalities: 'Modalities',
    noOfInstances: 'Series/Instances',
    stationName: 'Station name',
    stationAet: 'Station AET',
    studyDescription: 'Description',
    archive: 'Archive',
    seriesName: 'Series name',
    seriesCreatedDate: 'Creation date',
    seriesModality: 'Modality',
    seriesDescription: 'Description',
    seriesNumber: 'Series number',
    seriesDateTime: 'Series date and time',
    seriesInstancesCount: 'Number of instances',
    modality: 'Modality',
    proposalDateTime: 'Proposal date',
    proposalSource: 'Proposal source and author',
    confirmationDateTime: 'Confirmation date',
    userConfirmation: 'Confirmation author',
    seen: 'Seen',
    actions: 'Actions',
    comments: 'Comments',
    tags: 'Tags',
    sendInfos: 'Sent',
    drSejf: 'Dr. Sejf',
    folderInfos: 'Folders',
    folderExpiratedInfos: 'Expiration in',
    day1: '{{days}} day',
    day234: '{{days}} days',
    days: '{{days}} days',
    extendExpiration: 'Extend expiration',
    hasAttachment: 'With attachment',
    shredding: 'Shredding proposal',
    shredded: 'Proposed for shredding',
    showDetailWithAttachments: 'Show detail with attachments',
    modalitiesNotShown: 'Contains hidden series',
    showDetail: 'Show detail',
    countStudies: 'Number of studies',
    studyLocked: 'Study locked',
    ...openInBrowser,
    downloadECG: 'Download ECG study',
    downloadEcgError: 'Failed to download ECG study',
    details: 'Comments',
    setSourceStudyForRequestReorder: 'Select source study for reorder request',
    setTargetStudyForRequestReorder: 'Select target study for reorder request',
    setStudyForRequestSplit: 'Select study for split request',
    setStudyForRequestMove: 'Select study for move request',
    setStudyForRequestCopy: 'Select study for copy request',
    setStudyForRequestSend: 'Select study for send request',
    setStudyForRequestEditPatient: 'Select study for edit patient request',
    setStudyForRequestEditStudy: 'Select study for edit study request',
    setStudyForRequestMoveToFolder: 'Select study for move to folder request',
    setStudyForRequestDeleting: 'Select study for deleting request',
    setStudyForRequestMoveToTrash: 'Select study for move to trash request',
    setStudyRequestForMoveToFolder: 'Select study for move to folder request',
    setStudyRequest: 'Select study for request',
    setStudyForSend: 'Send study',
    setStudyForSplit: 'Split study',
    setStudyForEdit: 'Edit study',
    setPatientForEdit: 'Edit patient',
    setStudyForCopy: 'Copy study',
    setStudyForMove: 'Move study',
    setStudyForArchive: 'Archive study',
    setStudyForExport: 'Export study',
    setStudyForReorder: 'Reorder study',
    setSourceStudyForReorder: 'Reorder study (select source study)',
    setTargetStudyForReorder: 'Select target study for reorder',
    setStudyForMoveToFolder: 'Add to folder',
    setStudyForDeleting: 'Delete study',
    setStudyForProposalShredding: 'Propose for shredding',
    setStudyForShredding: 'Shred',
    setStudyForShreddingPrint: 'Print shredding protocol',
    setStudyForBasket: 'Add to temporary folder',
    removeStudyFromFolder: 'Remove study from folder',
    removeSelectedStudiesFromFolder: 'Remove selected studies from folder',
    removeAllStudiesFromFolder: 'Remove all studies from folder',
    studyAddedToBasket: 'Study added to temporary folder',
    studiesAddedToBasket: 'Studies added to temporary folder',
    removeStudyFromBasket: 'Remove study from temporary folder',
    removeSelectedStudiesFromBasket: 'Remove selected studies from temporary folder',
    removeAllStudiesFromBasket: 'Remove all studies from temporary folder',
    importIcon: 'Select this study for import',
    instanceNumber: 'Instance number',
    deleteImage: 'Delete',
    multipleRemoveImages: 'Delete instances',
  },

  Header: {
    addWorkplacesFromLdap: 'Add workplaces from address book',
    ...header,
  },

  StudyDetail: {
    ...sizeAndWeight,
    studyIsNotAvailable: 'The study is no longer available in the archive',
    edit: 'Study detail',
    studyNotFound: 'Study not found.',
    accessionNumber: 'Accession number',
    dateTime: 'Study date',
    modality: 'Modality',
    numberOfStudyRelatedSeries: 'Number of series',
    numberOfStudyRelatedInstances: 'Number of images',
    numberOfStudyRelatedSeriesAndInstances: 'Number of series / images',
    changeTimelineOrder: 'Reverse timeline',
    filterByModality: 'Filter by modalities',
    filterByDescription: 'Filter by description',
    removeFilterForTimeline: 'Reset timeline',
    filteringByModalities: 'Filtering by modalities',
    filteringByDescription: 'Filtering by description',
    institutionName: 'Performing institution',
    archive: { name: 'Archive' },
    studyDescription: 'Study description',
    referringPhysician: { name: 'Referring physician' },
    requestingPhysician: { name: 'Requesting physician' },
    performingPhysician: { name: 'Performing physician' },
    patient: {
      dateBirth: 'Date of birth',
      name: 'Patient name',
      identificationNumber: 'Patient ID',
    },
    ...studyStates,
    ...sexes,
    comments: 'Comments',
    tags: 'Tags',
    deleteSerie: 'Delete',
    seriesTab: 'Series',
    order: 'Order',
    attachments: 'Attachments to order',
    series: {
      title: 'Series',
      number: 'Series number',
      stationAet: 'Station AET',
      modality: 'Modality',
      description: 'Description',
      numberOfInstances: 'Images',
      preview: 'Series preview',
    },
    preview: 'Preview',
    studyTagsCreateTitle: 'Author and date of tag creation',
    studyTagsDelete: 'Delete tag',
    commentCreateTitle: 'Author and date of comment creation',
    commentUpdateTitle: 'Author and date of comment update',
    commentUpdate: 'Edit comment',
    commentDelete: 'Delete comment',
    confirmDelete: 'Are you sure you want to delete?',
    confirmCancel: 'Are you sure you want to cancel?',
    commentExport: 'Export comment',
    confirmExport: 'Are you sure you want to export?',
    tagCreateTitle: 'Author and date of tag creation',
    tagDelete: 'Delete tag',
    fileCreateTitle: 'Author and date of file upload',
    fileDelete: 'Delete attachment',
    ...actionList,
    ...dataForShredding,
    confirmMoveStudyToTrashTitle: 'Are you sure you want to delete?',
    confirmMoveStudyToTrashContent: 'The study will be moved to the trash',
    dialog_comment: 'Comment',
    dialog_comment_label: 'Comment for study',
    dialog_attachment: 'Add attachment',
    dialog_createSR: 'Create SR',
    commentExported: 'The request to export the comment has been submitted for processing.',
    commentNotExported: 'Failed to process the request to export the comment.',
    ...dialogOther,
    filename: 'File name',
    createdWhen: 'Creation date and time',
    size: 'Size',
    user: 'Uploaded by user',
    folders: 'Folders',
    multipleAddToFolder: 'Add to worklist',
    multipleRemoveSeries: 'Delete series',
    removeSeries: 'Deleting series',
    reasonRemoveSeries: 'Reason for deletion',
    confirmDeletingSeries: 'The selected series will be deleted',
    errorDeletingSeries: 'Failed to delete the selected series',
    removeImages: 'Deleting instances',
    reasonRemoveImages: 'Reason for deletion',
    confirmDeletingImages: 'The selected instances will be deleted',
    errorDeletingImages: 'Failed to delete the selected instances',
    report: 'Report',
    forbidden: 'You do not have permission to view the study!',
    deleteOk: 'Request to delete the study has been successfully submitted',
    deleteNok: 'Failed to submit request to delete the study',
    seriesPreview: 'Series preview',
    bodyPart: 'Body part',

    ...dialogTexts,

    // Archived
    ...availability,
    ...printSetting,
    ...sejfSendForm,
    studyAvailability: 'Study availability',

    modified: 'Modified',
    confirmArchivingStudy: 'Archive the selected study?',

    ...archiving,

    // Dose
    doses: 'Doses',

    unableShowAttachement:
      'The attachment cannot be displayed. To view it, you need to download the attachment to your computer.',

    operations: 'Operations',
    notify:
      'The counts of images/series may differ from the counts in the series tab due to settings!',

    ...togglePreview,
    statusOfCode: 'Status',
    reopen: 'Reopen the study to short-term archive',
    studyIsReopening: {
      error: 'Failed to process the request to reopen the study.',
      ok: 'The request to reopen the study has been submitted for processing.',
    },
    ...studyOfStatus,

    // SR
    seriesDescription: 'Report name',
    language: 'Report language',
    physician: 'Physician',
    ...name,
    dataTypes: 'Item type',
    text: 'Text item',
    createSrSubmitOk: 'The request to create SR has been successfully submitted.',
    createSrSubmitNok: 'Failed to submit request to create SR',

    // dicomAttributes
    dc: {
      tag: 'Tag',
      vr: 'VR',
      tagName: 'Name',
      value: 'Value',
    },
    ...decubitsCode,

    successSetStudyStatuses: 'Successfully set study statuses',
    errorSetStudyStatuses: 'Failed to set study statuses',

    certName: 'Certificate name',
    certKey: 'Certificate password',
    mailSend: 'Send an email to the doctor',
    noSigned: 'The finding will not be signed.',

    createPdfError: 'Failed to generate PDF.',
    incorrect_certificate_password:
      'Failed to generate PDF because the correct password was not entered.',
    certificate_not_found:
      'Failed to generate PDF because the selected certificate was not found on the server.',
  },

  StudiesBasket: {
    studyBasketTitle: 'Temporary Study Basket',
    ...actionList,
    someStudiesAreLocked:
      'Buttons are not available because some of the studies are currently being processed further.',
    cannotAction:
      'The {{action}} button is not available because selected studies from the {{archives}} archives do not support this operation.',
    cannotActionMoreArchives:
      'The {{action}} button is not available because selected studies are from different archives.',
    cannotMoveAvailability:
      'The Move button is not available because some of the studies are in the Archived state.',
    cannotArchiveAvailability:
      'The Archive button is not available because studies can only be archived in the Online state.',
    openInBrowser: {
      viewerByTypeMWV:
        'The Open in Browser button is not available because studies can only be viewed using WebVision.',
      onlyFrom1Archive:
        'The Open in Browser button is not available because studies can only be viewed from one archive.',
      only1StudyFromMSArchive:
        'The Open in Browser button is not available because only 1 study can be viewed from the MS archive.',
      max20Studies:
        'The Open in Browser button is not available because a maximum of 20 studies can be viewed.',
      noDefViewer: 'The Open in Browser button is not available because no viewer is defined.',
    },
    ...archiving,
    ...dataForShredding,

    confirmRemoveStudyFromBasket:
      'Are you sure you want to remove the selected study from the temporary study basket?',
    confirmRemoveStudiesFromBasket:
      'Are you sure you want to remove the selected studies from the temporary study basket?',
    confirmRemoveAllStudiesFromBasket:
      'Are you sure you want to remove all studies from the temporary study basket?',
  },

  Comment: {
    create: 'Comment',
    text: 'Comment for study',
    textMWL: 'Comment for order',
    errorSaving: 'Error saving comment',
    changeStateError: 'Failed to change status.',
    incorrect_certificate_password: 'The correct password to create the signature was not entered.',
    certificate_not_found: 'The selected certificate was not found on the server.',
    ...dialogTexts,
  },

  StudyTags: {
    successSaving: 'Tag added',
    errorSaving: 'Error adding tag',
    undefined: ' ',
    select: 'Select',
    ...dialogTexts,
    registerItemSaved: "Tag '{{registerItemName}}' saved successfully",
    errorSaveRegisterItem: 'Error saving tag to database',
    create: 'Create',
  },

  StudyExport: {
    format: 'Format',
    frameState: 'Frame rate',
    useCompress: 'Use compression',
    addStudyReport: 'Attach report',
    patientName: 'Patient name',
    modality: 'Modality',
    dateStudyCreated: 'Date study created',
    timeStudyCreated: 'Time study created',
    dateExportSelected: 'Date export selected',
    timeExportSelected: 'Time export selected',
    exportType: 'Export type',
    exportState: 'State',
    exportValidity: 'Validity',
    size: 'Size',
    available: 'Available',
    unavailable: 'Unavailable',
    seriesOfStudy: 'Series',
    dateTime: 'Date and time study created',
    exportDateTime: 'Date and time of export',
    downloadableTo: 'Valid until',
    validExports: 'Valid exports',
    allExports: 'All exports',
    // Status
    APPROVED: 'Approved',
    FINISHED: 'Finished',
    FAILED: 'Failed',
    // Type
    PICTURE: 'Pictures',
    VIDEO: 'Video',
    DICOM: 'Study',
    IMG_CD: 'Image CD',
    IMG_DVD: 'Image DVD',
    downloadError: 'Failed to download file',
  },

  Orders: {
    actions: 'Actions',
    title: 'Request List',
    detail: 'Request Detail',
    create: 'Create New Request',
    edit: 'Edit Request',
    patient: 'Patient',
    list: 'Request List',
    description: 'Request Description',
    identificationNumber: 'Patient ID',
    dateBirth: 'Date of Birth',
    sex: 'Gender',
    healthInsuranceCompany: 'Health Insurance Company',
    originalDatetimeRequested: 'Original Date and Time Requested',
    originalDateRequested: 'Original Date Requested',
    originalTimeRequested: 'Original Time Requested',
    expertness: 'Expertness',
    diagnosisBasic: 'Diagnosis Code',
    diagnosisOther: 'Other Diagnoses',
    detail_size: 'Patient Height',
    detail_weight: 'Patient Weight',
    requestingPhysician: 'Requesting Physician',
    referringPhysician: 'Referring Physician',
    performingPhysician: 'Performing Physician',
    priority: 'Priority',
    requestedProcedure: 'Requested Procedure',
    station: 'Station',
    unit: 'Unit',
    modality: 'Modality',
    icp: 'ICP',
    other: 'Other',
    name: 'Last Name and First Name',
    accessionNumber: 'Request Accession Number',
    createdWhen: 'Created Date',
    datetime: 'Scheduled Date and Time',
    state: 'State',
    saved: 'Request successfully saved.',
    errorSaving: 'Failed to save request.',
    submitError: 'An error occurred while searching.',
    ...sexes,
    comments: 'Comments',
    attachments: 'Attachments',
    action: {
      addComment: 'Add Comment',
      addAttachment: 'Add Attachment',
      createStudy: 'Create Study',
      print: 'Print',
      edit: 'Edit',
      showStudyOrder: 'Show Study Order',
    },
    orderNotFound: 'Failed to load request.',
    orderFromStudyNotFound: 'Request for this study does not exist.',
    deleted: 'Modality worklist successfully deleted.',
    ...name,
    ...checkName,
    ...dialogTexts,
    ...allowedExtensions,
    noData: 'No records found.',
    print: {
      title: 'Study Cover Sheet:',
      patientName: 'Patient',
    },
    isNotFacilityCode: 'Facility code is not filled in, contact administrator.',
    back: 'Back',
    ...sizeAndWeight,
    errorDeleting: 'Failed to delete request.',
    healthInsuranceCompanyRequired: 'Select health insurance company.',
    expertnessRequired: 'Select expertness.',
    ...select,
    addPhysician: 'Add Physician',
    orderDetail: 'Request Detail',
    orderDelete: 'Delete Request',
    orderEdit: 'Edit Request',
    confirmDelete: 'Are you sure you want to delete?',
    ...messagesForGrid,
    mwlHasAttachments: 'Request has at least one attachment.',
    dicom: 'DICOM Data',
    nondicom: 'Multimedia Data',
    ...errors,
  },

  Request: {
    requestsList: 'Request List',
    requestsListForSplit: 'Request List for Split',
    requestsListFor: {
      editPatient: 'Request List for Patient Edit',
      editStudy: 'Request List for Study Edit',
      move: 'Request List for Move',
      copy: 'Request List for Copy',
    },
    requestsListForReorder: 'Request List for Reorder',
    requestsListForSend: 'Request List for Send',
    requestsListForMoveToFolder: 'Request List for Move to Folder',
    requestsListForMoveToTrash: 'Request List for Move to Trash',

    createdWhen: 'Created Date',
    typeOfOperation: 'Operation Type',
    state: 'Request State',
    studyState: 'Study State',
    empty: '-',
    aborted: 'Aborted',
    approved: 'Approved',
    failed: 'Failed',
    finished: 'Finished',
    waiting: 'Waiting',
    comment: 'Comment',
    new: 'New',
    rejected: 'Rejected',
    requestingUser: 'Requesting User',
    studyDate: 'Study Date',
    queueRequestDetail: 'Request Detail',
    accessionNumber: 'Accession Number',
    requestNotFound: 'Request not found',
    ...studyFunctions,
    search: 'Search',
    'requestEdit.study.create': 'Create New Study Edit Request',
    'requestEdit.patient.create': 'Create New Patient Edit Request',
    requestSendCreate: 'Create New Send Request',
    requestSplitCreate: 'Create New Split Request',
    'request.move.create': 'Create New Move Request',
    'request.copy.create': 'Create New Copy Request',
    requestMoveToFolderCreate: 'Create New Move to Folder Request',
    requestMoveToTrashCreate: 'Create New Move to Trash Request',
    chooseMultipleStudiesForRequest: 'Select Studies for Request',
    backToRequestsList: 'Back to Request List',
    selectedStudies: 'Selected Studies',
    requestUser: 'Request User',
    saved: 'Request successfully saved.',
    errorSaving: 'Failed to save request',
    patient: 'Patient',
    reset: 'Reset',
    ...sexes,

    requestStateChanged: 'Request state changed',
    errorChangingState: 'Error changing request state',
    name: 'Name',
    surname: 'Surname',
    owner: 'Owner',
    comments: 'Comments',
    history: 'History',
    oldState: 'Original State',
    newState: 'New State',
    author: 'Author',
    studies: 'Selected Studies',
    sourceStudy: 'Source Study',
    sourceNewStudy: 'Source Study after Approval',
    targetStudy: 'Target Study',
    newStudy: 'New Study',
    newPlannedStudy: 'Future Study',
    oldStudy: 'Original Study',
    repairStudy: 'Repaired Study',
    copyStudies: 'Copied Studies',
    moveStudies: 'Moved Studies',
    ...gridStudyItems,

    'operationType.other': '?',
    'operationType.send': 'Send Settings',
    'operationType.reorder': 'Series and Images Selection',
    'operationType.split': 'Split Settings',
    'operationType.copy': 'Copy Settings',
    'operationType.move': 'Move Settings',
    'operationType.move_to_folder': 'Selected Worklists',

    // Send
    'data.sendType': 'Send Type',
    'data.facility': 'Recipient (Healthcare Facility)',
    'data.exchangeNetwork': 'Exchange Network',
    'data.sendType.ExchangeNetwork': 'Exchange Network',
    'data.sendType.DrSejf': 'Dr. Sejf',
    'data.sendType.CloudPacs': 'CloudPacs',
    'data.sendType.mDex': 'MDex',
    'data.viewer': 'Viewer',
    'data.includeStudyReport': 'Report',
    'data.anonymization': 'Anonymization',
    'data.patientID': 'Patient ID',

    // Edit
    ...dataForEdit,
    inArchive: 'in Archive ',

    // Reorder
    'data.series': 'Number of Selected Series',
    'data.images': 'Number of Selected Images',
    // Split
    'data.includeComments': 'Include Comments',
    // Distribute: Copy / Move
    'data.archiveTo': 'Target Archive',

    ...yesno,
    ...requestsActions,
    ...dialogTexts,
  },

  Reports: {
    titleStatistics: 'Statistics',
    show: 'Show',
    chartType: 'Chart Type',
    archives: 'Archives',
    stationNames: 'Station Names',
    INCREMENTAL: 'Incremental',
    CUMULATIVE: 'Cumulative',
    year: 'Year',
    month: 'Month',
    sum: 'Total occupancy of selected stations',
    labelY: 'Storage Occupancy [GB]',
    labelQuantityY: 'Number of Studies',
    cannotWiewStatisticNow:
      'Due to lack of data, statistics cannot be displayed at the moment. Please try again later.',
    undefined: 'Undefined',
    titleStudiesByStudyTagsStatistics: 'Statistics by Tags',
    tagName: 'Tag Name',
    tagCount: 'Number of Studies with Tag',
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
    setAllStations: 'All Stations',
    setNoStations: 'No Station',
    modalityNames: 'Modalities',
    setAllModalities: 'All Modalities',
    setNoModalities: 'No Modality',
    filling: 'Storage Occupancy',
    quantity: 'Number of Studies',
    byModalities: 'By Modalities',
    byAets: 'By Stations',
  },

  FindingReports: {
    user: 'User',
    hospital: 'Hospital',
    studyDate: 'Activity date',
    search: 'Search',
    physicianName: 'Physician name',
    hospitalName: 'hospital',
    findingCount: 'Finding count',
  },

  Logs: {
    auditLogList: 'User Activities',
    dateTime: 'Date and Time',
    username: 'Username',
    ipAddress: 'IP Address',
    activity: 'Activity',
    subject: 'Product',
    typeOfObject: 'Object Type',
    patientID: 'Patient ID',
    note: 'Note',
    noData: 'No records',
    requestId: 'Request ID',
    ...messagesForGrid,
  },

  Users: {
    username: 'Username',
    firstname: 'First Name',
    lastname: 'Last Name',
    middlename: 'Middle Name',
    prefix: 'Prefix',
    suffix: 'Suffix',
    search: 'Search',
    retired: 'With terminated employment',
    retire: 'Terminate employment',
    retiredDate: 'Employment will be terminated as of the date',
    retireInfoText:
      "The employment of the user {{firstName}} {{lastName}} ({{username}}) will be terminated as of {{retiredDate}}. The system will retain the user's personal data until {{dateTo}}. After this date, all data about the user will be removed",
    revertInfoText: 'The employment of the user was terminated as of {{retiredDate}}',
    cancelTermination: 'Cancel the termination of employment',
    reallyCancelTermination: 'Really cancel the termination of employment?',
    afterRevert: 'User editing will be allowed afterwards',
    cancelTerminationSuccessfully: 'The termination of employment was successfully canceled',
    errorSavingRevert: 'Failed to cancel the termination of employment',
    errorSavingNoRetired: 'Failed to terminate employment',
    userRetired: "The user's employment has been terminated",
    icp: 'ICP',
    userIsInternal: 'Internal User',
    userCanBeNotifiedAboutRequestActions: 'Show Notifications About Requests',
    workPlaces: 'Work Places',
    units: 'Units',
    create: 'Create New User',
    edit: 'Edit User',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    invalidPassword: 'Password and Confirm Password do not match',
    saved: 'User successfully saved.',
    deleted: 'User successfully deleted.',
    errorDeleting: 'Failed to delete user.',

    userProfile: 'User Profile',
    userProfileWorkPlaces: 'Work Places',
    userProfileViewer: 'Viewer',
    userProfileLanguage: 'Language',
    userProfileButtonsSelected: 'Selected Tiles',
    userProfileButtonsUnselected: 'Unselected Tiles',
    userProfileButtonsSetAutomaticOpened: 'Automatically Opened on Login',
    userProfileButtons: 'Tiles',
    userProfileNotifications: 'Notifications',
    userProfileFacilities: 'Facilities',
    userProfileSetNotification: 'Set Notification Type',
    userProfileCertificates: 'Signature certificates',
    darkMode: 'Dark Mode',

    // Event types
    ...eventTypes,

    // Reaction types
    ...reactionTypes,

    ...yesno,

    save: 'Save',
    useLocalUserDirectory: 'Local Directory',
    // Form errors
    error: {
      generalError: 'Error while saving user.',
      apiNotWorking: 'API server is not responding. Please contact technical support.',
      errorSaving: 'Error while saving user.',
      errorUserExists: 'Username already exists.',
      invalidPassword: 'Password and Confirm Password do not match.',
      errorUserPasswordRequired: 'Please enter a password.',
    },

    checkUserPassword: 'Password expiration check',
    openUserProfile: 'Open user profile',
    passwordExpired: 'Your password has already expired. Enter a new password ',
    passwordWillExpired: 'Your password will expire in {{inDays}}. Enter the new password ',
    passwordExpiredLink: 'in the user profile',
    withTotp: 'With active TOTP',
    resetTotpDialog: 'Reset TOTP settings',
    resetTotpForUser: 'Really reset TOTP settings for user',
    reset: 'Reset',
    storno: 'Storno',
    totpResetSuccess: 'TOTP settings reset',
    totpResetError: 'TOTP settings could not be reset',
  },

  NotificationTemplates: {
    eventType: 'Event Type',
    reactionType: 'Reaction Type',
    create: 'Create New Notification Template',
    missingTemplates: 'Missing notification template for these events',
    ...header,

    // Reaction types
    ...reactionTypes,

    // Event types
    ...eventTypes,

    // Errors
    error: { notification: { template: { exists: 'Notification template already exists' } } },
    errorDeleting: 'Error deleting template',
    deleted: 'Template deleted',
    saved: 'Template saved',
    errorSaving: 'Error saving template',
    edit: 'Edit Notification',

    dialog: { notificationSetting: 'Notification Setting' },
    ...dialogTexts,
  },

  FacilitiesAndExchangeNetworks: {
    setFacilities: 'Facilities',
    setExchangeNetwork: 'Exchange Networks',
  },
  ExchangeNetworks: {
    edit: 'Edit Network',
    name: 'Name',
    description: 'Description',
    ip: 'IP',
    port: 'Port',
    urlForUpdate: 'URL for Update',
    color: 'Color',
    errorSaving: 'Error saving network.',
    saved: 'Network saved successfully.',
    exchangeNetworkNotFound: 'Network not found.',
  },
  RegisterGroups: {
    name: 'Name',
    parent: 'Parent Group',
    children: 'Subgroup',
    edit: 'Update Group of Tags',
    create: 'New Group of Tags',
    createSubRegisterGroups: 'Create New Subgroup of tags',
    editRegisterItem: 'Edit Tag in Group',
    createRegisterItem: 'Create New Tag in Group',
    parentRegisterItems: 'Parent Items',
    registerItems: 'Tags in Group',
    subgroups: 'Subgroups',
    newRegisterSubgroup: 'New Subgroup',
    newRegisterItem: 'New Item',
    state: 'State',
    active: 'Active',
    inactive: 'Inactive',
    registerGroupNotFound: 'Group not found',
    registerItemNotFound: 'Item not found',
    confirmChangeState: 'Are you sure you want to change the state of',
    errorSaveRegisterGroup: 'Error saving to database',
    registerItemSaved: "Item '{{registerItemName}}' saved successfully",
    errorSaveRegisterItem: 'Error saving to database',
    errorSaveRegisterItemIsUsedName: 'Tag name is already used',
    errorRegisterGroupExists: 'Group already exists.',
    errorSaving: 'Error saving group.',
    saved: 'Group saved successfully.',
  },

  Facilities: {
    favourite: 'Favorite',
    action: 'Action',
    create: 'Create New Device',
    edit: 'Edit Device',
    facility: 'Healthcare Facility',
    code: 'Device Code',
    name: 'Name',
    epacs: 'ePacs',
    redimed: 'ReDiMed',
    city: 'Address',
    country: 'Country',
    errorSaving: 'Error saving device.',
    saved: 'Device saved successfully.',
    facilityNotFound: 'Facility not found.',
    facilityExchangeNetworkNotFound: 'Exchange network not found.',
    facilityExchangeNetworks: 'Exchange Networks',
    facilityExchangeNetwork: {
      exhangeNetwork: { name: 'Network Name' },
      identifier: 'Access Point Identifier',
      add: 'Add Network for Device',
      edit: 'Edit Network for Device',
    },
    exchangeNetworkSaved: 'Exchange network saved successfully.',
    exchangeNetworkErrorSaving: 'Error saving exchange network.',
    deleted: 'Exchange network removed from device.',
    errorDeleting: 'Failed to remove exchange network.',
    isNotValid:
      'Either local facility is not set or local facility does not have a valid device code (unique 4-character code).',
    updateFacilitiesByRemoteSource: 'Update device list',
    updateFacilitiesByRemoteSourceSuccessfully: 'Device list updated.',
    updateFacilitiesByRemoteSourceError: 'Failed to update device list.',
    addFavourite: 'Add to Favorites',
    removeFavourite: 'Remove from Favorites',
    changeFacilities: {
      success: 'Change in favorite devices successful.',
      error: 'Failed to change favorite devices.',
    },
  },

  Import: {
    reset: 'Reset',
    dicom: {
      title: 'Import DICOM data',
      grid: {
        patientName: 'Last Name and First Name',
        patientId: 'Patient ID',
        studyDate: 'Study Date',
        patSex: 'Sex',
        studyDescription: 'Study Description',
        files: 'Number (Size)',
      },
      editDicomTagsDialog: 'Edit DICOM Attributes',
      serieNumber: 'Series No. {{number}}',
      numberAndDescription: '(number of images: {{number}}, description:{{description}})',
    },
    nonDicom: {
      title: 'Import Multimedia Data',
      grid: { fileName: 'File Name', size: 'Size' },
    },
    importWebcam: 'Add Images from Webcam',
    orderSearch: 'Search Order',
    patientManagementSearch: 'Search Patient',
    findStudy: 'Search Study',
    allowedExtensions: 'Files in {{rules}} format are allowed',
    archive: 'Target Archive',
    delete: 'Delete',
    confirm: 'Confirm',
    close: 'Close',
    notifications: {
      success: 'Import request has been submitted for processing.',
      error: 'Failed to process the data import request.',
      mdexSuccess: 'The request to send via MDex has been successfully processed.',
      mdexError: 'The request to send via MDex could not be processed.',
    },
    modality: 'Modality',
    save: 'Save',
    uploadFiles: 'Upload Files',
    uploadFolder: 'Upload Folder',
    removeAll: 'Remove All',
    unknownFormat: 'Unknown Format',
    noDecubits: 'No items to select',
    ...sexes,
    uppy: {
      browseFile: 'browse file',
      browseFiles: 'browse files',
      browseFolders: 'browse folders',
      dropPasteBoth: 'Drop files or folder, %{browseFiles} or %{browseFolders}',
      dropPasteFile: 'Drop file or %{browseFiles}',
      dropPasteFiles: 'Drop files or %{browseFiles}',
      browseFileUDI: 'browse file',
      dropPasteFileUDI: 'Drop file or %{browseFiles}',
      importUDIFile: 'Import UDI from file',
      camera: 'Camera',
      noCameraTitle: 'Camera not available',
      noCameraDescription:
        'In order to take pictures or record video, please connect a camera device',
    },
    uploadingFiles: 'Uploading files is in progress...',
    editFileDialog: 'Certificate import',
  },

  Studies: {
    ...studyFunctions,
    ...sizeAndWeight,
    ...errors,
    ...openInBrowser,
    automaticRefresh: 'Automatic Refresh',
    studyListTitle: 'Study Search',
    studyReorderTitle: 'Study Reorder',
    studyReorderTitleRequest: 'New Study Reorder Request',
    studyReorderTitleRequestEdit: 'Edit Study Reorder Request',
    studyEditTitle: 'Study Edit',
    patientEditTitle: 'Patient Edit',
    studyExportTitle: 'Study Export',
    multipleEdit: 'Edit',
    selectedStudies: 'Selected Studies',
    existingStudy: 'Existing Study',
    existingSourceStudy: 'Source Study',
    existingTargetStudy: 'Target Study',
    newStudy: 'New Study',
    patientID: 'Patient ID',
    sex: 'Sex',
    dateOfBirth: 'Date of Birth',
    dateCreated: 'Date Created',
    timeCreated: 'Time Created',
    accessionNumber: 'Accession Number',
    studyDescription: 'Study Description',
    archive: 'Archive',
    ...sexes,
    other: 'Other',
    loadFromExisting: 'Load from Existing Study',
    reset: 'Reset',
    studySendType: 'Send Type',
    cloudRecipient: 'Cloud Recipient',
    facility: 'Facility',
    selectExchangeNetwork: 'Selected Network',
    epacs: 'ePacs',
    redimed: 'Redimed',
    sendTypeExchangeNetworks: 'Exchange Network',
    sendTypeCloudsPacs: 'CloudPacs',
    sendTypeDrSejf: 'drSejf',
    viewer: 'Viewer',
    includeStudyReport: 'Include Study Report',
    annotations: 'Add Patient Data to Images',
    facilities: 'Facilities',
    seriesOfExistingStudy: 'Series of Existing Study',
    seriesOfNewStudy: 'Series of New Study',
    created: 'Created',
    instanceNumber: 'Instance Number',
    save: 'Save',
    findOrder: 'Find Order',
    loadFromOrder: 'Load from Order',
    patient: 'Patient',
    study: 'Study',
    birthDate: 'Birth Date',
    studyDate: 'Study Date',
    studyTime: 'Study Time',
    find: 'Find',
    name: 'Last Name and First Name',
    dateTime: 'Date and Time Created',
    unit: 'Unit',
    modality: 'Modality',
    studyList: 'Study List',
    otherOptions: 'Other Options',
    includeComments: 'Include Comments from Existing Study',
    errorRealisator: 'Error Communicating with Realisator',
    referringPhysician: 'Referring Physician',
    requestingPhysician: 'Requesting Physician',
    performingPhysician: 'Performing Physician',
    splitSend: 'Split Study',
    patientAttributesChangedConfirm:
      'You have changed one of the attributes: name, last name, or patient ID. Confirm the change?',
    errorCreatingRequest: 'Error Creating Request',
    ...searchDateButtons,
    originalDatetimeRequested: 'Planned Date and Time',
    ...name,
    ...checkName,
    multipleAddToFolder: 'Add to Folder',
    studyListAddToFolderTitle: 'Add Study to Folder',
    addToFolder: 'Add',
    selectFolder: 'Select Folder',
    ...select,
    folder: 'Folder',
    notSelectedFolder: 'Select a Folder',
    studySelectedToFolder: 'Study successfully added to folder',
    errorSelectingStudyToFolder: 'Failed to add study to folder',
    errorConflictingStudies:
      'Failed to add study to folder due to conflicting studies - patient IDs:',
    studyInstanceUid: 'StudyInstanceUid',
    editDicomAttributes: 'Edit DICOM Attributes',
    modalities: 'Modalities',
    patientBirthDate: 'Patient Birth Date',
    archiveName: 'Archive',
    patientId: 'Patient ID',
    patientSex: 'Patient Sex',
    noOfInstances: 'No. of Instances',
    removeSourceStudyForReorder: 'Remove Source Study for Reorder',

    // Edit
    edit: 'Edit',
    studyEditSubmitOk: 'Request for study edit submitted successfully.',
    patientEditSubmitOk: 'Request for patient edit submitted successfully.',
    editSubmitError: 'Failed to submit study edit request.',
    setRequestForEditStudy: 'Create request for study edit',
    setRequestForEditStudyEdit: 'Edit request for study edit',
    setRequestForEditPatient: 'Create request for patient edit',
    setRequestForEditPatientEdit: 'Edit request for patient edit',
    requestForStudyEditAdded: 'Request for study edit created',
    requestForStudyEditEdited: 'Request for study edit edited',
    requestForPatientEditAdded: 'Request for patient edit created',
    requestForPatientEditEdited: 'Request for patient edit edited',
    editTypeStudyTitleRequest: 'New request for study edit',
    editTypeStudyTitleRequestEdit: 'Edit request for study edit',
    editTypePatientTitleRequest: 'New request for patient edit',
    editTypePatientTitleRequestEdit: 'Edit request for patient edit',
    patientLocked:
      'Cannot edit patient because one or more studies are being processed. Please try again later.',
    editPatientDataConfirm:
      'You have made changes to patient data. Do you really want to apply the changes to all studies of this patient?',
    editPatientDataForStudyConfirm:
      'You have made changes to patient data. Do you really want to apply the changes to this study?',
    editConfirmTitle: 'Edit patient data',
    confirm: 'Confirm',
    showInfoAboutRequestingPhysician:
      'Information about the requesting physician is not available for this type of archive.',
    studiesEditStudy: {
      patient: 'Patient',
      study: 'Study',
    },
    fixWillChangeAllStudiesOfSelectedePatient:
      'The fix will change all studies of the selected patient.',

    // Split
    studySplitTitle: 'Split study',
    studySplitTitleRequest: 'New request for study split',
    studySplitTitleRequestEdit: 'Edit request for study split',
    split: 'Split',
    setRequestForSplit: 'Create request for split',
    editRequestForSplit: 'Edit request for split',
    successfullySplit: 'Request for study split submitted successfully',
    errorSplitting: 'Failed to submit request for study split',
    requestForSplitAdded: 'Request for study split created',

    // Archiving
    ...archiving,

    // Send
    studySendTitle: 'Send study',
    multipleSend: 'Send',
    studySend: 'Send study',
    send: 'Send',
    submitSendError: 'Failed to process request for study send.',
    submitSendOk: 'Request for study send submitted successfully.',
    studySendTitleRequest: 'New request for study send',
    studySendTitleRequestEdit: 'Edit request for study send',
    setRequestForSend: 'Create request for send',
    requestForSendAdded: 'Request for send created',
    selectFacility: 'Select facility...',
    selectRecipient: 'Select cloud recipient...',
    selected: { studies: 'Number of selected studies: ' },
    // selected: {studies: {and: {series: 'Number of selected studies and series: '}}},
    selected_studies_and_series: 'Number of selected studies and series: ',
    setRequestFor: {
      send: 'Create request for send',
      moveToFolder: 'Create request for add to worklist',
      moveToTrash: 'Create request for delete',
      move: 'Create request for move',
      copy: 'Create request for copy',
    },
    editRequestFor: {
      send: 'Edit request for send',
      moveToFolder: 'Edit request for add to worklist',
      moveToTrash: 'Edit request for delete',
      move: 'Edit request for move',
      copy: 'Edit request for copy',
    },
    editRequestForSend: 'Edit request for send',
    favourite: 'Favorite',
    others: 'Others',
    selected_documents: 'Number of selected documents: ',

    studyMoveToFolderTitleRequest: 'New request for add study to worklist',
    studyMoveToFolderTitleRequestEdit: 'Edit request for add study to worklist',
    setRequestForMoveToFolder: 'Create request for add to worklist',
    requestForMoveToFolderAdded: 'Request for add to worklist created',
    editRequestForMoveToFolder: 'Edit request for add to worklist',
    requestCreate: {
      moveToFolder: {
        confirmation: 'Do you really want to create a request to add the study to the worklist?',
      },
      moveToTrash: { confirmation: 'Do you really want to create a request to delete the study?' },
      move: { confirmation: 'Do you really want to create a request to move the study?' },
      copy: { confirmation: 'Do you really want to create a request to copy the study?' },
      send: { confirmation: 'Do you really want to create a request to send the study?' },
    },
    requestEdit: {
      moveToFolder: {
        confirmation: 'Do you really want to edit the request to add the study to the worklist?',
      },
      moveToTrash: { confirmation: 'Do you really want to edit the request to delete the study?' },
      move: { confirmation: 'Do you really want to edit the request to move the study?' },
      copy: { confirmation: 'Do you really want to edit the request to copy the study?' },
      send: { confirmation: 'Do you really want to edit the request to send the study?' },
    },

    // Trash
    studyMoveToTrashTitleRequest: 'New request for delete study',
    studyMoveToTrashTitleRequestEdit: 'Edit request for delete study',
    setRequestForMoveToTrash: 'Create request for delete',
    requestForMoveToTrashAdded: 'Request for delete created',
    editRequestForMoveToTrash: 'Edit request for delete',

    // Distribute
    studyAction: {
      move: {
        title: 'Move study',
        confirmation: 'Do you really want to move the study?',
      },
      copy: {
        title: 'Copy study',
        confirmation: 'Do you really want to copy the study?',
      },
      send: {
        title: 'Send study',
        confirmation: 'Do you really want to send the study?',
      },
      request: {
        move: 'New request for move study',
        copy: 'New request for copy study',
      },
      requestEdit: {
        move: 'Edit request for move study',
        copy: 'Edit request for copy study',
      },
    },
    study_move: 'Move study',
    study_copy: 'Copy study',

    document: { send_documents: { confirmation: 'Do you really want to send the documents?' } },
    selectedStudiesAndSeries: 'Number of selected studies and series: ',
    multiple: {
      move: 'Move',
      copy: 'Copy',
    },
    move: 'Move',
    copy: 'Copy',
    close: 'Close',
    submit: {
      move: {
        error: 'Failed to process request for study move.',
        ok: 'Request for study move submitted successfully.',
      },
      copy: {
        error: 'Failed to process request for study copy.',
        ok: 'Request for study copy submitted successfully.',
      },
    },
    archiveTo: 'Target archive',

    requestFor: {
      move: {
        added: 'Request for move created',
        updated: 'Request for move edited',
      },
      copy: {
        added: 'Request for copy created',
        updated: 'Request for copy edited',
      },
    },
    distributeAndUpdateStudy: 'Edit study',
    moveNoRights:
      'Cannot move the study. This user does not have the rights to move to another archive. Please contact the Portal module administrator.',
    copyNoRights:
      'Cannot copy the study. This user does not have the rights to copy to another archive. Please contact the Portal module administrator.',

    // Export
    exportType: 'Export Type',
    compression: 'Enable Compression',
    report: 'Add Report',
    multipleExport: 'Export',
    author: 'Author',
    reportTitle: 'Report',
    date: 'Date',
    text: 'Message',
    export: 'Export',
    quality: 'Quality',
    framerate: 'Frame Rate',
    successfullyExport: 'Request for study export submitted',
    errorExport: 'Error submitting request for study export',
    errorBadImage: 'Invalid request, cannot export',
    totalSize: 'Total Size',
    size: 'Size',
    maxSize: 'Max Allowed Size',
    maxSizeExceeded: 'Max size exceeded, change selection',
    loadingValue: 'Loading value...',
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
    studyExportFormat: 'Study Export Format',
    exportConfirmTitle: 'Confirm Study Export',
    exportConfirmMorePatientId: 'Selected studies have different patient IDs.',
    exportConfirmMissingReport: 'One of the exported studies is missing a report.',
    exportConfirmCalculationError: 'Failed to calculate total size.',
    calculationError: 'unknown',

    // Studies, series, images list
    studyPatientId: 'Patient ID: {{patientId}}',
    studyDateBirth: 'Date of Birth: {{dateBirth}}',
    studyDateTime: 'Study Time: {{dateTime}}',
    studySeriesImages: 'Series/Images: {{series}}/{{images}}',
    serieImages: 'Images: {{images}}',

    // Reorder
    sourceStudy: 'Source Study',
    targetStudy: 'Target Study',
    searchSourceStudy: 'Search Source Study',
    searchTargetStudy: 'Search Target Study',
    studyContainsComment: 'Note: Study contains a comment',
    cannotReorderSameStudies: 'Cannot reorder the same study',
    reorderSend: 'Reorder Study',
    reorder: 'Reorder',
    studyWillBeDeleted:
      'All series from the source study will be moved. Source study will be deleted.',
    seriesOfSourceStudy: 'Series of Source Study',
    seriesOfTargetStudy: 'Series of Target Study',
    addedToNewStudy: 'Series added to target study',
    successfullyReordered: 'Request for study reorder submitted',
    errorReordering: 'Error submitting request for study reorder',
    sourceStudySelected: 'Source study added',
    targetStudySelected: 'Target study added',
    sourceStudyRemoved: 'Source study removed',
    targetStudyRemoved: 'Target study removed',
    setRequestForReorder: 'Create request for reorder',
    editRequestForReorder: 'Edit request for reorder',

    // Requests
    orderSearch: 'Search Order',
    requestComment: 'Request Comment',
    additionalRequestForm: 'Additional Request Information',
    requestingUser: {
      lastName: 'User Last Name',
      firstName: 'User First Name',
    },
    requestForReorderAdded: 'Request for reorder created',
    errorEditingRequest: 'Error editing request',
    requestEdited: 'Request edited',

    // Scientific archive
    scientificArchiveTitle: 'Search in Scientific Archive',
    // patientManagement
    patientManagementSearch: 'Search Patient',
    findPatientManagement: 'Find Patient',
    patientManagementAnnouncement:
      'Enter the full last name and at least two characters of the first name, or enter the full patient ID (birth number).',
    loadingData: 'Searching for studies in the archive ',
    findStudy: 'Find Study',

    // Signing
    submitSigningError: 'Failed to process request for study signing.',
    submitSigningOk: 'Request for study signing submitted successfully.',

    // Shredding
    ...dataForShredding,

    // Dose monitoring
    doseMonitoringTitle: 'Dose Monitoring',
    doseMonitoringExport: 'Export',
    noData: 'No records',
    doseError: 'Failed to retrieve information',
    doseErrorInIE:
      'Failed to calculate dose. Try refreshing the page or using a different browser.',
    bodyPartExamined: 'Body Part Examined',
    dose: 'Dose',

    ...allowedExtensions,
    allowedFilesDicom: 'Only DICOM files are allowed.',
    uploadingFiles: 'Uploading files...',

    images: 'Images',
    timeTakenFromSeries: 'Time taken from series',
    openInBrowser: 'Open in Browser',
    openReportTitle: 'View Report',
    modalitiesInStudy: 'Modality',
    addModality: 'Add New Modality',

    user: 'Author',
    operation: 'Operation',

    ...togglePreview,

    comment: 'Comment',

    // shreddings
    shreddingProposalsTitle: 'List of Shredding Proposals',
    shreddingConfirmedTitle: 'Shredding History',

    patientName: 'Patient Name',
    confirmationDate: 'Shredding Confirmation Date',
    userConfirmation: 'Confirmation Author',
    userProposal: 'Proposal Author',
    shreddingPrintTitle: 'Shredding Execution Protocol',
    shreddingPrintContent1:
      'A total of {{par1}} documents were shredded by permanent deletion from the PACS archives.',
    shreddingPrintContent:
      'A total of {{par1}} studies were shredded by permanent deletion from the PACS archives.',
    action: { print: 'Print' },

    ...printSetting,
    ...sejfSendForm,

    seriesUID: 'Series UID',
    submitDeletingError: 'Deletion request not accepted.',
    submitDeletingOk: 'Deletion request accepted.',
    submitDeletingOkAndNok: 'Deletion request accepted for {{par1}} studies.',
    confirmMoveToTrashTitle: 'Move Selected Studies to Trash?',
    confirmMoveToTrash:
      'You have selected {{par1}} studies for deletion. Are you sure you want to perform this operation?',
    confirmArchivingStudiesTitle: 'Archive Studies',
    confirmArchivingStudies:
      'You have selected {{par1}} studies for archiving. Are you sure you want to perform this operation?',

    ...errorViewerMessages,

    // import - decubits
    addImageType: 'Add Image Type',
    doNotAddImageType: 'Do Not Add Image Type',
    imageType: 'Image Type',
    selectBodyPart: 'Select Body Part',
    ...decubitsCode,
    M: 'mDex',
    E: 'ePacs',
    R: 'Redimed',

    allowOverwrite: 'Overwrite',
    editingFromOrder:
      'You are editing the data taken from the request. The operation may cause data inconsistency.',
    editingFromStudy:
      'You are editing data taken from study. The operation may cause data inconsistency.',

    cannotSplitWithAllSelected: 'Cannot split study if all series are selected',

    couldNotLoadSeries: 'Could not load series for study',
    couldNotLoadDicomData: 'Could not load DICOM data for study',

    listOfSeries: 'List of Series',
    studyUID: 'StudyInstanceUid',

    reportForStudyNotFound: 'There is no report for this study',
    dosesForStudyNotFound: 'There are no doses for this study',
  },

  Grid: {
    noData: 'No records',
    ...messagesForGrid,
    actions: 'Actions',
    edit: 'Edit',
    edit_group: 'Edit',
    edit_register_item: 'Edit',
    edit_order: 'Edit',
    edit_redirect: 'Open',
    editableColumn: 'Edit value',
    removeStudyFromFolder: 'Remove study from worklist',
    confirmRemoveStudyFromFolder: 'Are you sure you want to remove the study from the worklist?',
    removeUserFromFolder: 'Remove user from worklist',
    confirmRemoveUserFromFolder: 'Are you sure you want to remove the user from the worklist?',
    removeDocumentFromFolder: 'Remove documentation from worklist',
    removeDocumentsFromFolder: 'Remove documentations from worklist',
    removeSerieFromFolder: 'Remove series from worklist',
    confirmRemoveSerieFromFolder: 'Are you sure you want to remove the series from the worklist?',
    confirmRemoveDocumentFromFolder:
      'Are you sure you want to remove the documentation from the worklist?',
    confirmRemoveDocumentsFromFolder:
      'Are you sure you want to remove the documentations from the worklist?',
    select: 'Select',
    select_order: 'Select',
    select_patient_management_to_import: 'Select',
    detail_order: 'Detail',
    choose: 'Select',
    delete: 'Delete',
    delete_order: 'Delete',
    delete_file: 'Delete',
    delete_attachment: 'Delete',
    deleteSerie: 'Delete',
    cancel_upload: 'Cancel',
    download: 'Download',
    confirmDelete: 'Are you sure you want to delete?',
    confirmChangeState: 'Are you sure you want to change the state?',
    confirmChangeActiveFacility: 'Are you sure you want to change the home facility?',
    confirmChangeDefaultModality: 'Are you sure you want to set as default modality?',
    confirmChangePrintable: 'Are you sure you want to change the Printable state?',
    confirmChangeExportable: 'Are you sure you want to change the Exportable state?',
    confirmChangeEnabled: 'Are you sure you want to change the visibility state?',
    confirmChangeBasic: 'Are you sure you want to set for basic form search?',
    active: 'active',
    inactive: 'inactive',
    activeFacility: 'local',
    inactiveFacility: 'external',
    default: 'default',
    detail: 'Detail',
    archive: 'Delete',
    setStudyForEdit: 'Edit study',
    archiveName: 'Archive',
    remove_file: 'Delete',
    favourite: 'favorite',
    unpopular: 'unpopular',
    stationAet: 'Station AET',
    notificationDetail: 'Notification detail',
    echoConnectedModality: 'Echo',
    selected: 'selected',
    addState: 'Add state',
    removeState: 'Remove state',

    // Order
    orderDetail: 'Order detail',
    orderEdit: 'Edit order',
    orderDelete: 'Delete order',
    mwlHasAttachments: 'The order has at least one attachment',
    view_attachement: 'View',

    // Requests
    ...requestsActions,

    // GridStudie
    createdDate: 'Study date',
    modalities: 'Modalities',
    studyDescription: 'Description',
    countImagesSeries: 'Images/Series',
    studyLocked: 'Study locked',
    setStudyForRequestEditStudy: 'Select for request to edit study',
    accessionNumber: 'Accession number',

    // GridSerie
    seriesPreview: 'Series preview',
    bodyPart: 'Body part',
    seriesNumber: 'Series number',
    dateTime: 'Date and time',
    modality: 'Modality',
    seriesDescription: 'Series description',
    noOfInstances: 'Number of instances',

    // Queue
    queueDetail: 'Queue detail',
    queueCancel: 'Cancel queue',
    confirmQueueCancel: 'Are you sure you want to cancel the queue?',

    confirmChangePriority: 'Are you sure you want to change the priority?',
    confirmChangePermission: 'Are you sure you want to change the permission?',
    ...userPermission,

    // Tests
    testDetail: 'Detail',
    testQuestions: 'Questions',
    testClone: 'Clone',
    testPublish: 'Publish',
    definitionEdit: 'Edit',
    testStart: 'Start test',
    continueTest: 'Continue test',
    testResultDetail: 'Result detail',
    studyDetail: 'Study detail',

    // Dose
    bodyPartExamined: 'Body part examined',
    dose: 'Dose',
    seriesUID: 'Series UID',
    instancesUID: 'Instances UID',

    // Clinical portal
    printable: 'Printable',
    exportable: 'Exportable',
    enabled: 'visible',
    basicForm: 'basic form',
    showDocument: 'Show documentation',
    retired: 'Terminate employment',
    resetTotp: 'Reset TOTP settings',

    permissions: 'Permissions',

    ...decubitsCode,
    ...sexes,
  },
  Queues: {
    stateOfQueue: 'Queue state',
    dateTime: 'Date and time',
    username: 'Username',
    operation: 'Operation',
    state: 'State',
    patientName: 'Last name and first name',
    studyDateTime: 'Study date and time',
    accessionNumber: 'Accession number',
    requestId: 'Request ID',
    noData: 'No records found',
    ...queueStates,
    ...studyFunctions,
    inArchive: ' in archive ',

    succesCancelQueue: 'Request to cancel the queue has been successfully sent.',
    errorCancelQueue: 'Failed to send request to cancel the queue.',

    targetNode: 'Target node:',
    drSejf: 'Dr. Sejf',
  },
  Cstore: {
    cstoreToExchangeNetwork: 'Sending',
    cstoreToArchive: 'Saving',
    allowedFilesDicom: 'Only DICOM files are allowed',
    archiveTo: 'Target Archive',
    targetAet: 'Target AET',
    targetIp: 'Target IP',
    targetPort: 'Target Port',
    sourceAet: 'Source AET',
    reset: 'Reset',
    echo: 'Echo',
    cstore: 'C-STORE',
    echoSuccessfully: 'Echo successful',
    echoFailed: 'Echo failed',
    successfullyCstore: 'C-STORE completed',
    errorCstore: 'C-STORE failed',
    noFiles: 'No files selected',
    uploadingFiles: 'Uploading files...',
  },
  Tools: {
    tools: 'Administrator Tools',
    bitLength: 'Bit Length',
    hashtext: 'Text / Hash',
    generate: 'Generate RSA Key',
    download: 'Download RSA Key',
    encrypt: 'Encrypt Text with Public RSA Key',
    decrypt: 'Decrypt Hash with Public RSA Key',
    invalidBitLength: 'Enter a valid bit length',
    invalidText: 'Enter text',
    invalidHash: 'Enter hash',
    generated: 'RSA Key generated',
    errorGenerating: 'Failed to generate RSA Key',
    encrypted: 'Text encrypted',
    errorEncrypting: 'Failed to encrypt text',
    decrypted: 'Hash decrypted',
    errorDecrypting: 'Failed to decrypt hash',
    toolsRSA: 'RSA',
  },
  AnnouncementsAndButtons: {
    setAnnouncements: 'Announcements',
    setButtons: 'Buttons',
  },
  Announcements: {
    create: 'Create new announcement',
    edit: 'Edit announcement',
    position: 'Position',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    languageJsonText: 'Text (json)',
    importanceLabel: 'Importance',
    workPlaces: 'Workplaces',
    selectMin1Workplace: 'Select at least one workplace',
    errorSaving: 'Error saving announcement.',
    saved: 'Announcement saved successfully.',
    name: 'Text',
    importance: {
      warning: 'Warning',
      info: 'Info',
      danger: 'Problem',
    },
  },
  Buttons: {
    text: 'Text',
    icon: 'Icon',
    url: 'URL',
    urlMustStartWithSlashOrHttp: 'Url must start with slash or "http"',
    create: 'New tile',
    edit: 'Edit tile',
    searchIcons: 'Search icons',
    workplaces: 'Workplaces',
    errorSaving: 'Error saving tile',
    languageJsonText: 'User title (json)',
    system: 'System tile',
    languageKey: 'System title',
    saved: 'Tile saved',
    deleted: 'Tile deleted',
    errorDeleting: 'Failed to delete tile',
    languageJsonText_cs: 'Title (Czech)',
    languageJsonText_en: 'Title (English)',
    languageJsonText_ru: 'Title (Russian)',
    searchFilter: 'Search studies',
    removedSelected: 'Selected tiles removed',
    errorRemovingSelected: 'Failed to remove selected tiles',
    deleteInactive: 'Delete inactive',
    selectedButtonsSplitBy: 'Split by workplace rights',
    automatic: 'automatic',
    manual: 'manual',
    manualUnassign: 'Remove workplace',
    userButtonUpdate: 'Update user tiles',
    deleteInactiveButtonsWarning:
      'By pressing the "Delete" button, the selected tiles will be deleted from the database.',
    assignButtonsAutomaticWarning:
      'Based on the configuration file, tiles will be assigned to workplaces according to the roles of each workplace.',
    assignButtonManualWarning:
      'Selected tiles will be added/removed to the workplace after pressing the button with the workplace.',
    assignUserWarning:
      'If the "Update user tiles" switch is enabled, all users will be set tiles according to the new settings, and after the operation is completed, all users will have all tiles available according to their workplaces.',
    successSplitButtons:
      'Tiles are currently being split. You can verify the result either in the log or by checking the data in the browser.',
    errorSplitButtons: 'Failed to split tiles',
    ...dialogTexts,
    ...yesno,
  },
  Dictionaries: {
    create: 'Add item to dictionary',
    createPhysician: 'Add item to Physicians dictionary',
    edit: 'Edit item in dictionary',
    editPhysician: 'Edit item in Physicians dictionary',
    name: 'Name',
    code: 'Code',
    unit: 'Unit',
    translate: 'Translate',
    order: 'Order',
    type: 'Type',
    value: 'Value',
    dicomTag: 'Dicom tag',
    expertness: 'Expertness',
    healthInsuranceCompany: 'Health insurance companies',
    studyOrderPriority: 'Priority',
    requestedProcedure: 'Requested procedure',
    physicians: 'Physicians',
    dose: 'Dose',
    studyDetailBookmarks: 'Study Detail Bookmarks',
    decubits: 'Body Parts',
    bodyPartExaminated: 'Body part',
    imageLaterality: 'Laterality',
    patientPosition: 'Patient position',
    bodyPartExaminatedRequired: 'Select body part',
    patientPositionRequired: 'Select patient position',
    duplicateDecubit: 'Record with body part, laterality, and patient position already exists',
    deleted: 'Item successfully deleted.',
    saved: 'Save successful',
    errorSaving: 'Save failed',
    errorDeleting: 'Failed to delete record',
    physicianNotFound: 'Physician not found',
    requestingPhysician: 'Requesting physician',
    referringPhysician: 'Referring physician',
    dictionaryNotFound: 'Dictionary items not found',
    ...name,
    ...yesno,
    ...decubitsCode,
    ...select,
  },
  Languages: {
    abbreviation: 'Abbreviation',
    name: 'Name',
    active: 'Active',
    default: 'Default',
    ...yesno,
    saved: 'Saved successfully',
    errorSaving: 'Failed to save changes',
    edit: 'Edit language:',
  },
  Settings: {
    setWorkplaces: 'Workplaces',
    setProducts: 'Products',
    setUsers: 'Users',
    setConnectedModalities: 'Connected Modalities',

    frontendSettings: 'Frontend Settings',
    primaryColor: 'Primary Color',
    menuBackground: 'Background color of left panel',
    menuColor: 'Text color of left panel',
    menuLogo: 'Left panel logo',
    mainTitle: 'Main title in page header',
    loginPageBackgroundColor: 'Login Page Background Color',
    loginPageColor: 'Login Page Text Color',
    logos: 'Logos',
    base64: 'Base64 String',
    width: 'Logo Width (px)',
    height: 'Logo Height (px)',
    openExternalConverter: 'Open External Image Conversion Service',
    frontendSettingsSaved: 'Settings Saved',
    frontendSettingsNotSaved: 'Error Saving Configuration',
    colors: 'Colors',
    texts: 'Texts',
    logo: {
      logoVariant: 'Application Logo Variant',
      application: 'Application Logo',
      marie: 'Marie Pacs Logo',
      customer: 'Customer Logo',
      variant: {
        dark: 'Dark',
        light: 'Light',
      },
    },
    application: 'Application',
    loginScreen: 'Login Screen',
    text: 'Text on Login Screen',
    footer: {
      title: 'Footer',
      links: 'Footer Links',
      orCzLink: 'ORCZ Company Link',
      link: 'Link URL',
      text: 'Link Text',
      helpdesk: 'Helpdesk Link',
      contact: 'Contact Information',
      label: 'Label',
      show: 'Show',
      contactText: 'Contact Information Text',
      visibleOnStartup: 'Automatically Show Label',
      day: 'Day',
      night: 'Night',
      weekend: 'Weekends/Holidays',
      phone: 'Phone',
      help: 'Help in footer',
    },
    footerColor: 'Footer Text Color',
    footerHoverColor: 'Footer Text Hover Color',
    udiValue: 'UDI value',
    uploadUDI: 'Load from file',
    footerLink: 'Footer Link',
  },
  Workplaces: {
    name: 'Name',
    code: 'Code',
    create: 'Create new workplace',
    // edit: 'Edit workplace',
    workplaceNotFound: 'Workplace not found',
    viewer: 'Viewer',
    allowedArchives: 'Allowed archives',
    roles: 'Roles',
    archives: 'Allowed archives',
    saved: 'Workplace saved successfully',
    errorSaving: 'Error saving workplace',
    deleted: 'Workplace deleted successfully',
    errorDeleting: 'Failed to delete workplace',
    addToWorkplaces: 'Add',
    addWorkplacesFromLdap: 'Add workplaces from LDAP',
    selectWorkplaces: 'Select workplaces from address book',
    noItems: 'No workplaces from address book',
    addWorkplacesFromLdapConfirmSet:
      'Are you sure you want to add selected workplaces from the address book?',
    addWorkplacesFromLdapContent: 'Only workplaces that have not been added yet will be added.',
    addWorkplacesFromLdapSuccessfully: 'Workplaces added',
    addWorkplacesFromLdapError: 'Failed to add workplaces',
    userProfileFacilities: 'Facilities',
    archiveActions: 'Archive actions',
    archiveAction: 'Archive action',
    allowedArchiveActions: 'Allowed actions',
    savedActionsForArchive: 'Archive actions saved',
    errorSavingActionsForArchive: 'Failed to save archive actions',
    allowedFunctions: 'Allowed functions',
    isAll: 'All',
    ...studyFunctions,
    ...dialogTexts,
    search: 'Search',
    findingStatuses: 'Finding statuses',
  },
  Products: {
    create: 'New product',
    code: 'Code',
    description: 'Description',
    name: 'Name',
    edit: 'Edit product',
    type: 'Type',
    subtype: 'Subtype',
    saved: 'Product saved successfully.',
    errorSaving: 'Error saving product.',
    settingSaved: 'Settings saved successfully.',
    settingErrorSaving: 'Error saving settings.',
    variable: 'Variable name',
    value: 'Value',
    createProductSetting: 'New setting',
    editProductSetting: 'Edit setting',
    settings: 'Settings',
    productNotFound: 'Product not found',
    realisator: 'Realisator',
    archive: 'Archive',
    viewer: 'Viewer',
    esb: 'ESB',
    portal: 'Portal',
    'patient management': 'PMI',
    'trusted archive': 'Trusted archive',
    MS: 'Marie Server',
    MSE: 'Marie Server Express',
    mash: 'MASH',
    node: 'DICOM node',
    trash: 'Trash',
    ceph: 'NAS Enterprise',
    signature: 'Electronic signature',
    other: 'Other',
    drSejf: 'Dr. Sejf',
    HISConnector: 'HIS Connector',
    webvision: 'Webvision',
    MSWeb: 'MSWeb',
    DPGW: 'DPGW',
    clinical_portal: 'Clinical portal',
    default: 'Default',
    ...yesno,
  },
  ConnectedModalities: {
    connectedModalities: 'Connected Modalities',
    archives: 'Archive Selection',
    aet: 'AE Title',
    ip: 'IP Address',
    port: 'Port',
    manufacturer: 'Manufacturer',
    storageDirectory: 'Storage Directory',
    comment: 'Comment',
    create: 'Create New Modality',
    edit: 'Edit Connected Modality',
    createSuccessfully: 'Modality created successfully',
    editSuccessfully: 'Modality edited successfully',
    deleteSuccessfully: 'Modality disconnected successfully',
    echoSuccessfully: 'Echo successful',
    createError: 'Failed to create modality',
    editError: 'Failed to edit modality',
    'error.same.name': 'Unable to create modality due to existing modality with the same name.',
    deleteError: 'Failed to delete modality',
    echoError: 'Echo failed',
    getConnectedModalitiesError:
      'Failed to load connected modalities. Please contact technical support.',
    missingArchives: 'Unable to manage connected modalities because no archive is available!',
    add: 'Add',
    back: 'Back',
    save: 'Save',
  },
  Folders: {
    detail: 'Worklist Detail',
    create: 'Create New Worklist',
    edit: 'Edit Worklist',
    name: 'Name',
    nameOfNew: 'Name of New Worklist',
    owner: 'Owner',
    showDetail: 'Show Detail',
    folderNotFound: 'Worklist not found.',
    errorSaving: 'Error saving worklist.',
    errorFolderExists: 'Worklist name already exists.',
    saved: 'Worklist saved successfully.',
    deleted: 'Worklist deleted successfully',
    errorDeleting: 'Failed to delete worklist',
    userRequired: 'Select a user',
    userAddedToFolder: 'Users added to worklist successfully',
    errorUserAddedToFolder: 'Failed to add users to worklist',
    deletedUser: 'User deleted successfully',
    errorDeletingUser: 'Failed to delete user',
    updateUserPermission: 'User permission updated',
    errorUpdateUserPermission: 'Failed to update user permission',
    deletedDocument: 'Documentation deleted successfully',
    errorDeletingDocument: 'Failed to delete documentation',
    createButton: 'Create new folder',
    0: '-',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
    documents: 'Selected Documentation',
    studies: 'Selected Studies',
    users: 'Users',
    userAdd: 'Add',
    fullname: 'Full Name',
    patientFullname: 'Patient Name',
    email: 'Email',
    permission: 'Permission',
    dialog: { user: 'Select a user' },
    select: 'Select a user',
    ...userPermission,
    action: {
      edit: 'Edit Worklist',
      delete: 'Delete Worklist',
    },
    selectFromList: 'Select a worklist',
    folderIsNotAvailable: 'Worklist is not available',
    confirmDelete: 'Are you sure you want to delete the worklist?',
    submitRemovingStudyError: 'Failed to remove study',
    submitRemovingStudyOk: 'Study removed successfully',
    confirmRemoveStudyFromFolder: 'Are you sure you want to remove the study from the worklist?',
    tabGrid: 'Worklist Grid',
    tabCalendar: 'Calendar',
    selectFolder: 'Select a worklist',
    studySelectedToFolder: 'Study added to worklist successfully',
    errorSelectingStudyToFolder: 'Failed to add study to worklist',
    seriesSelectedToFolder: 'Series added to worklist successfully',
    errorSelectingSeriesToFolder: 'Failed to add series to worklist',
    documentsSelectedToFolders: 'Documents added to worklist successfully',
    errorSelectingDocumentsToFolders: 'Failed to add documents to worklist',
    removedStudyFromFolder: 'Study removed from worklist',
    errorRemovingStudyFromFolder: 'Failed to remove study from worklist',
    removedSeriesFromFolder: 'Series removed from worklist',
    errorRemovingSeriesFromFolder: 'Failed to remove series from worklist',
    multipleAddToFolder: 'Add to worklist',
    multipleShowDocumentsFromFolder: 'Show documentation',
    multipleRemoveDocumentsFromFolder: 'Remove documentation from worklist',
    removeSelectedStudiesFromFolder: 'Remove from worklist',
    removeAllStudiesFromFolder: 'Remove all studies from worklist',
    confirmRemoveStudiesFromFolder:
      'Are you sure you want to remove the selected studies from the worklist?',
    confirmRemoveAllStudiesFromFolder:
      'Are you sure you want to remove all studies from the worklist?',
    confirmExtendExpiration: 'Are you sure you want to extend the expiration?',
    extendedExpiration: 'Expiration extended successfully',
    errorExtendedExpiration: 'Failed to extend expiration',

    ...dialogTexts,

    loadingData: 'Searching for studies in the archive ',
    expiration: 'Expiration in',
    expirationHelp: 'Expiration is calculated from the date the study was added to the worklist.',

    ...expirationButtons,
    ...clinicalPortalDocumentation,
  },
  Filters: {
    create: 'New filter',
    edit: 'Edit filter',
    name: 'Name',
    workPlaces: 'Workplaces',
    selectMin1Workplace: 'Select at least one workplace',
    filterType: 'Filter type',
    onlyForMe: 'Only for me',
    errorSaving: 'Error saving filter.',
    errorFilterrExists: 'Filter name already exists.',
    saved: 'Filter saved successfully.',
    deleted: 'Filter deleted successfully',
    errorDeleting: 'Failed to delete filter',
    authorName: 'Author name',
    authorTooltip: 'Author of the last change',
    ...state,
  },
  ModalitiesUnitsStations: {
    setModalities: 'Modality',
    setUnits: 'Units',
    setStations: 'Stations',
  },
  Modalities: {
    create: 'Create new modality',
    edit: 'Edit modality',
    name: 'Name',
    color: 'Color',
    show: 'Show',
    multiframe: 'Loop (multiframe)',
    description: 'Description',
    modalityNotFound: 'Modality not found.',
    errorSaving: 'Error saving modality.',
    'error.administration.modality.exists.with.this.name':
      'Modality with this name already exists.',
    'error.administration.modality.exists.with.this.color':
      'Color is already used by another modality.',

    saved: 'Modality saved successfully.',
    deleted: 'Modality deleted successfully',
    errorDeleting: 'Failed to delete modality',
    ...yesno,
    ...header,
  },
  Units: {
    create: 'Create new unit',
    edit: 'Edit unit',
    code: 'Code',
    name: 'Name',
    unitNotFound: 'Unit not found.',
    errorSaving: 'Error saving unit.',
    errorUnitExists: 'Code or name of unit already exists.',
    saved: 'Unit saved successfully.',
    deleted: 'Unit deleted successfully',
    errorDeleting: 'Failed to delete unit',
    ...header,
  },
  Stations: {
    create: 'Create new station',
    edit: 'Edit station',
    code: 'Code',
    aetitle: 'Name (aetitle)',
    modality: 'Modality',
    unit: 'Unit',
    stationNotFound: 'Station not found.',
    errorSaving: 'Error saving station.',
    saved: 'Station saved successfully.',
    deleted: 'Station deleted successfully',
    errorDeleting: 'Failed to delete station',
    ...header,
  },

  GeneralSettings: {
    setPriorities: 'Priority',
    setOperations: 'Operations',
    setNotificationSettings: 'Notification Settings',
    setNotificationTemplates: 'Notification Templates',
    setDictionaries: 'Dictionaries',
    setLanguages: 'Languages',
    setHospitals: 'Hospital',
  },
  PrioritiesAndOperations: {
    name: 'Priority Name',
    operation: 'Operation Type',
    priority: 'Priority',
    dialog: { priority: 'Priority' },
    errorSaving: 'Error saving priority.',
    saved: 'Priority saved successfully.',
    deleted: 'Priority deleted successfully',
    errorDeleting: 'Failed to delete priority',
    updateOperationPriority: 'Priority changed for operation',
    errorUpdateOperationPriority: 'Failed to change priority for operation',
    ...studyFunctions,
    ...dialogTexts,
    ...header,
  },
  Hospitals: {
    identifier: 'Identifier',
    name: 'Hospital name',
    dialog: { hospital: 'Hospital' },
    errorSaving: 'Error saving hospital.',
    saved: 'Hospital saved successfully.',
    deleted: 'Hospital deleted successfully',
    errorDeleting: 'Failed to delete hospital',
    ...dialogTexts,
    ...header,
  },

  ClinicalPortalSettings: {
    clinicalPortal: 'Clinical Portal Settings',
    viewer: 'Viewer',
    searchPatientItems: 'Patient Search Items',
    formats: 'Formats',
    typesOfDocument: 'Types of Document',
    bookmarks: 'Bookmarks',
    formItems: 'Form Items',

    // Format
    tabFormatsName: 'Name',
    formatTitle: 'Format',
    create: 'Create new format',
    edit: 'Edit format',
    formatName: 'Name',
    formatNotFound: 'Format not found',
    error: {
      administration: {
        format: { exists: { with: { this: { name: 'Format name already exists' } } } },
        typeOfDocument: {
          exists: { with: { this: { name: 'Type of document name already exists' } } },
        },
        bookmark: { exists: { with: { this: { name: 'Bookmark name already exists' } } } },
      },
    },
    errorFormatExists: 'Format already exists',
    errorSaving: 'Error saving format',
    saved: 'Format saved successfully',
    deleted: 'Format deleted successfully',
    errorDeleting: 'Failed to delete format',

    // TypeOfDocument
    name: 'Name',
    typesOfDocumentName: 'Technical Name',
    allowedFormats: 'Formats',
    typeOfDocumentTitle: 'Type of Document',
    createTypeOfDocument: 'Create new type of document',
    editTypeOfDocument: 'Edit type of document',
    typeOfDocumentName: 'Name',
    typeOfDocumentTechnicalName: 'Technical Name',
    typesOfDocumentIconName: 'Icon Name',
    typeOfDocumentIconName: 'Icon Name',
    typeOfDocumentIconColor: 'Icon Color',
    typeOfDocumentCanShow: 'Show documents of this type',
    typeOfDocumentStandardizedPrintEnabled: 'Allow standardized print',
    canUseEldax: 'Possibility to download documentation in Eldax',
    searchIcons: 'Search Icons',
    typeOfDocumentNotFound: 'Type of document not found',
    errorTypeOfDocumentExists: 'Type of document already exists',
    savedTypeOfDocument: 'Type of document saved successfully',
    deletedTypeOfDocument: 'Type of document deleted successfully',
    errorDeletingTypeOfDocument: 'Failed to delete type of document',

    // Bookmark
    bookmarksName: 'Name',
    bookmarksPosition: 'Position',
    allowedTypesOfDocument: 'Allowed Types of Document',
    allowedWorkplaces: 'Allowed Workplaces',
    createBookmark: 'Create new bookmark',
    editBookmark: 'Edit bookmark',
    bookmarkNotFound: 'Bookmark not found',
    errorBookmarkExists: 'Bookmark already exists',
    savedBookmark: 'Bookmark saved successfully',
    errorSavingBookmark: 'Error saving bookmark',
    deletedBookmark: 'Bookmark deleted successfully',
    errorDeletingBookmark: 'Failed to delete bookmark',

    allColumns: 'Show all columns',
    author: 'Author',
    clinic_code: 'Clinic code',
    clinic_name: 'Clinic name',
    created_at: 'Created at',
    description: 'Description',
    document_id: 'Document id',
    source: 'Source',

    // FormItems
    formItemName: 'Code',
    formItemPosition: 'Position',
    savedFormItem: 'Form item saved successfully',
    errorSavingFormItem: 'Error saving form item',
    formItemNotFound: 'Form item not found',
    editFormItem: 'Edit form item',

    ...dialogTexts,
    ...header,
  },

  ClinicalPortal: {
    title: 'Patient Search',
    medicalDocumentationsSearch: 'Medical Documentation Search',
    name: 'Last Name and First Name',
    patientInsuranceNumber: 'Insurance Number',
    patientIdentificationNumber: 'Identification Number',
    patientID: 'Patient ID',
    patientBirthDate: 'Date of Birth',
    patientSex: 'Sex',
    address: 'Address',
    telephone: 'Telephone',
    motherMaidenName: "Mother's Maiden Name",
    masterPatientID: 'Master Patient Index (MPI) Number',
    noData: 'No records found',
    noFindPatient: 'Patient not found',
    ...messagesForGrid,
    ...sexes,
    detail: 'Medical Documentation',
    showDetail: 'Show Detail',
    multipleExport: 'Export Documentation',
    multiplePrint: 'Print Documentation',
    multipleStandardizedPrint: 'Print Documentation (Standardized)',
    multipleShow: 'Show',
    multipleAddToFolder: 'Add to Worklist',
    sendDocument: 'Send via Dr. Sejf',
    unsupportedCombination:
      'Unsupported combination of document types - operation cannot be performed! Only DICOM documents or non-DICOM documents can be selected.',
    isEnabledSendOnlyPdfAndHasSelectedDicom: 'Any type of document can be selected except DICOM',
    isEnabledSendOnlyDicomAndHasSelectedPdf: 'Only DICOM documents can be selected',
    documentList: 'Document List',
    ...sejfSendForm,
    uuid: 'Identifier',
    modifiedDatetime: 'Modified Date',
    tags: 'Tags',
    documentsDetail: 'Document Detail',
    unknownDocument: 'No document selected',
    notLoadedDocument: 'Document not loaded',
    unselect: 'Unselect Document',
    reselect: 'Select Document',
    addTag: 'Add Tag',
    layoutChooser: 'Layout Chooser',
    ...dialogOther,
    showOriginalDocument: 'Show Original',
    ...clinicalPortalDocumentation,
    documentTagDelete: 'Delete Tag',
    confirmDelete: 'Are you sure you want to delete?',
    documentCreated: 'Created:',
    documentUnit: 'Department Name:',
    documentNumberUnit: 'Department Number:',
    documentWorkplaceNumber: 'Workplace Number:',
    documentExpertise: 'Expertise:',
    documentPrinted: 'Printed:',
    documentPatientLastName: 'Last Name',
    documentPatientFirstName: 'First Name',
    documentPatientTitle: 'Title',
    documentPatientInsuranceNumber: 'Insurance Number',
    documentPatientResidence: 'Residence:',
    documentPatientHealthInsurance: 'Health Insurance',
    documentPatientEmployer: 'Employer:',
    documentPatientProfession: 'Profession:',
    documentPatientPhone: 'Phone:',
    documentPatientMail: 'E-mail:',
    documentPatientAattendingGeneralPractitioner: ' Attending General Practitioner:',
    documentPatientDischargedFromUnit: 'Discharged from Unit:',
    documentPatientHospitalizedFrom: 'Patient was hospitalized from',
    documentPatientHospitalizedTo: 'to',
    documentCopyCreator: 'Copy of the document created by:',
    hospitalizationFrom: 'Hospitalized from:',
    hospitalizationTo: 'to:',
    diagnosisCode: 'Diagnosis Code:',
    diagnosisDescription: 'Diagnosis Description:',
    diagnosisType: 'Diagnosis Type Code:',
    diagnosisTypeCode: 'Diagnosis Type Code:',
    diagnosisTypeDescription: 'Diagnosis Type Description:',
    diagnosisPriorityCode: 'Diagnosis Priority Level:',
    procedureFromTime: 'Procedure Date From:',
    procedureToTime: 'to:',
    anesthesiaFromTime: 'Anesthesia Start Date From:',
    anesthesiaToTime: 'to:',
    performanceFromTime: 'Physician Performance Date From:',
    performanceToTime: 'to:',
    procedureDescription: 'Procedure Type:',
    procedureReoperation: 'Reoperation:',
    procedureOperatingRoom: 'Operating Room:',
    transferEnteredAt: 'Placed in Department:',
    transferFromTime: 'From:',
    transferToTime: 'To:',
    prescriptionDrugCode: 'Drug Code:',
    prescriptionDrugName: 'Drug Name:',
    prescriptionDrugDescription: 'Drug Description:',
    prescriptionDrugExporig: 'Number of Drug Packages:',
    prescriptionDrugDsig: 'Dosage:',
    prescriptionNumberOfDrugPrescription: 'Number of Prescription Repeats:',
    getDataError: 'Error loading patient data',
    printDocumentError: 'Error generating PDF for printing',
    exportDocumentError: 'Error generating PDF for export',
    morePatientInfo: 'More Information',
    lessPatientInfo: 'Less Information',
    patientInsurance: 'Health Insurance',
    email: 'E-mail',
    employer: 'Employer',
    occupation: 'Occupation',
    attendingGeneralPractitioner: 'Attending General Practitioner',
    bloodGroup: 'Blood Group',
    allergy: 'Allergy',
    sendDicomDocumentsTitle: 'Sending DICOM Documents via Dr. Sejf',
    sendPdfDocumentsTitle: 'Sending PDF Documents via Dr. Sejf',
    submitSendError: 'Failed to process request to send documents.',
    submitSendOk: 'Request to send documents has been submitted for processing.',
    ...studyFunctions,
    pdfPage: 'Page',
    pdfPageOf: 'of',
    pdfLoading: 'Loading PDF...',
    pdfLoadingError: 'Failed to load PDF',
    errorEsbGetOriginalDocument: 'No original document exists for this document',
    loadingDataForFacility: 'Loading documents from {{par1}}',
    loadingData: 'Loading document',
  },
  WebsocketNotifications: {
    ...eventTypes,
    ...studyFunctions,
    ID: 'ID',
    detail: 'Notification detail',
    subject: 'Message subject',
    ...state,
    list: 'List',
    new: 'New',
    read: 'Read',
    archived: 'Archived',
    approved: 'Approved',
    failed: 'Failed',
    finished: 'Finished',
    date: 'Date',
    status: 'Status',
    edit: 'Notification detail',
    created: 'Created on',
    patient: 'Patient',
    accessionNumber: 'Accession Number',
    sourceArchive: 'Source Archive',
    archivedSuccessfully: 'Notification archived',
    errorArchiving: 'Failed to archive notification',
    newSetNotificationSuccessfully: 'Notification set as new',
    newSetNotificationsSuccessfully: 'Notifications set as new',
    newSetNotificationError: 'Failed to set notification as new',
    newSetNotificationsError: 'Failed to set notifications as new',
    readSetNotificationSuccessfully: 'Notification set as read',
    readSetNotificationsSuccessfully: 'Notifications set as read',
    readSetNotificationError: 'Failed to set notification as read',
    readSetNotificationsError: 'Failed to set notifications as read',
    archivedSetNotificationSuccessfully: 'Notification archived',
    archivedSetNotificationsSuccessfully: 'Notifications archived',
    archivedSetNotificationError: 'Failed to archive notification',
    archivedSetNotificationsError: 'Failed to archive notifications',
    multipleSetAsRead: 'Mark as read',
    multipleSetAsNew: 'Mark as new',
    multipleSetAsArchived: 'Archive',
    readConfirmSet: 'Set selected notifications as read?',
    newConfirmSet: 'Set selected notifications as new?',
    archivedConfirmSet: 'Archive selected notifications?',
    goToStudy: 'Go to study',
    study: 'Study',
    operation: 'Operation type',

    archiveNotification: 'Archive',
    downloadAttachment: 'Download export',
    showAuditLog: 'Audit log',
    showRequest: 'Request',
    sign: 'Sign study',
    canSign: 'New study clones need to be manually signed. Please sign the study:',
    listExport: 'List of exports',

    selectedStudy: 'Selected study',
    exportedStudy: 'Exported study',
    selectedStudies: 'Selected studies',
    sourceStudy: 'Source study',
    sourceStudyNew: 'Source study - modified',
    targetStudy: 'New study',
    updateStudy: 'Updated study',

    ...gridStudyItems,
    ...sexes,

    nNewNotifications: [
      'You have {{n}} new notification',
      'You have {{n}} new notifications',
      'You have {{n}} new notifications',
    ],
    noNotifications: 'No notifications',

    studyState: 'State',
    ...dataForEdit,

    downloadError: 'Failed to download file',
    aborted: 'Aborted',
    waiting: 'Waiting',
    rejected: 'Rejected',
    workplaceChangedFirstPArt: 'There has been a change in the system.',
    workplaceChangedSecondPArt:
      'The change will take effect after logging out and logging back in.',
    resultsNum: 'Showing {{size}} latest notifications',
    loadNext: 'Load more notifications',
    noNotificationForRequestId: 'No notification found for request',
    noNotification: 'No notification found',
    targetNode: 'Target node:',
    drSejf: 'Dr. Sejf',
    errorDescription: 'Reason',
    SEND_MDEX_OK: 'Sent via mDex successfully.',
    SEND_MDEX_NOK: 'Failed to send via mDex.',
  },

  NCPeH: {
    title: 'Search in the National Contact Point for eHealth',
    country: 'Country',
    documentTypes: 'Document Types',
    accessReasons: 'Access Reasons',
    EPrescription: 'ePrescription',
    PatientSummary: 'Patient Summary',
    treatment: 'Doctor Visit',
    emergency: 'Emergency Case',
    nationalSearch: 'National Search',
    nationalSearchTooltip:
      'National search returns all available documents. International search returns the most recent document.',
    search: 'Search',
    unknownError: 'unknown',
    submitErrorNCPeH: 'Error NCPeH: {{message}} (Code: {{code}})',
    submitError: 'Error: {{message}} (Code: {{code}})',
    name: 'Last Name and First Name',
    patientBirthDate: 'Date of Birth',
    patientSex: 'Sex',
    address: 'Address',
    email: 'Email',
    telephone: 'Telephone',
    documentsDetail: 'Patient obtained from NCPeH',
    creationDate: 'Creation Date',
    description: 'Description',
    author: 'Author',
    documentDetail: 'Patient Summary',
    downloadError: 'Failed to download document',
    ...countries,
    ...sexes,
    examples: 'Examples of identification documents for each country',
    infoForPatient: 'Information for the patient',
    searchDocuments: 'Search Documents',
  },

  Tests: {
    ...yesno,
    startingTest: 'Starting test',
    testsList: 'Tests list',
    type: 'Type',
    state: 'State',
    draft: 'Draft',
    ready: 'Ready',
    study: 'Study',
    general: 'General',
    all: 'All',
    search: 'Search',
    name: 'Test name',
    description: 'Test description',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    create: 'New test',
    edit: 'Edit test',
    editTest: 'Edit test',
    editTestShort: 'Edit',
    maxWrongAnswersAllowed: 'Max wrong answers allowed',
    maxTestLength: 'Max test length',
    public: 'Public',
    showResults: 'Show results',
    repeatedly: 'Repeatedly',
    allowPreview: 'Allow preview',
    testNotFound: 'Test not found',
    backToTests: 'Back to tests list',
    author: 'Author',
    modification: 'Last modification',
    publish: 'Publish',
    clone: 'Clone',
    testDetail: 'Test detail',
    delete: 'Delete',
    reallyDelete: 'Really delete?',
    definitions: 'Definitions',
    newStep: 'New step',
    addDefinition: 'Add definition',
    editDefinition: 'Edit definition',
    label: 'Question',
    time: 'Time (s)',
    addAnswer: 'Add answer',
    answer: 'Answer',
    correctness: 'Answer is correct',
    questionAdded: 'Question added',
    questionSubmitFailed: 'Error submitting question, please try again',
    text: 'Answer',
    group: 'Group',
    item: 'Item',
    questionsMin: 'Minimum number of questions',
    questionsMax: 'Maximum number of questions',
    registerItem: 'Item',
    testDeleted: 'Test deleted',
    questionDeleted: 'Definition deleted',
    deleted: 'Answer deleted',
    confirmCloneTitle: 'Really clone the test?',
    confirmPublishTitle: 'Really publish the test?',
    confirm: 'Confirm',
    close: 'Close',
    testCloned: 'Test cloned',
    errorCloning: 'Failed to clone test',
    testPublished: 'Test published',
    errorPublishing: 'Failed to publish test',
    position: 'Position',
    startTest: 'Start test',
    currentTest: 'Current test',
    saved: 'Test saved',
    edited: 'Test edited',
    errorSaving: 'Error saving test',
    comment: 'Comment',
    previous: 'Previous',
    next: 'Next',
    finish: 'Finish',
    finishTest: 'Finish test',
    pressFinishButton: 'Press Finish button to submit the test',
    testResults: 'Test results',
    allTestResults: 'All test results',
    successRate: 'Success rate',
    yourAnswers: 'Your answers',
    timeIsUp: 'Time is up for the test',
    timeIsUpDialogText: 'Time is up for the test. Please confirm completion.',
    goToTests: 'Go to tests page',
    errorAnswerDeleting: 'Cannot delete test',
    errorDeleting: 'Cannot delete definition',
    questionEdited: 'Definition edited',
    errorExecutingTest: 'Failed to load test',
    showInBrowser: 'Open in browser',
    rightAnswer: 'Correct',
    wrongAnswer: 'Wrong',
    openAll: 'Open all',
    closeAll: 'Close all',
    correctAnswers: 'Correct answers',
    startedWhen: 'Test started',
    finishedWhen: 'Test finished',
    datePeriod: 'Period',
    wrongTests: 'Unsuccessful',
    successfullTests: 'Successful',
    testState: 'Test result',
    executedBy: 'User',
    passed: 'Passed',
    failed: 'Failed',
    time_abbr_h: 'h',
    time_abbr_m: 'min',
    time_abbr_s: 'sec',
    testLength: 'Test length',
    globalStats: 'Global statistics',
    fullname: 'User',
    statType: 'Statistic type',
    select: 'Select',
    errorRate: 'Error rate',
    executedTests: 'Executed tests',
    allUsers: 'All users',
    commentsCount: 'Comments',
    noExecutionsFound: 'No tests found for the selected filter',
    noStatisticsFound: 'No statistics found',
    cannotLoadStatistics: 'Failed to load statistics',
    trialStart: 'Trial start',
    errorUsageCount: 'Incorrect / Total usage',
    patientID: 'Patient ID',
    studyDetail: 'Study detail',
    isStudyExecutedTestType: 'For image test, only one answer can be selected',
    correctAnswer: 'Correct answer to the question.',
    incorrectanswer: 'Incorrect answer to the question.',
    correctlyAnswered: 'Correctly answered.',
    incorrectlyAnswered: 'Incorrectly answered.',
    explanationCheckbox: 'Checked checkbox indicates the user-selected answer to the question.',
    explanationDoneIcon:
      'This icon and the answer highlighted in blue indicate the correct answer.',
    explanationCancelIcon: 'This icon indicates the incorrect answer.',
    explanationColor:
      'Bold and blue-highlighted answer indicates the correct answer to the question.',
    dateToCanNotBeBeforeDateFrom: 'Valid to cannot be before valid from',
    atLeaseOneAnswerMustBeAdded: 'At least one answer must be added',
    atLeaseOneAnswerMustBeCorrect: 'At least one answer must be marked as correct',
    loadingComment: 'Loading comment...',
    yourQuestions: 'Your questions',
    reallyCompleteTheTest: 'Really complete the test?',
    unansweredQuestions:
      'Number of unanswered questions {{numberOfUnanswered}} out of {{questionsCount}}',
    back: 'Back',
    isUnanswered: 'Unanswered',
    confirmDelete: 'Really delete?',
    testStart: 'Start test',
    loadingPreviews: 'Loading previews...',
    redirectingToBrowser: 'Redirecting to browser...',
  },

  Reactour: {
    common: {
      searchButtonTours: 'Press the button to start searching.',
      searchButtonDisabledTours:
        "If the button is disabled, it means you haven't filled in any form fields yet and cannot perform a search.",
      headTableWithSortAndFilter:
        'In the table header, you can click on the column label to sort the table in ascending or descending order based on that column. You can also drag and drop the column by clicking and holding the label. Clicking on the three dots in the column label will display additional options.',
      bodyTable: 'The table displays the search results.',
      columnChooserButton:
        'Clicking this button will display a modal window with a list of columns. You can select which columns should be visible or hidden.',
      pager:
        'At the bottom right of the table, you can set the number of records to be displayed. If there are more records than the set limit, pagination is enabled.',
      gridColumns:
        'The icon allows you to show or hide individual columns in the results. You can customize which record information you want to see and which is irrelevant to you at the moment.',
      gridFilters:
        'The icon is used to apply additional filters to the data in the table. After setting filters in the previous part of the form, press this button to apply additional filters to the already searched data.',
      gridExport:
        'Download as CSV can be selected. If filters are used, only filtered records will be downloaded.',
      gridReFetching:
        'The button with the circular icon enables or disables automatic data refreshing in the table at a set time interval. This means that your data will be regularly updated without the need for manual refresh.',
      dateButton:
        'By selecting a button, you specify the date of the scheduled period. If you choose "Custom", you need to enter at least one date from the "From/To" options.',
      dateButtonComment:
        'Double-clicking the button will immediately start the search for the selected period.',
      auditDateSelect:
        'By selecting an option from the dropdown menu, you specify the date of the activity. If you choose "Custom", you need to enter the dates "From/To".',
      selectItems: 'Select options from the dropdown menu.',
      quitSelectItems: 'Press the esc key to exit the dropdown menu.',
      clearButton:
        'This button is used to immediately clear all filled values in the search form. Use it if you want to start a new search with a clean form.',
      gridActions: {
        columns: {
          nameSearch:
            'This search panel is used to quickly find and display a specific column. Simply enter the column name you want to filter, and the columns will dynamically update to display only those that match your search.',
          select:
            'Here you can toggle the visibility of individual columns. By clicking the toggle switch next to the column name, you can either show or hide the column. This allows you to customize the display according to your preferences and needs.',
          hideAll:
            'The "Hide All" button instantly hides all columns. This is useful if you want to start with a clean slate and then manually select the columns you want to display.',
          showAll:
            'The "Show All" button ensures that all columns are visible in the table. This is ideal if you want to have an overview of all available data without manually adding columns back to the display.',
        },
        filters: {
          delete: 'Clicking this button will delete the selected filter.',
          column:
            'The column determines which column you want to apply the filter to. By selecting, for example, "Last Name", you specify the filtering for that specific column.',
          operator:
            'The operator defines the type of comparison to be used for filtering the data. For example, "contains" will find all records that have the specified text in the selected column. Other operators may include options such as "equals", "greater than", and more.',
          value:
            'In the value field, enter the criteria by which you want to search in the selected column. This can be specific text, a number, or a date, depending on the selected column and operator.',
          addFilter:
            'The "Add Filter" button allows you to add new criteria for searching. After clicking it, a new row will appear with options to select the column, operator, and enter a value. Use this tool to specify multiple conditions and search for records that match all the specified filters.',
          deleteAll:
            'The "Remove All" button is used to quickly remove all set filters. Clicking this button will immediately remove all active filters and return to displaying all records without any search restrictions.',
        },
      },
    },
    folders: {
      addButton: 'Clicking this button will display a form for entering a new folders.',
      bodyTable:
        'The folder name is clickable, and clicking the link will display the folders detail. There are also action buttons that, when clicked, will display a form to edit the folder or a dialog to confirm the deletion of the folder. If the buttons are not active, it means you are not the owner of the folder or do not have permission to perform the action.',
      edit: {
        form: "Here you can edit the name of your folder. Enter the desired folder name in the field and use the 'Save' button to save the changes or 'Back' if you decide not to save the changes.",
        usersAdd:
          "This section allows you to manage users associated with the folder. You can use the 'Add' button to add new users to the list.",
        usersGrid:
          'The table displays all users currently included in the folder. Here you will be able to view and manage users once they are added.',
      },
      detail: {
        form: "In this section, you will find basic information about the folder, such as the name and owner. You can also perform actions on the folder using the 'Edit Folder' button to change the information or the 'Delete Folder' button to permanently remove the list from the application.",
        tabs: 'Under the basic information, you will find tabs that contain related information about the folder.',
        grids: 'Here, related information is displayed in a table based on the selected tab.',
      },
    },
    orders: {
      addButton: 'Clicking this button will display a form for entering a new request.',
      fillInTheFields: 'Fill in one of the items to search for requests.',
      patientId: 'Enter patient ID',
      bodyTable:
        'The first column contains action buttons that, when clicked, will display the detail or a form to edit the request.',
      create:
        'Fill in all the necessary information to create a new request. These fields are mandatory: Last Name, Patient ID, Date of Birth, Gender.',
      edit: 'Here you can edit the details of an existing request. These fields are mandatory: Last Name, Patient ID, Date of Birth, Gender.',
      save: 'Clicking this button will save the request.',
      back: 'Clicking this button will take you back to the request overview. If you have filled in any information in the form, it will be lost.',
      detail: {
        mainInfo: 'Here you will find basic information about the patient.',
        additionalInfo:
          'Here you will find information about the request and more detailed patient information.',
        actionButtons:
          'By clicking on the individual buttons, you can edit the request, create an study from the request, or print the request.',
        navBar:
          'In this section, you can switch between additional information related to the request, such as comments or related attachments.',
      },
    },
    searchList: {
      exportList:
        'This button will take you to the page with a list of all study exports. Here you will find a complete overview of exported studies, including detailed information about each study.',
      sentHistory:
        'Clicking this button will open the page with the history of sent studies. The page provides an overview of all sent studies with the ability to view details of individual records.',
      filters:
        'This dropdown list allows you to apply pre-saved filters to study searches. This allows you to quickly search without having to fill in all the parameters in the search form again. Simply select a previously saved filter and the search will automatically adjust according to the chosen criteria.',
      searchFormFields:
        'This section allows you to enter specific criteria to find the records you need.',
      expansionArrow:
        'Clicking the downward arrow will display additional fields that allow you to further specify your search based on additional parameters.',
      searchSources:
        'Here you can select individual archives in which the search for studies will be performed. You can select multiple archives at once or just one.',
      searchButton:
        'After entering all the necessary information into the search form, click this button to start the search. The system will process your criteria and display relevant results.',
      clearButton:
        'This button is used to immediately clear all filled values in the search form. Use it if you want to start a new search with a clean form.',
      saveFilterButton:
        'If you have set specific search criteria and plan to use them again in the future, you can save these settings for quicker access. Clicking this button will save the current filter configurations.',
      searchResultGrid:
        'This area displays the results of your search. After performing the search, a list of studies matching the entered criteria will appear here. You can work with them further - view details, export data, or perform other actions as needed.',
      searchFormSelectedItems: 'Here are the modalities options.',
    },
    studyDetail: {
      headerSection:
        'Here you will find key information about the patient and the study. Including name, patient ID, gender, date of birth, and study specifics such as date, time, and modality.',
      timeline:
        'The timeline displays key milestones or time-related data related to the examination, allowing you to track the progression or different stages of the examination.',
      mainSection:
        'This section contains detailed information about the study, including request number, dates and times, patient height and weight, study description, and its current status.',
      mainSection_preview:
        'A preview image that provides a visual representation of the study or related images.',
      buttons:
        'Below the study information, you will find a toolbar with buttons for various actions such as sending, printing, editing, copying, and other study-related functions.',
      tabs: 'At the bottom of the screen, there are tabs for different sections related to the study, including comments, operations, tags, series, and worklists.',
      statusDialog: {
        selectAll: 'By clicking this button, you will select all statuses.',
        choices: 'Here you can select the statuses to be assigned to the study.',
        saveButton: 'By clicking this button, you will save the selected statuses to the study.',
        closeButton:
          'By clicking this button, you will close the dialog window. The statuses will not be saved.',
      },
    },
    exportList: {
      appBar:
        "You are on the page with exports. You can switch between 'Valid Exports' and 'All Exports' to filter the displayed records.",
      grid: "Here you will find a table with exports, where each row represents one export. In the 'Actions' column, you can download the corresponding exports.",
    },
    importDicom: {
      uppySelector:
        'This section is for importing DICOM data. You can drag and drop files or folders from your computer directory here, or use the button to select files/folders you want to upload.',
      grid: 'In this section, you will see a list of files that you have uploaded. Each row represents individual DICOM files with details such as patient name, patient ID, study date, patient gender, study description, and file size. When uploading multimedia files, an unknown format will be displayed as the last name and first name.',
      targetArchive:
        "Use the 'Target Archive' dropdown menu to select where the import should go. It is important to choose the correct archive to properly categorize the data and make it easily accessible for further use.",
      saveButton:
        "After selecting files and specifying the target archive, click 'Save' to start the import process. It is not possible to start importing if no files are selected or if the target archive is not selected.",
      expansionArrow:
        'Clicking the arrow will expand the record, where you can select specific series to be imported. Further expansion will show specific DICOM images.',
      editRow:
        'By clicking the icon, you can edit individual details of the record that were imported from the DICOM file.',
      deleteRow: 'By clicking the icon, you can delete the record if you do not want to import it.',
      deleteAll: 'Deletes all records from the overview.',
      uploadFolder: 'Allows you to additionally upload additional folders to be imported.',
      uploadFiles: 'Allows you to additionally upload additional files to be imported.',
    },
    importNonDicom: {
      uppySelector:
        'This section is for importing multimedia data. You can drag and drop files or folders from your computer directory here, or use the button to select files/folders you want to upload.',
      allowedFiles:
        'Allowed formats include - jpg, jpeg, mp4, mpeg4, pdf. If you try to upload a different file type, the file will not be uploaded.',
      grid: 'In this section, you will see a list of files that you have uploaded. Each row represents individual files with details such as patient name, patient ID, study date, patient gender, study description, and file size. When uploading multimedia files, an unknown format will be displayed as the last name and first name.',
      targetArchive:
        "Use the 'Target Archive' dropdown menu to select which archive the data should be imported into. It is important to choose the correct archive to properly categorize the data and make it easily accessible for further use.",
      saveButton:
        "After selecting files and specifying the target archive, click 'Save' to start the import process. It is not possible to start importing if no files are selected or if the target archive is not selected.",
      deleteRow: 'By clicking the icon, you can delete the record if you do not want to import it.',
      deleteAll: 'Deletes all records from the overview.',
      uploadFolder: 'Allows you to additionally upload additional folders to be imported.',
      uploadFiles: 'Allows you to additionally upload additional files to be imported.',
      findButtons:
        'Here you can search for existing requests, patients, or studies. When selected, the form will be automatically pre-filled with available data.',
      patientAndStudy:
        'Form section with patient data and study data. It can be filled in manually or by searching for a request, patient, or study.',
      decubits:
        'By clicking the button, a new section will appear where you can select a specific area on an interactive body image and then select a specific part of the body.',
      resetButton: 'Clears all filled data in the form.',
    },
    mdex: {
      send: {
        navBar: 'Here you can select where and which study you want to upload and send.',
        recipients: 'You can select the recipient using the dropdown menu.',
        button:
          'After pressing the button, the selected study will be sent to the chosen recipient.',
      },
      transmission: {
        navBar: 'Here you can switch between overviews of Incoming or Outgoing data.',
        gridIncoming: 'In this section, you can see all incoming transmissions.',
        gridOutgoing: 'In this section, you can see all outgoing transmissions.',
      },
      addressBook: {
        synchronize:
          'By pressing this button, you initiate the synchronization/update of all recipients.',
        grid: 'In this section, you can see a list of all available recipients.',
      },
    },
    cstore: {
      send: {
        navBar: 'The possibility of sending local studies.',
        recipients: 'Use the drop-down menu to select the recipient.',
        networks:
          'If you selected a recipient with more than one network in the previous step, then you can select a network using the drop-down menu.',
        button: 'After pressing the button, the studies are sent to the recipient of your choice.',
      },
      save: {
        navBar: 'The possibility of saving local studies.',
        archiveId: 'You can select the target archive using the drop-down menu.',
        targetAet: 'You can enter the target AET.',
        targetIp: 'You can enter the target IP',
        targetPort: 'You can enter the target PORT',
        sourceAet: 'You can enter the source AET',
        clear: 'Press the button to delete the selected criteria.',
        echo: 'After pressing the button, the target node is verified.',
        button:
          'After pressing the button, the studies are sent to the target node you have chosen.',
      },
    },
    shreddings: {
      common: {
        dateForm:
          'By selecting the button, you can quickly determine the period for which you want to display shredding proposals. If you select Custom, you must enter at least one date from the options From/To.',
        clear: 'By pressing the button, you clear the selected criteria.',
        search: 'By pressing the button, you initiate the search for shredding proposals.',
      },
      proposals: {
        form: 'Here you can fill in the items to search for shredding proposals in the table. You can filter by proposal date. By clicking the search button, you update the shredding proposals table.',
        dateSelect:
          'By selecting an option from the dropdown menu, you specify the proposal date. If you select Custom, you must enter the dates From/To.',
        grid: 'In this section, you see a list of all shredding requests in the selected period.',
      },
      history: {
        form: 'Here you can fill in the items to search for shredding history in the table. You can filter between shredding proposals or shredding confirmations, by author, date, automatic proposal, or user proposal. By clicking the search button, you update the shredding history table.',
        shreddingDate:
          'Here you can fill in the items to search for shredding history in the table. You can filter between shredding proposals or shredding confirmations, by author, date, automatic proposal, or user proposal. By clicking the search button, you update the shredding history table.',
        dateSelect:
          'By selecting an option from the dropdown menu, you specify the date. If you select Custom, you must enter the dates From/To.',
        dateFrom:
          'Based on the selected value from the dropdown menu for the date, a calculated date is displayed. Alternatively, for the Custom value, you can manually enter the From date.',
        dateTo:
          'Based on the selected value from the dropdown menu for the date, a calculated date is displayed. Alternatively, for the Custom value, you can manually enter the To date.',
        confirmationProposalButton:
          'You can choose whether to search among shredding proposals or shredding confirmations.',
        authorSelector:
          'By selecting the author from the dropdown menu, you can easily narrow down the search to their records.',
        sourceShredding:
          'These buttons allow you to filter shreddings based on their source. By selecting one of these buttons, you can easily segment and display specific types of proposals.',
        search: 'By pressing the button, you initiate the search for shredding history.',
        grid: 'In this section, you see a list of all filtered shredding requests or shredding confirmations based on your search criteria.',
      },
    },
    request: {
      addButton:
        'After clicking the button, you will be redirected to a new page where you can search for studies and then submit a request for the selected action.',
      operationType:
        'Using the dropdown menu, you can specify the type of operation you want to search for.',
      requestingUser:
        "In the 'Requesting User' field, enter the name of the user whose requests you want to search for. This filter allows you to narrow down the search to specific users and quickly find the necessary records.",
      dateForm:
        "By selecting the button, you quickly determine the period for which you want to display requests. If you select 'Custom', you must enter at least one date from the options From/To.",
      dateButtonComment:
        'If you double-click the date button, the application will automatically start searching for the selected time period without the need for further confirmation.',
      requestStatus:
        'Here you can select which request statuses you want to search for. You can select multiple options. If you want all statuses, click the select all button.',
      clear: 'By clicking the button, you clear the selected criteria.',
      search: 'By clicking the button, you initiate the search for requests.',
      grid: 'In this area, the results of your search are displayed. After performing the search, a list of requests matching the entered criteria will appear here.',
      requestGridActions:
        'The first icon opens the request detail. By clicking the second icon, you approve the request, and by clicking the third icon, you reject the request.',
      detail: {
        mainSection:
          'In this section, you see the main information related to the request. You can either approve or reject the request using the buttons.',
        otherSections: 'In this section, you see more detailed information about the request.',
        comments: 'In this section, you see all comments related to the request.',
        studies: 'In this section, you see all studies related to the request.',
        folders: 'In this section, you see all folders related to the request.',
        history: 'In this section, you see the complete history of the request.',
      },
    },
    filters: {
      addButton: 'After clicking the button, a form for entering a new filter will be displayed.',
      bodyTable:
        'In the first column, there are action buttons where you can activate/deactivate individual filters. You can also edit or delete individual filters.',
      studyForm:
        'Here you can choose which values will be saved in the filter. The form is the same as for searching for studies.',
      additionalInfo:
        'Here you set the details of the filter, such as the name, whether the filter will be visible only to you, and for which workstations it will be available.',
      save: 'By clicking the button, you save the filter.',
      back: 'By clicking the button, you go back to the filter overview. If you have filled in the form, the entered data will be lost.',
      edit: 'Here you can edit the values of an existing filter.',
      editAdditionalInfo:
        'Here you can edit the details of the filter. The filter name is a required field.',
    },
    requestQueue: {
      status:
        'Here you can select the status of requests you want to search for. By clicking on "Select All", all request statuses will be selected.',
      searchButton: 'By clicking the button, you initiate the search.',
      grid: "In the first column, there are action buttons where you can view the request details. By clicking on the patient's last name and first name, you will be redirected to the patient's study detail.",
    },
    tagsArchive: {
      comment:
        'In this field, you can add a comment to your search. The comment serves as a specification of what you are looking for in the scientific archive. The search is performed for a substring match.',
    },
    tagsArchiveAndIntegrationPlatform: {
      search: 'By clicking the button, you initiate the search for requests.',
      clear: 'By clicking the button, you clear all selected tags.',
      activeTags:
        'Here you can refine your search by selecting from various tags. Each tag represents a category or criterion that you can use to filter the results. Arrows indicate the hierarchical structure of tags, where some tags may be subgroups of others. This arrangement allows you to specify your area of interest more precisely.',
      inActiveTags:
        'By checking the box, you can display inactive tags, which you can then use to further refine your search, similar to active tags.',
      grid: 'By using the arrow, you can view specific series of the study.',
    },
    registerGroups: {
      addButton: 'After clicking the button, a form for entering a new tag will be displayed.',
      registerGroups:
        'In the first column, there are action buttons where you can activate/deactivate individual tags or modify existing tags.',
      grid: 'Here, all tags and their structure are displayed - arrows indicate the hierarchical structure of tags, where some tags may be subgroups of others. You can modify or activate/deactivate individual tags.',
      detail: {
        form: 'Here, you can modify the name of the tag group. You can also activate/deactivate the tag group using the toggle switch.',
        registerGroupsChildrenAdd: 'Here, you can add subgroups to the selected tag group.',
        registerGroupsChildrenGrid:
          'Here, you can see all subgroups assigned to the tag group. You can activate/deactivate or modify individual subgroups.',
        tagsAdd: 'Here, you can add tags to the selected tag group.',
        tagsGrid:
          'Here, you can see all tags assigned to the tag group. You can activate/deactivate or modify individual tags.',
      },
    },
    tests: {
      addButton: 'After clicking the button, a form for entering a new test will be displayed.',
      searchForm:
        'Here, you can choose the type and status of the test you want to search for. Clicking the search button initiates the search.',
      grid: 'Here, you can see all existing tests. The first column contains action buttons.',
      filterHeaders:
        'Here, you can filter by columns. Simply start typing in the respective fields.',
      actions:
        'Here, you can view the test details, edit or copy the test. You can also publish or delete tests.',
      create:
        'Fill in all the necessary information to create a new test. These fields are mandatory: Test Name, Type, Valid From, Maximum Number of Incorrect Answers, Maximum Test Duration.',
      edit: 'Here, you can edit the details of an existing test. These fields are mandatory: Test Name, Type, Valid From, Maximum Number of Incorrect Answers, Maximum Test Duration.',
      save: 'Clicking the button saves the test.',
      back: 'Clicking the button takes you back to the test overview. If you have filled in the form, the entered data will be lost.',
      startForm: 'Here, you can select the test type to refine your search.',
      startGrid:
        'Here, you can see the search results. The first column contains an action icon that starts the respective test.',
      resultForm:
        'Here, you can filter the results by date. Clicking the search button initiates the search.',
      dateSelect:
        'By selecting an option from the dropdown menu, you specify the time period. If you select Custom, you need to enter the From/To dates.',
      resultGrid: 'Here, you can see the results of your search.',
      globalStatisticsForm:
        'Here, you can refine the global test results by type, user, or date. Clicking the search button initiates the search.',
      globalStatisticsTypeExecuted:
        'If you select "Executed Tests", you can further refine the results by user and date.',
      globalStatisticsTypeErrorRate:
        'If you select "Error Rate", you cannot further refine the results.',
      globalStatisticsUserSelector:
        'By selecting a user from the dropdown menu, you can easily narrow down the search to their records.',
      globalStatisticsGrid:
        'Here, you can see the global statistics based on your search criteria. The results are graphically represented above the table.',
      detail: {
        mainSection: 'Here, you can find basic information about the test.',
        buttons:
          'Using the buttons, you can edit, copy, publish, delete, or test the test execution. Test execution is not counted in the statistics. Once a test is published, you can no longer add or edit questions in the test.',
        definitions:
          'You add questions to the test as definitions. In the Definitions tab, you can see an overview of all questions. To add another question to the test, click on the "Add Definition" tab.',
        question: 'Here, you can enter the question text.',
        order: 'Here, you determine the order of the question in the test.',
        answer:
          'Here, you can enter the answer text and indicate whether the answer is correct. Providing answers is optional.',
        addAnswer: 'Clicking the button adds another answer to the question.',
        addQuestion: 'Clicking the button adds the answer to the test.',
      },
    },
    statistics: {
      checkBoxes:
        "Here you can select the type of statistics you want to display. 'Storage Occupancy' provides an overview of the usage of the data storage, while 'Number of Studies' displays the total number of studies for a specific period. You can also specify the graph results based on various parameters such as 'Year', 'Month', graph type, and archive.",
      additionalInfo:
        'Additional options allow you to customize the display of statistics based on specific stations or modalities.',
      showButton:
        "By clicking 'Show', all set filters are applied and the graph display is updated.",
      grid: 'Here you can see the graph that corresponds to your selected criteria.',
      byTagStatistics: 'Here you can see statistics by tag.',
    },
    auditLogs: {
      form: 'Here you can fill in items to refine the results of the audit logs in the table. You can filter by user, patient ID, and time period. By clicking the search button, you update the audit log table.',
      grid: "In this area, you can see the audit logs that have been filtered according to the criteria you entered. By default, today's logs are displayed.",
    },
    clinicalPortal: {
      byPatient: {
        searchForm:
          'Here you can search for a patient by their name, surname, personal identification number, or patient ID. By clicking the search button, the search is initiated. If you need to enter more specific details, click the arrow to display more search options.',
        grid: 'Here you can see the result of your search.',
      },
      ncpeh: {
        searchForm:
          'Here you can search in the National Contact Point for eHealth. You can select the country, type of document you want to search for, and the reason for access. Depending on the country selection, you need to enter patient identification details.',
        searchButton: 'By clicking this button, you initiate the search.',
      },
    },
    basket: {
      buttons:
        'Here you can perform various actions with studies in the Temporary Study Basket. You can send, copy, archive, export, add to worklist, add tags, or view in viewer. Each button corresponds to a specific action that you can perform on the selected records.',
      grid: 'Here is the list of all studies that have been added to the temporary study basket. The information includes patient name, description, archive, patient ID, request number, gender, date of birth, study date, modality, and more. You can select records to perform actions from the first step.',
      deleteButtons:
        'Here you can remove selected or all studies from the temporary study basket. Note: removing a study from the temporary study basket does not lead to its permanent loss. The study remains available in the system and can be found in the list of all studies.',
    },
    study: {
      back: 'Clicking the button takes you back to the previous page.',
      send: {
        sendBy: 'Here, you can choose how to send the study.',
        listOfStudies:
          'Here, you can see the list of studies that will be sent. You can expand each study to view individual series of images.',
        sendButton: 'Clicking the button sends the study.',
      },
      reorderSource: {
        deleteButton: 'Clicking this button removes the source study from the reorder.',
        grid: 'Here, you can see the source study for reordering.',
      },
      split: {
        existingStudyForm:
          'In this section, you will find all the information about the existing study.',
        newStudyButton:
          'In this section, there are buttons that allow you to find an existing request, study, or patient. By selecting one, the form for a new study will be automatically pre-filled with the available information.',
        newStudyForm:
          'In this section, there is information about the new study that will be created when splitting the current study.',
        existingStudySeries:
          'In this section, you will find the series of the existing study. You can view individual images of each series and select entire series or individual images from a series using checkboxes.',
        newStudySeries:
          'In this section, you will find the series that will be part of the new study.',
        otherChoices:
          'By using a checkbox, you can choose whether to include comments from the original study when splitting.',
        splitButton: 'Clicking this button will initiate the split of the study.',
      },
      edit: {
        patientStudyForm:
          'Here, you can edit the patient and study details. The following fields are mandatory: Last Name, Patient ID, Date of Birth, Gender, Study Description, Study Date, Study Time.',
        patientForm:
          'Here, you can edit the patient details. The following fields are mandatory: Last Name, Patient ID, Date of Birth, Gender.',
        patientFormGrid: 'Here, selected studies related to the patient are displayed.',
        loadFromOrder:
          'After clicking the button, you will be able to search for a request. After selecting a request, the study details will be pre-filled based on the available information from the request.',
        saveButton: 'Clicking this button will save the changes.',
      },
      copy: {
        targetArchive:
          'Using the dropdown menu, you can choose the target archive where the studies should be copied.',
        anonymization:
          'Using the dropdown menu, you can choose the type of anonymization to apply.',
        listOfStudy:
          'Here, you can see a list of studies for the patient. You can select which series should be copied using checkboxes.',
        copyButton: 'Clicking this button will initiate the copy of the studies.',
      },
      move: {
        targetArchive:
          'Using the dropdown menu, you can choose the target archive where the studies should be moved.',
        anonymization:
          'Using the dropdown menu, you can choose the type of anonymization to apply.',
        listOfStudy:
          'Here, you can see a list of studies for the patient. You can select which series should be moved using checkboxes.',
        moveButton: 'Clicking this button will initiate the move of the studies.',
        showEditForm: 'Toggling the status, will allow you to edit the details of the study.',
      },
      export: {
        type: 'You can choose the export type using the dropdown menu.',
        anonymization:
          'You can choose the anonymization type using the dropdown menu when exporting.',
        listOfStudy:
          'Here, you can see a list of studies for the patient. You can select which series should be exported using checkboxes.',
        exportButton: 'Clicking this button will initiate the export of the studies.',
      },
      addToFolder: {
        newFolder:
          'If you want to create a new folder, you can enter its name here and confirm it by clicking the button.',
        gridExistingFolders:
          'Here, you can choose from existing folders where you can move the studies. You can select multiple folders at once.',
        listOfStudy:
          'Here, you can see a list of studies for the patient. You can select which series should be added to the folder using checkboxes.',
        addToFolderButton: 'Clicking this button will initiate the move of the studies.',
      },
      doseMonitoring: {
        export: 'Clicking this button will export the doses.',
        grid: 'Here, you can monitor all doses for the patient.',
      },
    },
    buttonFormIcon:
      'Icon name can be found by clicking on "Search icons". The icon name has to be written in lower case letters. In case of multiword names, it is necessary to use underscore as seperator of the words.',
    buttonFormIconExample: 'e.g. Icon name AddHomeWork has to be written as add_home_work.',
    buttonFormIconConvert:
      'In case you would copy name from the link, then automatic conversion would happen. Conversion occurs while typing icon name as well.',
    findingReport: {
      user: 'You can select an user using the drop-down menu.',
      hospital: 'You can select a hospital using the drop-down menu.',
      grid: 'In this area, the results of your search are displayed. After performing the search, a list of finding reports matching the entered criteria will appear here.',
    },
  },

  Histories: {
    requestCreatedWhen: 'Date and Time',
    user: 'User',
    patientId: 'Patient ID',
    name: 'Last Name and First Name',
    dateTime: 'Study Date',
    accessionNumber: 'Request Number',
    sentMethod: 'Exchange Network/Dr. Sejf',
    recipient: 'Recipient',
    loadingData: 'Searching for history',
    webSocketError: 'Failed to retrieve information',
    webSocketErrorInIE:
      'Failed to retrieve history. Try refreshing the page or using a different browser.',
    requestDetail: 'Request Detail',
  },

  StudyAnonymization: {
    ...name,
    PatientID: 'Patient ID',
    PatientBirthDate: 'Patient Birth Date',
    noAnonymization: 'None',
    noName: 'No Name',
    anonymization: 'Anonymization',
    parameters: 'Anonymization Parameters',
  },

  About: {
    about: 'About the application',
    content:
      'This web application is developed and managed by OR-CZ spol. s.r.o., ID: 48168921, with registered office at Gorazdova 1477/2, Předměstí, 571 01 Moravská Třebová.',
    portalVersion: 'Current version of the application frontend/backend:',
    label: 'Label',
  },
  Mdex: {
    mdex: 'MDEX',
    mdexSendTitle: 'MDEX - Send Data ',
    sendData: 'Send Data',
    transmissions: 'Transmissions Overview',
    addressBook: 'MDEX Recipient Address Book',

    institutionName: 'Recipient',
    aeTitle: 'AET',
    city: 'City',
    address: 'Address',
    email: 'Email',
    phone: 'Phone',
    dicomHost: 'DICOM Host',
    dicomPort: 'DICOM Port',
    synchronizeAddressBooks: 'Synchronize',
    synchronized: 'Synchronization successful',
    errorSynchronizing: 'Synchronization failed',
    ...select,
    submitSendOk: 'Data sent for processing',
    submitSendError: 'Failed to send data for processing',
    dicomFromArchive: 'DICOM - Data from Archive',
    dicomFromStation: 'DICOM - Local Data',
    nondicom: 'Multimedia Data',
    ...dialogTexts,
  },

  Transmissions: {
    setIncoming: 'Incoming data',
    setOutgoing: 'Outgoing data',
    transferStart: 'Transfer start',
    transferEnd: 'Transfer end',
    institutionFrom: 'Sender',
    institutionTo: 'Recipient',
    city: 'City',
    cityFrom: 'Sender city',
    cityTo: 'Recipient city',
    patientName: 'Patient name',
    patientIdent: 'Patient identification',
    objectIdent: 'Object identification',
    objectCount: 'Object count',
    objectSize: 'Size',
    transmissionDetail: 'Transmission detail',
    actions: 'Actions',
  },
  StudyFunctions: {
    ...studyFunctions,
  },

  PriorityStatusesConfig: {
    order: 'Order',
    code: 'Code',
    name: 'Name',
    priority: 'Priority',
    state: 'State',
    dialog: {
      commentState: 'Findings status',
      saved: 'Finding status successfully saved',
      errorSaving: 'Error Saving Findings Status',
    },
    deleted: 'Findings Status Deleted Successfully',
    canUse: 'Can use?',
    canView: 'Can view?',
    grid: {
      canUse: 'use',
      canView: 'see',
    },
    isDeprecated: 'Invalid',
    isNotDeprecated: 'Valid',
    showOnlyValid: 'Show only valid comments',
    ...header,
  },
  Certificates: {
    ...header,
    dialog_attachment: 'Add certificate',
    certificateName: 'Name',
    deleted: 'Certificate removed successfully',
    errorDeleting: 'The certificate could not be deleted',
    change: {
      success: 'Change of default certificate was successful',
      error: 'Failed to change default certificate',
    },
  },
};

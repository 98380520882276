import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Box, Button, Grid } from '@mui/material';
import OverflowedDialog from 'components/Dialog/OverflowedDialog';
import { IPatient } from 'components/Import/_types';
import FormInput from 'components/Form/Input/Input';

const EditFileDialog: React.FC<any> = ({
  editDialogState,
  toggleEditDialog,
  fileForEditDialog,
  updateFiles,
}) => {
  const { t } = useTranslation('Import');

  const parseDicomTags = (data: IPatient) => {
    return { name: get(data, 'name', '') };
  };

  const methods = useForm<any>();
  const {
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = methods;

  const name = watch('file.name');

  const initialValues = useMemo(() => parseDicomTags(fileForEditDialog), [fileForEditDialog]);

  useEffect(() => {
    reset({ file: initialValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileForEditDialog]);

  const onSubmit = async (values: any) => {
    updateFiles(values.file);
    toggleEditDialog(false);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <OverflowedDialog
          title={t('editFileDialog')}
          open={editDialogState}
          onClose={() => {
            toggleEditDialog(false);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
          actions={
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Button
                onClick={() => toggleEditDialog(false)}
                variant="contained"
                size="large"
                color="inherit"
                sx={{ mr: 1 }}
              >
                {t('close')}
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                type="submit"
                disabled={!name}
              >
                {t('confirm')}
              </Button>
            </Box>
          }
        >
          <Box data-tour="editPatientStudyForm">
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                <FormInput
                  name="file.name"
                  label={t('nonDicom.grid.fileName')}
                  required={true}
                  errors={errors}
                />
              </Grid>
            </Grid>
          </Box>
        </OverflowedDialog>
      </form>
    </FormProvider>
  );
};

export default EditFileDialog;

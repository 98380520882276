import { GridSortDirection } from '@mui/x-data-grid';

export const DEFAULT_LOCALE = 'cs';
// export const ROLES_LOAD = 'ROLES_LOAD';
// export const IS_LOADING = 'IS_LOADING';
// export const SHRINK_MENU = 'SHRINK_MENU';
// export const SET_THEME = 'SET_THEME';
// export const SET_MODE = 'SET_MODE';
// export const VALIDATORS_LOAD = 'VALIDATORS_LOAD';
// export const SET_LOCAL_GRID_SETTINGS = 'SET_LOCAL_GRID_SETTINGS';
// export const SET_PROFILE = 'SET_PROFILE';
// export const SET_GRID_REFETCH = 'SET_GRID_REFETCH';
// export const ADD_SEARCH_RESULTS = 'ADD_SEARCH_RESULTS';
// export const STORE_ORDER_RESULTS = 'STORE_ORDER_RESULTS';
// export const STORE_QUEUE_RESULTS = 'STORE_QUEUE_RESULTS';
// export const STORE_LOG_RESULTS = 'STORE_LOG_RESULTS';
// export const STORE_CONFIRMATION_DATA = 'STORE_CONFIRMATION_DATA';
// export const STORE_DOCUMENT_SELECTION = 'STORE_DOCUMENT_SELECTION';
// export const STORE_TEST_RESULTS = 'STORE_TEST_RESULTS';

export const STORE_STRUCTURE_RESULTS = 'STORE_STRUCTURE_RESULTS';
export const STORE_VOCABULARY_IDENTITY = 'STORE_VOCABULARY_IDENTITY';
export const STORE_VOCABULARY_ROW = 'STORE_VOCABULARY_ROW';
export const STORE_VOCABULARY_KEYS = 'STORE_VOCABULARY_KEYS';

// Themes and modes
export const THEME_MODE_DARK = 'dark';
export const THEME_MODE_LIGHT = 'light';

export const BACK = 'back';

export const EXCHANGE_NETWORK_REDIMED = 'ReDiMed';
export const EXCHANGE_NETWORK_EPACS = 'ePacs';

// Profiles
export const PROFILE_OPEN_ID = 'PROFILE_OPEN_ID';
export const PROFILE_SAML = 'PROFILE_SAML';
export const PROFILE_DB = 'db';
export const PROFILE_ORLDAP = 'orldap';
export const ENABLED_PROFILE_EDIT_PROFILES = [PROFILE_DB, PROFILE_ORLDAP];
export const SET_VIEWER = 'SET_VIEWER';

// Login
export const USER_STORE = 'USER_STORE';
export const STORE_LAST_PAGE_STATE = 'STORE_LAST_PAGE_STATE';
export const WAS_REDIRECTED = 'WAS_REDIRECTED';
export const SET_USE_2FA = 'SET_USE_2FA';
export const USER_UNLOAD = 'USER_UNLOAD';
export const COOKIE_PORTAL_VERSION_NAME = 'COOKIE_PORTAL_VERSION_NAME';
export const SET_LISTS = 'SET_LIST_MODALITIES';
export const SET_TRASH = 'SET_TRASH';
export const SET_GRID_SETTINGS = 'SET_GRID_SETTINGS';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_ALLOWED_ARCHIVES_FOR_FUNCTIONS = 'SET_ALLOWED_ARCHIVES_FOR_FUNCTIONS';
export const STORE_IMPORT_UPLOADING_STATE = 'STORE_IMPORT_UPLOADING_STATE';
export const STORE_SEARCH_RESULTS = 'STORE_SEARCH_RESULTS';
export const STORE_BASKET = 'STORE_BASKET';
export const RESET_BASKET = 'RESET_BASKET';

export const LOGIN_METHOD_DB = 'db';
export const LOGIN_METHOD_AD = 'ad';
export const LOGIN_METHOD_LDAP = 'ldap';
export const LOGIN_METHOD_SAML = 'headers';
export const LOGIN_METHOD_SPNEGO = 'spnego';
export const LOGIN_METHOD_OPENID = 'openid';

export const LOGIN_METHODS_WITH_LOGIN_FORM = [LOGIN_METHOD_DB, LOGIN_METHOD_AD, LOGIN_METHOD_LDAP];
export const LOGIN_METHODS_WITH_AUTOLOGIN = [LOGIN_METHOD_SAML, LOGIN_METHOD_SPNEGO];

// Token functions
export const TOKEN_FUNCTION_DETAIL = 'detail';
export const TOKEN_FUNCTION_UPLOAD = 'upload';
export const TOKEN_FUNCTION_UNSIGNED_STUDIES_LIST = 'unsignedStudiesList';
export const CERTIFICATE_TOKEN_FUNCTION_LOGIN = 'certificate-login';
export const CERTIFICATE_TOKEN_FUNCTION_DETAIL = 'certificate-detail';
export const CERTIFICATE_TOKEN_FUNCTION_SEARCH_ONLY_OPEN = 'certificate-searchOnlyOpen';
export const CERTIFICATE_TOKEN_FUNCTION_SEARCH = 'certificate-search';
export const CERTIFICATE_TOKEN_FUNCTION_IMPORT = 'certificate-upload';

export const EDIT = 'edit';
export const EDIT_REDIRECT = 'edit_redirect';
export const EDIT_PRODUCTS = 'edit_products';
export const EDIT_ORDER = 'edit_order';
export const EDIT_GROUP = 'edit_group';
export const EDIT_REGISTER_ITEM = 'edit_register_item';
export const EDIT_EXTERNAL_VOCABULARY = 'edit_external_vocabulary';
export const DELETED_EXTERNAL_VOCABULARY = 'deleted_external_vocabulary';
export const EDIT_IN_DIALOG = 'edit_in_dialog';
export const DETAIL_ORDER = 'detail_order';
export const DELETE_ORDER = 'delete_order';
export const HAS_ORDER_ATTACH = 'has_order_attach';
export const SELECT_IN_GRID = 'select_in_grid';
export const SELECT_ORDER_TO_EDIT_STUDY_IN_DISTRIBUTE = 'select_order_to_edit_study_in_distribute';
export const SELECT_ORDER_TO_EDIT_STUDY = 'select_order_to_edit_study';
export const SELECT_ORDER_TO_EDIT_PATIENT = 'select_order_to_edit_patient';
export const SELECT_ORDER_TO_NEW_STUDY_FORM = 'SELECT_ORDER_TO_NEW_STUDY_FORM';
export const SELECT_ORDER_TO_IMPORT = 'select_order_to_import';
export const SELECT_PATIENT_MANAGEMENT_TO_IMPORT = 'select_patient_management_to_import';
export const SELECT_STUDY_TO_IMPORT = 'select_study_to_import';
export const SELECT_ORDER = 'select_order';
export const DOWNLOAD = 'download';
export const DELETE_USER_FROM_FOLDER = 'delete_user_from_folder';
export const DELETE_DOCUMENT_FROM_FOLDER = 'delete_document_from_folder';
export const DELETE_DOCUMENTS_FROM_FOLDER = 'delete_documents_from_folder';
export const DELETE = 'delete';
export const DELETE2 = 'delete2';
export const DELETE_SERIE = 'delete_serie';
export const SHOW_DOCUMENT = 'show_document';
export const VIEW_ATTACHEMENT = 'view_attachement';
export const DELETE_ATTACHMENT = 'delete_attachment';
export const VIEW_DOCUMENT = 'view_document';
export const DEFAULT = 'default';
export const ACTIVE = 'active';
export const ACTIVE_REGISTER_ITEM = 'activeRegisteritem';
export const ACTIVE_EXTERNAL_VOCABULARY = 'activeExternalVocabulary';
export const FAVOURITE = 'favourite';
export const PRINTABLE = 'printable';
export const EXPORTABLE = 'exportable';
export const IS_BASIC = 'isBasic';
export const ACTIVE_FORM_ITEM = 'activeFormItem';
export const ENABLED = 'enabled';
export const ENABLED_BOOKMARK = 'enabled_bookmark';
export const ENABLED_USER = 'enabled_user';
export const RETIRE = 'retire';
export const EDIT_FORMAT = 'edit_format';
export const EDIT_TYPE_OF_DOCUMENT = 'edit_type_of_document';
export const EDIT_BOOKMARK = 'edit_bookmark';
export const EDIT_FORM_ITEM = 'edit_form_item';
export const FAVOURITE_FACILITY_FOR_WORKPLACE = 'favourite_for_workplace';
export const DETAIL = 'detail';
export const TEST_DETAIL = 'testDetail';
export const TEST_EDIT = 'testEdit';
export const TEST_CLONE = 'testClone';
export const TEST_PUBLISH = 'testPublish';
export const TEST_DELETE = 'testDelete';
export const TEST_START = 'testStart';
export const TEST_RESULT_DETAIL = 'testResultDetail';
export const STUDY_DETAIL = 'STUDY_DETAIL';
export const STORE_REQUEST_SEARCH_RESULTS = 'STORE_REQUEST_SEARCH_RESULTS';
export const REQUEST_DETAIL = 'requestDetail';
export const REQUEST_APPROVE = 'requestApprove';
export const REQUEST_REJECT = 'requestReject';
export const REQUEST_EDIT = 'requestEdit';
export const REQUEST_DELETE = 'requestDelete';
export const REQUEST_COPY = 'requestCopy';
export const REQUEST_MOVE = 'requestMove';
export const REQUEST_MOVE_TO_FOLDER = 'requestMoveToFolder';
export const REQUEST_MOVE_TO_TRASH = 'requestMoveToTrash';
export const REQUEST_SPLIT = 'requestSplit';
export const REQUEST_SEND = 'requestSend';
export const REQUEST_ABORT = 'requestAbort';
export const ACTIVE_FACILITY = 'activeFacility';
export const DEFAULT_MODALITY = 'defaultModality';
export const REQUEST_EDIT_PATIENT = 'edit_patient';
export const REQUEST_EDIT_STUDY = 'edit_study';
export const SEND = 'send';
export const SEND_CONFIRM = 'send_confirm';
export const MOVE = 'move';
export const MOVE_TO_FOLDER = 'moveToFolder';
export const MOVE_TO_TRASH = 'moveToTrash';
export const COPY = 'copy';
export const SPLIT = 'split';
export const LIST = 'list';
export const LIST_FOR_FOLDER = 'listForFolder';
export const LIST_UNSIGNED = 'listUnsigned';
export const DOSE_MONITORING = 'dose_monitoring';
export const DOSE_MONITORING_EXPORT = 'dose_monitoring_export';
export const EXTEND_EXPIRATION = 'extendExpiration';
export const REMOVE_STUDY_FROM_FOLDER = 'removeStudyFromFolder';
export const REMOVE_ALL_STUDIES_FROM_FOLDER = 'removeAllStudiesFromFolder';
export const REMOVE_STUDIES_FROM_BASKET = 'removeStudiesFromBasket';
export const REMOVE_ALL_STUDIES_FROM_BASKET = 'removeAllStudiesFromBasket';
export const REORDER_SOURCE = 'reorderSource';
export const REORDER_TARGET = 'reorderTarget';
export const EXPORT = 'export';
export const ARCHIVE = 'archive';
export const PROPOSAL_SHREDDING = 'proposalShredding';
export const SHREDDING = 'shredding';
export const SHREDDING_PRINT = 'shreddingPrint';
export const ARCHIVED = 'ARCHIVED';
export const SET_STUDIES = 'SET_STUDIES';
export const SET_BULK_STUDIES = 'SET_BULK_STUDIES';
export const RESET_STUDIES = 'RESET_STUDIES';
export const RESET_ALL_STUDIES = 'RESET_ALL_STUDIES';
export const REMOVE_FILE = 'remove_file';
export const CANCEL_UPLOAD = 'cancel_upload';

//
export const SEARCH_FUNCTION_SEARCH = 'SEARCH';
export const SEARCH_FUNCTION_SEND = 'SEND';
export const SEARCH_FUNCTION_REORDER = 'REORDER';
export const SEARCH_FUNCTION_SPLIT = 'SPLIT';
export const SEARCH_FUNCTION_EDIT = 'EDIT';
export const SEARCH_FUNCTION_EDIT_PATIENT = 'EDIT_PATIENT';
export const SEARCH_FUNCTION_EDIT_STUDY = 'EDIT_STUDY';
export const SEARCH_FUNCTION_COPY = 'COPY';
export const SEARCH_FUNCTION_MOVE = 'MOVE';
export const SEARCH_FUNCTION_REOPEN = 'REOPEN';
export const SEARCH_FUNCTION_MOVE_TO_FOLDER = 'MOVE_TO_FOLDER';
export const SEARCH_FUNCTION_MOVE_TO_TRASH = 'MOVE_TO_TRASH';
export const SEARCH_FUNCTION_SCIENTIFIC_ARCHIVE = 'SCIENTIFICARCHIVE';
export const SEARCH_FUNCTION_ALL = 'ALL';
export const SEARCH_FUNCTION_ARCHIVE = 'ARCHIVATION';
export const SEARCH_FUNCTION_IMPORT = 'IMPORT';
export const SEARCH_FUNCTION_EXPORT = 'EXPORT';
export const SEARCH_FUNCTION_UNSIGNED = 'UNSIGNED';
export const SEARCH_FUNCTION_DOSE_MONITORING = 'DOSE_MONITORING';
export const SEARCH_FUNCTION_CREATE_SR = 'CREATE_SR';
export const SEARCH_FUNCTION_CHANGE_STATUSES = 'CHANGE_STATUSES';
export const SEARCH_FUNCTION_ADD_TAGS = 'ADD_TAGS';
export const LOCKED_STUDY = 'LOCKED_STUDY';
export const VIEWER = 'VIEWER';
export const ECG = 'ECG';
export const PREVIEW = 'PREVIEW';
export const SELECT_CONNECTED_MODALITY_FOR_EDIT = 'SELECT_CONNECTED_MODALITY_FOR_EDIT';
export const DELETE_CONNECTED_MODALITY = 'DELETE_CONNECTED_MODALITY';
export const ECHO_CONNECTED_MODALITY = 'ECHO_CONNECTED_MODALITY';

export const DETAIL_QUEUE = 'detail_queue';
export const CANCEL_QUEUE = 'cancel_queue';
export const DETAIL_REQUEST = 'detail_request';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const BASKET = 'basket';

export const SYSTEM_SETTINGS_FOR_SEARCH_FUNCTION = {
  [SEARCH_FUNCTION_SEARCH]: 'searchAllowed',
  [SEARCH_FUNCTION_SEND]: 'sendAllowed',
  [SEARCH_FUNCTION_REORDER]: 'reorderAllowed',
  [SEARCH_FUNCTION_SPLIT]: 'splitAllowed',
  [SEARCH_FUNCTION_EDIT]: 'updateAllowed',
  [SEARCH_FUNCTION_COPY]: 'copyAllowed',
  [SEARCH_FUNCTION_MOVE]: 'moveAllowed',
  [SEARCH_FUNCTION_SCIENTIFIC_ARCHIVE]: 'scientificArchiveAllowed',
  [SEARCH_FUNCTION_ARCHIVE]: 'archivingAllowed',
  [SEARCH_FUNCTION_IMPORT]: 'importAllowed',
  [SEARCH_FUNCTION_EXPORT]: 'exportAllowed',
  [SEARCH_FUNCTION_UNSIGNED]: 'studyCanBeSigned',
};

export const EDIT_TYPE_PATIENT_SLUG = 'editPatient';
export const EDIT_TYPE_STUDY_SLUG = 'editStudy';

export const SEXES = [
  { value: 'M', label: 'male' },
  { value: 'F', label: 'female' },
  { value: 'O', label: 'different' },
];

export const ARCHIVE_WITH_DATA_INPUT_ALLOWED = 'dataInputAllowed';

// TIME BUTTONS
export const DATE_BTN_LAST_24_HOURS = '24h';

// DATE BUTTONS
export const DATE_BTN_TODAY = 'today';
export const DATE_BTN_YESTERDAY = 'yesterday';
export const DATE_BTN_LAST_THREE_DAYS = 'lastTreeDays';
export const DATE_BTN_LAST_WEEK = 'lastWeek';
export const DATE_BTN_LAST_MONTH = 'lastMonth';
export const DATE_BTN_CUSTOM_DATE = 'customDate';

export const PRODUCT_CLOUD_PACS = 'CloudPacs';
export const PRODUCT_DR_SEJF = 'DrSejf';
export const PRODUCT_EXCHANGE_NETWORK = 'ExchangeNetwork';

// STATES
export const STATE_FINISHED = 'FINISHED';
export const STATE_APPROVED = 'APPROVED';
export const STATE_REJECTED = 'REJECTED';
export const STATE_NEW = 'NEW';
export const STATE_ABORTED = 'ABORTED';
export const STATE_FAILED = 'FAILED';
export const STATE_WAITING = 'WAITING';

export const REQUEST_ALL_STATES = [
  STATE_FINISHED,
  STATE_APPROVED,
  STATE_REJECTED,
  STATE_NEW,
  STATE_ABORTED,
  STATE_FAILED,
  STATE_WAITING,
] as const;

// STUDY SOURCE TYPE
export const STUDY_TYPE_REQUESTED = 'requested';
export const STUDY_TYPE_PROVIDED = 'provided';

// OPERATION TYPE
export const OPERATION_TYPE_SEND = 'SEND';
export const OPERATION_TYPE_REORDER = 'REORDER';
export const OPERATION_TYPE_SPLIT = 'SPLIT';
export const OPERATION_TYPE_EDIT = 'EDIT';
export const OPERATION_TYPE_EDIT_STUDY = 'EDIT_STUDY';
export const OPERATION_TYPE_EDIT_PATIENT = 'EDIT_PATIENT';
export const OPERATION_TYPE_COPY = 'COPY';
export const OPERATION_TYPE_MOVE = 'MOVE';
export const OPERATION_TYPE_MOVE_TO_FOLDER = 'MOVE_TO_FOLDER';
export const OPERATION_TYPE_MOVE_TO_TRASH = 'MOVE_TO_TRASH';
export const OPERATION_TYPE_CREATE_SR = 'CREATE_SR';
export const OPERATION_TYPE_EXPORT = 'EXPORT';
export const OPERATION_TYPE_IMPORT = 'IMPORT';
export const OPERATION_TYPE_REOPEN = 'REOPEN';

export const REQUEST_OPERATION_TYPES = [
  OPERATION_TYPE_SEND,
  OPERATION_TYPE_REORDER,
  OPERATION_TYPE_SPLIT,
  OPERATION_TYPE_EDIT,
  OPERATION_TYPE_EDIT_STUDY,
  OPERATION_TYPE_EDIT_PATIENT,
  OPERATION_TYPE_COPY,
  OPERATION_TYPE_MOVE,
  OPERATION_TYPE_MOVE_TO_FOLDER,
  OPERATION_TYPE_MOVE_TO_TRASH,
  OPERATION_TYPE_CREATE_SR,
  OPERATION_TYPE_EXPORT,
  OPERATION_TYPE_IMPORT,
  OPERATION_TYPE_REOPEN,
] as const;

export type REQUEST_STATE = (typeof REQUEST_ALL_STATES)[number];
export type OPERATION_TYPE = (typeof REQUEST_OPERATION_TYPES)[number];

// INSTANCE TIME TYPE
export const INSTANCE_TIME = 'INSTANCE_TIME';
export const INSTANCE_TIME_SERIE_TIME = 'INSTANCE_TIME_SERIE_TIME';
export const INSTANCE_TIME_ACQUISITION_TIME = 'INSTANCE_TIME_ACQUISITION_TIME';

// KEYBOARD KEYCODES
export const KEY_ESCAPE = 27;
export const KEY_ENTER = 13;

// STUDY AVAILABILITY
export const STUDY_AVAILABILITY_ONLINE = 0;
export const STUDY_AVAILABILITY_ARCHIVED = 1;
export const STUDY_AVAILABILITY_OFFLINE = 2;
export const STUDY_AVAILABILITY_UNAVAILABLE = 3;

// NOTIFICATION STATES
export const NOTIFICATIONS_STATE_NEW = 'NEW';
export const NOTIFICATIONS_STATE_READ = 'READ';
export const NOTIFICATIONS_STATE_ARCHVIED = 'ARCHIVED';

// Alerts
export const ALERT_ADD = 'ALERT_ADD';
export const ALERT_REMOVE = 'ALERT_REMOVE';
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_INFO = 'info';
export const DEFAULT_HIDE_DURATION = 6000;
// TESTS
export const TEST_STATE_DRAFT = 'DRAFT';
export const TEST_STATE_READY = 'READY';
export const TEST_TYPE_GENERAL = 'GENERAL';
export const TEST_TYPE_STUDY = 'STUDY';
export const DEFINITION_EDIT = 'definitionEdit';
export const TEST_FINISHED = 'TEST_FINISHED';
export const QUESTION_NEXT = 'QUESTION_NEXT';
export const QUESTION_PREV = 'QUESTION_PREV';
export const TEST_PASSED = 'PASSED';
export const TEST_FAILED = 'FAILED';
export const TEST_STATS_TYPE_EXECUTED = 'EXECUTED';
export const TEST_STATS_TYPE_ERROR_RATE = 'ERROR_RATE';

export const USER_PERMISSION = [
  { value: 'READ', label: 'read', tooltip: 'read_tooltip' },
  { value: 'READ_PLUS_WRITE', label: 'read_plus_write', tooltip: 'read_plus_write_tooltip' },
  {
    value: 'WRITE_MINUS_DELETE',
    label: 'write_minus_delete',
    tooltip: 'write_minus_delete_tooltip',
  },
  { value: 'WRITE_PLUS_UPDATE', label: 'write_plus_update', tooltip: 'write_plus_update_tooltip' },
];

export const DECUBITS_CODE = [
  { value: 'HEAD', label: 'head' },
  { value: 'LARM', label: 'larm' },
  { value: 'RARM', label: 'rarm' },
  { value: 'BODY', label: 'body' },
  { value: 'LLEG', label: 'lleg' },
  { value: 'RLEG', label: 'rleg' },
];

// STUDY TABS
export const TAB_COMMENTS = 'TAB_COMMENTS';
export const TAB_ADD_NEW_DEFINITION = 'TAB_ADD_NEW_DEFINITION';
export const TAB_EDIT_DEFINITION = 'TAB_EDIT_DEFINITION';
export const TAB_DEFINITIONS = 'TAB_DEFINITIONS';
export const TAB_TAGS = 'TAB_TAGS';
export const TAB_FOLDERS = 'TAB_FOLDERS';
export const TAB_SERIES = 'TAB_SERIES';
export const TAB_ORDER = 'TAB_ORDER';
export const TAB_REPORT = 'TAB_REPORT';
export const TAB_DOSES = 'TAB_DOSES';
export const TAB_OPERATIONS = 'TAB_OPERATIONS';

// FOLDER TABS
export const TAB_DOCUMENTS = 'TAB_DOCUMENTS';
export const TAB_STUDIES = 'TAB_STUDIES';
export const TAB_USERS = 'TAB_USERS';

// EXPIRATION BUTTONS
export const EXPIRATION_BTN_ONE_DAY = '1d';
export const EXPIRATION_BTN_ONE_WEEK = '1w';
export const EXPIRATION_BTN_TWO_WEEK = '2w';
export const EXPIRATION_BTN_THREE_WEEK = '3w';
export const EXPIRATION_BTN_ONE_MONTH = '1m';

// ASSIGN BUTTONS
export const ASSIGN_BTN_AUTOMATIC = 'automatic';
export const ASSIGN_BTN_MANUAL = 'manual';
export const SELECTED_BTN_SPLIT_BY = 'selectedButtonsSplitBy';

// HTTP statuses
export const FORBIDDEN = 'FORBIDDEN';

// Preview size
export const PREVIEW_URL_SIZE = 100;
export const PREVIEW_URL_SIZE_COMPACT_MODE = 62;
export const DIALOG_PREVIEW_SIZE = 850;

// statusOfCode
export const UNCOLLAPSED = 'UNCOLLAPSED';
export const COLLAPSED = 'COLLAPSED';
export const CLOSED = 'CLOSED';
export const ERROR = 'ERROR';

// archive type name
export const ARCHIVE_TYPE_NAME_MS = 'MS';
export const ARCHIVE_TYPE_NAME_DPGW = 'DPGW';

export const SEJF_SENDFORM_DICOM = 'DICOM';
export const SEJF_SENDFORM_PDF = 'PDF';
export const SEJF_SENDFORM_ORIGINAL = 'ORIGINAL';

export const SET_CONFIG = 'SET_CONFIG';

export const TRANSFER_SEND = 'SEND';
export const TRANSFER_RECEIVED = 'RECEIVED';

// Functions of studies
export const FUNCTION_ARCHIVATION = 'archivation';
export const FUNCTION_COPY = 'copy';
export const FUNCTION_EDIT_PATIENT = 'editPatient';
export const FUNCTION_EDIT_STUDY = 'editStudy';
export const FUNCTION_EXPORT = 'export';
export const FUNCTION_IMPORT_STUDY = 'importStudy';
export const FUNCTION_MOVE = 'move';
export const FUNCTION_REORDER = 'reorder';
export const FUNCTION_SCIENTIFIC_ARCHIVE = 'scientificArchive';
export const FUNCTION_SEARCH = 'search';
export const FUNCTION_SEND = 'send';
export const FUNCTION_SIGNATURE = 'signature';
export const FUNCTION_SPLIT = 'split';
export const FUNCTIONS_OF_STUDIES = [
  FUNCTION_ARCHIVATION,
  FUNCTION_COPY,
  FUNCTION_EDIT_PATIENT,
  FUNCTION_EDIT_STUDY,
  FUNCTION_EXPORT,
  FUNCTION_IMPORT_STUDY,
  FUNCTION_MOVE,
  FUNCTION_REORDER,
  FUNCTION_SCIENTIFIC_ARCHIVE,
  FUNCTION_SEARCH,
  FUNCTION_SEND,
  FUNCTION_SIGNATURE,
  FUNCTION_SPLIT,
];

export const MAPPING_FUNCTIONS_OF_STUDIES = [
  { key: SEARCH_FUNCTION_ARCHIVE, value: FUNCTION_ARCHIVATION },
  { key: SEARCH_FUNCTION_COPY, value: FUNCTION_COPY },
  { key: SEARCH_FUNCTION_EDIT, value: 'edit' },
  { key: SEARCH_FUNCTION_EDIT_PATIENT, value: FUNCTION_EDIT_PATIENT },
  { key: SEARCH_FUNCTION_EDIT_STUDY, value: FUNCTION_EDIT_STUDY },
  { key: SEARCH_FUNCTION_EXPORT, value: FUNCTION_EXPORT },
  { key: SEARCH_FUNCTION_IMPORT, value: FUNCTION_IMPORT_STUDY },
  { key: SEARCH_FUNCTION_MOVE, value: FUNCTION_MOVE },
  { key: SEARCH_FUNCTION_REORDER, value: FUNCTION_REORDER },
  { key: SEARCH_FUNCTION_SCIENTIFIC_ARCHIVE, value: FUNCTION_SCIENTIFIC_ARCHIVE },
  { key: SEARCH_FUNCTION_SEARCH, value: FUNCTION_SEARCH },
  { key: SEARCH_FUNCTION_SEND, value: FUNCTION_SEND },
  { key: SEARCH_FUNCTION_UNSIGNED, value: FUNCTION_SIGNATURE },
  { key: SEARCH_FUNCTION_SPLIT, value: FUNCTION_SPLIT },
];

export const STORE_FACILITIES_MANUAL_LOAD = 'STORE_FACILITIES_MANUAL_LOAD';

// type
export type GridDataType = { xs?: any; sm?: any; md?: any; lg?: any; xl?: any };
export type InputType = 'text' | 'password' | 'number' | 'timePicker';
export type TypographyType = 'div' | 'p';
export type AnnouncementType = 'info' | 'warning' | 'danger';

export const DATAGRID_SETTINGS_COLUMN_WIDTHS = 'columnWidths';
export const DATAGRID_SETTINGS_COLUMN_VISIBILITY_MODEL = 'columnVisibilityModel';
export const DATAGRID_SETTINGS_COLUMN_SORT_MODEL = 'columnSortModel';
export const DATAGRID_SETTINGS_COLUMN_ORDER_MODEL = 'columnOrderModel';
export const DATAGRID_SETTINGS_COLUMN_PINNED_COLUMNS = 'pinnedColumns';

export const GRID_SEARCH_SETTINGS_KEY = 'studyMUI';
export const GRID_SEARCH_SERIES_SETTINGS_KEY = 'studyMUIseries';
export const GRID_STUDY_DETAIL_SERIES_SETTINGS_KEY = 'studyDetailMUIseries';
export const GRID_STUDY_DETAIL_INSTANCES_SETTINGS_KEY = 'studyDetailMUIinstances';
export type ActionArchivesKey = 'send' | 'copy' | 'move' | 'archive' | 'export' | 'shredding';

// WEBSOCKET NOTIFICATION
export const SET_WEBSOCKET_NOTIFICATIONS = 'SET_NOTIFICATION_COUNT';
export const NOTIFICATION_ROW_COUNT = 100;

// Notification events
export const UPLOAD_STUDY_OK = 'UPLOAD_STUDY_OK';
export const UPDATE_STUDY_OK = 'UPDATE_STUDY_OK';
export const UPDATE_PATIENT_OK = 'UPDATE_PATIENT_OK';
export const SEND_STUDY_OK = 'SEND_STUDY_OK';
export const DISTRIBUTE_COPY_OK = 'DISTRIBUTE_COPY_OK';
export const DISTRIBUTE_MOVE_OK = 'DISTRIBUTE_MOVE_OK';
export const DISTRIBUTE_MOVE_AND_UPDATE_OK = 'DISTRIBUTE_MOVE_AND_UPDATE_OK';
export const DISTRIBUTE_COPY_AND_UPDATE_OK = 'DISTRIBUTE_COPY_AND_UPDATE_OK';
export const SAVE_STRUCTURED_REPORT_OK = 'SAVE_STRUCTURED_REPORT_OK';
export const SHREDDING_STUDY_OK = 'SHREDDING_STUDY_OK';

export const ASC = 'ASC';
export const DESC = 'DESC';

// COMPACT MODE STYLES
export const COMPACT_MODE_FORM_ELEMENT_HEIGHT = '30px';
export const COMPACT_MODE_FORM_ELEMENT_FONT_SIZE = '12px';
export const COMPACT_MODE_FORM_ELEMENT_LINE_HEIGHT = '1.2';

// DATA GRID
export const DATA_GRID_FILTER_HEADER_ROW_HEIGHT = 40;
export const DATA_GRID_DEFAULT_SORTING: GridSortDirection[] = ['asc', 'desc'];
export const DATA_GRID_ROW_HEIGHT = 30;
export const DATA_GRID_SERIES_ROW_HEIGHT = 100;
export const DATA_GRID_SERIES_ROW_HEIGHT_COMPACT_MODE = 70;

// THEME
export const ORCZ = 'orcz';
export const CKTCH = 'cktch';

// MEDIA QUERY
export const MEDIA_QUERY_MIN_HEIGHT_FOR_DATA_GRID = 600;
export const MEDIA_QUERY_MIN_WIDTH_FOR_DATA_GRID = 800;

export const DATA_FOR_DOCUMENT_DETAIL_FROM_STORAGE = 'dataForDocumentDetailFromStorage';

export const FINISHED = 'FINISHED';
export const SECOND_READ = 'SECOND_READ';

export const INCORRECT_CERTIFICATE_PASSWORD = 'incorrect_certificate_password';
export const CERTIFICATE_NOT_FOUND = 'certificate_not_found';

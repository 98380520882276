import { get } from 'lodash';
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { bytesToSize } from 'utils/import';
import { Tooltip } from '@mui/material';
import { DATA_GRID_DEFAULT_SORTING, DATA_GRID_ROW_HEIGHT } from 'constants/constants';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import useGridLocalization from 'components/SearchResultsMUI/useGridLocalization';

const FilesGrid: React.FC<any> = ({
  files,
  removeFile,
  containerId,
  toggleEditDialog,
  setFileForEditDialog,
  canEdit = false,
}) => {
  const { t } = useTranslation('Import');
  const { compactMode } = useAppInfo();

  const gridLocalization = useGridLocalization();

  const columns: GridColDef[] = [
    {
      field: 'fileName',
      headerName: t('nonDicom.grid.fileName'),
      flex: 1,
      valueGetter: (value: any, row: any) => get(row, 'name', ''),
    },
    {
      field: 'size',
      headerName: t('nonDicom.grid.size'),
      flex: 0.5,
      valueGetter: (value: any, row: any) => bytesToSize(get(row, 'data.size', 0)),
    },
    {
      field: 'actions',
      headerName: t('Akce'),
      flex: 0.5,
      type: 'actions',
      getActions: ({ row }: GridRowParams) =>
        canEdit
          ? [
              <GridActionsCellItem
                data-tour="files-grid-editRow"
                icon={
                  <Tooltip title={t('edit')}>
                    <EditIcon />
                  </Tooltip>
                }
                showInMenu={false}
                label={t('isNoOk')}
                onClick={() => {
                  toggleEditDialog();
                  setFileForEditDialog(row);
                }}
              />,
              <GridActionsCellItem
                data-tour="import-nonDicom-deleteRow"
                icon={
                  <Tooltip title={t('delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                showInMenu={false}
                label={t('isNoOk')}
                onClick={() => removeFile(row.id)}
              />,
            ]
          : [
              <GridActionsCellItem
                data-tour="import-nonDicom-deleteRow"
                icon={
                  <Tooltip title={t('delete')}>
                    <DeleteIcon />
                  </Tooltip>
                }
                showInMenu={false}
                label={t('isNoOk')}
                onClick={() => removeFile(row.id)}
              />,
            ],
    },
  ];
  return (
    <div
      id={containerId}
      style={{
        maxHeight: 350,
        width: '100%',
        overflowY: 'auto',
      }}
    >
      <DataGridPro
        sortingOrder={DATA_GRID_DEFAULT_SORTING}
        headerFilters={true}
        rows={files}
        columns={columns}
        localeText={gridLocalization}
        rowHeight={DATA_GRID_ROW_HEIGHT}
        density={compactMode ? 'compact' : 'standard'}
        slotProps={{
          headerFilterCell: {
            InputComponentProps: {
              size: 'small',
            },
          },
        }}
      />
    </div>
  );
};

export default FilesGrid;

import { useMemo } from 'react';
import { Tooltip, IconButton, Box, CircularProgress } from '@mui/material';
import {
  GridRowParams,
  GridRenderCellParams,
  GridCellParams,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-pro';
import {
  get,
  join,
  isEmpty,
  uniq,
  isArray,
  find,
  isString,
  isNumber,
  includes,
  reduce,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import TagsIcon from '@mui/icons-material/LabelOutlined';
import SendIcon from '@mui/icons-material/Send';
import CommentsIcon from '@mui/icons-material/Comment';
import FolderIcon from '@mui/icons-material/FolderOpen';
import AttachIcon from '@mui/icons-material/AttachFile';
import DoneIcon from '@mui/icons-material/Done';
import PriorityIcon from '@mui/icons-material/PriorityHigh';
import ArchiveInboxIcon from '@mui/icons-material/Inbox';
import ArchiveArchivedIcon from '@mui/icons-material/Archive';
import ArchiveOfflineIcon from '@mui/icons-material/ExploreOff';
import ArchiveUnavailableIcon from '@mui/icons-material/Error';

import {
  GRID_SEARCH_SETTINGS_KEY,
  LIST_FOR_FOLDER,
  SEARCH_FUNCTION_ALL,
  SEXES,
  STUDY_AVAILABILITY_ARCHIVED,
  STUDY_AVAILABILITY_OFFLINE,
  STUDY_AVAILABILITY_ONLINE,
} from 'constants/constants';
import { useGridActions } from './useGridActions';
import styles from './_styles';
import { IUseGridColumns, IActionItem } from './_types';
import { getMedoroIcon } from 'modules/Studies/StudyDetail/_icons';
import { useGridSettings } from './useGridSettings';
import { useAppInfo } from 'utils/hooks/useAppInfo';
import { format } from 'date-fns';
import { getTitleForArchivingStudyIcon, joinParams } from 'utils/study';
import { useUser } from 'utils/hooks/useUser';
import { getSettingValueForVariable } from 'utils/products';

export const useGridColumns = ({
  action = '',
  actions = [],
  isShredding = false,
  isShreddingPrint = false,
  isArchive = false,
  operations = {},
  isRequest = false,
  setSelection,
  nameAsLink = true,
  handleCloseDialog,
  importProps,
  disableStudyAndPatientForm,
  isActionFirstColumn = false,
  showReferringPhysician = true,
}: IUseGridColumns) => {
  const { t } = useTranslation('SearchResults');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { columnsInSearchGridWithAdditionalInfoBeingLoaded, getArchivesForFunctions } =
    useAppInfo();
  const { hasRole } = useUser();
  const canSearchAndViewStudy = hasRole('ROLE_VIEW_STUDY');
  const { actionsToShow } = useGridActions({
    actions,
    operations,
    isRequest,
    isFromColumn: true,
    setSelection,
    handleCloseDialog,
    importProps,
    disableStudyAndPatientForm,
  });

  const { getGridColumnsWidths, getGridColumnsOrder } = useGridSettings();

  const allArchives = getArchivesForFunctions(SEARCH_FUNCTION_ALL);
  const archivingAllowedForArchives = useMemo(() => {
    return reduce(
      allArchives,
      (acc: any, archive) => {
        const archiveAllowed = getSettingValueForVariable(
          get(archive, 'systemSetting', []),
          'archivingAllowed',
        );
        acc[archive.id] = archiveAllowed === 'true';
        return acc;
      },
      {},
    );
  }, [allArchives]);

  const columnWidths = getGridColumnsWidths(GRID_SEARCH_SETTINGS_KEY);
  const columnOrder = getGridColumnsOrder(GRID_SEARCH_SETTINGS_KEY);

  const getName = (value: any) => (isString(value) ? value.replace(/\^/g, ' ') : null);

  const renderHeaderWithSpinner = (params: GridColumnHeaderParams) => {
    return (
      <Box sx={{ fontWeight: 500 }}>
        {params.colDef.headerName}
        {includes(columnsInSearchGridWithAdditionalInfoBeingLoaded, params.colDef.field) && (
          <CircularProgress size={16} sx={{ ml: 1 }} />
        )}
      </Box>
    );
  };

  const getUniqueTags = (row: any) => {
    const studyTags = get(row, 'studyExtendedInfo.studyTags') || [];
    const tagsArray: string[] = [];
    try {
      studyTags.forEach((studyTag: string) =>
        studyTag.split('*^*').forEach((item: string) => {
          tagsArray.push(
            join(
              item
                .substr(1)
                .split('#')
                .map((tag: string) => {
                  return tag;
                }),
              ' > ',
            ) as string,
          );
        }),
      );
      if (!isEmpty(tagsArray)) {
        return uniq(tagsArray).sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
      }
    } catch (e) {
      return [];
    }
  };

  const getUniqueSendInfos = (row: any) => {
    const sendInfos = get(row, 'studyExtendedInfo.sendInfos', null) || [];
    let sendInfosArray: any[] = [];
    try {
      sendInfos.forEach((sendInfo: any) =>
        sendInfosArray.push(
          get(sendInfo, 'sejf', false)
            ? t('drSejf')
            : `${get(sendInfo, 'exchangeNetworkName', '')}: ${get(sendInfo, 'facilityName', '')}`,
        ),
      );
      if (!isEmpty(sendInfosArray)) {
        return uniq(sendInfosArray);
      }
      return [];
    } catch (e) {
      return [];
    }
  };

  const getFolderInfos = (row: any) => {
    const folderInfos = get(row, 'studyExtendedInfo.folderInfos', null) || [];
    const folderInfosArray: any[] = [];
    let daysToExpiration = -1;
    let xDaysToExpiration = '';
    let lastViewed = '';
    try {
      if (action === LIST_FOR_FOLDER) {
        const folderInfo = find(folderInfos, { id: get(row, 'folderId') });
        if (folderInfo) {
          daysToExpiration = get(folderInfo, 'daysToExpiration', -1);
          lastViewed = get(folderInfo, 'lastViewed', '');
        }
        xDaysToExpiration =
          daysToExpiration < 0
            ? ''
            : daysToExpiration === 1
              ? t('day1', { days: daysToExpiration })
              : daysToExpiration > 0 && daysToExpiration < 5
                ? t('day234', { days: daysToExpiration })
                : t('days', { days: daysToExpiration });
      } else {
        folderInfos.forEach((folderInfo: any) =>
          folderInfosArray.push(get(folderInfo, 'name', '')),
        );
      }

      return {
        folderInfosArray,
        xDaysToExpiration,
        lastViewed,
      };
    } catch (e) {
      return {
        folderInfosArray,
        xDaysToExpiration,
      };
    }
  };

  const columns = useMemo(() => {
    return [
      {
        field: 'actions',
        headerName: t('actions'),
        hideable: false,
        width: 100,
        type: 'actions',
        align: 'right',
        minWidth: 100,
        getActions: (params: GridRowParams) => {
          return actionsToShow.map((action: IActionItem) => action.action(params.row));
        },
      },
      ...(!isShredding
        ? [
          {
            field: 'comments',
            headerName: t('comments'),
            width: 100,
            renderHeader: renderHeaderWithSpinner,
            valueGetter: (value: any, row: any) => {
              const comments = get(row, 'studyExtendedInfo.comments', []);
              return isArray(comments) && comments.length > 0;
            },
            renderCell: ({ row }: GridCellParams) => {
              const comments = get(row, 'studyExtendedInfo.comments', []);
              return (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {isArray(comments) && comments.length > 0 ? (
                    <Tooltip title={comments.join(', ')} placement="right">
                      <IconButton sx={styles.detailIcon}>
                        <CommentsIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </div>
              );
            },
          },
        ]
        : []),
      ...(!isShredding
        ? [
          {
            field: 'tags',
            headerName: t('tags'),
            width: 100,
            renderHeader: renderHeaderWithSpinner,
            valueGetter: (value: any, row: any) => {
              const tags = getUniqueTags(row);
              return isEmpty(tags) ? false : true;
            },
            renderCell: ({ row }: GridRenderCellParams) => {
              const tags = getUniqueTags(row);

              return !isEmpty(tags) ? (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Tooltip
                    title={
                      <>
                        {(tags || []).map((tag: string) => (
                          <div key={tag}>{tag}</div>
                        ))}
                      </>
                    }
                    placement="right"
                  >
                    <IconButton sx={styles.detailIcon}>
                      <TagsIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : null;
            },
          },
        ]
        : []),
      ...(!isShredding
        ? [
          {
            field: 'sendInfos',
            headerName: t('sendInfos'),
            width: 100,
            renderHeader: renderHeaderWithSpinner,
            valueGetter: (value: any, row: any) => {
              const sendInfos = getUniqueSendInfos(row);
              return isEmpty(sendInfos) ? false : true;
            },
            renderCell: ({ row }: GridRenderCellParams) => {
              const sendInfos = getUniqueSendInfos(row);
              return !isEmpty(sendInfos) ? (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Tooltip
                    title={
                      <>
                        {sendInfos.map((sendInfo) => (
                          <div key={sendInfo}>{sendInfo}</div>
                        ))}
                      </>
                    }
                    placement="right"
                  >
                    <IconButton sx={styles.detailIcon}>
                      <SendIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : null;
            },
          },
        ]
        : []),
      ...(!isShredding
        ? [
          {
            field: 'folderInfos',
            width: 100,
            headerName: t(action === LIST_FOR_FOLDER ? 'folderExpiratedInfos' : 'folderInfos'),
            renderHeader: renderHeaderWithSpinner,
            valueGetter: (value: any, row: any) => {
              const folderInfo = getFolderInfos(row);
              if (action === LIST_FOR_FOLDER) {
                if (folderInfo.xDaysToExpiration.length === 0) {
                  return false;
                }
                return true;
              }

              return isEmpty(folderInfo.folderInfosArray) ? false : true;
            },
            renderCell: ({ row }: GridRenderCellParams) => {
              const folderInfo = getFolderInfos(row);
              return action === LIST_FOR_FOLDER ? (
                <span>{folderInfo.xDaysToExpiration}</span>
              ) : !isEmpty(folderInfo.folderInfosArray) ? (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Tooltip
                    title={
                      <>
                        {folderInfo.folderInfosArray.map((folderInfo) => (
                          <div key={folderInfo}>{folderInfo}</div>
                        ))}
                      </>
                    }
                    placement="right"
                  >
                    <IconButton sx={styles.detailIcon}>
                      <FolderIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : null;
            },
          },
        ]
        : []),
      ...(!isShredding && action === LIST_FOR_FOLDER
        ? [
          {
            field: 'folderHistory',
            width: 150,
            headerName: t('folderHistory'),
            renderHeader: renderHeaderWithSpinner,
            valueGetter: (value: any, row: any) => {
              const folderInfo = getFolderInfos(row);
              const folderInfoAsDateTime =
                folderInfo.lastViewed && new Date(folderInfo.lastViewed);
              return folderInfoAsDateTime;
            },
            type: 'dateTime',
          },
        ]
        : []),
      ...(!isShredding
        ? [
          {
            field: 'hasAttachment',
            width: 100,
            headerName: t('hasAttachment'),
            renderHeader: renderHeaderWithSpinner,
            valueGetter: (value: any, row: any) => {
              const isOk = get(row, 'ok', true);
              const hasAttachment = get(row, 'studyExtendedInfo.hasAttachment', false);
              return isOk && hasAttachment ? true : false;
            },
            renderCell: ({ row }: GridRenderCellParams) => {
              const isOk = get(row, 'ok', true);
              const pathnameForLinkBack = get(row, 'pathnameForLinkBack', null);
              const hasAttachment = get(row, 'studyExtendedInfo.hasAttachment', false);
              const action2 = pathname ? pathname.split('/').slice(-1)[0] : null;
              return isOk && hasAttachment ? (
                <Tooltip title={t('showDetailWithAttachments')}>
                  <Box
                    onClick={() => {
                      navigate(
                        `/study/${get(row, 'hashIID')}${pathnameForLinkBack
                          ? `?pathname=${pathnameForLinkBack}`
                          : action2
                            ? `?from=${action2}`
                            : ''
                        }&tab=wl&subtab=att`,
                      );
                    }}
                    sx={styles.gridLink}
                  >
                    <IconButton sx={styles.detailIcon}>
                      <AttachIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : null;
            },
          },
        ]
        : []),
      ...(!isShredding
        ? [
          {
            field: 'shredding',
            width: 120,
            headerName: t('shredding'),
            renderHeader: renderHeaderWithSpinner,
            valueGetter: (value: any, row: any) => {
              return get(row, 'studyExtendedInfo.hasShreddingProposal', false) ? true : false;
            },
            renderCell: ({ row }: GridRenderCellParams) => {
              const shredding = get(row, 'studyExtendedInfo.hasShreddingProposal', false);
              return shredding ? (
                <Tooltip title={t('shredded')}>
                  <IconButton sx={styles.detailIcon}>
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
              ) : null;
            },
          },
        ]
        : []),
      ...(isShredding
        ? [
          {
            field: 'proposalDateTime',
            headerName: t('proposalDateTime'),
            width: 150,
            valueGetter: (value: any, row: any) => {
              const proposalDateTime = get(row, 'proposal.createdDate', null);
              const proposalDateTimeAsDateTime = proposalDateTime && new Date(proposalDateTime);
              return proposalDateTimeAsDateTime;
            },
            type: 'dateTime',
          },
        ]
        : []),
      {
        field: 'name',
        headerName: t('name'),
        hideable: false,
        width: 150,
        renderCell: ({ row }: GridRenderCellParams) => {
          const value = get(row, 'name', '');
          const isOk = get(row, 'ok', true);
          const pathnameForLinkBack = get(row, 'pathnameForLinkBack', null);
          let action2 = pathname ? pathname.split('/').slice(-1)[0] : null;

          if (isRequest) action2 = pathname;
          return isOk && nameAsLink && canSearchAndViewStudy ? (
            <Tooltip title={getName(value)}>
              <Box
                onClick={() => {
                  navigate(
                    `/study/${get(row, 'hashIID')}${pathnameForLinkBack
                      ? `?pathname=${pathnameForLinkBack}`
                      : action2
                        ? `?from=${action2}${action2 === 'documents' ? '&pathBack=' + pathname : ''
                        }`
                        : ''
                    }`,
                  );
                }}
                sx={styles.gridLink}
              >
                {getName(value)}
              </Box>
            </Tooltip>
          ) : isString(value) ? (
            <Box>{getName(value)}</Box>
          ) : null;
        },
      },
      {
        field: 'patientId',
        headerName: t('patientId'),
        width: 120,
        // valueGetter: (value: any, row: any) => get(params, 'row.patient.identificationNumber', ''),
      },
      {
        field: 'accessionNumber',
        headerName: t('accessionNumber'),
        width: 150,
      },
      {
        field: 'patientSex',
        headerName: t('patientSex'),
        width: 70,
        valueGetter: (value: any, row: any) => {
          const patientSex = get(row, 'patientSex', '');
          return find(SEXES, { value: patientSex }) !== undefined
            ? t(`patient_sex_${patientSex}`)
            : patientSex;
        },
      },
      {
        field: 'patientBirthDate',
        headerName: t('patientBirthDate'),
        width: 125,
        type: 'date',
        valueGetter: (value: any) => value && new Date(value),
        valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy'),
      },
      {
        field: 'dateTime',
        headerName: t('dateTime'),
        width: 150,
        type: 'dateTime',
        valueGetter: (value: any) => value && new Date(value),
        valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy HH:mm'),
      },
      {
        field: 'modality',
        headerName: t('modalities'),
        width: 80,
        /*valueGetter: (value: any, row: any) =>
        isArray(get(params, 'row.modalitiesInStudy'))
          ? get(params, 'row.modalitiesInStudy').join(', ')
          : '',*/
      },
      {
        field: 'noOfInstances',
        headerName: t('noOfInstances'),
        width: 70,
        /*valueGetter: (value: any, row: any) =>
        `${get(params, 'row.numberOfStudyRelatedSeries', '')}/${get(
          params,
          'row.numberOfStudyRelatedInstances',
          '',
        )}`,*/
      },
      ...(!isShredding
        ? [
          {
            field: 'modalitiesNotShown',
            headerName: t('modalitiesNotShown'),
            width: 140,
            renderCell: ({ row }: GridRenderCellParams) => {
              const showExp = !isEmpty(get(row, 'modalitiesNotShown', []));
              return (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {showExp ? (
                    <Tooltip title={t('notify')} placement="right">
                      <PriorityIcon />
                    </Tooltip>
                  ) : null}
                </div>
              );
            },
          },
        ]
        : []),
      ...(!isShredding
        ? [
          {
            field: 'stationAet',
            headerName: t('stationAet'),
            width: 90,
            renderHeader: renderHeaderWithSpinner,
            valueGetter: (value: any, row: any) => {
              const stationAet = get(row, 'studyExtendedInfo.stationAet', null);
              return stationAet;
            },
          },
        ]
        : []),
      {
        field: 'studyDescription',
        headerName: t('studyDescription'),
        width: 150,
      },
      {
        field: 'archiveName',
        headerName: t('archive'),
        width: 110,
        // valueGetter: (value: any, row: any) => get(params, 'row.archive.name', ''),
      },
      ...(!isShredding
        ? [
          {
            field: 'studyStatus',
            headerName: t('StudyDetail:studyStatus'),
            width: 140,
            valueGetter: (value: any, row: any) => {
              const states = get(row, 'statuses', []);
              return isArray(states) && states.length > 0 ? true : false;
            },
            renderCell: ({ row }: GridRenderCellParams) => {
              const states = get(row, 'statuses', []).map((status: any, index: number) => {
                const uuid = get(status, 'uuid', index);
                const name = get(status, 'name', '');
                const title =
                  name.charAt(0) === '{' ? t(`StudyDetail:${name.slice(1, -1)}`) : name;
                const icon = get(status, 'icon', '');
                return (
                  <Tooltip title={title} key={uuid}>
                    {getMedoroIcon(icon)}
                  </Tooltip>
                );
              });
              return states;
            },
          },
        ]
        : []),
      ...(isShredding
        ? [
          {
            field: 'proposalSource',
            headerName: t('proposalSource'),
            width: 200,
            valueGetter: (value: any, row: any) => get(row, 'proposal.source', ''),
          },
        ]
        : []),
      ...(isShreddingPrint
        ? [
          {
            field: 'confirmationDateTime',
            headerName: t('confirmationDateTime'),
            valueGetter: (value: any, row: any) =>
              new Date(get(row, 'proposal.confirmationDate', '')),
            valueFormatter: (value: any) => value && format(value, 'dd. MM. yyyy HH:mm'),
          },
          {
            field: 'userConfirmation',
            headerName: t('userConfirmation'),
            valueGetter: (value: any, row: any) => get(row, 'proposal.userConfirmation', ''),
          },
        ]
        : []),
      ...(showReferringPhysician
        ? [
          {
            field: 'referringPhysician',
            headerName: t('referringPhysician'),
            valueGetter: (value: any, row: any) =>
              joinParams([
                get(row, 'referringPhysician.name.lastName', ''),
                get(row, 'referringPhysician.name.firstName', ''),
              ]),
          },
        ]
        : []),
      {
        field: 'availability',
        headerName: t('StudyDetail:studyAvailability'),
        width: 140,
        renderCell: ({ row }: GridCellParams) => {
          const availability = get(row, 'availability', '');
          const archiveHasAllowedArchiving = archivingAllowedForArchives[get(row, 'archiveId', '')];
          if (!archiveHasAllowedArchiving) {
            return <div />;
          }
          return (
            <Tooltip title={t(getTitleForArchivingStudyIcon(row)) as String}>
              {availability === STUDY_AVAILABILITY_ONLINE ? (
                <ArchiveInboxIcon />
              ) : availability === STUDY_AVAILABILITY_ARCHIVED ? (
                <ArchiveArchivedIcon />
              ) : availability === STUDY_AVAILABILITY_OFFLINE ? (
                <ArchiveOfflineIcon />
              ) : (
                <ArchiveUnavailableIcon />
              )}
            </Tooltip>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    actionsToShow,
    isShredding,
    action,
    isArchive,
    navigate,
    pathname,
    nameAsLink,
    columnsInSearchGridWithAdditionalInfoBeingLoaded,
  ]);

  const injectColumnWidthsIntoColumns = () => {
    return columns.map((column) => {
      const columnWidthFromGridSettings = get(find(columnWidths, { field: column.field }), 'width');

      return {
        ...column,
        width: isNumber(columnWidthFromGridSettings)
          ? columnWidthFromGridSettings
          : isNumber(column.width)
            ? column.width
            : 150,
      };
    });
  };

  const reorderColumnsByGridSettings = (columns: any[]) => {
    if (!isArray(columnOrder) || columnOrder.length === 0) {
      return columns;
    }
    const sortedColumns = [...columns].sort((a, b) => {
      const indexA = columnOrder.indexOf(a.field);
      const indexB = columnOrder.indexOf(b.field);

      // Pokud a ani b nejsou v order poli, ponecháme je ve stávajícím pořadí.
      if (indexA === -1 && indexB === -1) {
        return 0;
      }

      // Pokud a není v order poli, posuneme ho za b.
      if (indexA === -1) {
        return 1;
      }

      // Pokud b není v order poli, posuneme ho za a.
      if (indexB === -1) {
        return -1;
      }

      // Oba prvky jsou v order poli, vrátíme je podle jejich pořadí v order poli.
      return indexA - indexB;
    });

    //Force action column first
    if (isActionFirstColumn) {
      const action = sortedColumns.splice(
        sortedColumns.findIndex((column) => column.field === 'actions'),
        1,
      );
      if (action.length > 0) {
        sortedColumns.unshift(action[0]);
      }
    }

    return sortedColumns;
  };

  const columnsWithWidthsFromGridSettings = injectColumnWidthsIntoColumns();
  const reorderedColumns = reorderColumnsByGridSettings(columnsWithWidthsFromGridSettings);

  return { columns: reorderedColumns };
};
